import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';
import { Tax, TaxLocation, ApplicableTaxes } from '../models/tax';
import { ArrayWithCache } from '../models/arrayWithCache';

@Injectable({
  providedIn: 'root',
})
export class TaxesService {
  protected CACHE_TIME_ALL: number = 24 * 60 * 60 * 1000; //1 day
  protected allTaxes: Array<Tax> = [];
  protected allTaxes_cacheTime: number = 0;

  protected CACHE_TIME_PRODUCT_TAX: number = 60 * 60 * 1000; //1 hour
  protected productTax: { [productID: number]: ArrayWithCache<TaxLocation> } = {};

  constructor(protected APIService: APIService) {}

  expireCache(): void {
    //TODO this code should be part of the testing code
    this.allTaxes_cacheTime = 0;

    for (const productID in this.productTax) {
      /* istanbul ignore else */
      if (Object.prototype.hasOwnProperty.call(this.productTax, productID)) {
        this.productTax[productID].__cacheTime = 0;
      }
    }
  }

  protected filterCache: { [filter: string]: ApplicableTaxes } = {};
  filter(filter: string, processRequest: boolean = false): Promise<ApplicableTaxes> {
    const deferred = new Deferred<ApplicableTaxes>();

    if (this.allTaxes_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME_ALL) < new Date().getTime()) {
      //If cache out of date
      this.filterCache = {};
    }

    if (this.filterCache[filter]) {
      deferred.resolve(this.filterCache[filter]);
    } else {
      //TODO this could have duplicate calls but unlikely
      this.all().then((types: Array<Tax>) => {
        const filterData: ApplicableTaxes = {};
        const regexp: RegExp | null = filter ? new RegExp(filter) : null;

        for (const type of types) {
          if (regexp === null || regexp.test(type.taxName)) {
            if (!filterData[type.locationID]) {
              filterData[type.locationID] = [];
            }
            filterData[type.locationID].push(type);
          }
        }

        this.filterCache[filter] = filterData;

        deferred.resolve(filterData);
      });
    }

    if (processRequest) {
      this.APIService.processRequests();
    }

    return deferred.promise;
  }

  all(): Promise<Array<Tax>> {
    const deferred = new Deferred<Array<Tax>>();

    if (this.allTaxes_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME_ALL) > new Date().getTime()) {
      deferred.resolve(this.allTaxes);
    } else {
      this.APIService.queueRequest<Array<Tax>>({
        endPoint: 'taxes',
        method: 'all',
      })
        .then((taxes: Array<Tax>) => {
          this.allTaxes_cacheTime = new Date().getTime();
          this.allTaxes = taxes;
          deferred.resolve(this.allTaxes);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  forProduct(productID: number | null = null): Promise<Array<TaxLocation>> {
    const deferred = new Deferred<Array<TaxLocation>>();

    if (
      productID !== null && //Doesn't need to be cached since only valid from backend
      typeof this.productTax[productID] !== 'undefined' &&
      (this.productTax[productID].__cacheTime || 0) + this.APIService.getCacheTime(this.CACHE_TIME_PRODUCT_TAX) >
        new Date().getTime()
    ) {
      deferred.resolve(this.productTax[productID]);
    } else {
      this.APIService.queueRequest<Array<TaxLocation>>({
        endPoint: 'taxes',
        method: 'forProduct',
        data: {
          productID: productID,
        },
      })
        .then((taxes: ArrayWithCache<TaxLocation>) => {
          taxes.__cacheTime = new Date().getTime();
          if (productID !== null) {
            this.productTax[productID] = taxes;
          }
          deferred.resolve(taxes);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }
}
