<ng-container *ngIf="realAccount && realAccount.accountType === 'Staff' && PERMISSIONS['STAFF_TOOLS']">
  <div
    class="menuContainer"
    [hidden]="!showBar"
  >
    <ul class="menubar">
      <li>&nbsp;</li>

      <li
        class="menu"
        (mouseenter)="mouseEnter($event)"
        (mouseleave)="mouseLeave($event)"
      >
        <a>Viewing as: {{ maskAs || 'Logged Out' }}</a>
        <ul>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); viewAs(AccountType.Staff)">Staff</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); viewAs(AccountType.Wholesale)">Wholesale</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); viewAs(AccountType.Customer)">Customer</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); viewAs(false)">Logged Out</span></a>
          </li>
        </ul>
      </li>

      <li
        class="menu"
        *ngIf="PERMISSIONS['ORDERS_QUOTE'] || PERMISSIONS['ORDERS_POSSESS']"
        (mouseenter)="mouseEnter($event)"
        (mouseleave)="mouseLeave($event)"
      >
        <a [hidden]="OrdersService.isPossessing || OrdersService.isQuoting">Orders</a>
        <a [hidden]="!OrdersService.isQuoting">Quoting "{{ OrdersService.focusedOrderKey }}"</a>
        <a [hidden]="!OrdersService.isPossessing">"{{ OrdersService.focusedOrderKey }}" Order</a>
        <ul>
          <li
            class="menuItem"
            *ngIf="PERMISSIONS['ORDERS_QUOTE']"
            [hidden]="OrdersService.isPossessing || OrdersService.isQuoting"
          >
            <a><span (click)="menuItemClicked($event); startQuote()">Start Quote</span></a>
          </li>

          <!--<li class='menuItem' *ngIf='PERMISSIONS["ORDERS_POSSESS"]' [hidden]='OrdersService.isPossessing || OrdersService.isQuoting'
          ><a><span (click)='menuItemClicked($event);possessOrder();'>Load Order</span></a></li>-->

          <li
            class="menuItem"
            *ngIf="PERMISSIONS['ORDERS_QUOTE'] || PERMISSIONS['ORDERS_POSSESS']"
            [hidden]="!(OrdersService.isPossessing || OrdersService.isQuoting)"
          >
            <a><span (click)="menuItemClicked($event); emailOrder()">Send to Email</span></a>
          </li>

          <li
            class="menuItem"
            *ngIf="PERMISSIONS['ORDERS_QUOTE'] || PERMISSIONS['ORDERS_POSSESS']"
            [hidden]="!(OrdersService.isPossessing || OrdersService.isQuoting)"
          >
            <a><span (click)="menuItemClicked($event); releaseOrder()">Release</span></a>
          </li>
        </ul>
      </li>

      <li
        class="menu"
        *ngIf="PERMISSIONS['WEBSITE_DEBUG']"
        (mouseenter)="mouseEnter($event)"
        (mouseleave)="mouseLeave($event)"
      >
        <a>Debugging</a>
        <ul>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); debug_error()">Make Error</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); debug_error_x()">Make X Errors</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); cart_outofdate()">Make Cart OutofDate</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); debug_steal('order')">Steal Order</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); debug_steal('user')">Steal User</span></a>
          </li>
          <li class="menuItem">
            <a><span (click)="menuItemClicked($event); debug_drop_all_orders()">Drop All Orders</span></a>
          </li>
        </ul>
      </li>

      <li>&nbsp;</li>
    </ul>
  </div>
  <div
    class="menuSpacer"
    [hidden]="!showBar"
  >
    &nbsp;
  </div>
</ng-container>
