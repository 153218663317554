<div class="pageContent">
  <div
    class="prompt"
    [innerHTML]="params.message"
  ></div>
  <input
    [hidden]="!(params.input === PromptType.Text)"
    type="text"
    class="input"
    [style.width]="params.width"
    [(ngModel)]="textInput"
  />
  <div class="validation">{{ validationMessage }}</div>
  <button
    type="button"
    class="no"
    [hidden]="!params.no"
    (click)="no()"
  >
    {{ params.no }}
  </button>
  <button
    type="button"
    class="yes"
    (click)="yes()"
  >
    {{ params.yes }}
  </button>
</div>
