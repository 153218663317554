import { Component, OnInit, OnDestroy } from '@angular/core';

import { WholesalePageResolver } from './wholesale.resolver';
import { WholesalePage } from './wholesale.model';

import { APIService } from '../../common/services/api.service';

import { Route, ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { UserAccount } from '../../common/models/account';
import { GlobalErrorHandler } from 'src/common/services/globalErrorHandler';

@Component({
  selector: 'ccf-wholesale',
  templateUrl: 'wholesale.component.html',
  styleUrls: ['wholesale.component.less'],
  providers: [],
})
//TODO file name wrong for class
export class WholesalePageComponent implements OnInit, OnDestroy {
  protected _subscriptions: Subscription[] = [];
  loggedIn!: boolean;

  constructor(
    private APIService: APIService,
    private ActivatedRoute: ActivatedRoute,
    private Router: Router,
    private ErrorHandler: GlobalErrorHandler,
  ) {}

  ngOnInit() {
    this._subscriptions.push(
      this.APIService.authenticatedDataStream().subscribe((userData?: UserAccount | null) => {
        if (typeof userData !== 'undefined') {
          const loggedIn: boolean = userData !== null;

          if (this.loggedIn && !loggedIn) {
            this.Router.navigateByUrl('/');
          }

          this.loggedIn = loggedIn;
        }
      }),
    );

    this._subscriptions.push(
      this.ActivatedRoute.data.subscribe((data) => {
        if (data['page']) {
          const page: WholesalePage = data['page'];
          this.loggedIn = page.loggedIn;

          if (!this.loggedIn || !page.isWholesale) {
            this.Router.navigateByUrl('/Page-Not-Found', {
              skipLocationChange: true,
            });
            this.ErrorHandler.reportError(new Error('TODO this code should not be triggered, remove'), undefined, true);
          }
        }
      }),
    );

    this.APIService.processRequests();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

export const PageRoute: Route = {
  path: 'Wholesale',
  component: WholesalePageComponent,
  pathMatch: 'full',
  resolve: {
    page: WholesalePageResolver,
  },
  data: {
    title: 'Wholesale',
  },
};
