<div class="pageContent">
  <div class="innerContent">
    <h1>Welcome to the Wholesale Division!</h1>

    <ccf-customer-page-menu page="home"></ccf-customer-page-menu>

    <p>
      Although the website may look the same, the prices shown reflect the Wholesale price
      <span class="smallText">(no further discounts applicable)</span>.
    </p>

    <h2 class="centered">All orders are to be placed through the Wholesale Shopping Cart.</h2>

    <p>
      Order minimums ($300.00) will be easier to calculate &amp; your costs are known before you order the product.
      Ordering online will help cut down on a lot of questions &amp; confusion, as well as ordering errors &mdash; such
      as wrong product names, packaging details required, etc.
    </p>

    <p>
      We continually update our website &amp; this is the best way to find out information on products. This includes
      Piece or Pack Sizes, Prices, Availability &amp; what’s new. Fragile items are also clearly marked &amp; will be at
      your risk when shipping.
    </p>

    <p class="smallText">
      Please note: Prices for Seasonal &amp;/or Bulk products will only be available on the website.
    </p>

    <p>
      Shipping charges (if applicable) will be added to the invoice as usual. A final invoice will be created once the
      product is pulled, packed &amp; ready to go.
    </p>

    <p class="smallText centered">
      The Estimated Total on your cart is NOT final - please pay the total off your Invoice.
    </p>

    <p>
      If you have any questions we are still available by phone: 604-437-8221 or email:
      <ccf-email-link emailAddress="Orders"></ccf-email-link>.
    </p>

    <p>We appreciate your business!</p>
  </div>
</div>
