export interface AutocompleteSourceRequest {
  term: string;
}
export type AutocompleteSourceCallback<T> = (values: AutocompleteItem<T>[] | T[]) => void;

export type AutocompleteSource<T> = (req: AutocompleteSourceRequest, res: AutocompleteSourceCallback<T>) => void;

declare global {
  interface JQuery {
    autocomplete<T>(options: AutocompleteOptions<T>): JQuery;

    data<T = unknown>(key: string): T | undefined;
  }
}

// reference https://api.jqueryui.com/autocomplete/
export interface UIAutocompleteData<T> {
  // Missing lots of properties
  search(value?: string): JQuery;
  _renderItem?: (ul: JQuery<HTMLUListElement>, item: AutocompleteItem<T>) => JQuery<HTMLLIElement>;
}

// Better Typing of JQueryUI.AutocompleteEvent
export type AutocompleteEvent<T> = (event: JQuery.Event, ui: AutocompleteUIParams<T>) => void;

// Better Typing of JQueryUI.AutocompleteEvents
export interface AutocompleteEvents<T> {
  change?: AutocompleteEvent<T>;
  close?: AutocompleteEvent<T>;
  create?: AutocompleteEvent<T>;
  focus?: AutocompleteEvent<T>;
  open?: AutocompleteEvent<T>;
  response?: AutocompleteEvent<T>;
  search?: AutocompleteEvent<T>;
  select?: AutocompleteEvent<T>;
}

// Better Typing of JQueryUI.AutocompleteOptions
export interface AutocompleteOptions<T> extends AutocompleteEvents<T> {
  appendTo?: string;
  autoFocus?: boolean;
  delay?: number;
  disabled?: boolean;
  minLength?: number;
  position?: unknown; // object
  source?: AutocompleteSource<T>;
  classes?: JQueryUI.AutocompleteClasses;

  renderItem?: (item: AutocompleteItem<T>) => string;
}

export interface AutocompleteItem<T> {
  label: string;
  value: T;
}

export interface AutocompleteUIParams<T> extends JQueryUI.AutocompleteUIParams {
  item: AutocompleteItem<T>;
}

export const BuildJQueryAutocomplete = <T>(
  element: JQuery<HTMLInputElement>,
  options: AutocompleteOptions<T>,
): UIAutocompleteData<T> => {
  element.autocomplete(options as unknown as JQueryUI.AutocompleteOptions);

  const jQueryAutocomplete = element.data<UIAutocompleteData<T>>('ui-autocomplete');
  if (typeof jQueryAutocomplete === 'undefined') {
    throw new Error('data("ui-autocomplete") should not be undefined right after creation');
  }

  if (typeof options.renderItem === 'function') {
    // eslint-disable-next-line no-underscore-dangle
    jQueryAutocomplete._renderItem = (ul: JQuery<HTMLUListElement>, item: AutocompleteItem<T>) => {
      return jQuery<HTMLLIElement>('<li></li>')
        .data('item.autocomplete', item)
        .append((options.renderItem && options.renderItem(item)) || '')
        .appendTo(ul);
    };
  }

  return jQueryAutocomplete;
};
