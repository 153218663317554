import { Big } from 'big.js';

import { APIOrderSheetItem } from '../checkout';
import { BaseOrderItem } from './base';
import { OrderSheetOrder } from '../order/ordersheet';
import { cloneDeep } from 'lodash-es';

export class OrderSheetOrderItem extends BaseOrderItem implements APIOrderSheetItem<Big> {
  webName: string | null = null;
  productID: number | null = null;
  productName: string | null = null;
  description: string | null = null;

  typeID: number | null = null;
  typeName: string | null = null;

  unitID: number | null = null;
  unitName: string | null = null;

  constructor(
    protected override parent: OrderSheetOrder,
    protected override data?: APIOrderSheetItem,
  ) {
    super(parent, data);
    if (data) {
      this.refreshed(data);
    }
  }

  override refreshed(item: APIOrderSheetItem): void {
    if (item.description) {
      this.description = item.description;
    }

    if (item.typeID) {
      this.typeID = item.typeID;

      if (typeof item.typeName !== 'undefined') {
        this.typeName = item.typeName;
      }
    }

    if (typeof item.priceDiscountable !== 'undefined') {
      this.priceDiscountable = item.priceDiscountable;
    }
    if (typeof item.productDiscountable !== 'undefined') {
      this.productDiscountable = item.productDiscountable;
    }
    if (typeof item.inDiscountableCategory !== 'undefined') {
      this.inDiscountableCategory = item.inDiscountableCategory;
    }

    this.productID = item.productID;
    this.productName = item.productName;

    this.unitID = item.unitID;
    this.unitName = item.unitName;

    if (item.productName) {
      this.webName = item.productName; //TODO what to do when too long (applies to other paypal fields with length like name)

      if (item.unitName !== 'Item') {
        this.webName += ' (' + item.unitName + ')';
      }

      if (item.typeID) {
        this.webName += ' (' + item.typeName + ')';
      }

      this.webName = this.webName.replace(/&/g, 'and');
    }

    if (typeof item.price !== 'undefined') {
      this.price = item.price ? new Big(item.price) : null;
    }
    if (typeof item.onsale !== 'undefined') {
      this.onsale = item.onsale ? new Big(item.onsale) : null;
    }
    if (typeof item.taxes !== 'undefined') {
      this.taxes = item.taxes;
    }

    super.refreshed(item);
  }

  override export(): Record<string, unknown> {
    const exportData: Record<string, unknown> = super.export();

    exportData['description'] = this.description;

    exportData['typeID'] = this.typeID;
    exportData['typeName'] = this.typeName;

    exportData['productID'] = this.productID;
    exportData['productName'] = this.productName;

    exportData['unitID'] = this.unitID;
    exportData['unitName'] = this.unitName;

    exportData['webName'] = this.webName;

    exportData['price'] = this.price ? new Big(this.price).toFixed(2) : null;
    exportData['onsale'] = this.onsale ? new Big(this.onsale).toFixed(2) : null;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }
}
