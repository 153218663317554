import { enumKeys } from '../helpers/enum';
import { Big } from 'big.js';
import { BaseOrderItem } from './orderItem/base';
import { BaseOrder } from './order/base';

export interface Discount<O extends BaseOrder<BaseOrderItem> = any, I extends BaseOrderItem = any> {
  name: string;
  shortName: string;
  marker: string;

  target?: DiscountTarget;
  targetFunc?: (self: Discount) => void; // TODO unsure if i like the name

  effect?: DiscountEffect;
  effectFunc?: (self: Discount, item: I) => Big; // TODO unsure if i like the name

  amount: Big;
  rate: string;
  total: Big;

  message: string;

  active?: boolean;

  // TODO this is related to discount codes but those aren't implemented
  overridden?: boolean;
  overriddenMessage?: string;
}

export interface APIDiscount {
  discountID: number | null;
  enabled: boolean;
  code: string;
  target: DiscountTarget | null;
  effect: DiscountEffect | null;
  effectAmount: string;
  min: string;
  message: string;
}

export enum DiscountTarget {
  'All Products' = 1,
  'Discountable Products' = 2,
}
export const DiscountTarget_keys = enumKeys(DiscountTarget);

export enum DiscountEffect {
  'Volume' = 1, // TODO remove this ? its the same as subtotal % reduction
  'Free Freight' = 2,
  'Subtotal % Reduction' = 3,
  'Subtotal Flat Reduction' = 4,
}
export const DiscountEffect_keys = [
  // DiscountEffect[1] // This is because volume isn't selectable
  DiscountEffect[2],
  DiscountEffect[3],
  DiscountEffect[4],
];
