import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Page, PageThumbnails } from '../models/search';
import { Section } from '../models/section';
import { ArrayWithCache } from '../models/arrayWithCache';
import { Deferred } from '../helpers/deferred';

import { DiabeticOptions, SearchStatsModeID, Word, SearchEntry, SearchURI } from '../models/search';
import {
  AutocompleteEvent,
  AutocompleteSourceCallback,
  AutocompleteSourceRequest,
  BuildJQueryAutocomplete,
} from '../models/jquery';

export const sortSuggestions = (a: KeywordSuggestion, b: KeywordSuggestion): number => {
  if (a.keyword < b.keyword) {
    return -1;
  } else if (a.keyword > b.keyword) {
    return 1;
  } else {
    return 0;
  }
};

export interface KeywordSuggestion {
  keyword: string;
  reason?: string;
}

export interface WordCorrections {
  [word: string]: string[];
}

export interface DataDump<T> {
  items: T;
  total: number;
}

export interface PageSaveData {
  uriID: number | null;
  uri: string;
  title: string;
  rawKeywords: string;
  description: string;
  newImage: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  protected CACHE_TIME: number = 10 * 60 * 1000; //10 minutes
  protected searchSuggestionsResults: {
    [word: string]: ArrayWithCache<string>;
  } = {};
  protected pages: { [searchKey: string]: ArrayWithCache<Page> } = {};

  private minLength: number = 3;

  constructor(protected APIService: APIService) {}

  expireCache() {
    for (const word in this.searchSuggestionsResults) {
      /* istanbul ignore else */
      if (Object.prototype.hasOwnProperty.call(this.searchSuggestionsResults, word)) {
        this.searchSuggestionsResults[word].__cacheTime = 0;
      }
    }

    for (const key in this.pages) {
      /* istanbul ignore else */
      if (Object.prototype.hasOwnProperty.call(this.pages, key)) {
        this.pages[key].__cacheTime = 0;
      }
    }
  }

  encodeCharliesURI(data: string): string {
    return encodeURIComponent(data.trim()).replace(/-/g, '–').replace(/%20/g, '-'); //TODO strip bad chars
  }

  decodeCharliesURI(data: string): string {
    return decodeURIComponent(data.replace(/-/g, '%20')).replace(/–/g, '-');
  }

  buildSearchURI(
    searchText: string = '',
    searchSection: Section | null = null,
    diabetic: DiabeticOptions = 'Both',
    similar: boolean = false,
    byPassEncode: boolean = false,
  ) {
    let URI = '/Search';
    if (searchText !== '') {
      URI += '/' + (!byPassEncode ? this.encodeCharliesURI(searchText) : searchText);
    }
    if (similar) {
      URI += '/similar';
    }
    if (searchSection !== null) {
      URI += '/' + searchSection.urlName;
    }
    if (diabetic !== 'Both') {
      URI += '/' + diabetic;
    }

    return URI;
  }

  bindAutocomplete(element: JQuery<HTMLInputElement>, selectFunc: AutocompleteEvent<string>) {
    return BuildJQueryAutocomplete<string>(element, {
      autoFocus: true,
      delay: 250,
      minLength: this.minLength,
      source: (req: AutocompleteSourceRequest, res: AutocompleteSourceCallback<string>) => {
        const text = req.term.trim().toLowerCase();
        const searchWords: string[] = text.split(' ');
        const lastWord = searchWords[searchWords.length - 1];
        // let otherWords = ''; //TODO why is this unused

        // if (searchWords.length > 1) {
        //   otherWords =
        //     searchWords.slice(0, searchWords.length - 1).join(' ') + ' ';
        // }

        this.searchSuggestions(lastWord)
          .then((results: string[]) => {
            res(results);
          })
          .catch((reason: unknown) => {
            //TODO something ?
            res([]);
          });

        this.APIService.processRequests();
      },
      select: selectFunc,
    });
  }

  searchSuggestions(word: string) {
    const result = new Deferred<Array<string>>();

    if (
      typeof this.searchSuggestionsResults[word] !== 'undefined' &&
      (this.searchSuggestionsResults[word].__cacheTime || 0) + this.APIService.getCacheTime(this.CACHE_TIME) >
        new Date().getTime()
    ) {
      result.resolve(this.searchSuggestionsResults[word]);
    } else {
      this.APIService.queueRequest<Array<string>>({
        endPoint: 'search',
        method: 'suggestions',
        data: {
          word: word,
        },
      })
        .then((words: ArrayWithCache<string>) => {
          words.__cacheTime = new Date().getTime();

          this.searchSuggestionsResults[word] = words;

          result.resolve(words);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }

    return result.promise;
  }

  searchCorrections(searchText: string) {
    const result = new Deferred<WordCorrections>();
    const wordCorrections: WordCorrections = {};

    const words = searchText.trim().split(' ');

    const promises: Promise<unknown>[] = [];
    for (const word of words) {
      if (word.length < this.minLength) {
        //pass;
      } else if (
        typeof this.searchSuggestionsResults[word] !== 'undefined' &&
        (this.searchSuggestionsResults[word].__cacheTime || 0) + this.APIService.getCacheTime(this.CACHE_TIME) >
          new Date().getTime()
      ) {
        if (
          this.searchSuggestionsResults[word].length >= 1 &&
          this.searchSuggestionsResults[word][0] === word.toLocaleLowerCase()
        ) {
          //pass;
        } else {
          wordCorrections[word] = this.searchSuggestionsResults[word];
        }
      } else {
        promises.push(
          this.APIService.queueRequest<Array<string>>({
            endPoint: 'search',
            method: 'suggestions',
            data: {
              word: word,
            },
          }).then((words: ArrayWithCache<string>) => {
            words.__cacheTime = new Date().getTime();

            this.searchSuggestionsResults[word] = words;

            if (
              this.searchSuggestionsResults[word].length >= 1 &&
              this.searchSuggestionsResults[word][0] === word.toLocaleLowerCase()
            ) {
              //pass
            } else {
              wordCorrections[word] = this.searchSuggestionsResults[word];
            }
          }),
        );
      }
    }

    Promise.all(promises)
      .then(() => {
        result.resolve(wordCorrections);
      })
      .catch((reason: unknown) => {
        result.reject(reason);
        //TODO should do something globally ?
      });

    return result.promise;
  }

  searchPage(words: string) {
    const result = new Deferred<Array<Page>>();

    words = words.trim();
    if (words.length === 0) {
      result.resolve([]);
    } else {
      const wordsList = words.toLowerCase().split(' ');
      wordsList.sort();
      const wordKey = wordsList.join(' ');

      if (
        typeof this.pages[wordKey] !== 'undefined' &&
        (this.pages[wordKey].__cacheTime || 0) + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
      ) {
        result.resolve(this.pages[wordKey]);
      } else {
        this.APIService.queueRequest<Array<Page>>({
          endPoint: 'search',
          method: 'pages',
          data: {
            searchText: words,
          },
        }).then((pages: ArrayWithCache<Page>) => {
          pages.__cacheTime = new Date().getTime();

          for (const page of pages) {
            page.imgSrc = '/images/dynamic/pageThumbnails/' + page.uriID + '.png';
          }

          this.pages[wordKey] = pages;

          result.resolve(this.pages[wordKey]);
        });
      }
    }

    return result.promise;
  }

  async log(
    words: string,
    searchSection: Section | null = null,
    diabetic: DiabeticOptions = 'Both',
    similar: boolean = false,
  ): Promise<boolean> {
    words = words.trim();
    if (words.length > 0) {
      let sectionID: number | null = null;
      if (searchSection !== null) {
        sectionID = searchSection.sectionID;
      }

      return this.APIService.queueRequest<boolean>({
        endPoint: 'search',
        method: 'log',
        data: {
          searchText: words,
          sectionID: sectionID,
          diabetic: diabetic,
          similar: similar,
        },
      });
    }
    return false;
  }

  countUsage(rawKeywords: string, target: string) {
    const deferred = new Deferred<number>();

    this.APIService.queueRequest<number>({
      //TODO error message ?
      endPoint: 'search',
      method: 'countUsage',
      data: {
        rawKeywords: rawKeywords,
        target: target,
      },
    })
      .then((result: number) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  addKeyword(mode: SearchStatsModeID, rawKeywords: string, keyword: string, target: string) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      //TODO error message ?
      endPoint: 'search',
      method: 'addKeyword',
      data: {
        mode: mode,
        rawKeywords: rawKeywords,
        keyword: keyword,
        target: target,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getUnused(page: string, perPage: number, days: number, sorting: string, searchText: string) {
    const deferred = new Deferred<DataDump<Array<Word>>>();

    this.APIService.queueRequest<DataDump<Array<Word>>>({
      //TODO error message ?
      endPoint: 'search',
      method: 'getUnused',
      data: {
        page: page,
        perPage: perPage,
        days: days,
        sorting: sorting,
        searchText: searchText,
      },
    })
      .then((result: DataDump<Array<Word>>) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getUsed(page: string, perPage: number, sorting: string, searchText: string) {
    const deferred = new Deferred<DataDump<Array<Word>>>();

    this.APIService.queueRequest<DataDump<Array<Word>>>({
      //TODO error message ?
      endPoint: 'search',
      method: 'getUsed',
      data: {
        page: page,
        perPage: perPage,
        sorting: sorting,
        searchText: searchText,
      },
    })
      .then((result: DataDump<Array<Word>>) => {
        for (const item of result.items) {
          item.searchLink = SearchURI + '/' + this.encodeCharliesURI(item.keyword);
        }

        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getSearches(page: string, perPage: number, days: number) {
    const deferred = new Deferred<DataDump<Array<SearchEntry>>>();

    this.APIService.queueRequest<DataDump<Array<SearchEntry>>>({
      //TODO error message ?
      endPoint: 'search',
      method: 'getSearches',
      data: {
        page: page,
        perPage: perPage,
        days: days,
      },
    })
      .then((result: DataDump<Array<SearchEntry>>) => {
        for (const item of result.items) {
          item.searchLink = SearchURI + '/' + this.encodeCharliesURI(item.rawSearch);

          if (item.similar) {
            item.searchLink += '/similar';
          }

          if (item.sectionID) {
            item.searchLink += '/' + item.sectionName;
          }

          if (item.diabetic !== null) {
            item.searchLink += '/' + (item.diabetic ? 'Diabetic' : 'Regular');
          }
        }

        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  removeUnused(keyword: string) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      //TODO error message ?
      endPoint: 'search',
      method: 'removeUnused',
      data: {
        keyword: keyword,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  removeUsed(keyword: string) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      //TODO error message ?
      endPoint: 'search',
      method: 'removeUsed',
      data: {
        keyword: keyword,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  allPages() {
    const deferred = new Deferred<Array<Page>>();

    this.APIService.queueRequest<Array<Page>>({
      //TODO error message ?
      endPoint: 'pages',
      method: 'uris',
    })
      .then((result: Array<Page>) => {
        const now = new Date();
        for (const page of result) {
          page.imgSrc = PageThumbnails(page.uriID!, now);
        }

        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getPage(uriID: number) {
    const deferred = new Deferred<Page>();

    this.APIService.queueRequest<Page>({
      //TODO error message ?
      endPoint: 'pages',
      method: 'uri',
      data: {
        uriID: uriID,
      },
    })
      .then((page: Page) => {
        const now = new Date();
        page.imgSrc = PageThumbnails(page.uriID!, now);

        deferred.resolve(page);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  savePage(data: PageSaveData) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      //TODO error message ?
      endPoint: 'pages',
      method: 'save',
      data: {
        uriID: data.uriID,
        title: data.title,
        uri: data.uri,
        description: data.description,
        rawKeywords: data.rawKeywords,
        newImage: data.newImage,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  removePage(page: Page) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      //TODO error message ?
      endPoint: 'pages',
      method: 'delete',
      data: {
        uriID: page.uriID,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
