import { Big } from 'big.js';

import { OrderType, DeliveryMethod, APIFountainOrder, FountainSize } from '../checkout';

import { Tax, ApplicableTaxes } from '../tax';
import { BaseOrder, DiscountSubtotals, OrderResponseObject } from './base';
import { FountainOrderItem } from '../orderItem/fountain';
import { Deferred } from '../../helpers/deferred';
import { cloneDeep } from 'lodash-es';

// TODO centralize
export const FOUNTAIN = { SMALL: '170', LARGE: '200' };
export const BUCKET = { '2lb': '30', '10lb': '130', '15lb': '200' };
export const DELIVERY_COST = '70';

export class FountainOrder extends BaseOrder<FountainOrderItem> implements APIFountainOrder<Big> {
  type = OrderType.Fountain as const;

  fountainSize: FountainSize | null = null;
  extraBucket: boolean = false;
  setup: boolean | null = false;
  chocolateTypeID: number | null = null;

  override isWholesale = false as const;

  //TODO somewhere force shipping delivery to vancouver only

  override reset(): void {
    super.reset();

    this.fountainSize = null;
    this.extraBucket = false;
    this.setup = null;
    this.chocolateTypeID = null;
  }

  protected override async preCalc(): Promise<void> {
    this.items = [];

    const taxPromises: Promise<unknown>[] = [];

    let GST!: ApplicableTaxes;
    taxPromises.push(
      this.dataLayer.tax.filter('^GST$').then((allGST: ApplicableTaxes) => {
        GST = allGST;
      }),
    );

    let TAXES!: ApplicableTaxes;
    taxPromises.push(
      this.dataLayer.tax.filter('').then((allTaxes: ApplicableTaxes) => {
        TAXES = allTaxes;
      }),
    );

    this.dataLayer.processRequests();
    await Promise.all(taxPromises);

    if (this.fountainSize === FountainSize.Small) {
      this.items.push(
        new FountainOrderItem(this, {
          key: 'fountain',
          quantity: 1,
          price: FOUNTAIN.SMALL,
          taxes: TAXES,
        }),
      );

      this.items.push(
        new FountainOrderItem(this, {
          key: 'bucket',
          quantity: 1,
          price: BUCKET['10lb'],
          taxes: GST,
        }),
      );
    } else if (this.fountainSize === FountainSize.Large) {
      this.items.push(
        new FountainOrderItem(this, {
          key: 'fountain',
          quantity: 1,
          price: FOUNTAIN.LARGE,
          taxes: TAXES,
        }),
      );

      this.items.push(
        new FountainOrderItem(this, {
          key: 'bucket',
          quantity: 1,
          price: BUCKET['15lb'],
          taxes: GST,
        }),
      );
    }

    if (this.extraBucket) {
      this.items.push(
        new FountainOrderItem(this, {
          key: 'extraBucket',
          quantity: 1,
          price: BUCKET['10lb'],
          taxes: GST,
        }),
      );
    }

    if (this.setup) {
      // TODO this isn't enabled in the UI anymore
      this.items.push(
        new FountainOrderItem(this, {
          key: 'setup',
          quantity: 1,
          price: '20',
          taxes: GST,
        }),
      );
    }
  }

  protected override async calcFreight(subtotal: Big, itemDiscount: Big, saleTotal: Big): Promise<void> {
    const allPromises: Promise<unknown>[] = [];

    if (
      this.deliveryInfo.method === DeliveryMethod.Ship // &&
      //this.deliveryInfo.postalCode && this.deliveryInfo.country === 'Canada'
    ) {
      allPromises.push(
        this.dataLayer.tax.filter('^GST$').then((allGST: ApplicableTaxes) => {
          const locationBC = 1;
          const GST: Tax = allGST[locationBC][0];

          this.totalFreight = new Big(DELIVERY_COST);
          this.freightTaxRate = new Big(GST.taxAmount).div(100);
          this.freightTaxName = GST.taxName;
        }),
      );
    }
    this.dataLayer.processRequests();
    await Promise.all(allPromises);
  }

  override async parse(
    data: OrderResponseObject<APIFountainOrder>,
    calledFrom: string,
    allDone?: Promise<void>,
  ): Promise<void> {
    const doneHere = new Deferred<void>();

    await super.parse(data, calledFrom, doneHere.promise);

    this.freeze();

    if (data.order) {
      const order = data.order;

      if (typeof order.payment !== 'undefined') {
        this.payment = order.payment;
      }
      if (typeof order.fountainSize !== 'undefined') {
        this.fountainSize = order.fountainSize;
      }
      if (typeof order.extraBucket !== 'undefined') {
        this.extraBucket = order.extraBucket;
      }
      if (typeof order.setup !== 'undefined') {
        this.setup = order.setup;
      }
      if (typeof order.chocolateTypeID !== 'undefined') {
        this.chocolateTypeID = order.chocolateTypeID;
      }
    }

    this.unfreeze();

    const done = async () => {
      await this.calculate();

      doneHere.resolve();
    };

    if (allDone) {
      allDone.then(() => {
        done();
      });
    } else {
      await done();
    }
  }

  override export() {
    const exportData = super.export();

    exportData['payment'] = this.payment;
    exportData['fountainSize'] = this.fountainSize;
    exportData['extraBucket'] = this.extraBucket;
    exportData['setup'] = this.setup;
    exportData['chocolateTypeID'] = this.chocolateTypeID;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }
  //protected async calcLocation(): Promise<void>;

  //protected async postCalc(): Promise<void>

  protected override async getDiscounts(discountSubtotals: DiscountSubtotals): Promise<void> {
    return;
  }

  override updateEstimation(): void {
    this.isEstimated = true;
  }

  protected newOrderItem(): FountainOrderItem {
    return new FountainOrderItem(this);
  }
}
