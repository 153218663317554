/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse } from '../services/api.service';
import { betterStringify } from '../helpers/betterStringify';

declare global {
  interface Window {
    dataLayer: Array<any>;
    CCF: CCFGlobals;
    APP_STAT: number;
    API_PRELOAD?: APIResponse;
    gtag: (...args: any[]) => void;
    betterStringify: typeof betterStringify;
    reportError: (error: Error | string, comment?: string, silent?: boolean) => void;
    _pendingErrors: Array<[error: Error | string, comment?: string, silent?: boolean]>;
    html2canvas: any;
    DD_RUM: any;
    DD_LOGS: any;
    isDev?: boolean;
  }

  interface Worker {
    CCF: CCFGlobals;
  }

  interface Navigator {
    readonly clipboard: Clipboard;
  }
}

window.betterStringify = betterStringify; //TODO i don't think we need this on window anymore

if (!self.CCF) {
  self.CCF = {};
}

export interface CCFGlobals {
  supportsPassiveListeners?: boolean;
  scrollTo?: number;
  LOADED?: boolean;
}
