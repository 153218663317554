import { Big } from 'big.js';

import { makeProductURL } from '../../helpers/urlFunctions';
import { APICartItem } from '../checkout';
import { BaseOrderItem } from './base';
import { CartOrder } from '../order/cart';
import { Status } from '../product';
import { cloneDeep } from 'lodash-es';
import { StockStatus } from '../type';

export class CartOrderItem extends BaseOrderItem implements APICartItem<Big> {
  webName: string | null = null;
  productID: number | null = null;
  productName: string | null = null;
  productURI: string | null = null;
  shortDescription: string | null = null;
  description: string | null = null;
  isDiabetic: boolean | null = null;
  productStatus: Status | null = null;

  thumbnail: string | null = null;

  priceStatus: Status | null = null;

  minMax: number | null = null;

  typeID: number | null = null;
  typeName: string | null = null;
  chocolateStockStatus: StockStatus | null = null;
  chocolateTypeStatus: Status | null = null;
  chocolateMinMax: number | null = null;

  unitID: number | null = null;
  unitName: string | null = null;

  packagingTypeID: number | null = null;
  packagingTypeName: string | null = null;
  packagingSubType: string | null = null;
  packagingTypeStatus: Status | null = null;

  customizationTypeID: number | null = null;
  customizationTypeName: string | null = null;
  customizationSubType: string | null = null;
  customizationTypeStatus: Status | null = null;

  categoriesIn: number[] = [];

  foundIn: string | null = null;

  constructor(
    protected override parent: CartOrder,
    protected override data?: APICartItem,
  ) {
    super(parent, data);
    if (data) {
      this.refreshed(data);
    }
  }

  override refreshed(item: APICartItem): void {
    if (item.description) {
      this.description = item.description;
    }

    if (typeof item.productStatus !== 'undefined') {
      this.productStatus = item.productStatus;
    }
    if (typeof item.priceStatus !== 'undefined') {
      this.priceStatus = item.priceStatus;
    }

    if (item.typeID) {
      this.typeID = item.typeID;
      this.typeName = item.typeName!;
      this.chocolateTypeStatus = item.chocolateTypeStatus;
      this.chocolateStockStatus = item.chocolateStockStatus;
      this.chocolateMinMax = item.chocolateMinMax;
    }

    if (typeof item.priceDiscountable !== 'undefined') {
      this.priceDiscountable = item.priceDiscountable;
    }
    if (typeof item.productDiscountable !== 'undefined') {
      this.productDiscountable = item.productDiscountable;
    }
    if (typeof item.inDiscountableCategory !== 'undefined') {
      this.inDiscountableCategory = item.inDiscountableCategory;
    }

    if (typeof item.minMax !== 'undefined') {
      this.minMax = item.minMax;
    }

    this.productID = item.productID;
    this.productName = item.productName;

    if (this.productID && this.productName) {
      this.productURI = makeProductURL(this.productName, this.productID);
    }
    this.shortDescription = item.shortDescription;
    this.isDiabetic = item.isDiabetic;
    this.isFragile = item.isFragile;

    this.thumbnail = item.thumbnail;

    this.unitID = item.unitID;
    this.unitName = item.unitName;

    if (item.packagingTypeID) {
      this.packagingTypeID = item.packagingTypeID;
      this.packagingTypeName = item.packagingTypeName!;
      this.packagingSubType = item.packagingSubType!;
      this.packagingTypeStatus = item.packagingTypeStatus;
    }

    if (item.customizationTypeID) {
      this.customizationTypeID = item.customizationTypeID;
      this.customizationTypeName = item.customizationTypeName!;
      this.customizationSubType = item.customizationSubType!;
      this.customizationTypeStatus = item.customizationTypeStatus;
    }

    if (item.foundIn) {
      this.foundIn = item.foundIn;
    }

    if (typeof item.categoriesIn !== 'undefined') {
      if (item.categoriesIn === null) {
        this.categoriesIn = [];
      } else {
        this.categoriesIn = item.categoriesIn;
      }
    }

    if (item.productName) {
      this.webName = item.productName; //TODO what to do when too long (applies to other paypal fields with length like name)

      if (item.unitName !== 'Item') {
        this.webName += ' (' + item.unitName + ')';
      }

      if (item.typeID) {
        this.webName += ' (' + item.typeName + ')';
      }

      if (item.packagingTypeName) {
        this.webName += ' (' + item.packagingTypeName;

        if (item.packagingSubType) {
          this.webName += ' - ' + item.packagingSubType;
        }
        this.webName += ')';
      }

      if (item.customizationTypeName) {
        this.webName += ' (' + item.customizationTypeName;

        if (item.customizationSubType) {
          this.webName += ' - ' + item.customizationSubType;
        }
        this.webName += ')';
      }

      this.webName = this.webName.replace(/&/g, 'and');
    }

    if (typeof item.price !== 'undefined') {
      this.price = item.price ? new Big(item.price) : null;
    }
    if (typeof item.onsale !== 'undefined') {
      this.onsale = item.onsale ? new Big(item.onsale) : null;
    }
    if (typeof item.taxes !== 'undefined') {
      this.taxes = item.taxes;
    }

    super.refreshed(item); //TODO move to top imo
  }

  override export() {
    const exportData = super.export();

    exportData['description'] = this.description;

    exportData['productStatus'] = this.productStatus;
    exportData['priceStatus'] = this.priceStatus;

    exportData['typeID'] = this.typeID;
    exportData['typeName'] = this.typeName;
    exportData['chocolateTypeStatus'] = this.chocolateTypeStatus;
    exportData['chocolateStockStatus'] = this.chocolateStockStatus;

    exportData['productID'] = this.productID;
    exportData['productName'] = this.productName;

    exportData['productURI'] = this.productURI;

    exportData['shortDescription'] = this.shortDescription;
    exportData['isDiabetic'] = this.isDiabetic;
    exportData['isFragile'] = this.isFragile;
    exportData['minMax'] = this.minMax;

    exportData['thumbnail'] = this.thumbnail;

    exportData['unitID'] = this.unitID;
    exportData['unitName'] = this.unitName;

    exportData['packagingTypeID'] = this.packagingTypeID;
    exportData['packagingTypeName'] = this.packagingTypeName;
    exportData['packagingSubType'] = this.packagingSubType;
    exportData['packagingTypeStatus'] = this.packagingTypeStatus;

    exportData['customizationTypeID'] = this.customizationTypeID;
    exportData['customizationTypeName'] = this.customizationTypeName;
    exportData['customizationSubType'] = this.customizationSubType;
    exportData['customizationTypeStatus'] = this.customizationTypeStatus;

    exportData['foundIn'] = this.foundIn;

    exportData['categoriesIn'] = this.categoriesIn;

    exportData['webName'] = this.webName;

    exportData['price'] = this.price ? new Big(this.price).toFixed(2) : null;
    exportData['onsale'] = this.onsale ? new Big(this.onsale).toFixed(2) : null;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }
}
