<div class="search searchBar">
  <form (submit)="doSearch()">
    <input
      class="searchInput searchText"
      spellcheck="true"
      autocomplete="off"
      list="headerSearchSuggestions"
      [(ngModel)]="searchText"
      name="searchText"
    />
    <button
      type="submit"
      class="searchButton"
    >
      Search
    </button>
  </form>
</div>
