import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { JobsService } from '../../common/services/jobs.service';
import { APIService } from '../../common/services/api.service';

import { JobsPage } from './jobsPage.model';
import { UserAccount, AccountType } from '../../common/models/account';

@Injectable({
  providedIn: 'root',
})
export class JobsPageResolver implements Resolve<JobsPage> {
  constructor(
    private JobsService: JobsService,
    private APIService: APIService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<JobsPage> {
    const _jobs = this.JobsService.getJobs();
    const _isStaff = this.APIService.authenticatedData().then((userData?: UserAccount | null) => {
      return !!(userData?.accountType === AccountType.Staff);
    });

    this.APIService.processRequests();

    return {
      jobs: await _jobs,
      isStaff: await _isStaff,
    };
  }
}
