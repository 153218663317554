import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';
import { HolidayHoursImage, HolidayHoursPath } from '../models/content';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  protected CACHE_TIME: number = 10 * 60 * 1000; //10 mins
  protected cacheTime_hoursImages: number = 0;

  protected hoursImages: Array<HolidayHoursImage> | null = null;

  constructor(protected APIService: APIService) {}

  expireCache() {
    this.cacheTime_hoursImages = 0;
  }

  getHolidayHoursImages() {
    const deferred = new Deferred<Array<HolidayHoursImage>>();

    if (
      this.hoursImages !== null &&
      this.cacheTime_hoursImages + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
    ) {
      deferred.resolve(this.hoursImages);
    } else {
      this.APIService.queueRequest<Array<HolidayHoursImage>>({
        endPoint: 'content',
        method: 'hoursImages',
      })
        .then((hoursImages: Array<HolidayHoursImage>) => {
          this.cacheTime_hoursImages = new Date().getTime();

          for (const image of hoursImages) {
            image.src = HolidayHoursPath + image.imageID + '.jpg';
          }

          this.hoursImages = hoursImages;

          deferred.resolve(this.hoursImages);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }
    return deferred.promise;
  }

  deleteImage(image: HolidayHoursImage) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'content',
      method: 'removeImage',
      data: {
        imageID: image.imageID,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  addImage(uploadID: number, type: 'HOURS' | 'NEWS' = 'HOURS') {
    const deferred = new Deferred<false | HolidayHoursImage>();

    this.APIService.queueRequest<false | HolidayHoursImage>({
      endPoint: 'content',
      method: 'addImage',
      data: {
        uploadID: uploadID,
        type: type,
      },
    })
      .then((result: false | HolidayHoursImage) => {
        if (result !== false) {
          result.src = HolidayHoursPath + result.imageID + '.jpg';
        }
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  imageReorder(image: HolidayHoursImage, direction: 'up' | 'down', type: 'HOURS' | 'NEWS' = 'HOURS') {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'content',
      method: 'reorderImage',
      data: {
        imageID: image.imageID,
        direction: direction,
        type: type, //TODO what is the point of hours vs news
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
