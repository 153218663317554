<div class="frontPage">
  <div class="mainPictures">
    <div id="MainPicture">
      <a class="spacer">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABXgAAAGQAQAAAAA8KDVmAAAABGdBTUEAAYagMeiWXwAAAAJiS0dEAAHdihOkAAAAB3RJTUUH4ggKAgIw3/49zAAAATpJREFUeNrtzgENADAIwDD8mz4ydpJWQefdMnXA9yu+vr6+vr6+PV9fX19fX9+er6+vr6+vb8/X19fX19e35+vr6+vr69vz9fX19fX17fn6+vr6+vr2fH19fX19fXu+vr6+vr6+PV9fX19fX9+er6+vr6+vb8/X19fX19e35+vr6+vr69vz9fX19fX17fn6+vr6+vr2fH19fX19fXu+vr6+vr6+PV9fX19fX9+er6+vr6+vb8/X19fX19e35+vr6+vr69vz9fX19fX17fn6+vr6+vr2fH19fX19fXu+vr6+vr6+PV9fX19fX9+er6+vr6+vb8/X19fX19e35+vr6+vr69vz9fX19fX17fn6+vr6+vr2fH19fX19fXu+vr6+vr6+PV9fX19fX9+er6+vr6+vb8/X19fX19e3t02CboFX4JjUAAAAAElFTkSuQmCC"
          alt=""
        />
      </a>

      <a
        *ngFor="let mainPicture of mainPictures.options"
        [hidden]="!mainPicture.displayed"
        [routerLink]="mainPicture.linkUrl"
        class="mainPicture"
      >
        <picture>
          <source
            type="image/webp"
            media="(min-width: 701px)"
            srcset="{{mainPicture.imgSrc}}.webp"
          />
          <source
            type="image/webp"
            media="(max-width: 700px)"
            srcset="{{mainPicture.imgSrc}}-700w.webp"
          />
          <source
            type="image/jp2"
            media="(min-width: 701px)"
            srcset="{{mainPicture.imgSrc}}.jp2"
          />
          <source
            type="image/jp2"
            media="(max-width: 700px)"
            srcset="{{mainPicture.imgSrc}}-700w.jp2"
          />
          <img
            src="{{ mainPicture.imgSrc }}.jpg"
            (error)="mainPictureError(mainPicture)"
            alt="Banner for {{ mainPicture.linkUrl }}"
            (load)="mainPictureLoaded($event, mainPicture)"
          />
        </picture>
      </a>
    </div>

    <div
      class="picSelectors"
      *ngIf="mainPictures.options.length > 1"
    >
      <div
        *ngFor="let mainPicture of mainPictures.options"
        [class.selected]="mainPictures.selected === mainPicture"
        (click)="showPicture(mainPicture)"
      >
        &nbsp;
      </div>
    </div>
  </div>

  <div class="scrolly">
    <div
      class="scrollArrow leftScrollArrow"
      [class.enabled]="sections.length > MAX_SECTIONS_DISPLAYED"
      (click)="scrollLeft()"
    >
      <img
        src="/images/static/arrow_left.png"
        alt="Scroll Left"
      />
    </div>

    <div
      class="scrollArrow rightScrollArrow"
      [class.enabled]="sections.length > MAX_SECTIONS_DISPLAYED"
      (click)="scrollRight()"
    >
      <img
        src="/images/static/arrow_right.png"
        alt="Scroll Right"
      />
    </div>

    <div
      class="innerScrolly"
      id="scrollingProducts"
    >
      <div
        class="frontPageItem"
        *ngFor="let section of sections"
      >
        <a
          *ngIf="section.selectedProduct"
          [routerLink]="section.selectedProduct.routerLink"
          (click)="productClick(section)"
        >
          <span class="sectionName"
            ><span>{{ section.sectionName }}</span></span
          >
          <br />
          <div class="frontPageImage">
            <!--
          --><img
              class="image110"
              [src]="section.selectedProduct.thumbnail"
              (load)="productLoaded($event, section)"
              (error)="productImageError(section)"
              [alt]="section.selectedProduct.productName"
            /><!--
        -->
          </div>
        </a>
      </div>
    </div>
  </div>

  <ccf-mobile-menu [inline]="true"></ccf-mobile-menu>

  <div class="frontPageDescription">
    <hr />
    <h1>{{ pageHeader }}</h1>
    <h2>{{ pageDescription }}</h2>
    <hr />
  </div>

  <!-- TODO <mobile-menu></mobile-menu> !-->
</div>
