<div class="jobsPage pageContent">
  <div class="jobHeader">
    <span class="jobHeaderText">Job Postings</span>

    <!--<div class='applyHere' [hidden]='!hasVisibleJobs'>
			<a class='charliesButtonRound' target='_blank' href='https://forms.gle/K6DJo1jShbe5Jxk57'>Apply Here</a>
		</div>!-->
  </div>
  <div
    [hidden]="hasVisibleJobs"
    class="job jobCenter"
  >
    <div class="jobBlurb noJobs">There are no job postings at this time.</div>
  </div>

  <ng-container *ngFor="let job of jobs">
    <div
      class="job"
      [class.legacy]="job.legacy"
      [hidden]="!isStaff && !job.enabled"
      [class.disabled]="!job.enabled"
      [class.external]="job.externalLink"
      [class.jobLeft]="job.position === 'left'"
      [class.jobCenter]="job.position === 'center'"
      [class.jobRight]="job.position === 'right'"
    >
      <a [routerLink]="job.routerLink">
        <a
          [href]="job.externalLink"
          target="_blank"
          [tabIndex]="job.externalLink ? 0 : -1"
        >
          <div class="jobContent">
            <div class="jobTitle">{{ job.title }}</div>
            <div
              class="jobBlurb"
              *ngIf="job.legacy"
            >
              {{ job.blurb }}
            </div>
            <div
              *ngIf="!job.legacy"
              class="jobHTML"
              [innerHTML]="job.blurb | render | safe: 'html'"
            ></div>
          </div>
        </a>
      </a>
    </div>

    <!-- <a
      *ngIf="job.externalLink"
      [href]="job.externalLink"
      target="_blank"
      class="job external"
      [hidden]="!isStaff && !job.enabled"
      [class.disabled]="!job.enabled"
      [class.jobLeft]="job.position === 'left'"
      [class.jobCenter]="job.position === 'center'"
      [class.jobRight]="job.position === 'right'"
    >
      <div
        class="jobTitle"
        [innerHTML]="job.title | safe: 'html'"
      ></div>

      <div
        class="jobBlurb"
        [innerHTML]="job.blurb | safe: 'html'"
      ></div>
    </a> -->
  </ng-container>
</div>
