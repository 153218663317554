import { Component } from '@angular/core';

import { Route } from '@angular/router';

@Component({
  selector: 'ccf-page-not-found',
  templateUrl: 'pageNotFound.component.html',
  styleUrls: ['pageNotFound.component.less'],
})
export class PageNotFoundComponent {}

export const PageRoute: Route = {
  path: '**',
  component: PageNotFoundComponent,
  data: {
    title: 'Page Not Found',
  },
};
