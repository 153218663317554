export const enumKeys = <T extends string, TEnumValue extends string | number>(enumeration: {
  [key in T]: TEnumValue | unknown;
}): T[] => {
  const keys: T[] = [];
  for (const key in enumeration) {
    if (Object.prototype.hasOwnProperty.call(enumeration, key)) {
      const value = enumeration[key];
      if (
        (typeof value === 'number' && enumeration[value.toString() as T] === (key as string)) || // This is a normal enum
        (typeof value === 'string' && typeof enumeration[value.toString() as T] === 'undefined') // This is a string enum
      ) {
        keys.push(key);
      }
    }
  }
  return keys;
};

export const enumValues = <T extends string, TEnumValue extends string | number>(enumeration: {
  [key in T]: TEnumValue | unknown;
}): TEnumValue[] => {
  return enumKeys(enumeration)
    .map((k) => enumeration[k])
    .filter((v): v is TEnumValue => typeof v === 'string' || typeof v === 'number');
};
