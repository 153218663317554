import { Routes } from '@angular/router';

import { PageRoute as FrontPageRoute } from './frontPage/frontPage.component';

import { PageRoute as LocationsRoute } from './locations/locations.component';

import { PageRoute as OurStoryRoute } from './ourStory/ourStory.component';

import { PageRoute as ContactRoute } from './contact/contact.component';
import { PageRoute as ContactThankYouRoute } from './contact/thankYou.component';

import { PageRoute as ThankYouRoute } from './thankyou/thankyou.component';

import { PageRoute as JobsRoute } from './jobs/jobsPage.component';

import { PageRoute as HolidayHoursRoute } from './holidayHours/holidayHours.component';

import { PageRoute as LoginPageRedirector } from './login/loginPageRedirector.component';

import { PageRoute as WholesalePageRoute } from './wholesale/wholesale.component';

import { PageRoute as PageNotFoundRoute } from './error/pageNotFound.component';
import { UriMatcher } from 'src/common/helpers/uriMatcher';

const AppRoutes: Routes = [
  FrontPageRoute,
  FrontPageRoute,

  LocationsRoute,
  OurStoryRoute,
  ThankYouRoute,
  ContactRoute,
  ContactThankYouRoute,
  JobsRoute,

  HolidayHoursRoute,

  LoginPageRedirector,

  WholesalePageRoute,

  {
    path: 'Frequently-Asked-Questions',
    loadChildren: () => import('./lazyModules/faq.module').then((m) => m.FAQModule),
  },

  {
    path: 'Cart',
    loadChildren: () => import('./lazyModules/cart.module').then((m) => m.CartModule),
  },

  {
    path: 'products',
    loadChildren: () => import('./lazyModules/products.module').then((m) => m.ProductsModule),
  },

  {
    path: 'Search',
    loadChildren: () => import('./lazyModules/search.module').then((m) => m.SearchModule),
  },

  {
    path: 'Donations',
    loadChildren: () => import('./lazyModules/donations.module').then((m) => m.DonationsModule),
  },

  {
    path: 'Corporate-Logos',
    loadChildren: () => import('./lazyModules/corpLogos.module').then((m) => m.CorpLogosModule),
  },

  {
    path: 'Custom-Labels',
    loadChildren: () => import('./lazyModules/customLabels.module').then((m) => m.CustomLabelsModule),
  },

  {
    path: 'Candy-Buffets',
    loadChildren: () => import('./lazyModules/candyBuffets.module').then((m) => m.CandyBuffetsModule),
  },

  {
    path: 'Chocolate-Fountains',
    loadChildren: () => import('./lazyModules/fountains.module').then((m) => m.FountainsModule),
  },

  {
    path: 'Fundraising',
    loadChildren: () => import('./lazyModules/fundraising.module').then((m) => m.FundraisingModule),
  },

  {
    matcher: UriMatcher,
    data: {
      path: '!(Fundraising|OrderSheet)!:sheetType',
    },
    loadChildren: () => import('./lazyModules/orderSheet.module').then((m) => m.OrderSheetModule),
  },

  {
    path: 'Customer',
    loadChildren: () => import('./lazyModules/customer.module').then((m) => m.CustomerModule),
  },

  {
    path: 'admin',
    loadChildren: () => import('../admin/admin.module').then((m) => m.AdminModule),
  },

  PageNotFoundRoute,
];

export { AppRoutes };
