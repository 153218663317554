//TODO test writing
import { Reflect } from 'core-js';

interface MetadataForProperty {
  // isReadonlyGetter?: boolean;
  optional?: boolean;
}

export type StaticImplements_Metadata<T> = {
  interfaceName: string;
  properties: { [P in keyof Required<T>]: MetadataForProperty };
};

const safeThrow = (error: Error): void => {
  setTimeout(() => {
    throw error;
  });
};

export const checkStaticallyImplementing = <T>(
  obj: { constructor: unknown },
  metadata: StaticImplements_Metadata<T>,
): void => {
  const staticClass = obj.constructor as Record<string, unknown>;

  const staticallyImplementing = Reflect.getMetadata('custom:staticallyImplementing', staticClass) as
    | StaticImplements_Metadata<unknown>
    | undefined;
  if (staticallyImplementing?.interfaceName !== metadata.interfaceName) {
    safeThrow(
      new Error(`Class "${staticClass['name']}" is missing @StaticImplements(${metadata.interfaceName}_Metadata)`),
    );
  } /* TODO readonly not set for runtime else {
    for (const propName in metadata.properties) {
      if (Object.prototype.hasOwnProperty.call(metadata.properties, propName)) {
        const propMetadata = metadata.properties[propName];
        const prop = Object.getOwnPropertyDescriptor(staticClass, propName);
        if (typeof prop === 'undefined') {
          if (!propMetadata.optional) {
            safeThrow(new Error(`Class static property ${propName} is missing`));
          }
        } else {
          if (
            propMetadata.isReadonlyGetter &&
            !(
              typeof prop.writable === 'undefined' &&
              typeof prop.get === 'function' &&
              typeof prop.set === 'undefined'
            )
          ) {
            safeThrow(new Error(`Class static property ${propName} must be readonly getter`));
          }
        }
      }
    }
  }*/
};

export const StaticImplements = <T extends object>(implementMetadata: StaticImplements_Metadata<T>) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return <U extends T>(constructor: U) => {
    Reflect.defineMetadata('custom:staticallyImplementing', implementMetadata, constructor);
  };
};
