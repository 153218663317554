import { OrderType } from '../checkout';
import { CartOrder } from './cart';
import { CorpLogoOrder } from './corpLogo';
import { CustomLabelOrder } from './customLabel';
import { FountainOrder } from './fountain';
import { OrderSheetOrder } from './ordersheet';
import { DataLayer } from '../../dataLayer';
import { DonationOrder } from './donation';
import { BaseOrderItem } from '../orderItem/base';
import { BaseOrder } from './base';

export function initializeOrder(type: OrderType, dataLayer: DataLayer): BaseOrder<BaseOrderItem> {
  if (type === OrderType.Cart) {
    return new CartOrder(dataLayer);
  } else if (type === OrderType.CorpLogo) {
    return new CorpLogoOrder(dataLayer);
  } else if (type === OrderType.CustomLabel) {
    return new CustomLabelOrder(dataLayer);
  } else if (type === OrderType.Fountain) {
    return new FountainOrder(dataLayer);
  } else if (type === OrderType.OrderSheet) {
    return new OrderSheetOrder(dataLayer);
  } else if (type === OrderType.Donation) {
    return new DonationOrder(dataLayer);
  } else {
    throw new Error(`Order type (${type}) isn't valid`);
  }
}
