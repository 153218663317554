import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Job, JobNew } from '../models/job';
import { Deferred } from '../helpers/deferred';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  protected CACHE_TIME: number = 60 * 60 * 1000; //1 Hour
  protected cacheTime_jobs: number = 0;
  protected jobs: Array<Job> = [];

  constructor(protected APIService: APIService) {}

  expireCache() {
    this.cacheTime_jobs = 0;
  }

  private reloadJobs() {
    const deferred = new Deferred<Array<Job>>();

    this.APIService.queueRequest<Array<Job>>({
      endPoint: 'content',
      method: 'jobs',
    })
      .then((jobs) => {
        this.cacheTime_jobs = new Date().getTime();

        this.jobs.splice(0, this.jobs.length);
        Array.prototype.push.apply(this.jobs, jobs);

        deferred.resolve(this.jobs);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getJobs() {
    const deferred = new Deferred<Array<Job>>();

    if (this.cacheTime_jobs + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      deferred.resolve(this.jobs);
    } else {
      this.reloadJobs()
        .then((jobs) => {
          deferred.resolve(jobs);
        })
        .catch((reason: string) => {
          deferred.reject('Jobs could not be loaded');
        });
    }

    return deferred.promise;
  }

  getJob(job: Pick<Job, 'jobID'>) {
    const deferred = new Deferred<Job>();

    this.APIService.queueRequest<Job>({
      endPoint: 'content',
      method: 'job',
      data: {
        jobID: job.jobID,
      },
    })
      .then((job) => {
        deferred.resolve(job);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  save(job: JobNew) {
    const deferred = new Deferred<boolean | string>();

    this.APIService.queueRequest<boolean | string>({
      endPoint: 'content',
      method: 'saveJob',
      data: {
        jobID: job.jobID,
        enabled: job.enabled,
        title: job.title,
        blurb: job.blurb,
        fullTitle: job.fullTitle,
        uriName: job.uriName,
        content: job.content,
      },
    })
      .then((result: boolean | string) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  remove(job: Pick<Job, 'jobID'>) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'content',
      method: 'removeJob',
      data: {
        jobID: job.jobID,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  reorder(job: Pick<Job, 'jobID'>, direction: 'up' | 'down') {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'content',
      method: 'reorderJob',
      data: {
        jobID: job.jobID,
        direction: direction,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
