import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../../common/services/api.service';
import { Menu } from '../../common/models/menu';
import { MenuService } from '../../common/services/menu.service';
import { MenuBase } from './menu.base';

@Component({
  selector: 'ccf-desktop-menubar',
  templateUrl: 'desktopMenuBar.component.html',
  styleUrls: ['desktopMenuBar.component.less'],
  providers: [],
})
export class DesktopMenuBarComponent extends MenuBase implements OnInit, OnDestroy {
  menus: Array<Menu> = [];

  constructor(
    private APIService: APIService,
    private MenuService: MenuService,
  ) {
    super();
  }

  ngOnInit() {
    jQuery(document.body).on('click.desktopMenuBar', ($event) => this.clickedOut($event));

    this.MenuService.getMenus().then((menus: Array<Menu>) => {
      this.menus.splice(0, this.menus.length);
      Array.prototype.push.apply(this.menus, menus);
    });

    this.APIService.processRequests();
  }

  ngOnDestroy() {
    jQuery(document.body).off('click.desktopMenuBar');
  }

  protected clickedOut(ev: JQuery.TriggeredEvent): void {
    if (this.menuOpened) {
      if (jQuery(ev.target || ev.currentTarget).closest('.desktopMenuBar').length === 0) {
        this.closeMenu();
      }
    }
  }
}
