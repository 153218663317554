import { Injectable } from '@angular/core';
import { AllConfig, Letter, Emails, SiteEnviroment } from '../models/config';
import { RealLocation } from '../models/tax';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';
import { Big } from 'big.js';
import { GlobalErrorHandler } from './globalErrorHandler';
import { BlockOrdersStatus } from '../models/checkout';
// import assert from 'assert';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  protected CACHE_TIME: number = 24 * 60 * 60 * 1000; //1 day
  protected cacheTime_configData: number = 0;

  protected locations_cacheTime: number = 0;
  protected locations: Array<RealLocation> = [];

  protected CACHE_TIME_SHORT: number = 10 * 60 * 1000; //10 minutes
  protected cacheTime_holidayHoursEnabled: number = 0;
  protected cacheTime_jobsEnabled: number = 0;

  protected letters: Array<Letter> = [];
  protected emails: Emails = {};
  protected enviroment: SiteEnviroment | null = null;
  protected maxOnlinePayment: Big | null = null;
  protected paypalAccount: string | null = null;
  protected postalCodeRegex: RegExp | null = null;
  protected zipCodeRegex: RegExp | null = null;
  protected telRegex: RegExp | null = null;

  protected holidayHoursEnabled: boolean | null = null;
  protected jobsEnabled: boolean | null = null;

  protected cacheTime_onlinePayments: number = 0;
  protected onlinePayments: boolean | null = null;

  protected cacheTime_blockOrdersStatus: number = 0;
  protected blockOrdersStatus: BlockOrdersStatus | null = null;

  protected gaClientID: string | null = null;

  constructor(
    protected APIService: APIService,
    private ErrorHandler: GlobalErrorHandler,
  ) {}

  expireCache() {
    this.cacheTime_configData = 0;
    this.cacheTime_holidayHoursEnabled = 0;
    this.cacheTime_jobsEnabled = 0;
    this.cacheTime_onlinePayments = 0;
    this.cacheTime_blockOrdersStatus = 0;
  }

  getLocations() {
    const deferred = new Deferred<Array<RealLocation>>();

    if (this.locations_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      deferred.resolve(this.locations);
    } else {
      this.APIService.queueRequest<Array<RealLocation>>({
        endPoint: 'locations',
        method: 'all',
      })
        .then((locations: Array<RealLocation>) => {
          this.locations_cacheTime = new Date().getTime();
          this.locations = locations;
          deferred.resolve(this.locations);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  lookupLocation(province: string, country: string) {
    const deferred = new Deferred<RealLocation | null>();

    this.getLocations().then((locations: Array<RealLocation>) => {
      for (const location of locations) {
        if (location.country === country && (location.province === province || location.provinceFull === province)) {
          deferred.resolve(location);
          return;
        }
      }
      deferred.resolve(null);
    });

    return deferred.promise;
  }

  getGAClient() {
    const result = new Deferred<string>();

    if (this.gaClientID !== null) {
      result.resolve(this.gaClientID);
    } else {
      if (typeof ga === 'undefined' || typeof ga.getAll !== 'function') {
        result.resolve('');
        this.ErrorHandler.reportError(new Error('ga is undefiend or does not have getAll'), undefined, true);
      } else {
        ga.getAll().forEach((tracker) => {
          if (tracker) {
            this.gaClientID = tracker.get('clientId');
            if (this.gaClientID) {
              result.resolve(this.gaClientID);
            } else {
              this.ErrorHandler.reportError(new Error('ClientId is not availible'), undefined, true);
            }
          } else {
            result.resolve('');
            this.ErrorHandler.reportError(new Error('Tracker is not defined'), undefined, true);
          }
        });
      }
    }

    return result.promise;
  }

  getHolidayHoursEnabled() {
    const result = new Deferred<boolean>();

    if (
      this.holidayHoursEnabled !== null &&
      this.cacheTime_jobsEnabled + this.APIService.getCacheTime(this.CACHE_TIME_SHORT) > new Date().getTime()
    ) {
      result.resolve(this.holidayHoursEnabled);
    } else {
      this.APIService.queueRequest<boolean>({
        endPoint: 'settings',
        method: 'hours',
      })
        .then((holidayHoursEnabled: boolean) => {
          this.cacheTime_jobsEnabled = new Date().getTime();

          this.holidayHoursEnabled = holidayHoursEnabled;

          result.resolve(this.holidayHoursEnabled);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }
    return result.promise;
  }

  setHolidayHoursEnabled(state: boolean) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'settings',
      method: 'setHours',
      data: {
        state: state,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  setJobsEnabled(state: boolean) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'settings',
      method: 'setJobs',
      data: {
        state: state,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getJobsEnabled() {
    //TODO should be a subscription
    const result = new Deferred<boolean>();

    if (
      this.jobsEnabled !== null &&
      this.cacheTime_jobsEnabled + this.APIService.getCacheTime(this.CACHE_TIME_SHORT) > new Date().getTime()
    ) {
      result.resolve(this.jobsEnabled);
    } else {
      this.APIService.queueRequest<boolean>({
        endPoint: 'settings',
        method: 'jobs',
      })
        .then((jobsEnabled: boolean) => {
          this.cacheTime_jobsEnabled = new Date().getTime();

          this.jobsEnabled = jobsEnabled;

          result.resolve(this.jobsEnabled);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }
    return result.promise;
  }

  getBlockOrdersStatus() {
    const result = new Deferred<BlockOrdersStatus>();

    if (
      this.blockOrdersStatus !== null &&
      this.cacheTime_blockOrdersStatus + this.APIService.getCacheTime(this.CACHE_TIME_SHORT) > new Date().getTime()
    ) {
      result.resolve(this.blockOrdersStatus);
    } else {
      this.APIService.queueRequest<BlockOrdersStatus>({
        endPoint: 'settings',
        method: 'getBlockOrdersStatus',
      })
        .then((blockOrdersStatus: BlockOrdersStatus) => {
          this.cacheTime_blockOrdersStatus = new Date().getTime();

          this.blockOrdersStatus = blockOrdersStatus;

          result.resolve(this.blockOrdersStatus);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }
    return result.promise;
  }

  getOnlinePayments() {
    const result = new Deferred<boolean>();

    if (
      this.onlinePayments !== null &&
      this.cacheTime_onlinePayments + this.APIService.getCacheTime(this.CACHE_TIME_SHORT) > new Date().getTime()
    ) {
      result.resolve(this.onlinePayments);
    } else {
      this.APIService.queueRequest<boolean>({
        endPoint: 'settings',
        method: 'onlinePayments',
      })
        .then((onlinePayments: boolean) => {
          this.cacheTime_onlinePayments = new Date().getTime();

          this.onlinePayments = onlinePayments;

          result.resolve(this.onlinePayments);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }
    return result.promise;
  }

  getAllConfig(): Promise<void> {
    const result = new Deferred<void>();

    if (this.cacheTime_configData + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      result.resolve();
    } else {
      this.APIService.queueRequest<AllConfig>({
        endPoint: 'config',
        method: 'all',
      })
        .then((data: AllConfig) => {
          this.enviroment = data.SITE_ENV;
          this.maxOnlinePayment = new Big(data.MAX_ONLINE_PAYMENT);
          this.paypalAccount = data.PAYPAL_ACCOUNT;
          this.processLetters(data.MOLDED_LETTERS);
          this.emails = data.EMAIL_ADDRESSES;
          this.postalCodeRegex = new RegExp(data.CA_POSTAL_CODE);
          this.zipCodeRegex = new RegExp(data.US_ZIP_CODE);
          this.telRegex = new RegExp(data.TEL_REGEX);

          this.cacheTime_configData = new Date().getTime();

          result.resolve();
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }
    return result.promise;
  }

  async getPostalCodeRegex(): Promise<RegExp> {
    await this.getAllConfig();
    if (this.postalCodeRegex === null) {
      throw 'Must have a Postal Code Regex';
    }
    return this.postalCodeRegex;
  }

  async getZipCodeRegex(): Promise<RegExp> {
    await this.getAllConfig();
    if (this.zipCodeRegex === null) {
      throw 'Must have a Zip Code Regex';
    }
    return this.zipCodeRegex;
  }

  async getTelRegex(): Promise<RegExp> {
    await this.getAllConfig();
    if (this.telRegex === null) {
      throw 'Must have a PhoneNumber Regex';
    }
    return this.telRegex;
  }

  getLetters() {
    const result = new Deferred<Array<Letter>>();

    this.getAllConfig().then(() => {
      result.resolve(this.letters);
    });

    return result.promise;
  }

  private processLetters(rawLetters: Array<string>) {
    const letters: Array<Letter> = [];

    for (let i = 0; i < rawLetters.length; i++) {
      const letter = rawLetters[i];
      let regexText = '';

      if (letter.length === 1) {
        regexText = '^' + letter;
      } else if (letter.indexOf('-') !== -1) {
        regexText = '^[' + letter.replace('-', '|') + ']';
      } else {
        const from = letter[1];
        let to;

        if (i >= rawLetters.length || letter[0] !== rawLetters[i + 1][0]) {
          to = 'z';
        } else {
          to = String.fromCharCode(rawLetters[i + 1].charCodeAt(1) - 1);
        }

        regexText = '^' + letter[0] + '[' + from + '-' + to + ']+';
      }

      regexText = regexText.toLowerCase();

      letters.push({
        letterID: i,
        display: letter,
        regexText: regexText,
        regex: new RegExp(regexText, 'i'),
      });
    }

    this.letters = letters;
  }

  matchLetterSync(text: string): Letter {
    for (const letter of this.letters) {
      if (letter.regex.test(text)) {
        return letter;
      }
    }
    this.ErrorHandler.reportError(new Error(`Could not match "${text}" to any letter`), undefined, true);

    return this.letters[0];
  }

  matchLetter(text: string): Promise<Letter> {
    const deferred = new Deferred<Letter>();

    this.getLetters().then((letters: Array<Letter>) => {
      deferred.resolve(this.matchLetterSync(text));
    });

    return deferred.promise;
  }

  getEmailSync(key: string): string {
    if (this.emails[key]) {
      return this.emails[key];
    } else {
      this.ErrorHandler.reportError(new Error(`Could not find email for "${key}"`), undefined, true);

      return '';
    }
  }

  async getEmail(key: string): Promise<string> {
    if (
      this.emails === null ||
      this.emails[key] === 'undefined' ||
      this.cacheTime_configData + this.APIService.getCacheTime(this.CACHE_TIME) < new Date().getTime()
    ) {
      const loadConfig = this.getAllConfig();
      this.APIService.processRequests();
      await loadConfig;
      if (this.emails[key] === undefined) {
        throw `Could not find email for "${key}"`;
      }
    }

    return this.emails[key];
  }

  async getEnviroment(): Promise<SiteEnviroment> {
    await this.getAllConfig();
    if (this.enviroment === null) {
      throw 'Must have a SiteEnviroment set';
    }
    return this.enviroment;
  }

  async getMaxOnlinePayment(): Promise<Big> {
    await this.getAllConfig();
    if (this.maxOnlinePayment === null) {
      throw 'Must have a Max Online Payment set';
    }
    return this.maxOnlinePayment;
  }

  async getPaypalAccount(): Promise<string> {
    await this.getAllConfig();
    if (this.paypalAccount === null) {
      throw 'Must have a Paypal Account configured';
    }
    return this.paypalAccount;
  }
}
