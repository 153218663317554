import { Big } from 'big.js';
import { BaseOrder } from '../order/base';

import { ApplicableTaxes } from '../tax';
import { DatabaseItem, DiscountOverride } from '../checkout';
import { cloneDeep } from 'lodash-es';
import { Discountable } from '../product';

export type Callback = () => void;

export class BaseOrderItem implements DatabaseItem {
  itemID: number | null = null;
  orderID: number | null = null;
  key: string | null = null;

  isFragile: boolean | null = null;
  priceDiscountable: Discountable = Discountable.Disabled;
  productDiscountable: Discountable = Discountable.Disabled;
  inDiscountableCategory: boolean = false;
  discountOverride: DiscountOverride | null = null;

  get isDiscountable(): boolean {
    if (this.onsale && this.onsale.gt(0)) {
      return false;
    }

    if (this.discountOverride === null) {
      return Discountable.isDiscountable(this.priceDiscountable, this.productDiscountable, this.inDiscountableCategory);
    }
    return this.discountOverride === DiscountOverride.ForceOn;
  }

  onsale: Big | null = null;
  price: Big | null = null;

  cost: Big | null = null;
  total: Big | null = null;
  taxes: ApplicableTaxes = {};

  quantity: number | null = null;
  oldQuantity: number | null = null;

  validQuantity: number | null = null; //TODO public getter only
  validateQuantity(valid: Callback | null = null, invalid: Callback | null = null): boolean {
    if (typeof this.quantity === 'string') {
      //Angular is putting strings in my ints, can't think of better way atm
      const strQTY = (<string>this.quantity).trim();

      try {
        const parsedValue = Number(new Big(strQTY));
        this.quantity = parsedValue;
      } catch (e) {
        this.quantity = this.validQuantity;
        if (invalid) {
          invalid();
        }
        return false;
      }
    } else {
      if (this.quantity !== null) {
        this.quantity = Math.floor(this.quantity);
      }
    }

    this.calculate();

    if (this.quantity === 0 && this.validQuantity !== 0) {
      this.quantity = null;
    } else {
      this.oldQuantity = this.validQuantity;
      this.validQuantity = this.quantity;
    }
    if (valid) {
      valid();
    }
    return true;
  }

  constructor(
    protected parent: BaseOrder<BaseOrderItem>,
    protected data?: DatabaseItem,
  ) {
    if (data) {
      this.refreshed(data);
    }
  }

  calculate(): void {
    this.total = null;
    this.cost = null;

    if (this.quantity) {
      if (this.onsale && this.onsale.gt(0)) {
        this.total = this.onsale.times(this.quantity);
        this.cost = this.onsale;
      } else if (this.price && this.price.gt(0)) {
        this.total = this.price.times(this.quantity);
        this.cost = this.price;
      }
    }
  }

  load(): Promise<unknown> {
    return this.parent.load();
  }

  refreshed(item: DatabaseItem): void {
    this.key = item.key;
    if (item.quantity === null || item.quantity <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.quantity = <any>''; //Hackish but displays most useful info
    } else {
      this.quantity = item.quantity;
    }
    this.validQuantity = this.quantity;

    if (item.itemID) {
      this.itemID = item.itemID;
    }

    if (item.orderID && !this.parent.orderID) {
      this.parent.orderID = item.orderID;
    }

    if (typeof item.taxes !== 'undefined') {
      this.taxes = item.taxes;
    }

    this.total = null;

    this.calculate();
  }

  export(): Record<string, unknown> {
    const exportData: Record<string, unknown> = {};

    exportData['key'] = this.key;
    exportData['quantity'] = this.quantity;
    exportData['validQuantity'] = this.validQuantity;
    exportData['itemID'] = this.itemID;
    exportData['taxes'] = this.taxes;

    exportData['priceDiscountable'] = this.priceDiscountable;
    exportData['productDiscountable'] = this.productDiscountable;
    exportData['inDiscountableCategory'] = this.inDiscountableCategory;
    exportData['discountOverride'] = this.discountOverride;
    exportData['discounted'] = this.isDiscountable;

    exportData['cost'] = this.cost ? new Big(this.cost).toFixed(2) : null;
    exportData['total'] = this.total ? new Big(this.total).toFixed(2) : null;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }

  async remove(): Promise<void> {
    return this.parent.removeItem(this);
  }
}
