import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrdersService } from '../../common/services/orders.service';
import { APIService } from '../../common/services/api.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AccountType, UserAccount, AccountTypeURL } from '../../common/models/account';
import { MenuBase } from '../menu/menu.base';
import { OrderType, OrderStatus, OrderSheetSeason } from '../../common/models/checkout';
import {
  PromptDialogComponent,
  PromptType,
  PromptParams,
  PromptResult,
} from '../../common/helpers/dialogs/prompt/promptDialog.component';
import { GlobalErrorHandler } from '../../common/services/globalErrorHandler';
import { DialogService } from '../../common/helpers/dialogs/dialog.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { QuoteParams, QuoteResult, StartQuoteDialogComponent } from './startQuote.component';
import { isEqual } from 'lodash-es';
import { BaseOrder } from '../../common/models/order/base';
import { PermissionsHad } from '../../common/models/permission';
import { OrderSheetOrder } from '../../common/models/order/ordersheet';
import { Big } from 'big.js';
import { AuthService } from 'src/common/services/auth.service';
import { first } from 'rxjs/operators';
import { BaseOrderItem } from 'src/common/models/orderItem/base';

@Component({
  selector: 'ccf-staff-tools',
  templateUrl: 'staffTools.component.html',
  styleUrls: ['staffTools.component.less'],
  providers: [],
})
export class StaffToolsComponent extends MenuBase implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  realAccount: UserAccount | null = null;
  realStream: BehaviorSubject<UserAccount | null | undefined>;

  maskAccount: UserAccount | null = null;
  maskStream: BehaviorSubject<UserAccount | null | undefined>;

  showBar: boolean = false;

  maskAs: AccountType | false = AccountType.Staff;

  AccountType = AccountType;
  OrderType = OrderType;

  PERMISSIONS: PermissionsHad = {};

  constructor(
    public OrdersService: OrdersService,
    private APIService: APIService,
    private ErrorHandler: GlobalErrorHandler,
    private DialogService: DialogService,
    private DomSanitizer: DomSanitizer,
    private AuthService: AuthService,
  ) {
    super();

    const storedMask = localStorage && localStorage.getItem('staffMaskAs');
    if (storedMask) {
      this.APIService.isMasked = true;
      this.maskAs = false;
      if (storedMask === AccountType.Customer || storedMask === AccountType.Wholesale) {
        this.maskAs = storedMask;
      }
    }

    const storedShowBar = localStorage && localStorage.getItem('staffBarVisible');
    if (storedShowBar === 'true') {
      this.showBar = true;
    }

    const storedPossession = localStorage && localStorage.getItem('T_possessed');
    if (storedPossession) {
      this.mustBeLoggedIn('You must be logged in or the order you have loaded will be released').then(
        (loggedIn: boolean) => {
          if (
            loggedIn &&
            this.PERMISSIONS['ORDERS_POSSESS'] &&
            storedPossession &&
            storedPossession.indexOf('|') !== -1
          ) {
            const key = storedPossession.split('|')[0];
            const ticket = storedPossession.split('|')[1];

            this.OrdersService.focusedOrderKey = key;
            this.OrdersService.isPossessing = true;

            setTimeout(() => {
              this.OrdersService.possess(ticket).then((order: BaseOrder<BaseOrderItem>) => {
                if (this.OrdersService.isPossessing) {
                  this.OrdersService.focusedOrder = order;
                  this.setPossessing();
                }
              });

              this.APIService.processRequests();
            }, 250);
          } else {
            this.OrdersService.focusedOrderKey = (storedPossession + '|').split('|')[0];
            this.OrdersService.detachFocusedOrder();
            localStorage && localStorage.removeItem('T_possessed'); //TODO this is already done in detach
          }
        },
      );
    }

    const storedQuote = localStorage && localStorage.getItem('T_quote');
    if (storedQuote) {
      this.mustBeLoggedIn('You must be logged in or the quote you have loaded will be released').then(
        (loggedIn: boolean) => {
          if (loggedIn && this.PERMISSIONS['ORDERS_QUOTE'] && storedQuote && storedQuote.indexOf('|') !== -1) {
            const key = storedQuote.split('|')[0];
            const ticket = storedQuote.split('|')[1];

            this.OrdersService.focusedOrderKey = key;
            this.OrdersService.isQuoting = true;

            setTimeout(() => {
              this.OrdersService.possess(ticket).then((order: BaseOrder<BaseOrderItem>) => {
                if (this.OrdersService.isQuoting) {
                  this.OrdersService.focusedOrder = order;
                }
              });

              this.APIService.processRequests();
            }, 250);
          } else {
            this.OrdersService.focusedOrderKey = (storedQuote + '|').split('|')[0];
            this.OrdersService.detachFocusedOrder();
            localStorage && localStorage.removeItem('T_quote'); //TODO this is already done in detach
          }
        },
      );
    }

    this.realStream = this.APIService.unMasked();
    this.maskStream = this.APIService.authenticatedDataStream();
  }

  private async mustBeLoggedIn(reason: string = 'You have been logged out.'): Promise<boolean> {
    const data = await this.APIService.unMasked().pipe(first()).toPromise();
    if (typeof data !== 'undefined' && data !== null) {
      return true;
    } else {
      const dialogRef = this.AuthService.promptLogin({
        message: reason,
        replaceOnly: undefined,
      });

      return !!(await dialogRef.afterClosed().toPromise());
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    jQuery(document).off('keydown.staffTools');
  }

  ngOnInit() {
    jQuery(document).on('keydown.staffTools', ($event: JQuery.TriggeredEvent) => {
      if ($event.altKey && $event.shiftKey && $event.ctrlKey) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.toggleBar();
      }
    });

    this._subscriptions.push(
      this.realStream.subscribe((realData?: UserAccount | null) => {
        if (typeof realData !== 'undefined') {
          if (!realData) {
            if (this.OrdersService.focusedOrderKey) {
              this.mustBeLoggedIn('If you logout the order / quote you have loaded will be released').then(
                (loggedIn: boolean) => {
                  if (!loggedIn) {
                    this.OrdersService.detachFocusedOrder();
                    localStorage.removeItem('T_possessed'); //TODO this is already done in detach
                    localStorage.removeItem('T_quote'); //TODO this is already done in detach
                  }
                },
              );
            }
          }

          this.realAccount = realData;

          if (this.APIService.isMasked) {
            this.remask();
          }
        }
      }),
    );

    this._subscriptions.push(
      this.APIService.permissionsStream().subscribe((permissions: PermissionsHad | undefined) => {
        if (permissions) {
          this.PERMISSIONS = permissions;
        }
      }),
    );

    this._subscriptions.push(
      this.maskStream.subscribe((maskData?: UserAccount | null) => {
        if (typeof maskData !== 'undefined') {
          this.maskAccount = maskData;
        }
      }),
    );

    this.APIService.processRequests();
  }

  private toggleBar(setTo?: boolean): void {
    this.showBar = typeof setTo === 'boolean' ? setTo : !this.showBar;

    /*if (this.hasOrder || this.hasQuote) {
      this.showBar = true;
    }*/

    if (this.showBar) {
      localStorage.setItem('staffBarVisible', 'true');
    } else {
      localStorage.removeItem('staffBarVisible');
    }
  }

  private remask(): void {
    if (this.realAccount) {
      let maskData: UserAccount | null;
      if (this.maskAs === false) {
        maskData = null;
      } else {
        maskData = jQuery.extend(true, {}, this.realAccount);
        maskData.accountType = this.maskAs;
        maskData.fake = true;
        if (this.maskAs === AccountType.Customer) {
          maskData.defaultURI = '/';
          maskData.accountTypeURL = AccountTypeURL.Customer;
        } else if (this.maskAs === AccountType.Wholesale) {
          maskData.defaultURI = '';
          maskData.accountTypeURL = AccountTypeURL.Wholesale;
        } else if (this.maskAs === AccountType.Staff) {
          maskData = this.realAccount;
        }
      }

      if (!isEqual(maskData, this.maskAccount)) {
        this.maskStream.next(maskData);
      }
    }
  }

  viewAs(mask: AccountType | false): void {
    if (mask !== this.maskAs) {
      this.maskAs = mask;
      if (mask === AccountType.Staff) {
        this.APIService.isMasked = false;
        this.maskStream.next(this.realStream.value);
        localStorage.removeItem('staffMaskAs');
      } else {
        this.APIService.isMasked = true;
        this.remask();
        localStorage.setItem('staffMaskAs', mask === false ? 'false' : mask);
      }
    }
  }

  startQuote(): void {
    const dialogRef = this.DialogService.show<QuoteParams, QuoteResult, StartQuoteDialogComponent>(
      StartQuoteDialogComponent,
      {
        message: 'Which type of Order do you want to make a Quote for ?',
        no: 'Cancel',
        yes: 'Quote',
      },
    );

    void dialogRef
      .afterClosed()
      .toPromise()
      .then((result: QuoteResult) => {
        if (result) {
          const useExisting = result.useExisting;
          const keyParts: string[] = ['T', OrderType[result.type].toLocaleLowerCase()];
          if (result.type === OrderType.OrderSheet) {
            keyParts.push(OrderSheetSeason[result.season!].toLocaleLowerCase());
          }
          const key = keyParts.join('_');

          const allPromises: Promise<unknown>[] = [];
          if (!useExisting) {
            allPromises.push(this.OrdersService.detach(key));
          }

          void Promise.all(allPromises).then(() => {
            void this.OrdersService.loadCartAndRefresh(key).then((order: BaseOrder<BaseOrderItem>) => {
              if (order.type === OrderType.OrderSheet) {
                (order as OrderSheetOrder).season = result.season!;
                (order as OrderSheetOrder).orderSheetType = result.sheetType!;
              }
              order
                .setAsQuote()
                .then(() => {
                  this.OrdersService.focusedOrder = order;
                  this.OrdersService.focusedOrderKey = order.key;
                  this.OrdersService.isQuoting = true;
                  this.OrdersService.gotoCheckout(this.OrdersService.focusedOrder);
                  localStorage.setItem('T_quote', order.key + '|' + order.orderID);
                })
                .catch((errorMessage: string) => {
                  this.DialogService.alert(errorMessage);
                  this.ErrorHandler.reportError(new Error(errorMessage), undefined, true);
                });
            });
            this.APIService.processRequests();
          });
        }
      });
  }

  emailOrder(): void {
    if (this.OrdersService.focusedOrder && this.OrdersService.focusedOrderKey) {
      this.OrdersService.emailOrder(this.OrdersService.focusedOrder, this.OrdersService.focusedOrderKey);
    }
  }

  possessOrder(): void {
    const dialogRef = this.DialogService.show<PromptParams, PromptResult, PromptDialogComponent>(
      PromptDialogComponent,
      {
        message: this.DomSanitizer.bypassSecurityTrustHtml(
          'Please paste the ticket of the order you wish to load. <br />' +
            '<a href="/admin/orders" target="_blank">Open Orders</a>',
        ),
        input: PromptType.Text,
        width: '32em',
        no: 'Cancel',
        yes: 'Load',
      },
    );

    void dialogRef
      .afterClosed()
      .toPromise()
      .then((result: PromptResult) => {
        if (typeof result === 'string') {
          this.doPossession(result);
        }
      });
  }

  private doPossession(ticket: string): void {
    this.OrdersService.possess(ticket)
      .then((order: BaseOrder<BaseOrderItem>) => {
        this.OrdersService.focusedOrder = order;
        this.OrdersService.focusedOrderKey = order.key;

        this.OrdersService.gotoCheckout(this.OrdersService.focusedOrder);

        if (order.status === OrderStatus.Quote) {
          this.OrdersService.isQuoting = true;
          localStorage.setItem('T_quote', order.key + '|' + ticket);
        } else {
          this.OrdersService.isPossessing = true;
          localStorage.setItem('T_possessed', order.key + '|' + ticket);

          this.setPossessing();
        }
      })
      .catch((errorMessage: string) => {
        this.DialogService.alert(errorMessage);
        this.ErrorHandler.reportError(new Error(errorMessage), undefined, true);
      });

    this.APIService.processRequests();
  }

  private setPossessing(): void {
    if (this.OrdersService.focusedOrder) {
      this.OrdersService.focusedOrder.isPossessed = true;

      const updateSub = this.OrdersService.focusedOrder.updated().subscribe(() => {
        if (
          this.OrdersService.focusedOrder &&
          this.OrdersService.focusedOrder.isPossessed &&
          OrderStatus.isClosed(this.OrdersService.focusedOrder.status, this.realAccount)
        ) {
          this.DialogService.alert('The order you had loaded with StaffTools has been completed.');
          updateSub.unsubscribe();
          this.OrdersService.releasedFocusedOrder();
        }
      });
      this._subscriptions.push(updateSub);

      const resetSub = this.OrdersService.focusedOrder.wasReset().subscribe(() => {
        this.OrdersService.releasedFocusedOrder();
      });
      this._subscriptions.push(resetSub);
    }
  }

  releaseOrder(): void {
    const dialogRef = this.DialogService.show<PromptParams, PromptResult, PromptDialogComponent>(
      PromptDialogComponent,
      {
        message: 'Are you sure you want to release this Order ?',
        input: PromptType.None,
        no: 'Cancel',
        yes: 'Release',
      },
    );

    void dialogRef
      .afterClosed()
      .toPromise()
      .then((result: PromptResult) => {
        if (result) {
          this.OrdersService.detachFocusedOrder();
        }
      });
  }

  debug_error(): void {
    throw new Error('Test error');
  }

  debug_error_x(): void {
    const timesString = prompt('How many errors ?');
    if (timesString) {
      const times = parseInt(timesString, 10);
      for (let i = 0; i < times; i++) {
        setImmediate(() => {
          throw new Error('Test error ' + i + ' - ' + new Date().getTime());
        });
      }
    }
  }

  debug_steal(what: 'order' | 'user'): void {
    let message: SafeHtml;
    if (what === 'order') {
      message = this.DomSanitizer.bypassSecurityTrustHtml(
        'Please paste the ticket or ID of the order you wish to steal. <br />' +
          '<a href="/admin/orders" target="_blank">Open Orders</a>',
      );
    } else {
      message = this.DomSanitizer.bypassSecurityTrustHtml(
        'Please paste the userID or userName of the account you wish to steal. <br />' +
          '<a href="/admin/accounts" target="_blank">Open Accounts</a>',
      );
    }

    const dialogRef = this.DialogService.show<PromptParams, PromptResult, PromptDialogComponent>(
      PromptDialogComponent,
      {
        message: message,
        input: PromptType.Text,
        width: '32em',
        no: 'Cancel',
        yes: 'Steal',
      },
    );

    void dialogRef
      .afterClosed()
      .toPromise()
      .then((result: PromptResult) => {
        if (typeof result === 'string') {
          let target: number | string;
          try {
            target = parseInt(new Big(result).toFixed(), 10);
          } catch (e) {
            target = result;
          }

          this.APIService.queueRequest<boolean | string>({
            endPoint: 'admin',
            method: 'steal_' + what,
            data: {
              target: target,
            },
          }).then((result: boolean | string) => {
            if (typeof result === 'string') {
              throw Error(result);
            } else {
              window.location.reload();
            }
          });

          this.APIService.processRequests();
        }
      });
  }

  cart_outofdate(): void {
    this.OrdersService.expireOrders();
  }

  debug_drop_all_orders(): void {
    this.OrdersService.detachAll().then(() => {
      window.location.reload();
    });
  }
}
