export type DeferredStatus = 'PENDING' | 'RESOLVED' | 'REJECTED';

export class Deferred<T> {
  promise: Promise<T>;
  resolve!: (value: T | PromiseLike<T>) => void;
  reject!: (reason?: unknown) => void;
  status: DeferredStatus = 'PENDING';

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = (value) => {
        this.status = 'RESOLVED';
        resolve(value);
      };

      this.reject = (reason) => {
        this.status = 'REJECTED';
        reject(reason);
      };
    });
  }
}
