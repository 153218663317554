/** This functin is dangerious TODO remove it
 *
 * @param object
 * @param path
 */
export const getProp = <T extends { [_ in keyof T]: unknown }>(object: T, path: string): unknown => {
  const [key, remaining] = path.split('.', 2) as [keyof T, string | undefined];

  object = object[key] as T;

  if (typeof remaining !== 'undefined') {
    return getProp(object, remaining);
  }
  return object;
};
