import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';

import { Router } from '@angular/router';
import { UserAccount } from '../models/account';
import { Subscription } from 'rxjs';
import { DialogService } from '../helpers/dialogs/dialog.service';
import {
  LoginDialogComponent,
  LoginParams,
  LoginResult,
  LoginOptions,
} from '../helpers/dialogs/login/loginDialog.component';
import { MatDialogRef } from '@angular/material/dialog';

export interface APILoginResult {
  loggedIn: boolean;
  message: string;
  // defaultURI: string; //TODO remove from actual API response
}

export enum LoginAttemptResult {
  Success = 0,
  'Wrong Password',
  'Old Style Attempt',
  'Inactive Account',
  'Invalid Account',
}

export interface LoginAttempt {
  attemptID: number;
  time: Date;
  user: string;
  result: LoginAttemptResult;
  ip: string;
  useragent: string;
}

export interface ChangePasswordResult {
  result: boolean;
  error?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // TODO not sure if like
  private _subscriptions: Subscription[] = [];

  protected CACHE_TIME: number = 5 * 60 * 1000; // 5 minutes

  protected loginTracking: boolean = false;

  constructor(
    private apiService: APIService,
    private router: Router,
    private dialogService: DialogService,
  ) {
    if (typeof localStorage !== 'undefined' && localStorage.getItem('loginTracking') !== null) {
      this.loginTracking = true;
    }

    this._subscriptions.push(
      this.apiService.authenticatedDataStream().subscribe((data?: UserAccount | null) => {
        if (typeof data !== 'undefined') {
          if (data === null && this.loginTracking) {
            this.loginTracking = false;

            if (typeof localStorage !== 'undefined') {
              localStorage.removeItem('loginTracking');
            }

            this.promptLogin({ message: 'You have been logged out.' });
          } else if (data && this.loginTracking === false) {
            this.loginTracking = true;

            if (typeof localStorage !== 'undefined') {
              localStorage.setItem('loginTracking', 'true');
            }
          }
        }
      }),
    );
  }

  public loginRequired(): Promise<boolean> {
    const result = new Deferred<boolean>();

    this.apiService
      .queueRequest<boolean>({
        endPoint: 'authenticate',
        method: 'loginRequired',
      })
      .then((status: boolean) => {
        result.resolve(status);
      })
      .catch((reason: Error) => {
        result.reject(reason);
      });
    return result.promise;
  }

  public logout(): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    this.loginTracking = false;

    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('loginTracking');
    }

    this.apiService
      .queueRequest<boolean>({
        endPoint: 'authenticate',
        method: 'logout',
      })
      .then((success: boolean) => {
        deferred.resolve(success);
        if (success && this.apiService.isAdmin) {
          void this.router.navigateByUrl('/');
        }
      })
      .catch((reason: Error) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    this.apiService.processRequests();

    return deferred.promise;
  }

  public loginAttempts(limit: number): Promise<Array<LoginAttempt>> {
    const deferred = new Deferred<Array<LoginAttempt>>();

    this.apiService
      .queueRequest<Array<LoginAttempt>>({
        endPoint: 'authenticate',
        method: 'loginAttempts',
        data: {
          limit,
        },
      })
      .then((attempts: Array<LoginAttempt>) => {
        deferred.resolve(attempts);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  public goHome(): void {
    void this.apiService.authenticatedData().then((userData?: UserAccount | null) => {
      if (typeof userData !== 'undefined') {
        if (userData !== null) {
          const home = userData.defaultURI || userData.accountTypeURL;
          void this.router.navigateByUrl(home);
        }
      }
    });
    this.apiService.processRequests();
  }

  public promptLogin(options: LoginOptions): MatDialogRef<LoginDialogComponent, LoginResult> {
    const dialogRef = this.dialogService.show<LoginParams, LoginResult, LoginDialogComponent>(
      LoginDialogComponent,
      {
        ...options,
        loginMethod: (userName: string, password: string) => this.login(userName, password),
      },
      undefined,
      options.replaceOnly,
    );
    void dialogRef
      .afterClosed()
      .toPromise()
      .then((result: LoginResult) => {
        if (result && options.goHome) {
          this.goHome();
        }
      });

    return dialogRef;
  }

  public login(username: string, password: string): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    if (username.length === 0) {
      deferred.reject('Username cannot be blank.');
    } else if (password.length === 0) {
      deferred.reject('Password cannot be blank.');
    } else {
      this.apiService
        .queueRequest<APILoginResult>({
          endPoint: 'authenticate',
          method: 'login',
          uniqueKey: username,
          data: {
            username,
            password,
          },
        })
        .then((result: APILoginResult) => {
          if (result.loggedIn) {
            deferred.resolve(true);
          } else {
            deferred.reject(result.message);
          }
        })
        .catch((reason: Error) => {
          deferred.reject(reason);
          // TODO should do something globally ?
        });

      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  public changePassword(currentPassword: string, newPassword: string): Promise<ChangePasswordResult> {
    const deferred = new Deferred<ChangePasswordResult>();

    this.apiService
      .queueRequest<ChangePasswordResult>({
        endPoint: 'authenticate',
        method: 'changePassword',
        data: {
          currentPassword,
          newPassword,
        },
      })
      .then((result: ChangePasswordResult) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }
}
