import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { DialogComponent, StaticDialogComponent_Metadata } from '../dialogComponent';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DialogService } from '../dialog.service';
import { SafeHtml } from '@angular/platform-browser';
import { ValidationCallback } from '..';
import { StaticImplements } from '../../staticImplements';

export enum PromptType {
  Text = 'text',
  None = 'none',
}

export type PromptResult = string | true | undefined;

export interface PromptParams {
  message: string | SafeHtml;
  input: PromptType;
  width?: string;
  validate?: (text: string, callback: ValidationCallback) => boolean;
  no?: string;
  yes: string;
}

@Component({
  selector: 'ccf-prompt-dialog',
  templateUrl: 'promptDialog.component.html',
  styleUrls: ['promptDialog.component.less'],
  providers: [],
})
@StaticImplements(StaticDialogComponent_Metadata)
export class PromptDialogComponent extends DialogComponent<PromptParams, PromptResult> implements OnInit, OnDestroy {
  public static DIALOG_CLASS = 'promptDialog';
  public static DEFAULT_CONFIG: MatDialogConfig = {};

  override isBlocking = true;

  textInput: string = '';
  validationMessage: string = '';

  PromptType = PromptType;

  constructor(
    private dialogService: DialogService,
    public override matDialogRef: MatDialogRef<PromptDialogComponent, PromptResult>,
    @Inject(MAT_DIALOG_DATA) public override params: PromptParams,
  ) {
    super(matDialogRef);
  }

  no(): void {
    this.closeDialog();
  }

  yes(): void {
    if (this.validate()) {
      this.closeDialog(this.params.input === PromptType.Text ? this.textInput : true);
    }
  }

  validate(): boolean {
    this.validationMessage = '';
    if (this.params.input === PromptType.Text && typeof this.params.validate === 'function') {
      return this.params.validate(this.textInput, (message: string) => {
        this.validationMessage = message;
      });
    } else {
      return true;
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.dialogService.closed();
  }

  ngOnInit() {
    this.dialogService.opened();
  }
}
