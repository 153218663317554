import { Big } from 'big.js';

import {
  OrderType,
  OrderFile,
  APIDonationOrder,
  DonationWith,
  DonationWork,
  DatabaseOrder,
  DatabaseItem,
} from '../checkout';

import { BaseOrder, OrderResponseObject } from './base';
import { Deferred } from '../../helpers/deferred';
import { cloneDeep } from 'lodash-es';
import { BaseOrderItem } from '../orderItem/base';

export class DonationOrder extends BaseOrder<BaseOrderItem> implements APIDonationOrder<Big> {
  type = OrderType.Donation as const;

  //org in contactInfo
  fundGroupName: string = '';
  charityNumber: string = '';

  //name in contactInfo
  //email in contactInfo
  //phone in contactInfo
  with: DonationWith | null = null;
  work: DonationWork | null = null;

  letter: Array<OrderFile> = [];

  eventDate: Date | null = null;
  where: string = '';
  people: string = '';
  eventType: string = '';
  donationPurpose: string = '';

  connection: string = '';
  whyCharlies: string = '';
  recognition: string = '';

  override reset(): void {
    super.reset();

    //org in contactInfo
    this.fundGroupName = '';
    this.charityNumber = '';

    //name in contactInfo
    //email in contactInfo
    //phone in contactInfo
    this.with = null;
    this.work = null;

    this.letter = [];

    this.eventDate = null;
    this.where = '';
    this.people = '';
    this.eventType = '';
    this.donationPurpose = '';

    this.connection = '';
    this.whyCharlies = '';
    this.recognition = '';
  }

  override async parse(
    data: OrderResponseObject<DatabaseOrder<DatabaseItem>>,
    calledFrom: string,
    allDone?: Promise<void>,
  ): Promise<void> {
    const doneHere = new Deferred<void>();

    await super.parse(data, calledFrom, doneHere.promise);

    this.freeze();

    if (data.order) {
      const order = data.order as APIDonationOrder;

      //org in contactInfo
      if (typeof order.fundGroupName !== 'undefined') {
        this.fundGroupName = order.fundGroupName;
      }
      if (typeof order.charityNumber !== 'undefined') {
        this.charityNumber = order.charityNumber;
      }

      //name in contactInfo
      //email in contactInfo
      //phone in contactInfo
      if (typeof order.with !== 'undefined') {
        this.with = order.with;
      }
      if (typeof order.work !== 'undefined') {
        this.work = order.work;
      }

      if (typeof order.letter !== 'undefined') {
        this.letter = order.letter;
      }

      if (typeof order.eventDate !== 'undefined') {
        this.eventDate = order.eventDate !== null ? new Date(order.eventDate) : null;
      }
      if (typeof order.where !== 'undefined') {
        this.where = order.where;
      }
      if (typeof order.people !== 'undefined') {
        this.people = order.people;
      }
      if (typeof order.eventType !== 'undefined') {
        this.eventType = order.eventType;
      }
      if (typeof order.donationPurpose !== 'undefined') {
        this.donationPurpose = order.donationPurpose;
      }

      if (typeof order.connection !== 'undefined') {
        this.connection = order.connection;
      }
      if (typeof order.whyCharlies !== 'undefined') {
        this.whyCharlies = order.whyCharlies;
      }
      if (typeof order.recognition !== 'undefined') {
        this.recognition = order.recognition;
      }
    }

    this.unfreeze();

    const done = async () => {
      await this.calculate();

      doneHere.resolve();
    };

    if (allDone) {
      allDone.then(() => {
        done();
      });
    } else {
      await done();
    }
  }

  override export() {
    const exportData = super.export();

    //org in contactInfo
    exportData['fundGroupName'] = this.fundGroupName;
    exportData['charityNumber'] = this.charityNumber;

    //name in contactInfo
    //email in contactInfo
    //phone in contactInfo
    exportData['with'] = this.with;
    exportData['work'] = this.work;

    exportData['letter'] = this.letter;

    exportData['eventDate'] = this.eventDate;
    exportData['where'] = this.where;
    exportData['people'] = this.people;
    exportData['eventType'] = this.eventType;
    exportData['donationPurpose'] = this.donationPurpose;

    exportData['connection'] = this.connection;
    exportData['whyCharlies'] = this.whyCharlies;
    exportData['recognition'] = this.recognition;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }

  override async calculate(): Promise<void> {
    return;
  }

  protected newOrderItem(): BaseOrderItem {
    return new BaseOrderItem(this);
  }
}
