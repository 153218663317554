import { Injectable } from '@angular/core';
import { ContactUsForm, FundraiserInquiry } from '../models/contactForm';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  constructor(protected APIService: APIService) {}

  submitContactUs(contactForm: ContactUsForm) {
    const result = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'contact',
      method: 'contactUs',
      data: contactForm,
    })
      .then((isSent: boolean) => {
        if (isSent) {
          result.resolve(isSent);
        } else {
          result.reject(isSent);
        }
      })
      .catch((reason: unknown) => {
        result.reject(reason);
        //TODO should do something globally ?
      });

    this.APIService.processRequests();

    return result.promise;
  }

  submitFundraiserInquiry(fundraiserInquiry: FundraiserInquiry) {
    const result = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'contact',
      method: 'fundraiserInquiry',
      data: fundraiserInquiry,
    })
      .then((isSent: boolean) => {
        if (isSent) {
          result.resolve(isSent);
        } else {
          result.reject(isSent);
        }
      })
      .catch((reason: unknown) => {
        result.reject(reason);
        //TODO should do something globally ?
      });

    this.APIService.processRequests();

    return result.promise;
  }
}
