<ul class="menu">
  <li></li>

  <li
    [hidden]="accountData && accountData.accountType !== 'Wholesale'"
    [class.selected]="page === 'home'"
  >
    <span>Welcome</span>
    <a [routerLink]="'/Wholesale'">Welcome</a>
  </li>

  <li [class.selected]="page === 'messages'">
    <span
      >Messages<span [hidden]="newMessages === 0"> ({{ newMessages }})</span></span
    >
    <a [routerLink]="'/Customer/Messages'"
      >Messages<span [hidden]="newMessages === 0"> ({{ newMessages }})</span></a
    >
  </li>

  <li [class.selected]="page === 'orders'">
    <span>Past Orders</span>
    <a [routerLink]="'/Customer/Orders'">Past Orders</a>
  </li>

  <li [class.selected]="page === 'profile'">
    <span>Edit Profile</span>
    <a [routerLink]="'/Customer/Profile'">Edit Profile</a>
  </li>

  <li [hidden]="accountData && accountData.accountType !== 'Wholesale'">
    <a
      target="_blank"
      href="/pdfs/Wholesale%20Terms%20-%202021.pdf"
      >Terms</a
    >
  </li>

  <li></li>
</ul>
