<div class="pageContent">
  <div
    class="prompt"
    [innerHTML]="params.message"
  ></div>

  <select
    class="charliesSelect"
    [(ngModel)]="typeDDL.selected"
    (ngModelChange)="typeDDL_changed(typeDDL)"
  >
    <option [ngValue]="undefined">--Type--</option>
    <option
      *ngFor="let option of typeDDL.options"
      [ngValue]="option"
    >
      {{ option.label }}
    </option>
  </select>
  <select
    *ngIf="seasonDDL"
    class="charliesSelect"
    [(ngModel)]="seasonDDL.selected"
    (ngModelChange)="seasonDDL_changed(seasonDDL)"
  >
    <option [ngValue]="undefined">--Season--</option>
    <option
      *ngFor="let option of seasonDDL.options"
      [ngValue]="option"
    >
      {{ option.label }}
    </option>
  </select>
  <select
    *ngIf="sheetTypeDDL"
    class="charliesSelect"
    [(ngModel)]="sheetTypeDDL.selected"
    (ngModelChange)="sheetTypeDDL_changed(sheetTypeDDL)"
  >
    <option
      *ngFor="let option of sheetTypeDDL.options"
      [ngValue]="option"
    >
      {{ option.label }}
    </option>
  </select>

  <br />
  <br />

  <input
    type="checkbox"
    [(ngModel)]="useExisting"
  />
  Use Existing if possible
  <br />

  <div class="validation">{{ validationMessage }}</div>

  <button
    type="button"
    class="no"
    (click)="no()"
  >
    {{ params.no }}
  </button>
  <button
    type="button"
    class="yes"
    (click)="yes()"
  >
    {{ params.yes }}
  </button>
</div>
