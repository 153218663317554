import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { ShippingTime, BaseRate, Rate } from '../models/freight';
import { Deferred } from '../helpers/deferred';
import { Big } from 'big.js';

export interface FreightData {
  baseRates: Array<BaseRate>;
  rates: Array<Rate>;
  shippingTimes: Array<ShippingTime>;
}

export interface FreightLookupData {
  amount: Big;
  tax?: Big;
  taxName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FreightService {
  //TODO move backend freight stuff here ?
  protected CACHE_TIME: number = 60 * 60 * 1000; //1 Hour
  protected cacheTime_all: number = 0;
  protected rates: Array<Rate> = [];
  protected baseRates: Array<BaseRate> = [];
  protected shippingTimes: Array<ShippingTime> = [];

  constructor(protected APIService: APIService) {}

  expireCache(): void {
    this.cacheTime_all = 0;
  }

  reload(): Promise<void> {
    const deferred = new Deferred<void>();

    this.APIService.queueRequest<FreightData>({
      endPoint: 'freight',
      method: 'all',
    })
      .then((data: FreightData) => {
        this.cacheTime_all = new Date().getTime();

        this.rates = data.rates;
        this.baseRates = data.baseRates;
        this.shippingTimes = data.shippingTimes;

        deferred.resolve();
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  lookupTime(postalCode: string): Promise<string | null> {
    const deferred = new Deferred<string | null>();

    if (this.cacheTime_all + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      deferred.resolve(this._lookupTime(postalCode));
    } else {
      this.reload()
        .then(() => {
          deferred.resolve(this._lookupTime(postalCode));
        })
        .catch((reason: string) => {
          deferred.reject('Times could not be loaded');
        });
    }

    return deferred.promise;
  }

  _lookupTime(postalCode: string): string | null {
    postalCode = postalCode.replace(/\s*/g, '').toLowerCase(); //TODO dup

    let bestMatchLen = 0;
    let match: ShippingTime | undefined;

    for (const times of this.shippingTimes) {
      const matchTexts = times['matchText'].split(',');

      for (let matchText of matchTexts) {
        matchText = matchText.replace(/\s*/g, '').toLowerCase();

        if (postalCode.slice(0, matchText.length) === matchText || matchText === '*') {
          if (matchText.length > bestMatchLen) {
            if (matchText !== '*') {
              bestMatchLen = matchText.length;
            }
            match = times;
          }
        }
      }
    }

    if (match) {
      return '' + match.fast + ' - ' + match.slow + ' days';
    } else {
      return null;
    }
  }

  lookup(amount: Big, postalCode: string): Promise<FreightLookupData | null> {
    const deferred = new Deferred<FreightLookupData | null>();

    if (this.cacheTime_all + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      deferred.resolve(this._lookup(amount, postalCode));
    } else {
      this.reload()
        .then(() => {
          deferred.resolve(this._lookup(amount, postalCode));
        })
        .catch((reason: string) => {
          deferred.reject('Rates could not be loaded');
        });
    }

    return deferred.promise;
  }

  _lookup(amount: Big, postalCode: string): FreightLookupData | null {
    let rateAmount: Big | null = null; //TODO dup
    for (const rate of this.rates) {
      if (amount.gte(rate['bottom'])) {
        if (amount.lte(rate['top'])) {
          rateAmount = new Big(rate['amount']);
          break;
        }
        if (new Big(rate['top']).eq(0)) {
          rateAmount = new Big(rate['amount']);
          break;
        }
      }
    }

    if (rateAmount === null) {
      return null;
    }

    postalCode = postalCode.replace(/\s*/g, '').toLowerCase();

    let bestMatchLen = 0;
    let bestMatchAmount = new Big('0');
    let bestMatchTax = new Big('0');
    let bestMatchTaxName = '';

    for (const baseRate of this.baseRates) {
      const matchTexts = baseRate['matchText'].split(',');

      for (let matchText of matchTexts) {
        matchText = matchText.replace(/\s*/g, '').toLowerCase();

        if (postalCode.slice(0, matchText.length) === matchText || matchText === '*') {
          if (matchText.length > bestMatchLen) {
            if (matchText !== '*') {
              bestMatchLen = matchText.length;
            }
            bestMatchAmount = new Big(baseRate['amount']);
            bestMatchTax = new Big(baseRate['rateTax']).div(100);
            bestMatchTaxName = baseRate['taxName'];
          }
        }
      }
    }

    const total = rateAmount.plus(bestMatchAmount);
    let tax: Big | undefined;
    let taxName: string | undefined;

    if (bestMatchTax.gt(0)) {
      tax = bestMatchTax;
    }

    if (bestMatchTaxName) {
      taxName = bestMatchTaxName;
    }

    return {
      amount: total,
      tax: tax,
      taxName: taxName,
    };
  }
}
