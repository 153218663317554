import { Injectable } from '@angular/core';
import { MainPicture, NewMainPicture } from '../models/mainPicture';
import { Product } from '../models/product';
import { Category } from '../models/category';
import { Section } from '../models/section';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';
import { ConfigService } from './config.service';
import { Letter } from '../models/config';

export interface FrontPageProduct extends Product {
  routerLink?: string;
  categoryUrlName: string;
}

export interface ProductResult extends Product {
  resultHtml?: string;
  text?: string;
}

export interface FrontPageSection extends Section {
  products: Array<FrontPageProduct>;
  selectedProduct?: FrontPageProduct;
  numCategories: number;
}

@Injectable({
  providedIn: 'root',
})
export class FrontPageService {
  protected CACHE_TIME: number = 60 * 60 * 1000; //1 Hour

  protected sections: Array<FrontPageSection> = [];
  protected cacheTime_sections: number = 0;

  protected sectionPlaceholders: Array<FrontPageSection> = [];
  protected cacheTime_sectionPlaceholders: number = 0;

  protected mainPictures: Array<MainPicture> = [];
  protected cacheTime_mainPictures: number = 0;

  constructor(
    private APIService: APIService,
    private ConfigService: ConfigService,
  ) {}

  expireCache() {
    this.cacheTime_mainPictures = 0;
    this.cacheTime_sections = 0;
    this.cacheTime_sectionPlaceholders = 0;
  }

  getSectionPlaceholders() {
    const result = new Deferred<Array<FrontPageSection>>();

    if (
      this.sectionPlaceholders !== null &&
      this.cacheTime_sectionPlaceholders + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
    ) {
      result.resolve(this.sectionPlaceholders);
    } else {
      this.APIService.queueRequest<Array<FrontPageSection>>({
        endPoint: 'home',
        method: 'lowestLevelSections',
      })
        .then((sections: Array<FrontPageSection>) => {
          this.sectionPlaceholders = sections;
          this.cacheTime_sectionPlaceholders = new Date().getTime();
          result.resolve(this.sectionPlaceholders);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }

    return result.promise;
  }

  getSections() {
    const result = new Deferred<Array<FrontPageSection>>();

    if (
      this.sections !== null &&
      this.cacheTime_sections + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
    ) {
      result.resolve(this.sections);
    } else {
      this.APIService.queueRequest<Array<FrontPageSection>>({
        endPoint: 'home',
        method: 'sectionProducts',
      })
        .then((sections: Array<FrontPageSection>) => {
          const promises: Array<Promise<unknown>> = [];

          for (let si = 0; si < sections.length; si++) {
            const section = sections[si];

            for (let pi = 0; pi < section.products.length; pi++) {
              const product = section.products[pi];
              product.routerLink = '/products/' + section.urlName + '/';

              if (!section.alphabetic) {
                if (section.numCategories > 1) {
                  product.routerLink += product.categoryUrlName;
                }
              } else {
                promises.push(
                  this.ConfigService.matchLetter(product.productName).then((letter: Letter) => {
                    product.routerLink += letter.display;
                  }),
                );
              }
            }
          }

          this.APIService.processRequests();

          Promise.all(promises).then(() => {
            this.sections.splice(0, this.sections.length);
            Array.prototype.push.apply(this.sections, sections);
            this.cacheTime_sections = new Date().getTime();

            result.resolve(this.sections);
          });
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }

    return result.promise;
  }

  getMainPictures() {
    const result = new Deferred<Array<MainPicture>>();

    if (
      this.mainPictures !== null &&
      this.cacheTime_mainPictures + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
    ) {
      result.resolve(this.mainPictures);
    } else {
      this.APIService.queueRequest<Array<MainPicture>>({
        endPoint: 'home',
        method: 'mainPictures',
      })
        .then((mainPictures: Array<MainPicture>) => {
          for (let i = 0; i < mainPictures.length; i++) {
            mainPictures[i].imgSrc = '/images/dynamic/frontPage/' + mainPictures[i].mainPictureID;
          }

          this.mainPictures.splice(0, this.mainPictures.length);
          Array.prototype.push.apply(this.mainPictures, mainPictures);
          this.cacheTime_mainPictures = new Date().getTime();

          result.resolve(this.mainPictures);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }

    return result.promise;
  }

  addProduct(product: Product, category: Category) {
    const deferred = new Deferred<boolean | string>();

    this.APIService.queueRequest<boolean | string>({
      //TODO error message ?
      endPoint: 'home',
      method: 'addSectionProduct',
      data: {
        productID: product.productID,
        categoryID: category.categoryID,
      },
    })
      .then((result: boolean | string) => {
        this.expireCache();
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  removeProduct(product: Product, section: Section) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      //TODO error message ?
      endPoint: 'home',
      method: 'removeSectionProduct',
      data: {
        productID: product.productID,
        sectionID: section.sectionID,
      },
    })
      .then((result: boolean) => {
        this.expireCache();
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  autocomplete(searchText: string, section: Section) {
    const deferred = new Deferred<Array<ProductResult>>();

    this.APIService.queueRequest<Array<ProductResult>>({
      //TODO error message ?
      endPoint: 'home',
      method: 'autocomplete',
      data: {
        searchText: searchText,
        sectionID: section.sectionID,
      },
    })
      .then((results: Array<ProductResult>) => {
        deferred.resolve(results);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  categoriesOf(product: Product, section: Section) {
    const deferred = new Deferred<Array<Category>>();

    this.APIService.queueRequest<Array<Category>>({
      //TODO error message ?
      endPoint: 'home',
      method: 'categories',
      data: {
        productID: product.productID,
        sectionID: section.sectionID,
      },
    })
      .then((results: Array<Category>) => {
        deferred.resolve(results);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  saveMainPicture(mainPicture: NewMainPicture) {
    const deferred = new Deferred<number>();

    this.APIService.queueRequest<number>({
      //TODO error message ?
      endPoint: 'home',
      method: 'saveMainPicture',
      data: {
        mainPictureID: mainPicture.mainPictureID,
        linkUrl: mainPicture.linkUrl,
        uploadID: mainPicture.uploadID,
      },
    })
      .then((mainPictureID: number) => {
        this.expireCache();
        deferred.resolve(mainPictureID);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  removeMainPicture(mainPictureID: number) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      //TODO error message ?
      endPoint: 'home',
      method: 'removeMainPicture',
      data: {
        mainPictureID,
      },
    })
      .then((result: boolean) => {
        this.expireCache();
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
