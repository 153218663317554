import { APIService } from '../services/api.service';
import { DiscountsService } from '../services/discounts.service';
import { TaxesService } from '../services/taxes.service';
import { ConfigService } from '../services/config.service';
import { FreightService, FreightLookupData } from '../services/freight.service';
import { GlobalErrorHandler } from '../services/globalErrorHandler';
import { Deferred } from '../helpers/deferred';
import { BaseOrder, OrderResponse, OrderResponseObject } from '../models/order/base';
import { OrderFile } from '../models/checkout';
import { DataLayer, ProcessRequests } from '.';
import { Big } from 'big.js';
import { OrderSaveData, OrderDataLayer, OrderAdvanceData, SQLVolumeDiscount } from './orders';
import { ConfigDataLayer } from './config';
import { RealLocation, ApplicableTaxes } from '../models/tax';
import { DiscountDataLayer } from './discounts';
import { ErrorsDataLayer, ErrorData } from './errors';
import { Subject } from 'rxjs';
import { TaxDataLayer } from './tax';
import { FreightDataLayer } from './freight';
import { BaseOrderItem } from '../models/orderItem/base';

class APIOrderDataLayer implements OrderDataLayer {
  constructor(
    protected dataLayer: DataLayer,
    protected apiService: APIService,
  ) {}

  async setAsQuote<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'setAsQuote',
        uniqueKey: order.key,
        data: {
          key: order.key,
          lastActive: order.lastActive,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  async load<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    this.apiService
      .queueRequest<OrderResponseObject<T>>({
        endPoint: 'orders',
        method: 'getMine',
        uniqueKey: `${order.key}|${order.lastActive}`,
        data: {
          key: order.key,
          lastActive: order.lastActive,
        },
      })
      .then((result: OrderResponseObject<T>) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  async save<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    saveData: OrderSaveData,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'save',
        uniqueKey: `${order.key}|${Object.keys(saveData).length}`,
        data: {
          key: order.key,
          lastActive: order.lastActive,
          fields: saveData,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  removeItem<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    item: T['items'][0],
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'removeItem',
        uniqueKey: `${order.key}|${item.key}`,
        data: {
          itemKey: item.key,
          key: order.key,
          lastActive: order.lastActive,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  saveItem<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    itemKey: string,
    saveItemData: { [key: string]: { [key: string]: unknown } }, // TODO any
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'saveItem',
        uniqueKey: `${order.key}|${itemKey}|${Object.keys(saveItemData).length}`,
        data: {
          key: order.key,
          itemKey,
          lastActive: order.lastActive,
          fields: saveItemData,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  addFile<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    uploadID: number,
    file: OrderFile,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'addFile',
        uniqueKey: `${file.fileName}|${file.size}`,
        data: {
          key: order.key,
          uploadID,
          file,
          lastActive: order.lastActive,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  removeFile<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    file: OrderFile,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'removeFile',
        uniqueKey: file.storageID,
        data: {
          key: order.key,
          file,
          lastActive: order.lastActive,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  advanceStatus<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    advanceData: OrderAdvanceData,
    sendDebugData?: boolean,
    isWholesale?: boolean,
    processRequests?: ProcessRequests,
  ): Promise<OrderResponseObject<T>> {
    const deferred = new Deferred<OrderResponseObject<T>>();

    if (this.apiService.isMasked && isWholesale) {
      advanceData['forceWholesale'] = true;
    }

    const data = {
      key: order.key,
      lastActive: order.lastActive,
      advanceData: advanceData,
      debugDump: sendDebugData ? order.export() : undefined,
    };

    this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'advance',
        uniqueKey: order.key,
        data,
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  addLogo<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    logoID: number,
    processRequests?: ProcessRequests,
  ): Promise<OrderResponseObject<T>> {
    const deferred: Deferred<OrderResponseObject<T>> = new Deferred();

    void this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'addLogo',
        uniqueKey: `${order.key}|${logoID}`,
        data: {
          key: order.key,
          lastActive: order.lastActive,
          logoID,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  setCustomPackaging<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    logoID: number,
    customPackaging: boolean,
    processRequests?: ProcessRequests,
  ): Promise<OrderResponseObject<T>> {
    const deferred: Deferred<OrderResponseObject<T>> = new Deferred();

    void this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'setCustomPackaging',
        uniqueKey: `${order.key}|${logoID}|${JSON.stringify(customPackaging)}`,
        data: {
          key: order.key,
          lastActive: order.lastActive,
          logoID,
          customPackaging,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  setBoxVisible<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    logoID: number,
    boxVisible: boolean,
    processRequests?: ProcessRequests,
  ): Promise<OrderResponseObject<T>> {
    const deferred: Deferred<OrderResponseObject<T>> = new Deferred();

    void this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'setBoxVisible',
        uniqueKey: `${order.key}|${logoID}|${JSON.stringify(boxVisible)}`,
        data: {
          key: order.key,
          lastActive: order.lastActive,
          logoID,
          boxVisible,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  removeLogo<T extends BaseOrder<BaseOrderItem>>(
    order: T,
    logoID: number,
    processRequests?: ProcessRequests,
  ): Promise<OrderResponseObject<T>> {
    const deferred: Deferred<OrderResponseObject<T>> = new Deferred();

    void this.apiService
      .queueRequest<OrderResponse<T>>({
        endPoint: 'orders',
        method: 'removeLogo',
        uniqueKey: `${order.key}|${logoID}`,
        data: {
          key: order.key,
          lastActive: order.lastActive,
          logoID,
        },
      })
      .then((result: OrderResponse<T>) => {
        if ('message' in result) {
          deferred.reject(result);
        } else {
          deferred.resolve(result);
        }
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }
}

class APIDiscountsDataLayer implements DiscountDataLayer {
  constructor(
    protected dataLayer: DataLayer,
    protected discountsService: DiscountsService,
    protected apiService: APIService,
  ) {}

  async getVolumeDiscount(
    amount: Big,
    nextStep: boolean = false,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<SQLVolumeDiscount | null> {
    const deferred = new Deferred<SQLVolumeDiscount | null>();

    this.discountsService
      .getVolumeDiscount(amount, nextStep)
      .then((volumeDiscount: SQLVolumeDiscount | null) => {
        deferred.resolve(volumeDiscount);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }
}

class APIConfigDataLayer implements ConfigDataLayer {
  constructor(
    protected dataLayer: DataLayer,
    protected configService: ConfigService,
    protected apiService: APIService,
  ) {}

  getLocations(processRequests: ProcessRequests = ProcessRequests.Pool): Promise<RealLocation[]> {
    const deferred = new Deferred<RealLocation[]>();

    this.configService
      .getLocations()
      .then((locations: RealLocation[]) => {
        deferred.resolve(locations);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  lookupLocation(
    province: string,
    country: string,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<RealLocation | null> {
    const deferred = new Deferred<RealLocation | null>();

    this.configService
      .lookupLocation(province, country)
      .then((location: RealLocation | null) => {
        deferred.resolve(location);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }
}

class APIFreightDataLayer implements FreightDataLayer {
  constructor(
    protected dataLayer: DataLayer,
    protected freightService: FreightService,
    protected apiService: APIService,
  ) {}

  lookup(
    amount: Big,
    postalCode: string,
    processRequests: ProcessRequests = ProcessRequests.Pool,
  ): Promise<FreightLookupData | null> {
    const deferred = new Deferred<FreightLookupData | null>();

    this.freightService
      .lookup(amount, postalCode)
      .then((data: FreightLookupData | null) => {
        deferred.resolve(data);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }

  lookupTime(postalCode: string, processRequests: ProcessRequests = ProcessRequests.Pool): Promise<string | null> {
    const deferred = new Deferred<string | null>();

    this.freightService
      .lookupTime(postalCode)
      .then((time: string | null) => {
        deferred.resolve(time);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }
}

class APITaxDataLayer implements TaxDataLayer {
  constructor(
    protected dataLayer: DataLayer,
    protected taxesService: TaxesService,
    protected apiService: APIService,
  ) {}

  filter(filter: string, processRequests: ProcessRequests = ProcessRequests.Pool): Promise<ApplicableTaxes> {
    const deferred = new Deferred<ApplicableTaxes>();

    this.taxesService
      .filter(filter)
      .then((taxes: ApplicableTaxes) => {
        deferred.resolve(taxes);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    if (processRequests) {
      this.apiService.processRequests();
    }

    return deferred.promise;
  }
}

class APIErrorsDataLayer implements ErrorsDataLayer {
  constructor(
    protected dataLayer: DataLayer,
    protected ErrorHandler: GlobalErrorHandler,
  ) {}

  errorsStream(): Subject<ErrorData> {
    return this.ErrorHandler.errorsStream();
  }

  reportError(error: Error, comment: string | undefined, silent: boolean): void {
    this.ErrorHandler.reportError(error, comment, silent);
  }

  handleError(error: Error): void {
    this.ErrorHandler.handleError(error);
  }
}

export class APIDataLayer implements DataLayer {
  order: OrderDataLayer;
  tax: TaxDataLayer;
  freight: FreightDataLayer;
  errors: ErrorsDataLayer;
  discounts: DiscountDataLayer;
  config: ConfigDataLayer;

  constructor(
    protected apiService: APIService,
    protected discountsService: DiscountsService,
    protected taxesService: TaxesService,
    protected configService: ConfigService,
    protected freightService: FreightService,
    protected errorHandler: GlobalErrorHandler,
  ) {
    this.order = new APIOrderDataLayer(this, apiService);
    this.tax = new APITaxDataLayer(this, taxesService, apiService);
    this.freight = new APIFreightDataLayer(this, freightService, apiService);
    this.errors = new APIErrorsDataLayer(this, errorHandler);
    this.discounts = new APIDiscountsDataLayer(this, discountsService, apiService);
    this.config = new APIConfigDataLayer(this, configService, apiService);
  }

  processRequests(): void {
    this.apiService.processRequests();
  }
}
