<div
  id="content"
  [hidden]="!loaded"
>
  <div
    id="maintext"
    class="holidayHoursPage"
    [class.preview]="loggedInStaff"
  >
    <h2 [hidden]="hoursEnabled">No special hours at this time.</h2>

    <div
      *ngFor="let image of images"
      [class.previewOnly]="!hoursEnabled"
    >
      <img
        class="holidayImage"
        [src]="image.src"
      />
    </div>
  </div>
</div>
