<div
  [class.appContainer]="!apiService.isAdmin"
  [class.adminContainer]="apiService.isAdmin"
>
  <ng-container *ngIf="!apiService.isAdmin">
    <div
      id="json-ld"
      [innerHTML]="schema | safe: 'html'"
    ></div>
    <ccf-staff-tools></ccf-staff-tools>
    <div
      id="topMessageContainer"
      *ngIf="topMessage && !isBot"
      [innerHTML]="topMessage.content | safe: 'html'"
    ></div>
    <div id="topStrip">
      <div class="pageFit">
        <img
          src="/images/dynamic/gen/banner.png"
          alt="Charlies Logo"
        />
      </div>
      <div class="strip">
        <div class="pageFit">
          <div
            class="welcomeMessage"
            [hidden]="!loggedIn"
          >
            &nbsp;<a
              class="goHome"
              [routerLink]="userHome"
              >{{ welcomeMessage }}</a
            >&nbsp;
            <div class="accountType">
              <a
                class="goHome"
                [routerLink]="userHome"
                >{{ dashboardText }}</a
              >
            </div>
          </div>

          <!--<div class='pdf_display' *ngIf='!(accountType === "Wholesale" || accountType === "Staff")'>
            <div>
              <a target='_blank' href='/pdfs/2024/Christmas%20-%20Brochure.pdf'>
                <img src='/images/dynamic/gen/christmas-cover.jpg' alt='' /><br />
                <span class='topTexts'>Christmas Brochure</span>
              </a>
            </div>
            <div class='orderSheetLink'>
              <a [routerLink]="'/OrderSheet/Christmas/Order'">
                Order<span> Sheet</span>
              </a>
            </div>
          </div>!-->

          <div
            class="fundOrderSheet"
            [hidden]="!fundraisingActive"
          >
            <a [routerLink]="'/Fundraising/Christmas/Order'">Fundraising OrderSheet</a>
          </div>

          <ccf-search-bar></ccf-search-bar>

          <div class="bestSellers">
            <a [routerLink]="'/products/Best-Sellers'"> Best Sellers </a>
          </div>
        </div>
      </div>
    </div>

    <div id="header">
      <div id="scrollToTop">
        <div>
          <div (click)="scrollToTop()">Scroll to Top</div>
        </div>
      </div>

      <!--<button type='button' style='position:fixed' (click)='makeError()'>TODO PRE_LAUNCH ERROR TEST</button>!-->

      <div class="logo">
        <a [routerLink]="['/']"
          ><img
            src="/images/dynamic/gen/banner.png"
            alt="Charlies Logo"
        /></a>
      </div>

      <div class="extraNotifications">
        <ul class="notificationsDesktop">
          <li>
            <a
              [routerLink]="['/Holiday-Hours']"
              class="holidayHours"
              [hidden]="!holidayHours"
            >
              <img
                src="/images/static/holiday-hours.png"
                alt=""
              /><br />
              <span class="topTexts">Holiday Hours</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/Contact-Us']"
              class="contactUs"
            >
              <img
                src="/images/static/email-icon.png"
                alt=""
              /><br />
              <span class="topTexts">Contact Us</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/Locations-and-Hours']"
              class="locations"
            >
              <img
                src="/images/static/location-with-map-icon-inverted.png"
                alt=""
              /><br />
              <span class="topTexts">Locations / Hours</span>
            </a>
          </li>
        </ul>

        <ccf-cart-indicator></ccf-cart-indicator>
      </div>

      <ccf-desktop-menubar></ccf-desktop-menubar>
      <ccf-mobile-menu [hidden]="onFrontPage"></ccf-mobile-menu>
    </div>
  </ng-container>

  <ng-container *ngIf="apiService.isAdmin && loggedIn">
    <div
      id="adminHeader"
      [hidden]="isPrinting"
    >
      <div id="scrollToTop">
        <div>
          <div (click)="scrollToTop()">Scroll to Top</div>
        </div>
      </div>

      <div
        class="topMenu"
        [hidden]="isPrinting"
      >
        <ul>
          <li></li>
          <li>
            <a
              tabindex="-1"
              [routerLink]="['/']"
              title="Goto Front End"
              >&larr;</a
            >
          </li>
          <li *ngIf="permissions['FRONT_PAGE_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/frontPage']"
              >Front Page</a
            >
          </li>
          <li *ngIf="permissions['CONTENT_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/content']"
              >Content</a
            >
          </li>
          <li *ngIf="permissions['PRODUCTS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/products']"
              >Products</a
            >
          </li>
          <li *ngIf="permissions['CATEGORIES_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/categories']"
              >Categories</a
            >
          </li>
          <li *ngIf="permissions['SECTIONS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/sections']"
              >Sections</a
            >
          </li>
          <li *ngIf="permissions['UNITS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/units']"
              >Units</a
            >
          </li>
          <li *ngIf="permissions['TYPES_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/types']"
              >Types</a
            >
          </li>
          <li *ngIf="permissions['SUPPLIERS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/suppliers']"
              >Suppliers</a
            >
          </li>
          <li *ngIf="permissions['LABELS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/labels']"
              >Labels</a
            >
          </li>
          <li *ngIf="permissions['CORPLOGOS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/corpLogos']"
              >Corp Logos</a
            >
          </li>
          <li *ngIf="permissions['GALLERY_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/gallery']"
              >Gallery</a
            >
          </li>
          <li *ngIf="permissions['DISCOUNTS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/discounts']"
              >Discounts</a
            >
          </li>
          <li *ngIf="permissions['FUNDRAISING_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/fundraising']"
              >Fundraising</a
            >
          </li>
          <li *ngIf="permissions['ORDERS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/orders']"
              >Orders</a
            >
          </li>
          <li *ngIf="permissions['ORDERS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/donations']"
              >Donations</a
            >
          </li>
          <li *ngIf="permissions['MESSAGES_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/messages']"
              >Messages</a
            >
          </li>
          <li *ngIf="permissions['ERRORS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/errors']"
              >Errors</a
            >
          </li>
          <li *ngIf="permissions['EMAILS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/emails']"
              >Emails</a
            >
          </li>
          <li *ngIf="permissions['IPN_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/ipn']"
              >IPN</a
            >
          </li>
          <!--<li *ngIf='permissions.QRCODES_VIEW'><a tabindex='-1' [routerLink]='["/admin/qr"]'>QR Codes</a></li>!-->
          <li *ngIf="permissions['PAGES_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/pages']"
              >Pages</a
            >
          </li>
          <li *ngIf="permissions['SEARCH_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/search']"
              >Search</a
            >
          </li>
          <li
            *ngIf="
              permissions['ADVANCED_ONLINE_PAYMENTS'] ||
              permissions['ADVANCED_FREIGHT_SETUP'] ||
              permissions['ADVANCED_TAX_SETUP']
            "
          >
            <a
              tabindex="-1"
              [routerLink]="['/admin/advanced']"
              >Advanced</a
            >
          </li>
          <li *ngIf="permissions['ACCOUNTS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/accounts']"
              >Accounts</a
            >
          </li>
          <li *ngIf="permissions['LOGIN_STATS_VIEW']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/loginAttempts']"
              >Login Attempts</a
            >
          </li>
          <li *ngIf="permissions['CHANGE_PASSWORD']">
            <a
              tabindex="-1"
              [routerLink]="['/admin/changePassword']"
              >Change Password</a
            >
          </li>
          <li>
            <a
              *ngIf="loggedIn"
              tabindex="-1"
              (click)="logout()"
              >Logout</a
            >
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  </ng-container>

  <router-outlet></router-outlet>

  <ng-container *ngIf="!apiService.isAdmin">
    <div id="footer">
      <ul class="links">
        <li>&nbsp;</li>
        <li>
          <strong>Company Info</strong>
          <ul>
            <li>
              <a [routerLink]="['/Locations-and-Hours']">Locations &amp; Hours</a>
            </li>
            <li><a [routerLink]="['/Our-Story']">Our Story</a></li>
            <li><a [routerLink]="['/Job-Postings']">Job Postings</a></li>
            <li><a [routerLink]="['/Contact-Us']">Contact Us</a></li>
          </ul>
        </li>

        <li>
          <strong>Shopping</strong>
          <br />
          <ul>
            <li>
              <a [routerLink]="['/Corporate-Logos/Order']">Corporate Logo Order Form</a>
            </li>
            <li>
              <a [routerLink]="['/Frequently-Asked-Questions/Shipping-Policy']">Shipping Policy</a>
            </li>
            <li>
              <a [routerLink]="['/Frequently-Asked-Questions/Return-Exchange-Policy']">Return / Exchange Policy</a>
            </li>
            <li>
              <a [routerLink]="['/Frequently-Asked-Questions/Volume-Discounts']">Volume Discounts</a>
            </li>
            <li>
              <a [routerLink]="['/Frequently-Asked-Questions/Wholesale']">Wholesale Stores</a>
            </li>
          </ul>
        </li>

        <li>
          <strong>Questions</strong>
          <br />
          <ul>
            <li>
              <a [routerLink]="['/Frequently-Asked-Questions']">Frequently Asked Questions</a>
            </li>
            <li><a [routerLink]="['/Donations']">Donations</a></li>
            <li>
              <a [routerLink]="['/Frequently-Asked-Questions/Tours']">Tours</a>
            </li>
            <li><a [routerLink]="['/Fundraising']">Fundraising</a></li>
            <li>
              <a [routerLink]="['/products/Monthly-Specials']">Monthly Specials</a>
            </li>
          </ul>
        </li>
      </ul>

      <div class="bottomlink-text">
        <span
          class="copyright"
          title="No part of this website, the pages contained herein, graphics or prices may be reproduced without the written permission of Charlie&#39;s Chocolate Factory"
          >&copy; &nbsp; {{ currentYear }} Charlie's Chocolate Factory. All rights reserved.</span
        >

        <div class="footer-right">
          <a
            [hidden]="loggedIn"
            class="login"
            (click)="login()"
            >Login</a
          >
          <a
            [hidden]="!loggedIn"
            class="logout"
            (click)="logout()"
            >Logout</a
          >
          <!-- TODO PRE_LAUNCH <a class='viewMobileSite' (click)="browser.setMobile(true)">View Mobile Site</a> !-->
          <!-- TODO PRE_LAUNCH <a class='viewFullSite' (click)='browser.setMobile(false)'>View Full Site</a> !-->

          <span>
            Visit us on
            <a
              class="facebook"
              rel="noopener"
              href="https://www.instagram.com/charlieschocolates/"
              target="_blank"
              ><img
                src="/images/static/instagram.png"
                width="24"
                height="24"
                alt="Link to Instagram"
            /></a>
            <a
              class="facebook"
              rel="noopener"
              href="https://www.facebook.com/charlieschocolate"
              target="_blank"
              ><img
                src="/images/static/minifb.gif"
                width="24"
                height="24"
                alt="Link to facebook"
            /></a>
          </span>
        </div>
      </div>
    </div>
  </ng-container>

  <ccf-error-alert *ngIf="!isBot"></ccf-error-alert>
</div>
