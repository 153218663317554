<mat-dialog-content>
  <div class="jobModal">
    <span
      class="close"
      (click)="closeDialog()"
      >Close</span
    >
    <strong
      ><u>{{ job.fullTitle }}</u></strong
    >
    <div
      *ngIf="content"
      class="renderArea"
      [innerHTML]="content | safe: 'html'"
    ></div>
    <div *ngIf="legacy">
      Location: {{ legacy.location }}<br />
      <br />
      Hours: {{ legacy.hours }}<br />
      <br />
      <div
        class="opening"
        [hidden]="!legacy.opening"
      >
        {{ legacy.opening }}
      </div>
      <div
        class="requirements"
        [hidden]="legacy.requirements.length < 1"
      >
        <u>Requirements:</u>
        <ul class="list">
          <li *ngFor="let requirement of legacy.requirements">
            {{ requirement.text }}
          </li>
        </ul>
        <br />
      </div>
      <u>Job Duties &amp; Description:</u>
      <ul class="list">
        <li *ngFor="let duty of legacy.duties">{{ duty.text }}</li>
      </ul>
      <div
        class="closing"
        [hidden]="!legacy.closing"
      >
        {{ legacy.closing }}
      </div>
      <br />
      If you are interested in the job &amp; have qualifications required, we ask that you email a resume to
      <ccf-email-link emailAddress="Jobs"></ccf-email-link> with the subject as "{{ legacy.emailSubject }}". We will not
      accept phone calls or an email to any other company email address. We will only contact those who have been
      selected.
    </div>
  </div>
</mat-dialog-content>
