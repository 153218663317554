import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextDialogComponent } from './text/textDialog.component';
import { AlertDialogComponent } from './alert/alertDialog.component';
import { PromptDialogComponent } from './prompt/promptDialog.component';
import { SelectDialogComponent } from './select/selectDialog.component';
import { FormsModule } from '@angular/forms';
import { LoginDialogComponent } from './login/loginDialog.component';
import { AddLogoDialogComponent } from './addLogo/addLogoDialog.component';
import { SendToDialogComponent } from './sentTo/sendTo.component';
import { LabelPrintDialogComponent } from './labelPrint/labelPrintDialog.component';
import { DirectivesModule } from '../../directives';
import { MatDatepickerModule } from '@angular/material/datepicker';

export type ValidationCallback = (message: string) => void;

@NgModule({
  imports: [FormsModule, CommonModule, DirectivesModule, MatDatepickerModule],
  declarations: [
    AlertDialogComponent,
    PromptDialogComponent,
    SelectDialogComponent,
    LoginDialogComponent,
    TextDialogComponent,
    AddLogoDialogComponent,
    SendToDialogComponent,
    LabelPrintDialogComponent,
  ],
  exports: [
    AlertDialogComponent,
    PromptDialogComponent,
    SelectDialogComponent,
    LoginDialogComponent,
    TextDialogComponent,
    AddLogoDialogComponent,
    SendToDialogComponent,
    LabelPrintDialogComponent,
  ],
})
export class DialogsModule {}
