import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { APIService } from '../../../common/services/api.service';

import { Subscription } from 'rxjs';
import { UserAccount } from '../../../common/models/account';
import { MessagesService, UserMessage } from '../../../common/services/messages.service';

@Component({
  selector: 'ccf-customer-page-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.less'],
  providers: [],
})
export class CustomerMenuComponent implements OnInit, OnDestroy {
  protected _subscriptions: Subscription[] = [];
  loggedIn: boolean = false;

  accountData: UserAccount | null = null;

  userHome: string = '';

  @Input() page: string = '';

  private _newMessages: number = 0;
  @Input() set newMessages(value: number) {
    this._newMessages = value;
  }
  get newMessages(): number {
    return this._newMessages;
  }

  constructor(
    private apiService: APIService,
    private messagesService: MessagesService,
  ) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this.apiService.authenticatedDataStream().subscribe((data?: UserAccount | null) => {
        if (typeof data !== 'undefined') {
          const loggedIn = data !== null;

          this.accountData = data;
          this.loggedIn = loggedIn;
        }
      }),
    );

    this.refresh();
  }

  refresh(): void {
    void this.messagesService.getMessagesForMe().then((messages: UserMessage[]) => {
      this.newMessages = 0;
      for (const message of messages) {
        if (!message.viewed) {
          this.newMessages++;
        }
      }
    });

    this.apiService.processRequests();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
