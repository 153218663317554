import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '../common/services/config.service';
import { MenuService } from '../common/services/menu.service';
import { SectionsService } from '../common/services/sections.service';
import { PageService } from '../common/services/page.service';
import { AuthService } from '../common/services/auth.service';
import { OrdersService } from '../common/services/orders.service';
import { APIService } from '../common/services/api.service';

import {
  Router,
  ActivatedRouteSnapshot,
  NavigationStart,
  NavigationEnd,
  Scroll,
  NavigationError,
} from '@angular/router';
import { Subscription } from 'rxjs';

import { pairwise } from 'rxjs/operators';
import { GtagService } from '../common/services/gtag.service';
import { UserAccount, AccountType } from '../common/models/account';
import { FundraisingGroupsService } from '../common/services/fundraisingGroups.service';
import { CategoriesService } from '../common/services/categories.service';
import { DiscountsService } from '../common/services/discounts.service';
import { TaxesService } from '../common/services/taxes.service';
import { PrintService } from '../common/services/print.service';
import { FreightService } from '../common/services/freight.service';
import { MessagesService } from '../common/services/messages.service';
import { CCFMessage } from '../common/models/messages';
import { PermissionsHad } from '../common/models/permission';
import { makeFakeRouterLinks } from '../common/helpers/routerLinkReplacer';
import { AM_I_BOT } from '../common/helpers/whichBot';
import * as pluralize from 'pluralize';
import { Location } from '@angular/common';
import { APP_TITLE } from '../common/models/config';

declare const FRONTEND_PACKAGE_VERSION: string;

const generateLdJson = (data: Record<string, unknown>) =>
  `<script type="application/ld+json">${JSON.stringify(data, null, 2).replace(/<\/script>/g, '<\\/script>')}</script>`;
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.less'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  url = window.location.protocol + '//' + window.location.host;
  schema = generateLdJson({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: this.url,
    potentialAction: {
      '@type': 'SearchAction',
      target: this.url + '/Search/{search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  });

  loggedIn: boolean = false;
  isPrinting: boolean = false;
  permissions: PermissionsHad = {};

  private settings: { [settingName: string]: unknown } = {};
  currentYear = new Date().getFullYear();
  private scrollVisible: boolean = false;
  private scrollPoint: number = 1200; // This is about three rows of products

  holidayHours: boolean = false;
  jobsEnabled: boolean = false;

  onFrontPage: boolean = false;

  fundraisingActive: boolean = false;

  welcomeMessage: string = '';
  dashboardText: string = '';
  accountType: AccountType | null = null;
  userHome: string = '/';

  topMessage: CCFMessage | null = null;

  isBot: boolean = AM_I_BOT;
  private topMessageElement?: JQuery<HTMLElement>;

  private _subscriptions: Subscription[] = [];

  constructor(
    public apiService: APIService,
    private configService: ConfigService,
    private sectionsService: SectionsService,
    private menuService: MenuService,
    private authService: AuthService,
    private ordersService: OrdersService,
    private router: Router,
    private pageService: PageService,
    private gtagService: GtagService,
    private categoriesService: CategoriesService,
    private fundraisingGroupsService: FundraisingGroupsService,
    private discountsService: DiscountsService,
    private taxesService: TaxesService,
    private freightService: FreightService,
    private messagesService: MessagesService,
    private printService: PrintService,
    private location: Location,
  ) {
    self.CCF.LOADED = true;

    if (!this.isBot) {
      window.DD_RUM.onReady(() => {
        window.DD_RUM.init({
          clientToken: 'pub64e10d7c235dd5067a3cf4f6fcb25d39',
          applicationId: 'd5f82101-2263-4f31-9fa8-fbdb9668e60e',
          site: 'us3.datadoghq.com',
          service: 'charlies-website',
          env: window['isDev'] ? 'dev' : 'prod',
          // Specify a version number to identify the deployed version of your application in Datadog
          version: FRONTEND_PACKAGE_VERSION,
          sessionSampleRate: 10,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });

        window.DD_RUM.startSessionReplayRecording();
      });
    }

    window.DD_LOGS.onReady(() => {
      window.DD_LOGS.setGlobalContextProperty('version', FRONTEND_PACKAGE_VERSION);
    });

    //TODO this guards against questionable suggestions
    pluralize.addUncountableRule('black');
    pluralize.addUncountableRule('white');

    if (navigator.appVersion.indexOf('Trident/') !== -1) {
      if (navigator.appVersion.indexOf('MSIE 10.0') !== -1) {
        jQuery('html').addClass('IE10');
      }
      if (navigator.appVersion.indexOf('rv:11.0') !== -1) {
        jQuery('html').addClass('IE11');
      }
    }
  }

  private checkIsAdmin(uri: string): void {
    const isAdminOld = this.apiService.isAdmin;
    this.apiService.isAdmin = uri === '/admin' || uri.indexOf('/admin/') !== -1;
    if (isAdminOld !== this.apiService.isAdmin) {
      // TODO not great but it works
      if (this.apiService.isAdmin) {
        jQuery('html').addClass('html_isAdmin');
      } else {
        jQuery('html').removeClass('html_isAdmin');
      }
    }
  }

  private getTitleData(routeSnapshot: ActivatedRouteSnapshot): Array<string> {
    let titles: Array<string>;
    if (routeSnapshot.firstChild) {
      titles = this.getTitleData(routeSnapshot.firstChild);
    } else {
      titles = [];
    }
    if (routeSnapshot.routeConfig && routeSnapshot.routeConfig.data && routeSnapshot.routeConfig.data['title']) {
      if (typeof routeSnapshot.routeConfig.data['title'] === 'string') {
        titles.unshift(routeSnapshot.routeConfig.data['title']);
      } else {
        titles.unshift(...routeSnapshot.routeConfig.data['title']);
      }
    }
    return titles;
  }

  private getComponentDataName(routeSnapshot: ActivatedRouteSnapshot): string | null {
    if (routeSnapshot.firstChild) {
      return this.getComponentDataName(routeSnapshot.firstChild);
    }

    return (routeSnapshot.data['_name'] as string) || null;
  }

  ngOnInit(): void {
    let routeScrollPositions: { [uri: string]: number } = {};
    let previousPage: string;

    this._subscriptions.push(
      // save or restore scroll position on route change
      this.router.events.pipe(pairwise()).subscribe(([prevRouteEvent, currRouteEvent]) => {
        if (currRouteEvent instanceof NavigationError) {
          this.location.replaceState(currRouteEvent.url);
          this.router.navigateByUrl('/Page-Not-Found', {
            skipLocationChange: true,
          });
        } else if (prevRouteEvent instanceof Scroll) {
          //TODO use it when it works
          prevRouteEvent = prevRouteEvent.routerEvent;
        } else if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
          const page = this.getComponentDataName(this.router.routerState.snapshot.root) || '';
          previousPage = page;
          if (page === 'ProductsListingPageComponent' && routeScrollPositions) {
            routeScrollPositions[prevRouteEvent.urlAfterRedirects || prevRouteEvent.url] = window.pageYOffset;
          }
        } else if (currRouteEvent instanceof NavigationEnd) {
          const page = this.getComponentDataName(this.router.routerState.snapshot.root) || '';
          if (previousPage === 'ProductViewPageComponent' && page === 'ProductsListingPageComponent') {
            self.CCF.scrollTo = routeScrollPositions[currRouteEvent.urlAfterRedirects || currRouteEvent.url] || 0;
          } else if (
            (previousPage === 'FAQPageComponent' && page === 'FAQPageComponent') ||
            (previousPage === 'JobsPageComponent' && page === 'JobsPageComponent')
          ) {
            // No scroll
          } else {
            window.scrollTo(0, 0);
            self.CCF.scrollTo = 0;
          }

          if (page !== 'ProductViewPageComponent') {
            routeScrollPositions = {};
          }

          this.endofRouting();
        }
      }),
    );

    //TODO combine these subscriptions
    this._subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.pageService.resetTitle();
        }

        if (event instanceof NavigationEnd) {
          this.onFrontPage = event.urlAfterRedirects === '/';

          this.checkIsAdmin(event.urlAfterRedirects);

          const titles = this.getTitleData(this.router.routerState.snapshot.root);
          titles.push(APP_TITLE);

          this.pageService.setBaseTitle(titles);
          setTimeout(() => {
            // This makes sure the other titles probably go through as they are already in queue to be processed
            this.gtagService.pageView(this.pageService.getTitleString(), event.urlAfterRedirects);
          });
        }
      }),
    );

    this._subscriptions.push(
      this.apiService.loginRequiredStream().subscribe((status?: boolean) => {
        if (typeof status !== 'undefined') {
          if (status) {
            const dialogRef = this.authService.promptLogin({ blocking: true });

            void dialogRef
              .afterClosed()
              .toPromise()
              .then((result) => {
                if (result) {
                  this.apiService.requeuePendingAuth();
                  this.apiService.processRequests();
                } else {
                  window.location.pathname = '/';
                }
              });
          }
        }
      }),
    );

    this._subscriptions.push(
      this.printService.isPrinting.subscribe((isPrinting: boolean) => {
        this.isPrinting = isPrinting;
      }),
    );

    this._subscriptions.push(
      this.apiService.permissionsStream().subscribe((permissions?: PermissionsHad) => {
        if (typeof permissions !== 'undefined') {
          this.permissions = permissions;
        }
      }),
    );

    this.trackScroll();
    document.addEventListener(
      'scroll',
      () => this.trackScroll(),
      self.CCF.supportsPassiveListeners ? { passive: true } : false,
    );

    // Because caching
    void this.configService.getAllConfig();

    // Used to show fundraising links
    void this.fundraisingGroupsService.fundraisingActive().then((result: boolean) => {
      // TODO subscription
      this.fundraisingActive = result;
    });

    // Used by the cart
    void this.configService.getLocations();
    // TODO promo this.DiscountsService.getDiscountsEnabled();
    void this.discountsService.refreshVolumeDiscounts();
    void this.taxesService.all();
    void this.freightService.reload();

    // Holiday hours and jobs enabled are not config settings
    void this.configService.getHolidayHoursEnabled().then((holidayHours: boolean) => {
      this.holidayHours = holidayHours; // TODO POST_LAUNCH this should be a subscription based thing
    });
    void this.configService.getJobsEnabled().then((jobsEnabled: boolean) => {
      this.jobsEnabled = jobsEnabled; // TODO this should be a subscription based thing
    });
    void this.sectionsService.all();
    void this.categoriesService.all();
    void this.menuService.getMenus();
    void this.menuService.getMobileMenus();
    void this.ordersService.loadAllCarts();
    void this.messagesService.loadSpecial().then((messages: CCFMessage[]) => {
      this.messagesLoaded(messages);
    });

    this._subscriptions.push(
      this.apiService.authenticatedDataStream().subscribe((data?: UserAccount | null) => {
        if (typeof data !== 'undefined') {
          if (data !== null) {
            if (!this.loggedIn) {
              window.DD_RUM.onReady(() =>
                window.DD_RUM.setUser({
                  id: data.username,
                  name: data.displayName,
                  email: data.email,
                  accountType: data.accountType,
                }),
              );
            }

            this.loggedIn = true;
            if (data.displayName) {
              this.welcomeMessage = 'Welcome ' + data.displayName + '!';
            }
            this.accountType = data.accountType;
            this.userHome = data.accountTypeURL;
            if (this.accountType === 'Staff') {
              this.dashboardText = 'Go to Backend';
            } else if (this.accountType === 'Wholesale') {
              this.dashboardText = 'Wholesale Dashboard';
            }
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            else if (this.accountType === 'Customer') {
              this.dashboardText = 'Customer Dashboard';
            }
          } else {
            if (this.loggedIn) {
              window.DD_RUM.onReady(() => window.DD_RUM.clearUser());
            }
            this.loggedIn = false;
            this.welcomeMessage = '';
            this.dashboardText = '';
            this.accountType = null;
            this.userHome = '/';
          }
        }
      }),
    );

    this.apiService.processRequests();
  }

  private endofRouting(): void {
    void this.messagesService.loadSpecial().then((messages: CCFMessage[]) => {
      this.messagesLoaded(messages);
    });

    this.apiService.processRequests();
  }

  private fakeRouterClick(event: JQueryEventObject) {
    const dest = jQuery(event.target).attr('href');

    if (dest) {
      void this.router.navigateByUrl(dest);
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private messagesLoaded(messages: CCFMessage[]): void {
    jQuery(document).off('click', 'a[fakerouter].inAppComponent');
    jQuery(document).on('click', 'a[fakerouter].inAppComponent', (event: JQueryEventObject) =>
      this.fakeRouterClick(event),
    );

    let newTopMessage: CCFMessage | undefined;
    for (const message of messages) {
      if (message.subject === 'Top Message') {
        newTopMessage = message;
      }
    }

    if (newTopMessage) {
      newTopMessage.content = makeFakeRouterLinks(newTopMessage.content);

      this.topMessage = newTopMessage;
    }

    setTimeout(() => {
      if (!this.topMessageElement || this.topMessageElement.length === 0) {
        this.topMessageElement = jQuery('#topMessageContainer');
      }

      if (newTopMessage) {
        this.topMessageElement.animate(
          {
            height: 'show',
          },
          1000,
        );
      } else {
        this.topMessageElement.animate(
          {
            height: 'hide',
          },
          1000,
          () => {
            this.topMessage = null;
          },
        );
      }
    }, 0);
  }

  login(): void {
    this.authService.promptLogin({ goHome: true });
  }

  logout(): void {
    void this.authService.logout();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());

    document.removeEventListener('scroll', () => this.trackScroll(), false); // TODO this dosn't work
  }

  scrollToTop(): void {
    jQuery('html, body').animate({ scrollTop: 0 }, 'fast');
  }

  trackScroll(): void {
    const scroll =
      document.body.scrollTop ||
      (typeof document.documentElement !== 'undefined' && document.documentElement.scrollTop) ||
      0;
    if (scroll < this.scrollPoint && this.scrollVisible) {
      this.scrollVisible = false;
      jQuery('#scrollToTop').animate({ opacity: 0 }, 'fast');
    } else if (scroll >= this.scrollPoint && this.scrollVisible === false) {
      this.scrollVisible = true;
      jQuery('#scrollToTop').animate({ opacity: 1 }, 'fast');
    }
  }
}
