import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { DDL, DDLDefaultValue } from '../../common/helpers/dropdownlist';
import { DialogService } from '../../common/helpers/dialogs/dialog.service';
import { OrderType, OrderSheetType, OrderSheetSeason } from '../../common/models/checkout';
import { DialogComponent, StaticDialogComponent_Metadata } from '../../common/helpers/dialogs/dialogComponent';
import { StaticImplements } from 'src/common/helpers/staticImplements';

const fundraising: Array<Option<OrderSheetType>> = [
  {
    label: 'Normal',
    value: OrderSheetType.Normal,
  },
  {
    label: 'Fundraising',
    value: OrderSheetType.Fundraising,
  },
];

const seasons: Array<Option<OrderSheetSeason>> = [
  {
    label: 'Valentines',
    value: OrderSheetSeason.Valentines,
    options: fundraising,
  },
  {
    label: 'Easter',
    value: OrderSheetSeason.Easter,
    options: fundraising,
  },
  {
    label: 'Christmas',
    value: OrderSheetSeason.Christmas,
    options: fundraising,
  },
];

export const OrderOptions: Array<Option<OrderType>> = [
  {
    label: 'Cart',
    value: OrderType.Cart,
  },
  {
    label: 'OrderSheet',
    value: OrderType.OrderSheet,
    options: seasons,
  },
  {
    label: 'CorpLogo',
    value: OrderType.CorpLogo,
  },
  {
    label: 'CustomLabel',
    value: OrderType.CustomLabel,
  },
  {
    label: 'Fountain',
    value: OrderType.Fountain,
  },
];

export interface Option<T = unknown> {
  label: string;
  value?: T;
  options?: Option[];
}

type QuoteDDL<T = unknown, D extends DDLDefaultValue = undefined> = DDL<Option<T>, 'value', D>;

export interface QuoteData {
  useExisting: boolean;
  type: OrderType;
  season?: OrderSheetSeason;
  sheetType?: OrderSheetType;
}

export type QuoteResult = QuoteData | undefined;

export interface QuoteParams {
  message: string | SafeHtml;
  no: string;
  yes: string;
}

@Component({
  selector: 'ccf-quote-dialog',
  templateUrl: 'startQuote.component.html',
  styleUrls: ['startQuote.component.less'],
  providers: [],
})
@StaticImplements(StaticDialogComponent_Metadata)
export class StartQuoteDialogComponent extends DialogComponent<QuoteParams, QuoteResult> implements OnInit, OnDestroy {
  public static DIALOG_CLASS = 'quoteDialog';
  public static DEFAULT_CONFIG: MatDialogConfig = {};

  typeDDL: QuoteDDL<OrderType>;
  seasonDDL?: QuoteDDL<OrderSheetSeason>;
  sheetTypeDDL?: QuoteDDL<OrderSheetType, OrderSheetType>;

  useExisting: boolean = false;
  validationMessage: string = '';

  constructor(
    private DialogService: DialogService,
    public override matDialogRef: MatDialogRef<StartQuoteDialogComponent, QuoteResult>,
    @Inject(MAT_DIALOG_DATA) public override params: QuoteParams,
  ) {
    super(matDialogRef);

    this.typeDDL = new DDL<Option<OrderType>, 'value', undefined>(OrderOptions, undefined, 'value', undefined);
  }

  typeDDL_changed(ddl: QuoteDDL<OrderType>): void {
    if (ddl.selected?.options) {
      this.seasonDDL = new DDL<Option<OrderSheetSeason>, 'value', undefined>(
        ddl.selected.options as Option<OrderSheetSeason>[],
        undefined,
        'value',
        undefined,
      );
    } else {
      delete this.seasonDDL;
      delete this.sheetTypeDDL;
    }
  }

  seasonDDL_changed(ddl: QuoteDDL<OrderSheetSeason>): void {
    if (ddl.selected?.options) {
      this.sheetTypeDDL = new DDL<Option<OrderSheetType>, 'value', OrderSheetType>(
        ddl.selected.options as Option<OrderSheetType>[],
        ddl.selected.options[0] as Option<OrderSheetType>,
        'value',
        ddl.selected.options[0].value as OrderSheetType,
      );
    } else {
      delete this.sheetTypeDDL;
    }
  }

  sheetTypeDDL_changed(ddl: QuoteDDL<OrderSheetType, OrderSheetType>): void {
    // Do nothing
  }

  no(): void {
    this.closeDialog();
  }

  yes(): void {
    if (this.validate() && typeof this.typeDDL.value !== 'undefined') {
      const value: QuoteData = {
        useExisting: this.useExisting,
        type: this.typeDDL.value,
        season: this.seasonDDL?.value,
        sheetType: this.sheetTypeDDL?.value,
      };
      this.closeDialog(value);
    }
  }

  validate(): boolean {
    this.validationMessage = '';

    if (typeof this.typeDDL.value === 'undefined') {
      this.validationMessage = 'Please select a type of order';
      return false;
    } else if (this.seasonDDL) {
      if (typeof this.seasonDDL.value === 'undefined') {
        this.validationMessage = 'Please select a season';
        return false;
      } else if (this.sheetTypeDDL) {
        if (typeof this.sheetTypeDDL.value === 'undefined') {
          this.validationMessage = 'Please select an order sheet type';
          return false;
        }
      }
    }

    return true;
  }

  override ngOnDestroy() {
    this.DialogService.closed();
  }

  ngOnInit() {
    this.DialogService.opened();
  }
}
