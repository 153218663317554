import { Dictionary } from 'ts-essentials';
import { isPrimitive, isPrimitiveMatcher } from './isPrimitive';

export interface CustomStringifyOpts {
  showAllProps?: boolean;
  depthLimit?: number;
  skipKeys?: Array<string | number>;
}

export const escapeString = (value: string): string => {
  const replaces = [
    { match: /\\/g, replace: '\\\\' },
    { match: /\b/g, replace: '\\b' },
    { match: /\f/g, replace: '\\f' },
    { match: /\n/g, replace: '\\n' },
    { match: /\r/g, replace: '\\r' },
    { match: /\t/g, replace: '\\t' },
    { match: /"/g, replace: '"' },
  ];

  for (const r of replaces) {
    value.replace(r.match, r.replace);
  }
  return value;
};

type JSON_PRIMITIVE = string | number | boolean;
const JSON_PRIMITIVE_MATCHER: isPrimitiveMatcher<JSON_PRIMITIVE> = /string|number|boolean/;

export function betterStringify(thing: unknown, stringifyOpts?: CustomStringifyOpts): string {
  const showAllProps: undefined | boolean = stringifyOpts && stringifyOpts.showAllProps;
  const depthLimit: undefined | number = stringifyOpts && stringifyOpts.depthLimit;
  const skipKeys: undefined | Array<string | number> = stringifyOpts && stringifyOpts.skipKeys;

  const OPEN = {
    object: '{',
    array: '[',
  };

  const CLOSE = {
    object: '}',
    array: ']',
  };

  const objects: Array<object> = [];
  const objectPaths: Array<string> = [];

  // generates the json
  const toJson = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: any | undefined,
    type: 'object' | 'array',
    depth: number,
    path: string,
  ): string => {
    if (obj === null) {
      return 'null';
    }

    const objIndex = objects.indexOf(obj);
    if (objIndex !== -1) {
      return `"->Reference:${escapeString(objectPaths[objIndex])}<-"`;
    } else {
      objects.push(obj);
      objectPaths.push(path);
    }

    const items: Array<string> = [];

    depth++;

    const action = (key: string | number, value: unknown): void => {
      if (skipKeys && skipKeys.indexOf(key) !== -1) {
        return;
      }

      const valueType = typeof value;
      let prop = '';

      if (typeof key === 'string') {
        prop += `"${escapeString(key)}":`;
      }

      if (typeof value === 'string') {
        prop += `"${escapeString(value)}"`;
      } else if (isPrimitive<JSON_PRIMITIVE>(value, JSON_PRIMITIVE_MATCHER)) {
        prop += value;
      } else if (valueType === 'object') {
        if (typeof depthLimit === 'undefined' || depth < depthLimit) {
          prop += toJson(value, valueType, depth, `${path}.${key}`);
        } else {
          prop = '"->Depth Limit Reached<-"';
        }
      } else {
        return;
      }
      items.push(prop);
    };

    if (obj instanceof Array) {
      type = 'array';
      for (let i = 0; i < obj.length; i++) {
        action(i, obj[i]);
      }
    } else {
      const asObj = obj as Dictionary<unknown>;
      for (const key in asObj) {
        if (showAllProps || Object.prototype.hasOwnProperty.call(asObj, key)) {
          try {
            action(key, asObj[key]);
          } catch (e) {
            if (e instanceof Error) {
              delete e.stack;
            }
            action(key, e);
          }
        }
      }
    }

    return OPEN[type] + items.join(',') + CLOSE[type];
  };

  if (!arguments.length) {
    return '';
  }

  const thingType = typeof thing;
  if (isPrimitive<JSON_PRIMITIVE>(thing, JSON_PRIMITIVE_MATCHER)) {
    return thing.toString(); // TODO shouldn't this put it in quotes ?
  } else if (thingType === 'object') {
    return toJson(thing, thingType, 0, '$');
  } else {
    return JSON.stringify(thing); // TODO
  }
}
