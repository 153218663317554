import { Component, OnInit } from '@angular/core';

import { Route, Router } from '@angular/router';

import { AuthService } from 'src/common/services/auth.service';

@Component({
  template: '',
})
export class LoginPageRedirectorComponent implements OnInit {
  constructor(
    private Router: Router,
    private AuthService: AuthService,
  ) {}

  ngOnInit() {
    this.Router.navigateByUrl('/');

    setTimeout(() => {
      this.AuthService.promptLogin({ goHome: true });
    });
  }
}

export const PageRoute: Route = {
  path: 'Login',
  component: LoginPageRedirectorComponent,
  pathMatch: 'full',
};
