import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';
import { FundraisingGroup, FundraisingGroupLite } from '../models/fundraising';

@Injectable({
  providedIn: 'root',
})
export class FundraisingGroupsService {
  protected CACHE_TIME: number = 60 * 60 * 1000; //1 Hour

  protected groups_cacheTime: { [groupName: string]: number } = {};
  protected groups: { [groupName: string]: FundraisingGroupLite } = {};

  protected fundActive: boolean = false;
  protected fundActive_cacheTime: number = 0;

  protected suggestions: { [search: string]: Array<string> } = {};
  protected suggestions_cacheTime: { [search: string]: number } = {};

  constructor(protected APIService: APIService) {}

  expireCache(): void {
    this.fundActive_cacheTime = 0;

    for (const groupName in this.groups_cacheTime) {
      if (Object.prototype.hasOwnProperty.call(this.groups_cacheTime, groupName)) {
        this.groups_cacheTime[groupName] = 0;
      }
    }

    for (const search in this.suggestions_cacheTime) {
      if (Object.prototype.hasOwnProperty.call(this.suggestions_cacheTime, search)) {
        this.suggestions_cacheTime[search] = 0;
      }
    }
  }

  fundraisingActive(): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    if (this.fundActive_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      deferred.resolve(this.fundActive);
    } else {
      this.APIService.queueRequest<boolean>({
        endPoint: 'fundraising',
        method: 'fundraisingActive',
      }).then((result: boolean) => {
        this.fundActive = result;
        this.fundActive_cacheTime = new Date().getTime();

        deferred.resolve(result);
      });
    }

    return deferred.promise;
  }

  saveGroup(group: FundraisingGroup): Promise<boolean | number> {
    const deferred = new Deferred<boolean | number>();

    this.APIService.queueRequest<boolean | number>({
      endPoint: 'fundraising',
      method: 'saveGroup',
      data: group,
    })
      .then((result: boolean | number) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  deleteGroup(group: FundraisingGroup): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'fundraising',
      method: 'deleteGroup',
      data: {
        groupID: group.groupID,
      },
    })
      .then((result: boolean) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  groupsWithUsage(year: string): Promise<Array<FundraisingGroup>> {
    const deferred = new Deferred<Array<FundraisingGroup>>();

    this.APIService.queueRequest<Array<FundraisingGroup>>({
      endPoint: 'fundraising',
      method: 'groupsWithUsage',
      data: {
        year: year,
      },
    })
      .then((groups: Array<FundraisingGroup>) => {
        deferred.resolve(groups);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  suggestGroups(groupName: string): Promise<Array<string>> {
    const deferred = new Deferred<Array<string>>();

    if (this.suggestions_cacheTime[groupName] + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      deferred.resolve(this.suggestions[groupName]);
    } else {
      this.APIService.queueRequest<Array<string>>({
        endPoint: 'fundraising',
        method: 'suggestGroups',
        data: {
          groupName: groupName,
        },
      }).then((result: Array<string>) => {
        this.suggestions[groupName] = result;
        this.suggestions_cacheTime[groupName] = new Date().getTime();

        deferred.resolve(result);
      });
    }

    return deferred.promise;
  }

  groupActive(groupName: string): Promise<false | FundraisingGroupLite> {
    const deferred = new Deferred<false | FundraisingGroupLite>();

    if (
      this.groups_cacheTime[groupName] &&
      this.groups_cacheTime[groupName] + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
    ) {
      deferred.resolve(this.groups[groupName]);
    } else {
      this.APIService.queueRequest<false | FundraisingGroupLite>({
        endPoint: 'fundraising',
        method: 'groupActive',
        data: {
          groupName: groupName,
        },
      }).then((result: false | FundraisingGroupLite) => {
        if (result === false) {
          delete this.groups[groupName];
          delete this.groups_cacheTime[groupName];
        } else {
          this.groups[groupName] = result;
          this.groups_cacheTime[groupName] = new Date().getTime();
        }

        deferred.resolve(result);
      });
    }

    return deferred.promise;
  }
}
