import { Component, OnInit, OnDestroy } from '@angular/core';

import { Route } from '@angular/router';
import { APIService } from '../../common/services/api.service';
import { ConfigService } from '../../common/services/config.service';
import { HolidayHoursImage } from '../../common/models/content';
import { ContentService } from '../../common/services/content.service';
import { Subscription } from 'rxjs';
import { UserAccount } from '../../common/models/account';

@Component({
  selector: 'ccf-holiday-hours',
  templateUrl: 'holidayHours.component.html',
  styleUrls: ['holidayHours.component.less'],
  providers: [],
})
//TODO file name wrong for class
export class HolidayHoursPageComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  images: Array<HolidayHoursImage> = [];
  hoursEnabled: boolean = false;
  loaded: boolean = false;
  loggedInStaff: boolean = false;

  constructor(
    private APIService: APIService,
    private ContentService: ContentService,
    private ConfigService: ConfigService,
  ) {}

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    let allPromises: Promise<unknown>[] = [];

    this._subscriptions.push(
      this.APIService.authenticatedDataStream().subscribe((data?: UserAccount | null) => {
        if (typeof data !== 'undefined') {
          this.loggedInStaff = data !== null && data.accountType === 'Staff';
        }
      }),
    );

    allPromises.push(
      this.ContentService.getHolidayHoursImages().then((images) => {
        //TODO make all this better
        this.images = images; //TODO POST_LAUNCH this should be a subscription based thing
      }),
    );

    allPromises.push(
      this.ConfigService.getHolidayHoursEnabled().then((holidayHours: boolean) => {
        this.hoursEnabled = holidayHours; //TODO POST_LAUNCH this should be a subscription based thing
      }),
    );

    this.APIService.processRequests();

    Promise.all(allPromises).then(() => {
      allPromises = [];

      this.loaded = true;
    });
  }
}

export const PageRoute: Route = {
  path: 'Holiday-Hours',
  component: HolidayHoursPageComponent,
  pathMatch: 'full',
  data: {
    title: 'Holiday Hours',
  },
};
