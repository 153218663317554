import { OutputBlockData, OutputData } from '@editorjs/editorjs';
import { ListData, ListItem } from '@editorjs/nested-list';

const doRender = (type: string, segment: OutputBlockData['data']): string => {
  if (type === 'paragraph') {
    return `<p>${segment['text']}</p>`;
  } else if (type === 'list') {
    const data: ListData = segment;
    const tag = data.style === 'ordered' ? 'ol' : 'ul';
    return `<${tag} class='list'>${data.items
      .map((i) => doRender('list-item', { ...i, style: data.style }))
      .join('\n')}</${tag}>`;
  } else if (type === 'list-item') {
    const data: ListItem & Pick<ListData, 'style'> = segment;
    return `<li>${data.content}${data.items.length ? doRender('list', { ...data, style: data.style }) : ''}</li>`;
  }
  return `Cannot render: ${type} - ${JSON.stringify(segment)}`;
};

export const parseBlocks = (data: string | OutputData) => {
  if (typeof data === 'string') {
    if (data[0] !== '{') {
      return data;
    }
    try {
      data = JSON.parse(data) as OutputData;
    } catch (e) {
      console.error('Failed to parse blocks json', { data });
      return data as string;
    }
  }
  return data;
};

export const render = (data: string | OutputData) => {
  data = parseBlocks(data);
  if (typeof data === 'string') {
    return data;
  } else {
    return data.blocks.map((b) => doRender(b.type, b.data)).join('\n');
  }
};
