import { ErrorsDataLayer } from './errors';
import { OrderDataLayer } from './orders';
import { DiscountDataLayer } from './discounts';
import { ConfigDataLayer } from './config';
import { TaxDataLayer } from './tax';
import { FreightDataLayer } from './freight';
import { enumKeys } from '../helpers/enum';

export enum ProcessRequests {
  Pool = 0,
  Now = 1,
  NA = -0,
}
export const ProcessRequests_keys = enumKeys(ProcessRequests);

export interface DataLayer {
  order: OrderDataLayer;
  tax: TaxDataLayer;
  freight: FreightDataLayer;
  errors: ErrorsDataLayer;
  discounts: DiscountDataLayer;
  config: ConfigDataLayer;
  processRequests(): void;
}
