import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';

import '../common/models/globals';

import { CartIndicatorComponent } from './cart/indicator.component';

import { SearchBarComponent } from './search/searchBar.component';

import { FrontPageComponent } from './frontPage/frontPage.component';
import { LocationsPageComponent } from './locations/locations.component';
import { OurStoryPageComponent } from './ourStory/ourStory.component';

import { ContactPageComponent } from './contact/contact.component';

import { JobsPageComponent } from './jobs/jobsPage.component';
import { JobDialogComponent } from './jobs/jobDialog.component';

import { ContactThankYouComponent } from './contact/thankYou.component';

import { WholesalePageComponent } from './wholesale/wholesale.component';

import { ThankYouComponent } from './thankyou/thankyou.component';

import { HolidayHoursPageComponent } from './holidayHours/holidayHours.component';

import { MobileMenuComponent } from './menu/mobileMenu.component';
import { DesktopMenuBarComponent } from './menu/desktopMenuBar.component';

import { LoginPageRedirectorComponent } from './login/loginPageRedirector.component';

import { FrontPageResolver } from './frontPage/frontPage.resolver';
import { JobsPageResolver } from './jobs/jobsPage.resolver';
import { WholesalePageResolver } from './wholesale/wholesale.resolver';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StaffToolsComponent } from './staffTools/staffTools.component';
import { StartQuoteDialogComponent } from './staffTools/startQuote.component';
import { AppRoutes } from './app.routing';
import { RouterModule } from '@angular/router';
import { SharedModule } from './app.shared.module';
import { GlobalErrorHandler } from 'src/common/services/globalErrorHandler';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    SharedModule,

    RouterModule.forRoot(AppRoutes, {
      urlUpdateStrategy: 'eager',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled', // TODO check if this works later
    }),
  ],
  declarations: [
    AppComponent,

    CartIndicatorComponent,

    SearchBarComponent,

    FrontPageComponent,
    LocationsPageComponent,
    OurStoryPageComponent,

    JobsPageComponent,
    JobDialogComponent,

    ContactThankYouComponent, // TODO merge ?
    ThankYouComponent,

    WholesalePageComponent,

    HolidayHoursPageComponent,

    ContactPageComponent,
    MobileMenuComponent,
    DesktopMenuBarComponent,

    StaffToolsComponent,

    LoginPageRedirectorComponent,

    StartQuoteDialogComponent,
  ],
  providers: [
    FrontPageResolver,
    JobsPageResolver,
    WholesalePageResolver,

    { provide: ErrorHandler, useExisting: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
