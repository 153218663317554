// TODO make this handle entirely
const routerLinkRegexSingleQuote = /<a href='(\/[^']*)'/i;
const routerLinkRegexDoubleQuote = /<a href="(\/[^"]*)"/i;
//TODO inAppComponent needs to be abtracted or this will be very buggy
const replacer = (substring: string, dest: string) => {
  return `<a class='charliesLink inAppComponent' fakerouter='${dest}' href='${dest}'`;
};

export const makeFakeRouterLinks = (html: string): string => {
  return html.replace(routerLinkRegexSingleQuote, replacer).replace(routerLinkRegexDoubleQuote, replacer);
};
