import { OnDestroy, Component } from '@angular/core';

import { MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { checkStaticallyImplementing, StaticImplements_Metadata } from '../staticImplements';

export interface StaticDialogComponent<P, R> {
  DIALOG_CLASS: string;
  ONLY_ONE?: MatDialogRef<DialogComponent<P, R>, R> | null;
  DEFAULT_CONFIG: MatDialogConfig;
}
export const StaticDialogComponent_Metadata: StaticImplements_Metadata<StaticDialogComponent<unknown, unknown>> = {
  interfaceName: 'StaticDialogComponent',
  properties: {
    DIALOG_CLASS: {},
    ONLY_ONE: { optional: true },
    DEFAULT_CONFIG: {},
  },
};

@Component({ template: '' })
export abstract class DialogComponent<P, R> implements OnDestroy {
  protected _subscriptions: Subscription[] = [];

  public keyboard: boolean | number = true;
  public isBlocking: boolean = false;

  public params!: P;

  defaultDialogResult?: R;

  constructor(public matDialogRef: MatDialogRef<DialogComponent<P, R>, R>) {
    this.matDialogRef.disableClose = true;

    checkStaticallyImplementing(this, StaticDialogComponent_Metadata);

    this._subscriptions.push(
      this.matDialogRef.backdropClick().subscribe(() => {
        if (!this.isBlocking) {
          this.closeDialog();
        }
      }),
    );

    this._subscriptions.push(
      this.matDialogRef.keydownEvents().subscribe((event) => {
        if (this.keyboard) {
          const keyCode = this.keyboard === true ? 27 : this.keyboard;
          if (event.keyCode === keyCode) {
            this.closeDialog();
          }
        }
      }),
    );
  }

  private _guard: (() => Promise<boolean>) | null = null;
  closeGuard(func: (() => Promise<boolean>) | null): void {
    this._guard = func;
  }

  closeDialog(dialogResult?: R): void {
    const done = () => {
      if (typeof dialogResult === 'undefined') {
        dialogResult = this.defaultDialogResult;
      }
      this.matDialogRef.close(dialogResult);
    };

    if (this._guard === null) {
      done();
    } else {
      void this._guard().then((result: boolean) => {
        if (!result) {
          done();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
