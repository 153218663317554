import { Injectable } from '@angular/core';
import { Menu } from '../models/menu';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  protected CACHE_TIME: number = 60 * 60 * 1000; //1 Hour
  protected cacheTime_menus: number = 0;
  protected menus: Array<Menu> = [];
  protected cacheTime_mobile_menus: number = 0;
  protected mobile_menus: Array<Menu> = [];

  constructor(protected APIService: APIService) {}

  expireCache() {
    this.cacheTime_mobile_menus = 0;
    this.cacheTime_menus = 0;
  }

  getMenus() {
    const result = new Deferred<Array<Menu>>();

    if (
      this.menus !== null &&
      this.cacheTime_menus + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
    ) {
      result.resolve(this.menus);
    } else {
      this.APIService.queueRequest<Array<Menu>>({
        endPoint: 'menu',
        method: 'get',
      })
        .then((menus: Array<Menu>) => {
          this.menus.splice(0, this.menus.length);
          Array.prototype.push.apply(this.menus, menus);
          this.cacheTime_menus = new Date().getTime();

          result.resolve(this.menus);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }

    return result.promise;
  }

  getMobileMenus() {
    const result = new Deferred<Array<Menu>>();

    if (
      this.mobile_menus !== null &&
      this.cacheTime_mobile_menus + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()
    ) {
      result.resolve(this.mobile_menus);
    } else {
      this.APIService.queueRequest<Array<Menu>>({
        endPoint: 'menu',
        method: 'getMobile',
      })
        .then((mobile_menus: Array<Menu>) => {
          this.mobile_menus.splice(0, this.mobile_menus.length);
          Array.prototype.push.apply(this.mobile_menus, mobile_menus);
          this.cacheTime_mobile_menus = new Date().getTime();

          result.resolve(this.mobile_menus);
        })
        .catch((reason: unknown) => {
          result.reject(reason);
          //TODO should do something globally ?
        });
    }

    return result.promise;
  }
}
