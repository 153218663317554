import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../../common/services/orders.service';
import { APIService } from '../../common/services/api.service';
import { OrderStatus, OrderType } from '../../common/models/checkout';
import { BaseOrder } from '../../common/models/order/base';
import { BaseOrderItem } from 'src/common/models/orderItem/base';

export interface OrderData {
  order: BaseOrder<BaseOrderItem>;
  uri: string;
  shouldDisplay: () => boolean;
  text: string;
}

@Component({
  selector: 'ccf-cart-indicator',
  templateUrl: 'indicator.component.html',
  styleUrls: ['indicator.component.less'],
})
export class CartIndicatorComponent implements OnInit {
  orders: Array<OrderData> = [];

  OrderStatus = OrderStatus;
  OrderType = OrderType;

  constructor(
    public OrdersService: OrdersService,
    private APIService: APIService,
  ) {}

  get isFocusing() {
    return this.OrdersService.isPossessing || this.OrdersService.isQuoting;
  }

  isFocused(order: BaseOrder<BaseOrderItem>): boolean {
    return order.isPossessed || order.status === OrderStatus.Quote;
  }

  ngOnInit() {
    let promises: Promise<unknown>[] = [];

    promises.push(
      this.OrdersService.loadCart('T_cart').then((cart: BaseOrder<BaseOrderItem>) => {
        this.orders.push({
          order: cart,
          uri: '/Cart',
          text: 'Checkout',
          shouldDisplay: () => {
            return (this.isFocusing && this.isFocused(cart)) || (!this.isFocusing && true);
          },
        });
      }),
    );

    promises.push(
      this.OrdersService.loadCart('T_corplogo').then((cart: BaseOrder<BaseOrderItem>) => {
        this.orders.push({
          order: cart,
          uri: '/Corporate-Logos/Order',
          text: 'Logo Order',
          shouldDisplay: () => {
            return (
              (this.isFocusing && this.isFocused(cart)) || (!this.isFocusing && cart.status !== OrderStatus.NotUsed)
            );
          },
        });
      }),
    );

    promises.push(
      this.OrdersService.loadCart('T_fountain').then((cart: BaseOrder<BaseOrderItem>) => {
        this.orders.push({
          order: cart,
          uri: '/Chocolate-Fountains/Order',
          text: 'Fountain',
          shouldDisplay: () => {
            return (
              (this.isFocusing && this.isFocused(cart)) || (!this.isFocusing && cart.status !== OrderStatus.NotUsed)
            );
          },
        });
      }),
    );

    promises.push(
      this.OrdersService.loadCart('T_customlabel').then((cart: BaseOrder<BaseOrderItem>) => {
        this.orders.push({
          order: cart,
          uri: '/Custom-Labels/Order',
          text: 'Labels',
          shouldDisplay: () => {
            return (
              (this.isFocusing && this.isFocused(cart)) || (!this.isFocusing && cart.status !== OrderStatus.NotUsed)
            );
          },
        });
      }),
    );

    //TODO ordersheet

    this.APIService.processRequests();

    Promise.all(promises).then(() => {
      promises = [];

      this.orders.sort((a: OrderData, b: OrderData) => {
        return b.order.type! - a.order.type!;
      });
    });
  }
}
