import { NgModule } from '@angular/core';
import { SafePipe } from './safePipe';
import { BigPipe } from './bigPipe';
import { FilterFor } from './filterFor';
import { CompareNumeric } from './compareMoney';
import { RenderHTMLPipe } from './renderPipe';

@NgModule({
  declarations: [SafePipe, BigPipe, FilterFor, CompareNumeric, RenderHTMLPipe],
  exports: [SafePipe, BigPipe, FilterFor, CompareNumeric, RenderHTMLPipe],
})
export class PipesModule {}
