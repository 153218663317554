import { ErrorHandler, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export interface ErrorAPI {
  message: string;
  error: string;
  comment?: string;
  stack?: string;
  pageURL?: string;
  userAgent?: string;
  //TODO track IP ?
}

export interface ErrorData {
  error: Error;
  comment?: string;
}

const MAX_SEND_TRIES = 5;
const SEND_ATTEMPT_DELAY = 1000;

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {
  static reportedErrors: { [key: string]: true } | null = {};

  constructor() {
    super();

    window.reportError = (error: Error | string, comment?: string, silent: boolean = false): void => {
      this.reportError(error, comment, silent);
    };

    const pendingErrors = window._pendingErrors.splice(0, window._pendingErrors.length);
    pendingErrors.forEach((e) => this.reportError(...e));
  }

  protected sendData(jsonData: string, timesTried: number = 0): void {
    timesTried++;
    const request = new XMLHttpRequest();
    request.open('POST', '/api/js_error.php');
    request.setRequestHeader('Content-type', 'application/json');
    request.onerror = (e) => {
      if (timesTried < MAX_SEND_TRIES) {
        setTimeout(() => {
          this.sendData(jsonData, timesTried);
        }, SEND_ATTEMPT_DELAY * timesTried);
      } else {
        GlobalErrorHandler.reportedErrors = null;
        throw e;
      }
    };
    setTimeout(function () {
      request.send(jsonData);
    }, 0);
  }

  protected errorHasHappend = new Subject<ErrorData>();
  errorsStream(): Subject<ErrorData> {
    return this.errorHasHappend;
  }

  reportError(error: Error | string, comment?: string, silent: boolean = false): void {
    if (GlobalErrorHandler.reportedErrors) {
      const defaultStack = new Error().stack;
      if (typeof error === 'string') {
        error = new Error(error);
      }

      if (error instanceof HttpErrorResponse) {
        //Not sure why the structure is so bad
        setTimeout(() => {
          const httpError = error as HttpErrorResponse;
          if (httpError.error) {
            if (httpError.error instanceof Error) {
              this.reportError(httpError.error);
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (httpError.error.error instanceof Error) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              this.reportError(httpError.error.error);
            }
          }
        });
      }

      if (!silent) {
        this.errorHasHappend.next({
          error: error,
          comment: comment,
        });
      }

      const data: ErrorAPI = {
        message: (silent ? '(Silent)! ' : '') + error.message.split('\n')[0],
        error: String(error).split('\n').slice(1).join('\n'), //TODO what does this do
        stack: 'stack' in error && error.stack ? error.stack : defaultStack,
      };

      try {
        data.pageURL = window.location.href;
      } catch (e) {
        //Do nothing
      }

      if (typeof navigator !== 'undefined' && typeof navigator.userAgent !== 'undefined') {
        data.userAgent = navigator.userAgent;
      }

      const key = JSON.stringify(data);
      if (typeof GlobalErrorHandler.reportedErrors[key] !== 'undefined') {
        return; //Duplicate error
      } else {
        GlobalErrorHandler.reportedErrors[key] = true;
      }

      if (comment) {
        data.comment = comment;
      }

      window.gtag('event', 'exception', {
        description: error.message,
        fatal: !silent,
      });

      // this.sendData(JSON.stringify(data));
    }
  }

  override handleError(error: Error | string): void {
    super.handleError(error);
    this.reportError(error);
  }
}
