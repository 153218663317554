import { Injectable } from '@angular/core';
import { Deferred } from '../helpers/deferred';
import { BehaviorSubject } from 'rxjs';

export type PrintReadyFunction = () => Promise<boolean>;

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  protected _isPrinting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isPrinting(): BehaviorSubject<boolean> {
    return this._isPrinting;
  }

  public printStart(): void {
    this._isPrinting.next(true);
    jQuery('body').addClass('isPrinting');
  }

  public printEnd(): void {
    this._isPrinting.next(false);
    jQuery('body').removeClass('isPrinting');
  }

  private alwaysReady: PrintReadyFunction = () => {
    const deferred = new Deferred<boolean>();
    deferred.resolve(true);
    return deferred.promise;
  };

  print(ready: PrintReadyFunction = this.alwaysReady, delay: number = 0): void {
    this.printStart();
    ready().then((cont: boolean) => {
      if (cont) {
        setTimeout(() => {
          window.print(), this.printEnd();
        }, delay);
      } else {
        this.printEnd();
      }
    });
  }

  enablePrinting(
    identifier: string = '.printService',
    ready: PrintReadyFunction = this.alwaysReady,
    delay: number = 0,
  ): void {
    jQuery(document).on('keydown' + identifier, ($event: JQuery.TriggeredEvent) => {
      if ($event.keyCode === 80 && (navigator.platform.match('Mac') ? $event.metaKey : $event.ctrlKey)) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.print(ready, delay);
      }
    });
  }

  disablePrinting(identifier: string = '.printService'): void {
    jQuery(document).off('keydown' + identifier);
  }
}
