<div class="pageContent">
  <div
    class="prompt"
    [innerHTML]="params.message"
  ></div>
  <input
    type="text"
    class="input email"
    name="email"
    autocomplete="none"
    [(ngModel)]="emailAddress"
    placeholder="*Email*"
    (change)="validate()"
  /><br />
  <input
    type="text"
    class="input name"
    name="name"
    autocomplete="none"
    [(ngModel)]="name"
    placeholder="Name"
    (change)="validate()"
  /><br />
  <input
    type="text"
    class="input company"
    name="company"
    autocomplete="none"
    [(ngModel)]="company"
    placeholder="Company"
    (change)="validate()"
  /><br />
  <div class="validation">{{ validationMessage }}</div>
  <button
    type="button"
    class="no"
    (click)="no()"
  >
    {{ params.no }}
  </button>
  <button
    type="button"
    class="yes"
    (click)="yes()"
  >
    {{ params.yes }}
  </button>
</div>
