import { Route, UrlSegment, UrlSegmentGroup, UrlMatchResult } from '@angular/router';

// StaticText
// ?StaticText - optional
// ?:param     - optional
// !Regex!
// ?!Regex!:paramName
// !Regex!:paramName
// :paramName
// ~!Regex!:paramName - skipable
export const UriMatcher = (
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route,
): UrlMatchResult | null => {
  if (!route.data || !route.data['path']) {
    throw new Error('route.data.path must be provided with UriMatcher');
  }

  const path: string = route.data['path'] as string;
  const parts = path.split('/');
  const posParams: { [key: string]: UrlSegment } = {};
  const consumed: UrlSegment[] = [];
  let currentIndex = 0;
  let isOptional: boolean = false;
  for (let part of parts) {
    isOptional = part.startsWith('?');
    const skipable = part.startsWith('~');
    if (isOptional || skipable) {
      part = part.substring(1);
    }

    if (currentIndex >= segments.length) {
      if (isOptional) {
        break;
      }
      if (skipable) {
        continue;
      }
      return null;
    } else {
      const current = segments[currentIndex];
      const isRegex = part.startsWith('!');
      let regex: RegExp | undefined;
      if (isRegex) {
        const lastIndex = part.lastIndexOf('!');
        const regexBody = part.substring(1, lastIndex);
        part = part.substring(lastIndex + 1); // Account for trailing !
        regex = new RegExp(regexBody, 'i');
      }

      const isPosParam = part.startsWith(':');

      let matched = false;
      if (regex) {
        if (regex.test(current.path)) {
          matched = true;
        }
      } else if (!isPosParam && part.toLocaleLowerCase() === current.path.toLocaleLowerCase()) {
        matched = true;
      } else if (isPosParam) {
        matched = true;
      }
      if (matched) {
        if (isPosParam) {
          posParams[part.substring(1)] = current;
        }
        consumed.push(current);
        currentIndex++;
      } else if (skipable) {
        continue;
      } else {
        return null;
      }
    }
  }
  if (route.pathMatch === 'full' && !isOptional && (segmentGroup.hasChildren() || currentIndex < segments.length)) {
    return null;
  } else {
    return { consumed, posParams };
  }
};
