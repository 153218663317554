/* eslint-disable prefer-rest-params */
import { Product } from '../models/product';
import { betterStringify } from './betterStringify';

export function makeProductURL(product: Product): string;
export function makeProductURL(productName: string, productID: number): string;
export function makeProductURL(): string {
  let productName: string;
  let productID: number;

  if (arguments.length === 2 && typeof arguments[0] === 'string' && typeof arguments[1] === 'number') {
    productName = arguments[0];
    productID = arguments[1];
  } else if (arguments.length === 1 && typeof arguments[0] === 'object') {
    const product: Product = arguments[0] as Product;
    productName = product.productName;
    productID = product.productID;
  } else {
    throw new Error(`makeProductURL called with invalid arguments(${betterStringify(arguments)})`);
  }

  let name = productName.replace(/\s+/g, '-');
  name = name.replace(/-+/g, '-');
  name = name.replace(/[^a-zA-Z0-9-]*/g, '');
  name = name.replace(/^-/g, '');

  return `${productID}-${name}`;
}
