import { Component, OnInit } from '@angular/core';

import { ContactUsForm } from '../../common/models/contactForm';

import { ContactFormService } from '../../common/services/contactForm.service';
import { ConfigService } from '../../common/services/config.service';
import { APIService } from '../../common/services/api.service';

import { Route, Router } from '@angular/router';

@Component({
  selector: 'ccf-contact',
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.less'],
  providers: [],
})
//TODO file name page should be in file name
export class ContactPageComponent implements OnInit {
  model: ContactUsForm = {
    name: '',
    phone: '',
    email: '',
    comments: '',
  };

  protected phoneRegex!: RegExp;

  hasValidated: boolean = false;
  invalid: { [field: string]: boolean } = {};

  constructor(
    private router: Router,
    private configService: ConfigService,
    private contactFormService: ContactFormService,
    protected apiService: APIService,
  ) {}

  ngOnInit(): void {
    void this.configService.getTelRegex().then((regex: RegExp) => {
      this.phoneRegex = regex;
    });

    this.apiService.processRequests();
  }

  revalidate(): void {
    if (this.hasValidated) {
      this.validate();
    }
  }

  validate(): boolean {
    this.hasValidated = true;
    this.invalid = {};

    this.model.name = this.model.name.trim();
    if (!this.model.name) {
      this.invalid['name'] = true;
    }

    this.model.phone = this.model.phone.trim();
    if (!this.model.phone || !this.phoneRegex.test(this.model.phone)) {
      this.invalid['phone'] = true;
    }

    this.model.email = this.model.email.trim();
    if (!this.model.email || !(this.model.email.indexOf('@') > 0)) {
      this.invalid['email'] = true;
    }

    this.model.comments = this.model.comments.trim();
    if (!this.model.comments) {
      this.invalid['comments'] = true;
    }

    return Object.keys(this.invalid).length === 0;
  }

  submit(): void {
    if (this.validate()) {
      void this.contactFormService
        .submitContactUs(this.model)
        .then((result: boolean) => {
          void this.router.navigateByUrl('/Contact-Us/Thank-You');
        })
        .catch((e) => {
          throw e;
        });
    }
  }
}

export const PageRoute: Route = {
  path: 'Contact-Us',
  component: ContactPageComponent,
  pathMatch: 'full',
  data: {
    title: 'Contact Us',
  },
};
