/* eslint-disable @typescript-eslint/no-explicit-any */
import { Primitive } from 'ts-essentials';
import { betterStringify } from './betterStringify';

export type PrimitiveDDLValue<T> = { value: T };
export const makePrimitiveDDL = <T extends Primitive, D extends DDLDefaultValue>(
  values: Array<T>,
  selected: T,
  _default: D,
) => {
  return new DDL(
    values.map((v) => {
      return { value: v };
    }),
    { value: selected },
    'value',
    _default,
  );
};

class DDLSelectionNotValid extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DDLSelectionNotValid';
  }
}

function isDDLObjectValue<T extends DDLObjectValue<T>>(thing: any, key: keyof any): thing is T {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return thing && typeof thing === 'object' && key in thing;
}

type DDLObjectValue<T> = { [_ in keyof T]: unknown };
export type DDLDefaultValue = Primitive | void;
export class DDL<T extends DDLObjectValue<T>, K extends keyof T, D extends DDLDefaultValue> {
  get value(): T[K] | D {
    if (isDDLObjectValue<T>(this._selected, this.primaryKey)) {
      return this._selected[this.primaryKey];
    }
    return this._selected;
  }
  set value(value: T[K] | D) {
    this._selected = GetValidDDLSelectionObject(this._options, value, this.primaryKey, this.defaultValue);
  }

  private _selected: T | D;
  get selected(): T | D {
    return this._selected;
  }
  set selected(value: T | D) {
    this._selected = GetValidDDLSelectionObject(this._options, value, this.primaryKey, this.defaultValue);
  }

  constructor(
    options: T[] = [],
    selected: T | T[K] | D,
    private primaryKey: K,
    public defaultValue: D,
  ) {
    this._options = options;
    this._selected = defaultValue;

    if (isDDLObjectValue<T>(selected, primaryKey)) {
      this.selected = selected;
    } else {
      this.value = selected;
    }
  }

  private _options: T[];
  get options(): T[] {
    return this._options;
  }

  set options(value: Array<T>) {
    const oldSelection = this.selected;
    this._options = value;

    try {
      this.selected = oldSelection;
    } catch (error: any) {
      if (error instanceof DDLSelectionNotValid) {
        if (isDDLObjectValue<T>(this.defaultValue, this.primaryKey)) {
          this.selected = this.defaultValue;
        } else {
          this.value = this.defaultValue;
        }
      } else {
        throw error;
      }
    }
  }
}

export const GetValidDDLSelectionObject = <T extends DDLObjectValue<T>, K extends keyof T, D extends DDLDefaultValue>(
  options: T[],
  value: T | T[K] | D,
  primaryKey: K,
  defaultValue: D,
): T | D => {
  const innerValue: D | T[K] = isDDLObjectValue<T>(value, primaryKey) ? value[primaryKey] : value;

  let item: T | undefined;
  function isElement(v: D | T[K]): v is T[K] {
    item = options.find((i) => i[primaryKey] === v);
    return typeof item !== 'undefined';
  }

  if (!isElement(innerValue)) {
    return defaultValue;
  } else if (typeof item !== 'undefined') {
    return item;
  }

  throw new DDLSelectionNotValid(
    `Value "${betterStringify(innerValue)}" not found in options using primaryKey "${primaryKey.toString()}"`,
  );
};

export const GetValidDDLSelection = <T extends DDLObjectValue<T>, K extends keyof T, D extends DDLDefaultValue>(
  options: T[],
  value: T[K] | D,
  primaryKey: K,
  defaultValue: D,
): T[K] | D => {
  const result = GetValidDDLSelectionObject<T, K, D>(options, value, primaryKey, defaultValue);

  return isDDLObjectValue<T>(result, primaryKey) ? result[primaryKey] : result;
};
