import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Section, Link } from '../models/section';
import { Deferred } from '../helpers/deferred';
import { GlobalErrorHandler } from './globalErrorHandler';

export interface SectionSaveData {
  sectionID: number | null;
  sectionName: string;
  parentSectionID: number | null;
  urlName: string;
  hasMaltitol: boolean;
  title: string;
  defaultCategoryID: number | null;
  extraLinks_top: Array<Link>;
  extraLinks_bottom: Array<Link>;
  alphabetic: boolean;
  noSearch: boolean;
  description: string;
  footer: boolean;
  madeToOrder: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  protected CACHE_TIME: number = 60 * 60 * 1000;
  protected cacheTime_sections: number = 0;
  protected sections: Array<Section> = [];
  protected sectionsByID: { [sectionID: number]: Section } = {};
  protected sectionsByURI: { [sectionURI: string]: Section } = {};
  protected sectionsByName: { [sectionName: string]: Section } = {};

  public hasData: boolean = false;
  public cacheValid(): boolean {
    return this.cacheTime_sections + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime();
  }

  constructor(
    protected APIService: APIService,
    protected GlobalErrorHandler: GlobalErrorHandler,
  ) {}

  expireCache(): void {
    this.cacheTime_sections = 0;
  }

  //TODO inflight
  private inflight_reloadSections?: Deferred<void>;
  private reloadSections(): Promise<void> {
    //TODO make sections a reference
    if (this.inflight_reloadSections) {
      return this.inflight_reloadSections.promise;
    }
    const deferred = (this.inflight_reloadSections = new Deferred<void>());

    this.APIService.queueRequest<Array<Section>>({
      endPoint: 'sections',
      method: 'all',
    })
      .then((sections: Array<Section>) => {
        this.cacheTime_sections = new Date().getTime();

        this.sectionsByID = {};
        this.sectionsByURI = {};
        this.sectionsByName = {};

        this.sections = sections;
        //this.sections.splice(0, this.sections.length);
        //Array.prototype.push.apply(this.sections, sections);

        for (let si = 0; si < this.sections.length; si++) {
          const section = this.sections[si];
          if (section.urlName) {
            this.sectionsByURI[section.urlName.toLocaleLowerCase()] = section;
          }
          this.sectionsByName[section.sectionName.toLocaleLowerCase()] = section;
          this.sectionsByID[section.sectionID] = section;
        }

        this.hasData = true;
        deferred.resolve();
        delete this.inflight_reloadSections;
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        delete this.inflight_reloadSections;
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  byName(sectionName: string): Promise<Section> {
    const result = new Deferred<Section>();

    const key = sectionName.toLocaleLowerCase();

    if (this.cacheValid()) {
      if (typeof this.sectionsByName[key] === 'undefined') {
        result.reject('Section Not Found byName (sectionName: ' + sectionName + ')');
      } else {
        result.resolve(this.sectionsByName[key]);
      }
    } else {
      this.reloadSections()
        .then(() => {
          if (typeof this.sectionsByName[key] === 'undefined') {
            result.reject('Section Not Found byName-reload (sectionName: ' + sectionName + ')');
          } else {
            result.resolve(this.sectionsByName[key]);
          }
        })
        .catch((reason: string) => {
          result.reject(reason);
        });
    }

    return result.promise;
  }

  byURI(sectionURI: string): Promise<Section> {
    const result = new Deferred<Section>();

    const key = sectionURI.toLowerCase();

    if (this.cacheValid()) {
      if (typeof this.sectionsByURI[key] === 'undefined') {
        result.reject('Section Not Found byURI (sectionURI: ' + sectionURI + ')');
      } else {
        result.resolve(this.sectionsByURI[key]);
      }
    } else {
      this.reloadSections()
        .then(() => {
          if (typeof this.sectionsByURI[key] === 'undefined') {
            result.reject('Section Not Found byURI-reload (sectionURI: ' + sectionURI + ')');
          } else {
            result.resolve(this.sectionsByURI[key]);
          }
        })
        .catch((reason: string) => {
          result.reject(reason);
        });
    }

    return result.promise;
  }

  byID(sectionID: number): Promise<Section> {
    const result = new Deferred<Section>();

    if (this.cacheValid()) {
      if (typeof this.sectionsByID[sectionID] === 'undefined') {
        result.reject(new Error(`Section Not Found byID (sectionID: ${sectionID})`));
      } else {
        result.resolve(this.sectionsByID[sectionID]);
      }
    } else {
      this.reloadSections()
        .then(() => {
          if (typeof this.sectionsByID[sectionID] === 'undefined') {
            result.reject(new Error(`Section Not Found byID-reload (sectionID: ${sectionID})`));
          } else {
            result.resolve(this.sectionsByID[sectionID]);
          }
        })
        .catch((reason: Error) => {
          result.reject(reason);
        });
    }

    return result.promise;
  }

  byIDSync(sectionID: number): Section | undefined {
    //TODO i think this should throw
    if (!this.hasData) {
      this.GlobalErrorHandler.reportError(
        new Error(`SectionService.byIDSync(${sectionID}) called before any data is loaded`),
      );
    }
    if (typeof this.sectionsByID[sectionID] === 'undefined') {
      this.GlobalErrorHandler.reportError(
        new Error(`SectionService.byIDSync(${sectionID}) did not find a section`),
        undefined,
        true,
      );
    }

    return this.sectionsByID[sectionID];
  }

  all(): Promise<Array<Section>> {
    const result = new Deferred<Array<Section>>();

    if (this.cacheValid()) {
      result.resolve(this.sections); //TODO all this API functions need to reconsider handing out references
    } else {
      this.reloadSections()
        .then(() => {
          result.resolve(this.sections);
        })
        .catch((reason: string) => {
          result.reject(reason);
        });
    }

    return result.promise;
  }

  allSync(): Array<Section> {
    if (!this.hasData) {
      this.GlobalErrorHandler.reportError(new Error('SectionService.allSync called before any data is loaded'));
    }

    return this.sections;
  }

  save(method: 'create' | 'update', data: SectionSaveData): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'sections',
      method: method,
      data: data,
    })
      .then((result: boolean) => {
        this.expireCache();
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  reorder(section: Section, before: Section | null, after: Section | null): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'sections',
      method: 'reorder',
      data: {
        sectionID: section.sectionID,
        before: before ? before.sectionID : null,
        after: after ? after.sectionID : null,
      },
    })
      .then((result: boolean) => {
        this.expireCache();
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
