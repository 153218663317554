import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Deferred } from '../../common/helpers/deferred';

import { MainPicture } from '../../common/models/mainPicture';
import { FrontPageService, FrontPageSection } from '../../common/services/frontPage.service';
import { APIService } from '../../common/services/api.service';

import { FrontPage } from './frontPage.model';

@Injectable()
export class FrontPageResolver implements Resolve<FrontPage> {
  constructor(
    private FrontPageService: FrontPageService,
    protected APIService: APIService,
    private Router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<FrontPage> {
    const result = new Deferred<FrontPage>();

    const page: FrontPage = {
      sections: [],
      mainPictures: [],
    };

    const allPromise: Promise<unknown>[] = [];

    allPromise.push(
      this.FrontPageService.getSections().then((sections: Array<FrontPageSection>) => {
        page.sections = sections;
      }),
    );

    allPromise.push(
      this.FrontPageService.getMainPictures().then((mainPictures: Array<MainPicture>) => {
        page.mainPictures = mainPictures;
      }),
    );

    this.APIService.processRequests();

    Promise.all(allPromise).then(() => {
      result.resolve(page);
    });

    return result.promise;
  }
}
