export interface Letter {
  letterID: number;
  display: string;
  regexText: string;
  regex: RegExp;
}

export interface Emails {
  [key: string]: string;
}

export type SiteEnviroment = 'development' | 'staging' | 'production';

export interface AllConfig {
  SITE_ENV: SiteEnviroment;
  ANALYTICS_ACCOUNT: string;
  MAX_ONLINE_PAYMENT: string;
  PAYPAL_ACCOUNT: string;
  MOLDED_LETTERS: Array<string>;
  EMAIL_ADDRESSES: Emails;
  CA_POSTAL_CODE: string;
  US_ZIP_CODE: string;
  TEL_REGEX: string;
}

export const APP_TITLE = "Charlie's Chocolate Factory";
