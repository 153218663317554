import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { DialogComponent, StaticDialogComponent } from './dialogComponent';
import { Deferred } from '../deferred';

const GLOBAL_DIALOG_DEFAULT: MatDialogConfig = {
  autoFocus: true,
};

type AlertParams = import('./alert/alertDialog.component').AlertParams;
type AlertResult = import('./alert/alertDialog.component').AlertResult;
type AlertDialogComponent = import('./alert/alertDialog.component').AlertDialogComponent;

type PromptParams = import('./prompt/promptDialog.component').PromptParams;
type PromptResult = import('./prompt/promptDialog.component').PromptResult;
type PromptDialogComponent = import('./prompt/promptDialog.component').PromptDialogComponent;

@Injectable()
export class DialogService {
  protected _isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private matDialog: MatDialog) {}

  get isOpen(): BehaviorSubject<boolean> {
    return this._isOpen;
  }

  public opened(): void {
    setTimeout(() => {
      // Prevents an error when the function is called at the wrong part of the expersion valiation loop
      this.isOpen.next(true);
    });
  }

  public show<P, R, T extends DialogComponent<P, R>>(
    dialog: (ComponentType<T> | TemplateRef<T>) & StaticDialogComponent<P, R>,
    params: P,
    options: MatDialogConfig = {},
    replaceOnly: undefined = undefined, // TODO this needs to be tested before allowed - boolean = false
  ): MatDialogRef<T, R> {
    const data: MatDialogConfig = {
      ...GLOBAL_DIALOG_DEFAULT,
      ...dialog.DEFAULT_CONFIG,
      ...options,

      panelClass: dialog.DIALOG_CLASS,
      data: params,
    };

    const onlyOneAllowed = typeof dialog.ONLY_ONE !== 'undefined';

    if (onlyOneAllowed && dialog.ONLY_ONE) {
      if (replaceOnly) {
        dialog.ONLY_ONE.componentInstance.params = params;
      }
      return dialog.ONLY_ONE as MatDialogRef<T, R>;
    }

    const dialogRef = this.matDialog.open<T, MatDialogConfig, R>(dialog, data);

    if (onlyOneAllowed) {
      dialog.ONLY_ONE = dialogRef;
      void dialogRef
        .afterClosed()
        .toPromise()
        .then(() => {
          dialog.ONLY_ONE = null;
        });
    }

    return dialogRef;
  }

  public closed(): void {
    this.isOpen.next(false);
  }

  public async alert(
    message: string,
    options: MatDialogConfig = {},
    replaceOnly: undefined = undefined, // TODO this needs to be tested before allowed - boolean = false
  ): Promise<AlertResult> {
    const deferred = new Deferred<AlertResult>();

    void import('./alert/alertDialog.component').then((alertDialog) => {
      void this.show<AlertParams, AlertResult, AlertDialogComponent>(
        alertDialog.AlertDialogComponent,
        {
          message,
        },
        options,
        replaceOnly,
      )
        .afterClosed()
        .toPromise()
        .then((result: AlertResult) => {
          deferred.resolve(result);
        });
    });

    return deferred.promise;
  }

  public async prompt(
    message: string,
    options: MatDialogConfig = {},
    replaceOnly: undefined = undefined, // TODO this needs to be tested before allowed - boolean = false
  ): Promise<PromptResult> {
    const deferred = new Deferred<PromptResult>();

    void import('./prompt/promptDialog.component').then((promptDialog) => {
      void this.show<PromptParams, PromptResult, PromptDialogComponent>(
        promptDialog.PromptDialogComponent,
        {
          message,
          input: promptDialog.PromptType.Text,
          no: 'Cancel',
          yes: 'Ok',
        },
        options,
        replaceOnly,
      )
        .afterClosed()
        .toPromise()
        .then((result: PromptResult) => {
          deferred.resolve(result);
        });
    });

    return deferred.promise;
  }

  public confirm(
    message: string,
    options: MatDialogConfig = {},
    replaceOnly: undefined = undefined, // TODO this needs to be tested before allowed - boolean = false
  ): Promise<PromptResult> {
    const deferred = new Deferred<PromptResult>();

    void import('./prompt/promptDialog.component').then((promptDialog) => {
      void this.show<PromptParams, PromptResult, PromptDialogComponent>(
        promptDialog.PromptDialogComponent,
        {
          message,
          input: promptDialog.PromptType.None,
          no: 'Cancel',
          yes: 'Ok',
        },
        options,
        replaceOnly,
      )
        .afterClosed()
        .toPromise()
        .then((result: PromptResult) => {
          deferred.resolve(result);
        });
    });

    return deferred.promise;
  }
}
