import { Component, OnInit, ElementRef } from '@angular/core';
import { SearchService } from '../../common/services/search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ccf-search-bar',
  templateUrl: 'searchBar.component.html',
  styleUrls: ['searchBar.component.less'],
})
export class SearchBarComponent implements OnInit {
  searchText: string = '';

  constructor(
    private Router: Router,
    private ElementRef: ElementRef,
    private SearchService: SearchService,
  ) {}

  ngOnInit() {
    const searchElement = jQuery(this.ElementRef.nativeElement).find<HTMLInputElement>('.searchBar .searchText');

    this.SearchService.bindAutocomplete(searchElement, (event, ui) => {
      event.preventDefault();
      const words = this.searchText.split(' ');
      words[words.length - 1] = ui.item.value;
      this.searchText = words.join(' ');

      if ((event as unknown as KeyboardEvent).keyCode === 13) {
        //TODO this needs to be tested, no idea why this works
        this.doSearch();
      }
    });
  }

  doSearch() {
    jQuery(this.ElementRef.nativeElement).find('.searchBar .searchText').blur();
    this.Router.navigateByUrl(this.SearchService.buildSearchURI(this.searchText));
    this.searchText = '';
  }
}
