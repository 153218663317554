import { Big } from 'big.js';

import { clear } from '../../helpers/clearIt';

import {
  OrderType,
  MoldType,
  OrderFile,
  BillingInfo,
  APICorpLogoOrder,
  LogoType,
  APICorpLogoItem,
  BoxSize,
  DeliveryMethod,
  PaymentType,
} from '../checkout';

import { CorpLogo } from '../corpLogo';
import { BaseOrder, DiscountSubtotals, OrderResponseObject } from './base';
import { CorpLogoOrderItem } from '../orderItem/corpLogo';
import md5 from 'md5';
import { ApplicableTaxes, RealLocation } from '../tax';
import { DiscountTarget, DiscountEffect, Discount } from '../discount';
import { DataLayer, ProcessRequests } from '../../dataLayer';
import { Deferred } from '../../helpers/deferred';
import { cloneDeep } from 'lodash-es';
import { Discountable } from '../product';

export class CorpLogoOrder extends BaseOrder<CorpLogoOrderItem> implements APICorpLogoOrder<Big> {
  type = OrderType.CorpLogo as const;

  reviewAudit: Array<number> = [];
  moldType: MoldType | null = null;
  pieceSizeShape: string = '';
  pieceNotSure: boolean = false;
  approx: string = '';
  logos: Array<CorpLogo<Big>> = [];
  logosByID: { [logoID: number]: CorpLogo<Big> } = {};

  override payment: PaymentType = PaymentType.PaymentRequest;

  newLogoPrice: Big | null = null;
  newLogoCustomPackaging: boolean = false;

  artwork: Array<OrderFile> = [];
  skipArt: boolean = false;

  override casePackMessage: string = '(Not including Individual Logos)';

  billingInfo: BillingInfo = {
    country: 'Canada',
    invalid: {},
  };

  labourItem: CorpLogoOrderItem | null = null;

  isChristmas: boolean = false;
  private _forceChristmas: boolean | null = null;
  get forceChristmas(): boolean | null {
    return this._forceChristmas;
  }
  set forceChristmas(value: boolean | null) {
    this._forceChristmas = value;
    if (this._forceChristmas !== null) {
      this.isChristmas = this._forceChristmas;
    } else {
      this.isChristmas = [9, 10, 11, 12].indexOf(new Date().getMonth() + 1) !== -1;
    }
  }

  hasCustomPackaging: boolean = false;

  constructor(protected override dataLayer: DataLayer) {
    super(dataLayer);

    this.billingInfo.invalid = this.invalid;

    this.queueSave('payment');

    this.isChristmas = [9, 10, 11, 12].indexOf(new Date().getMonth() + 1) !== -1;
    //TODO has to load from snapshots if its around to get isChristmas
  }

  override reset(): void {
    //TODO delete all properties then override with new object
    /*var newInstance = this.constructor(this.dataLayer);

    for (let key in this) {
      if (this.hasOwnProperty(key)) {
        delete (<any>this)[key];
      }
    }
    //TODO this breaks Subjects
    Object.assign(this, newInstance);*/

    super.reset();

    this.moldType = null;
    this.pieceSizeShape = '';
    this.pieceNotSure = false;
    this.approx = '';
    this.logos = [];
    this.logosByID = {};
    this.newLogoPrice = null;

    this.hasCustomPackaging = false;

    clear(this.artwork);
    this.skipArt = false;

    clear(this.billingInfo);
    this.billingInfo.country = 'Canada';
    this.billingInfo.invalid = this.invalid;

    this.reviewAudit = [];
    this.forceChristmas = null;
  }

  override async parse(
    data: OrderResponseObject<APICorpLogoOrder>,
    calledFrom: string,
    allDone?: Promise<void>,
  ): Promise<void> {
    const doneHere = new Deferred<void>();

    if (data.order) {
      //Has to be first
      const order = data.order;

      if (typeof order.newLogoPrice !== 'undefined') {
        this.newLogoPrice = order.newLogoPrice ? new Big(order.newLogoPrice) : null;
      }

      if (typeof order.newLogoCustomPackaging !== 'undefined') {
        this.newLogoCustomPackaging = order.newLogoCustomPackaging;
      }

      if (typeof order.logos !== 'undefined') {
        this.logos = [];

        for (const logo of order.logos) {
          let price: Big | null = null;
          if (typeof logo.price !== 'undefined') {
            price = logo.price ? new Big(logo.price) : null;
          }
          const _logo = Object.assign({}, logo, { price: price });
          this.logos.push(_logo);
          this.logosByID[_logo.logoID] = _logo;
        }
      }
    }

    await super.parse(data, calledFrom, doneHere.promise);

    this.freeze();

    if (data.order) {
      const order = data.order;

      if (typeof order.reviewAudit !== 'undefined') {
        this.reviewAudit = order.reviewAudit;
      }

      if (typeof order.moldType !== 'undefined') {
        this.moldType = order.moldType;
      }
      if (typeof order.pieceSizeShape !== 'undefined') {
        this.pieceSizeShape = order.pieceSizeShape;
      }
      if (typeof order.pieceNotSure !== 'undefined') {
        this.pieceNotSure = order.pieceNotSure;
      }
      if (typeof order.approx !== 'undefined') {
        this.approx = order.approx;
      }

      if (typeof order.skipArt !== 'undefined') {
        this.skipArt = order.skipArt;
      }

      if (typeof order.forceChristmas !== 'undefined') {
        this.forceChristmas = order.forceChristmas;
      }

      if (typeof order.billingInfo !== 'undefined') {
        if (this.deliveryInfo && this.deliveryInfo.sameAs) {
          this.billingInfo = this.deliveryInfo;
        } else {
          if (order.billingInfo) {
            //TODO check these out
            jQuery.extend(true, this.billingInfo, order.billingInfo);
          } else {
            clear(this.billingInfo);
          }
        }
      }
      this.billingInfo.invalid = this.invalid;
      this.billingInfo.country = this.billingInfo.country || 'Canada';

      if (typeof order.artwork !== 'undefined') {
        this.artwork = order.artwork;
      }
    }

    await this.checkData();

    this.unfreeze();

    const done = async () => {
      await this.calculate();

      doneHere.resolve();
    };

    if (allDone) {
      allDone.then(() => {
        done();
      });
    } else {
      await done();
    }
  }

  override export() {
    const exportData = super.export(); //TODO typing for this and all other exports

    exportData['logos'] = [];
    for (const logo of this.logos) {
      const exportLogo: CorpLogo<string | Big> = cloneDeep(logo);
      exportLogo['price'] = logo.price ? new Big(logo.price).toFixed(2) : null;
      (exportData['logos'] as CorpLogo<string | Big>[]).push(exportLogo);
    }

    exportData['newLogoPrice'] = this.newLogoPrice ? new Big(this.newLogoPrice).toFixed(2) : null;
    exportData['newLogoCustomPackaging'] = this.newLogoCustomPackaging;

    exportData['moldType'] = this.moldType;
    exportData['pieceSizeShape'] = this.pieceSizeShape;
    exportData['pieceNotSure'] = this.pieceNotSure;
    exportData['approx'] = this.approx;

    exportData['skipArt'] = this.skipArt;

    exportData['billingInfo'] = this.billingInfo;
    exportData['artwork'] = this.artwork;
    exportData['hasCustomPackaging'] = this.hasCustomPackaging;
    exportData['reviewAudit'] = this.reviewAudit;
    exportData['forceChristmas'] = this.forceChristmas;
    exportData['isChristmas'] = this.isChristmas;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }

  async setBoxVisible(logo: CorpLogo<Big | string>): Promise<void> {
    const doneHere = new Deferred<void>();

    const result = await this.dataLayer.order.setBoxVisible(this, logo.logoID, !!logo.boxVisible, ProcessRequests.Now);
    await this.parse(
      result as any as OrderResponseObject<APICorpLogoOrder>,
      'corpLogo.setBoxVisible',
      doneHere.promise,
    );

    if (result.outcome === 'Success') {
      await this.checkData();
    }

    doneHere.resolve();
  }

  async setCustomPackaging(logo: CorpLogo<Big | string>): Promise<void> {
    const doneHere = new Deferred<void>();

    const result = await this.dataLayer.order.setCustomPackaging(
      this,
      logo.logoID,
      !!logo.customPackaging,
      ProcessRequests.Now,
    );
    await this.parse(
      result as any as OrderResponseObject<APICorpLogoOrder>,
      'corpLogo.setCustomPackaging',
      doneHere.promise,
    );

    if (result.outcome === 'Success') {
      await this.checkData();
    }

    doneHere.resolve();
  }

  async addLogo(logoID: number): Promise<void> {
    const doneHere = new Deferred<void>();

    const result = await this.dataLayer.order.addLogo(this, logoID, ProcessRequests.Now);
    await this.parse(result as any as OrderResponseObject<APICorpLogoOrder>, 'corpLogo.addLogo', doneHere.promise);

    if (result.outcome === 'Success' && result.logo) {
      let logo: CorpLogo<string | Big> = result.logo;

      let price: Big | null = null;
      if (typeof logo.price !== 'undefined') {
        price = logo.price ? new Big(logo.price) : null;
      }
      Object.assign(logo, { price: price });

      if (typeof this.logosByID[logo.logoID] === 'undefined') {
        this.logos.push(logo as CorpLogo<Big>);
        this.logosByID[logo.logoID] = logo as CorpLogo<Big>;
      } else {
        logo = Object.assign(this.logosByID[logo.logoID], logo);
        if (this.logos.indexOf(logo as CorpLogo<Big>) === -1) {
          //Not even sure this can happen
          this.logos.push(logo as CorpLogo<Big>);
        }
      }

      await this.checkData();
    }

    doneHere.resolve();
  }

  async removeLogo(logoID: number): Promise<void> {
    const doneHere = new Deferred<void>();

    const result = await this.dataLayer.order.removeLogo(this, logoID, ProcessRequests.Now);
    await this.parse(result as any as OrderResponseObject<APICorpLogoOrder>, 'corpLogo.removeLogo', doneHere.promise);

    if (result.outcome === 'Success') {
      for (let i = 0; i < this.logos.length; i++) {
        if (this.logos[i].logoID === logoID) {
          this.logos.splice(i, 1);
          break;
        }
      }

      delete this.logosByID[logoID];

      for (const item of this.items) {
        if (item.logoID === logoID) {
          item.logoID = null;
          this.saveItem(item, 'logoID', ProcessRequests.Pool);
        }
      }

      this.dataLayer.processRequests();

      await this.checkData();
    }

    doneHere.resolve();
  }

  newItem(type: LogoType): void {
    const key = md5(new Date().getTime().toString() + Math.random()).toString();

    const data: APICorpLogoItem = {
      key: key,
      logoID: null,
      quantity: null,
      logoType: type,
    };

    if (type === LogoType['Individual Logos']) {
      data.description = '';
      data.typeID = null;
      data.foilSubType = null;
    } else if (type === LogoType['Gift Boxes']) {
      data.boxSize = null;
      data.boxStyle = null;
      data.typeFoils = [
        {
          typeID: null,
          foilSubType: null,
        },
        {
          typeID: null,
          foilSubType: null,
        },
        {
          typeID: null,
          foilSubType: null,
        },
      ];
    }

    const item = new CorpLogoOrderItem(this, data);
    this._addItem(item);
  }

  refillItems(min: number = 1): void {
    let numLogos: number = 0;
    let numBoxes: number = 0;

    for (const item of this.items) {
      if (item.logoType === LogoType['Individual Logos']) {
        numLogos++;
      } else if (item.logoType === LogoType['Gift Boxes']) {
        numBoxes++;
      }
    }

    for (let i = 0; i < min - numLogos; i++) {
      this.newItem(LogoType['Individual Logos']);
    }
    for (let i = 0; i < min - numBoxes; i++) {
      this.newItem(LogoType['Gift Boxes']);
    }
  }

  private _labourTax: ApplicableTaxes | null = null;
  private getLabourTax(): Promise<ApplicableTaxes> {
    const deferred = new Deferred<ApplicableTaxes>();

    if (this._labourTax !== null) {
      deferred.resolve(this._labourTax);
    } else {
      this.dataLayer.config.getLocations().then((locations: RealLocation[]) => {
        this.dataLayer.config.lookupLocation('BC', 'Canada').then((location: RealLocation | null) => {
          if (location === null) {
            throw new Error('Could not find location for BC Canada.');
          }

          this.dataLayer.tax.filter('^GST$').then((allGST: ApplicableTaxes) => {
            const BC_GST = allGST[location.locationID];

            const labourTax: ApplicableTaxes = {};
            for (const loc of locations) {
              labourTax[loc.locationID] = BC_GST;
            }

            this._labourTax = labourTax;
            deferred.resolve(labourTax);
          });
          this.dataLayer.processRequests();
        });
        this.dataLayer.processRequests();
      });
      this.dataLayer.processRequests();
    }

    return deferred.promise;
  }

  async checkData(): Promise<void> {
    const allPromises: Promise<unknown>[] = [];

    const OLD_hasCustomPackaging = this.hasCustomPackaging;
    this.hasCustomPackaging = false;
    if (this.moldType === MoldType.Reorder) {
      let index = 1;
      for (const logo of this.logos) {
        logo.index = index++;

        if (logo.customPackaging) {
          this.hasCustomPackaging = true;
        }
      }
    } else {
      this.hasCustomPackaging = this.newLogoCustomPackaging;
    }

    const defaultPrice: Big | null = this.logos.length === 1 ? this.logos[0].price : null;
    for (const item of this.items) {
      let logo: CorpLogo<Big> | null = null;
      if (item.logoID) {
        logo = this.logosByID[item.logoID];
      }

      if (OLD_hasCustomPackaging && !this.hasCustomPackaging && item.discountOverride !== null) {
        item.discountOverride = null;
        allPromises.push(this.saveItem(item, 'discountOverride', ProcessRequests.Pool));
      }

      if (item.logoType === LogoType['N/A']) {
        item.productDiscountable = Discountable.Disabled;
      } else if (item.logoType === LogoType['Individual Logos']) {
        item.productDiscountable = Discountable.Disabled;

        if (this.moldType === MoldType.New) {
          item.price = this.newLogoPrice ? new Big(this.newLogoPrice) : null;
        } else {
          if (this.logos.length < 2) {
            item.price = defaultPrice ? new Big(defaultPrice) : null;
          } else if (logo && logo.price) {
            item.price = new Big(logo.price);
          } else {
            item.price = null;
          }
        }
      } else if (item.logoType === LogoType['Gift Boxes']) {
        item.productDiscountable = Discountable.Enabled;
        switch (item.boxSize) {
          case BoxSize['1/2 lb']:
            item.price = new Big(17.5);
            item.boxContains = '1 Tray Logos';
            break;
          case BoxSize['1 lb']:
            item.price = new Big(34.0);
            item.boxContains = '1 Tray Logos\n' + '1 Tray Assorted Chocolates';
            break;
          case BoxSize['1 1/2 lb']:
            item.price = new Big(51.0);
            item.boxContains = '1 Tray Logos\n' + '2 Trays Assorted Chocolates';
            break;
          case BoxSize['2 lb']:
            item.price = new Big(68.0);
            item.boxContains = '2 Trays Logos\n' + '2 Trays Assorted Chocolates';
            break;
          case BoxSize['3 lb']:
            item.price = new Big(102.0);
            item.boxContains =
              '2 Trays Logos\n' + '2 Trays Assorted Chocolates\n' + '1 Tray Meltaways\n' + '1 Tray Nuts & Caramels';
            break;
          case BoxSize['5 lb']:
            item.price = new Big(170.0);
            item.boxContains =
              '4 Trays Logos\n' +
              '3 Trays Assorted Chocolates\n' +
              '1 Tray Meltaways\n' +
              '1 Tray Nuts & Caramels\n' +
              '1 Tray Molded Truffles';
            break;
          default:
            item.price = null;
            item.boxContains = null;
        }
      }
    }

    this.dataLayer.processRequests();

    await Promise.all(allPromises);

    return this.calculate();
  }

  protected GST_HST?: ApplicableTaxes;
  protected override async preCalc(): Promise<void> {
    const allPromises: Promise<unknown>[] = [];

    this.GST_HST = await this.dataLayer.tax.filter('^(GST|HST)$', ProcessRequests.Now);

    for (const item of this.items) {
      item.taxes = this.GST_HST;
      if (item.logoType === null) {
        item.logoType = LogoType['Individual Logos'];

        if (item.itemID) {
          allPromises.push(this.saveItem(item, ['logoType'], ProcessRequests.Pool));
        }
      }
    }

    this.forceTBD = false;
    if (this.hasCustomPackaging) {
      for (const item of this.items) {
        if (item.quantity && item.quantity > 0) {
          if (!item.priceOverride || !item.priceOverride.gt(0)) {
            this.forceTBD = true;
            break;
          }
        }
      }
    }

    this.dataLayer.processRequests();
    await Promise.all(allPromises);
  }

  protected override async getDiscounts(discountSubtotals: DiscountSubtotals): Promise<void> {
    // await super.getDiscounts(discountSubtotals);

    // TODO remove hardcoding
    const discountable = discountSubtotals[DiscountTarget['Discountable Products']];
    let amount: number | undefined;
    if (discountable.gt(3500)) {
      amount = 20;
    } else if (discountable.gt(2000)) {
      amount = 15;
    } else if (discountable.gt(1000)) {
      amount = 10;
    }
    if (amount) {
      this.discounts.push({
        name: 'Volume Discount',
        shortName: 'Volume',
        marker: '*',

        target: DiscountTarget['Discountable Products'],
        effect: DiscountEffect['Subtotal % Reduction'],

        amount: new Big(amount).div(100),
        rate: new Big(amount).toFixed(0) + '%',
        total: new Big(0),

        message: 'Volume discount only applies to products marked with a *',

        active: true,
      });
    }

    if (discountSubtotals[DiscountTarget['Discountable Products']].gt(5000)) {
      this.discounts.push({
        name: 'Bonus Discount',
        shortName: 'Bonus',
        marker: '*',

        target: DiscountTarget['Discountable Products'],
        effect: DiscountEffect['Subtotal % Reduction'],

        amount: new Big('1.5').div(100),
        rate: new Big('1.5').toFixed(1) + '%',
        total: new Big(0),

        message: 'Bonus discount only applies to products marked with a *',

        active: true,
      });
    }

    if (this.isChristmas && discountSubtotals[DiscountTarget['All Products']].gt(0) && this.hasGiftBoxes()) {
      this.discounts.push({
        name: 'No Wrap Discount',
        shortName: 'No Wrap',
        marker: '*',

        targetFunc: (self: Discount): void => {
          let allNoWrap: Big = new Big(0);
          for (const item of this.items) {
            if (item.logoType === LogoType['Gift Boxes'] && item.cmasWrap === false && item.total) {
              allNoWrap = allNoWrap.plus(item.total);
            }
          }

          self.total = allNoWrap.times(self.amount).round(2);
        },
        effectFunc: (self: Discount, item: CorpLogoOrderItem): Big => {
          if (item.logoType === LogoType['Gift Boxes'] && item.cmasWrap === false && item.total) {
            return item.total.times(self.amount);
          }
          return new Big(0);
        },

        amount: new Big('2.5').div(100),
        rate: new Big('2.5').toFixed(1) + '%',
        total: new Big(0),

        message: "No wrap discount only applies to products which aren't wrapped and marked with a *",

        active: true,
      });
    }
  }

  private hasGiftBoxes(): boolean {
    for (const item of this.items) {
      if (item.logoType === LogoType['Gift Boxes'] && item.quantity) {
        return true;
      }
    }
    return false;
  }

  protected override suminate(): [Big, Big, Big] {
    // eslint-disable-next-line prefer-const
    let [subtotal, totalTax, saleTotal] = super.suminate();

    if (this.moldType === MoldType['New']) {
      const fee = new Big(350);
      subtotal = subtotal.plus(fee);

      if (this.locationID && this.GST_HST && this.GST_HST[this.locationID]) {
        for (const tax of this.GST_HST[this.locationID]) {
          const value = fee.times(tax.taxAmount).div(100);

          if (typeof this.taxSums[tax.taxName] === 'undefined') {
            this.taxSums[tax.taxName] = new Big(0);
          }
          this.taxSums[tax.taxName] = this.taxSums[tax.taxName].plus(value);
          totalTax = totalTax.plus(value);
        }
      }
    }

    return [subtotal, totalTax, saleTotal];
  }

  override updateEstimation(): void {
    super.updateEstimation();

    if (this.deliveryInfo.method === DeliveryMethod.Ship) {
      this.isEstimated = true;
    }
  }

  protected newOrderItem(): CorpLogoOrderItem {
    return new CorpLogoOrderItem(this);
  }
}
