import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APP_TITLE } from '../models/config';

export interface Page {
  uri: string;
  title?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private titleParts: Array<string> = [];
  private baseTitle: Array<string> = [];
  private seperator: string = ' - ';

  shoppingPage: Page;

  constructor(
    private Title: Title,
    private Location: Location,
  ) {
    this.shoppingPage = {
      uri: '/',
    };
  }

  private _setTitle(): void {
    const title = this.getTitle();
    this.Title.setTitle(title.join(this.seperator));
  }

  resetTitle() {
    this.titleParts = [];
    this.baseTitle = [];
  }

  setTitle(title: string | Array<string>, seperator: string = ' - '): void {
    if (typeof title === 'string') {
      title = [title];
    }
    this.seperator = seperator;
    this.titleParts = title;
    this._setTitle();
  }

  setBaseTitle(title: string | Array<string>, seperator: string = ' - '): void {
    if (typeof title === 'string') {
      title = [title];
    }
    this.seperator = seperator;
    this.baseTitle = title;
    this._setTitle();
  }

  /*addToTitle(title: string | Array<string>): void {
    if (typeof title === 'string') {
      title = [title];
    }
    this.titleParts.unshift.apply(this.titleParts, title);
    this._setTitle();
  }*/

  setShoppingPage(forcedTitle?: string | null, forcedURI?: string): void {
    if (forcedURI) {
      this.shoppingPage.uri = forcedURI;
    } else {
      this.shoppingPage.uri = this.Location.path();
    }

    const title = this.getTitle();

    if (forcedTitle === null) {
      delete this.shoppingPage.title;
    } else if (forcedTitle) {
      this.shoppingPage.title = forcedTitle;
    } else if (title.length > 0 && title[0] !== APP_TITLE) {
      this.shoppingPage.title = title[0];
    } else {
      delete this.shoppingPage.title;
    }
  }

  getShoppingPage(): Page {
    return this.shoppingPage;
  }

  getTitle(): Array<string> {
    return [...this.titleParts, ...this.baseTitle];
  }

  getTitleString(): string {
    return this.getTitle().join(this.seperator);
  }
}
