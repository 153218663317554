import { Menu } from '../../common/models/menu';

export const nowMS = () => new Date().getTime();
export const EVENT_TIMING = 500;
export const getMenuElement = (currentTarget: HTMLElement) => jQuery(currentTarget).closest('.menu')[0];

export class MenuBase {
  protected menuOpened: HTMLElement | null = null;

  private times: Array<number> = [];
  log(msg: string): void {
    return;
    this.times.push(nowMS());

    console.debug(
      msg +
        ' ' +
        (this.times.length === 1 ? 'MARK' : this.times[this.times.length - 1] - this.times[this.times.length - 2]),
    );
  }

  private lastMouseEnter: number = 0;
  private blockMouseEvents: boolean = false;

  mouseEnter(event: Event): void {
    this.log('mouseEnter');
    const newMenu = <HTMLElement>event.currentTarget;

    if (this.blockMouseEvents) {
      return;
    }

    if (this.menuOpened !== newMenu) {
      this.closeMenu();
      this.openMenu(newMenu);
      this.lastMouseEnter = nowMS();
    }
  }

  mouseLeave(event: Event): void {
    this.log('mouseLeave');
    const menu = <HTMLElement>event.currentTarget;

    if (this.menuOpened !== menu || this.blockMouseEvents) {
      return;
    }

    this.closeMenu();
  }

  menuItemClicked(event: Event): void {
    this.log('menuItemClicked');
    this.closeMenu();
  }

  menuTitleClicked(event: Event): void {
    this.log('clickEvent');
    const newMenu = getMenuElement(<HTMLElement>event.currentTarget);

    let skipToggle: boolean = false;
    if (this.lastMouseEnter + EVENT_TIMING > nowMS()) {
      if (this.menuOpened === newMenu) {
        skipToggle = true;
      }
    }

    if (this.menuOpened === newMenu) {
      if (!skipToggle) {
        this.closeMenu();
      } else {
        this.blockMouseEvents = true;
      }
    } else {
      this.closeMenu();
      this.openMenu(newMenu);
      this.blockMouseEvents = true;
    }
  }

  protected openMenu(menu: HTMLElement): void {
    if (menu) {
      this.menuOpened = menu;
      this.menuOpened.classList.add('hovered');
    }
  }

  protected closeMenu(): void {
    this.blockMouseEvents = false;
    if (this.menuOpened) {
      this.menuOpened.classList.remove('hovered');
      this.menuOpened = null;
    }
  }

  touchEvent: TouchEvent | null = null;
  touchStart(event: TouchEvent, menu: Menu): void {
    this.log('touchStart');
    if (menu.path && menu.menus) {
      this.touchEvent = event;
    }
  }

  touchEnd(event: TouchEvent): void {
    this.log('touchEnd');
    if (this.touchEvent) {
      const start = this.touchEvent.changedTouches[0];
      const end = event.changedTouches[0];

      if (start.clientX === end.clientX && start.clientY === end.clientY) {
        const touchedMenu = getMenuElement(<HTMLElement>event.currentTarget);

        if (touchedMenu !== this.menuOpened) {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();

          this.closeMenu();

          this.menuOpened = touchedMenu;
          this.menuOpened.classList.add('hovered');
        }
      }
    }
  }
}
