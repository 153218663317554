export type DiabeticOptions = 'Diabetic' | 'Regular' | 'Both';
const AllDiabeticOptions: readonly DiabeticOptions[] = ['Diabetic', 'Regular', 'Both'] as const;

export function isDiabeticOptions(value: string): value is DiabeticOptions {
  return AllDiabeticOptions.includes(value as DiabeticOptions);
}

export interface Keyword {
  // TODO this is badly named refactor this and that
  keywordID?: number;
  keyword: string;
  suggestions?: Array<string>;
}

export type SearchStatsMode = 'Unused Words' | 'Used Words' | 'Recent Searches';
export type SearchStatsModeID = 'unused' | 'used' | 'searches';

export interface Word {
  // TODO this is badly named refactor this and that
  keyword: string;
  count: number;
  searchLink?: string;
}

export const SearchURI = '/Search';

export interface SearchEntry {
  time: Date;
  ip: string;
  rawSearch: string;
  searchLink?: string;
  sectionID: number;
  sectionName: string;
  diabetic: boolean;
  similar: boolean | null;
}

export function PageThumbnails(uriID: number, nocache: boolean | Date = false) {
  if (nocache === true) {
    nocache = new Date();
  }

  return `/images/dynamic/pageThumbnails/${uriID}.png` + (nocache !== false ? '?' + (<Date>nocache).getTime() : '');
}

export interface Page {
  uriID: number | null;
  uri: string;
  title: string;
  description: string;
  keywords: Array<Keyword>;
  imgSrc?: string;
  uriInvalid?: boolean;
  imageLoaded?: boolean;
  //pageImageID: number //TODO this isn't used and should be removed from database
}
