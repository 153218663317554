import { Component, OnInit, OnDestroy, Inject, ElementRef } from '@angular/core';

import { DialogService } from '../dialog.service';
import { DialogComponent, StaticDialogComponent_Metadata } from '../dialogComponent';
import { UserAccount } from '../../../models/account';
import { APIService } from '../../../services/api.service';

import { MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Validation } from '../../../models/validation';
import { SafeHtml } from '@angular/platform-browser';
import { StaticImplements } from '../../staticImplements';

export type LoginResult = boolean | undefined;

export interface LoginOptions {
  message?: string | SafeHtml;
  blocking?: boolean;
  goHome?: boolean;
  replaceOnly?: never; // TODO when this is tested boolean;
}

export interface LoginParams extends LoginOptions {
  loginMethod: (userName: string, password: string) => Promise<boolean>;
}

@Component({
  selector: 'ccf-login-dialog',
  templateUrl: 'loginDialog.component.html',
  styleUrls: ['loginDialog.component.less'],
  providers: [],
})
@StaticImplements(StaticDialogComponent_Metadata)
export class LoginDialogComponent extends DialogComponent<LoginParams, LoginResult> implements OnInit, OnDestroy {
  public static DIALOG_CLASS: string = 'loginDialog';
  public static DEFAULT_CONFIG: MatDialogConfig = {};
  public static ONLY_ONE: MatDialogRef<LoginDialogComponent> | null = null;

  loggedIn?: boolean;
  message?: string;

  username: string = '';
  password: string = '';

  invalid: Validation = {};
  hasValidated: boolean = false;

  constructor(
    private apiService: APIService,

    private elementRef: ElementRef,
    private dialogService: DialogService,
    public override matDialogRef: MatDialogRef<LoginDialogComponent, LoginResult>,
    @Inject(MAT_DIALOG_DATA) public override params: LoginParams,
  ) {
    super(matDialogRef);

    this.isBlocking = !!this.params.blocking;
    this.keyboard = !this.isBlocking;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    jQuery(document).off('keydown.login');

    this.dialogService.closed();
  }

  ngOnInit(): void {
    this.dialogService.opened();

    this._subscriptions.push(
      this.apiService.authenticatedDataStream().subscribe((userData?: UserAccount | null) => {
        if (typeof userData !== 'undefined') {
          this.loggedIn = userData !== null;

          if (this.loggedIn) {
            this.closeDialog(true);
          }
        }
      }),
    );

    const focusCheckTimer = setInterval(() => {
      const element = jQuery(this.elementRef.nativeElement).find('[name=username]');
      if (element.length !== 0) {
        clearInterval(focusCheckTimer);
        element.focus();
      }
    }, 50);

    jQuery(document).on('keydown.login', ($event: JQuery.TriggeredEvent) => {
      if ($event.keyCode === 13) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        if (this.valid()) {
          this.login();
        }
      }
    });

    this.apiService.processRequests();
  }

  private validate(): boolean {
    this.invalid = {};

    if (!this.username.trim()) {
      this.invalid['username'] = 'Username is required.';
    }

    if (!this.password) {
      this.invalid['password'] = 'Password is required.';
    }

    return Object.keys(this.invalid).length === 0;
  }

  valid(): boolean {
    this.hasValidated = true;
    return this.validate();
  }

  revalidate(): void {
    if (this.hasValidated) {
      this.validate();
    }
  }

  login(): void {
    if (this.valid()) {
      this.params
        .loginMethod(this.username, this.password)
        .then((status: boolean) => {
          if (status) {
            this.closeDialog(true);
          }
        })
        .catch((reason: Error | string) => {
          if (typeof reason === 'string') {
            this.message = reason;
          } else {
            this.message = reason.message;
          }
        });
    }
  }

  no(): void {
    this.closeDialog();
  }

  yes(): void {
    this.login();
  }
}
