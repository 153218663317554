import { Pipe, PipeTransform } from '@angular/core';
import { OutputData } from '@editorjs/editorjs';
import { parseBlocks, render } from '../directives/editorjs/render';

@Pipe({
  name: 'render',
})
export class RenderHTMLPipe implements PipeTransform {
  transform(value: string | OutputData, stripTags?: boolean): string {
    let output = parseBlocks(value);
    if (typeof output !== 'string') {
      output = render(output);
    }

    if (stripTags) {
      output = output.replace(/<\/?([^>]*)>/g, '');
    }

    return output;
  }
}
