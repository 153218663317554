import { Component, OnInit, Input } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'ccf-email-link',
  templateUrl: 'emailLink.component.html',
  styleUrls: ['emailLink.component.less'],
})
export class EmailLinkComponent implements OnInit {
  @Input() emailAddress!: string;

  @Input() displayText?: string;

  email!: string;

  constructor(
    private configService: ConfigService,
    protected apiService: APIService,
  ) {}

  ngOnInit(): void {
    void this.configService.getEmail(this.emailAddress).then((email: string) => {
      this.email = email;
    });

    this.apiService.processRequests();
    // TODO its possible that other requests accidently get sent early, should make sure all code uses the all promises check
  }
}
