import { APICorpLogoItem, BoxSize, LogoType, BoxStyle, CorpLogoTypeFoil } from '../checkout';
import { BaseOrderItem } from './base';
import { CorpLogoOrder } from '../order/corpLogo';
import { cloneDeep } from 'lodash-es';
import { Big } from 'big.js';

export class CorpLogoOrderItem extends BaseOrderItem implements APICorpLogoItem<Big> {
  logoID: number | null = null;
  logoType: LogoType | null = null;

  description: string = '';
  foilSubType: string | null = null;
  typeID: number | null = null;
  typeName: string | null = null;

  private _priceOverride: Big | null = null;
  private _priceOverrideString: string | null = null;
  get priceOverride(): Big | null {
    return this._priceOverride;
  }
  set priceOverride(value: Big | null) {
    this._priceOverride = value;
    this._priceOverrideString = value ? value.toFixed(2) : value;
  }
  get priceOverrideString(): string | null {
    return this._priceOverrideString;
  }
  set priceOverrideString(value: string | null) {
    this._priceOverrideString = value;
  }

  validatePriceOverride(): void {
    if (this._priceOverrideString !== null) {
      this._priceOverrideString = this._priceOverrideString.trim();
    } else {
      this._priceOverrideString = '';
    }

    if (this._priceOverrideString === '') {
      this.priceOverride = null;
    } else {
      try {
        this.priceOverride = new Big(this._priceOverrideString);
      } catch (e) {
        this._priceOverrideString = this.priceOverride ? this.priceOverride.toFixed(2) : this.priceOverride;
      }
    }
  }

  boxSize: BoxSize | null = null;
  boxStyle: BoxStyle | null = null;
  boxContains: string | null = null;
  boxContainsOverride: string | null = null;
  typeFoils: Array<CorpLogoTypeFoil> = [];
  cmasWrap: boolean = false;

  constructor(
    protected override parent: CorpLogoOrder,
    protected override data?: APICorpLogoItem,
  ) {
    super(parent, data);

    if (data) {
      this.refreshed(data);
    }
  }

  override refreshed(item: APICorpLogoItem): void {
    super.refreshed(item);

    if (typeof item.logoID !== 'undefined') {
      this.logoID = null;
      for (const logo of this.parent.logos) {
        if (item.logoID === logo.logoID) {
          this.logoID = logo.logoID;
        }
      }
    }
    if (typeof item.logoType !== 'undefined') {
      this.logoType = item.logoType;
    }

    if (typeof item.description !== 'undefined') {
      this.description = item.description || '';
    }
    if (typeof item.foilSubType !== 'undefined') {
      this.foilSubType = item.foilSubType;
    }
    if (typeof item.typeID !== 'undefined') {
      this.typeID = item.typeID;

      if (typeof item.typeName !== 'undefined') {
        this.typeName = item.typeName;
      }
    }

    if (typeof item.boxSize !== 'undefined') {
      this.boxSize = item.boxSize;
    }
    if (typeof item.boxStyle !== 'undefined') {
      this.boxStyle = item.boxStyle;
    }
    if (typeof item.boxContainsOverride !== 'undefined') {
      this.boxContainsOverride = item.boxContainsOverride;
    }

    if (typeof item.productDiscountable !== 'undefined') {
      this.productDiscountable = item.productDiscountable;
    }

    if (typeof item.typeFoils !== 'undefined') {
      this.refillFoils();
      for (let i = 0; i < this.typeFoils.length; i++) {
        this.typeFoils[i].typeID = item.typeFoils[i].typeID;
        this.typeFoils[i].foilSubType = item.typeFoils[i].foilSubType;
      }
    }

    if (typeof item.cmasWrap !== 'undefined') {
      this.cmasWrap = item.cmasWrap;
    }

    if (typeof item.priceOverride !== 'undefined') {
      this.priceOverride = item.priceOverride ? new Big(item.priceOverride) : null;
    }

    if (typeof item.discountOverride !== 'undefined') {
      this.discountOverride = item.discountOverride;
    }

    this.checkItem();
  }

  private refillFoils(): void {
    if (!this.typeFoils) {
      this.typeFoils = [];
    }

    const missing: number = 3 - this.typeFoils.length;
    for (let i = 0; i < missing; i++) {
      this.typeFoils.push({});
    }
  }

  private checkItem(): void {
    if (this.logoType === LogoType['Gift Boxes']) {
      this.refillFoils();
      for (const tf of this.typeFoils) {
        if (typeof tf.typeID === 'undefined') {
          tf.typeID = null;
        }
        if (typeof tf.foilSubType === 'undefined') {
          tf.foilSubType = null;
        }
      }
    }
  }

  override calculate(): void {
    if (!this.parent.hasCustomPackaging && this.logoType !== LogoType['N/A']) {
      super.calculate();
    } else {
      this.total = null;
      this.cost = null;
      if (this.quantity && this.priceOverride && this.priceOverride.gt(0)) {
        this.total = this.priceOverride.times(this.quantity);
        this.cost = this.priceOverride;
      }
    }
  }

  override export() {
    const exportData = super.export();

    exportData['logoID'] = this.logoID;
    exportData['logoType'] = this.logoType;

    exportData['priceOverride'] = this.priceOverride;

    exportData['description'] = this.description;
    exportData['foilSubType'] = this.foilSubType;

    exportData['typeID'] = this.typeID;
    exportData['typeName'] = this.typeName;

    exportData['boxSize'] = this.boxSize;
    exportData['boxStyle'] = this.boxStyle;
    exportData['boxContainsOverride'] = this.boxContainsOverride;

    exportData['typeFoils'] = this.typeFoils;

    exportData['cmasWrap'] = this.cmasWrap;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }
}
