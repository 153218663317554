import { Validation } from './validation';
import { ApplicableTaxes } from './tax';
import { enumKeys } from '../helpers/enum';
import { CorpLogo } from './corpLogo';
import { UserAccount, AccountType } from './account';
import { Status, Discountable } from './product';
import { StockStatus } from './type';

export interface Step {
  number: number;
  title: string;
  shortTitle?: string;
  uri: string;
  key: string;
  isComplete?: boolean;
  validate: () => boolean;
}

export enum OrderType {
  Cart = 1,
  OrderSheet,
  CorpLogo,
  CustomLabel,
  Fountain,
  Donation,
}

export enum DeliveryMethod {
  Ship = 1,
  Pickup,
}

export enum Store {
  Burnaby = 1,
  Poco,
}

export enum BarWrapperTemplates {
  Horizontal = 1,
  Vertical = 2,
}

export enum CircleTemplates {
  Template1 = 1,
  Template2,
  OldTemplate3,
  Template3Artwork,
}

export enum RectangleTemplates {
  Template1 = 1,
  OldTemplate2,
  OldTemplate3,
  Template2Artwork,
}

export type CustomLabelsTemplates = BarWrapperTemplates | CircleTemplates | RectangleTemplates;

export enum OrderStatus {
  NotUsed = 1,
  Open,
  Processing,
  Paid,
  Submitted,
  Closed,
  Locked,
  Quote,
  Mold_TBD,
  Refunded,
  Correction,
  Corrected,
}

export namespace OrderStatus {
  const openStatus = [OrderStatus.NotUsed, OrderStatus.Open, OrderStatus.Processing, OrderStatus.Quote];
  const closeStatus = [
    OrderStatus.Paid,
    OrderStatus.Submitted,
    OrderStatus.Closed,
    OrderStatus.Locked,
    OrderStatus.Refunded,
    OrderStatus.Corrected,
  ];
  export const isOpen = function (status: OrderStatus, user: UserAccount | null) {
    const statuses = openStatus.slice(0);
    if (user && user.accountType === AccountType.Staff) {
      Array.prototype.push.apply(statuses, [OrderStatus.Mold_TBD, OrderStatus.Correction]);
    }
    return statuses.indexOf(status) !== -1;
  };
  export const isClosed = function (status: OrderStatus, user: UserAccount | null) {
    const statuses = closeStatus.slice(0);
    if (!(user && user.accountType === AccountType.Staff)) {
      Array.prototype.push.apply(statuses, [OrderStatus.Mold_TBD, OrderStatus.Correction]);
    }
    return statuses.indexOf(status) !== -1;
  };
}

export enum PaymentType {
  Instore = 1,
  Paypal,
  Interac,
  PaymentRequest,
  TooLarge,
  CasePack,
  CallIn,
  PaypalRequest,
  Wholesale,
}

export interface APIContactInfo {
  contactInfoID?: number | null;
  name?: string;
  orgName?: string;
  email?: string;
  phoneNumber?: string;
  requiredDate?: string;
  division?: string;
}

export interface ContactInfo extends APIContactInfo {
  invalid: Validation;
}

export interface APILocationInfo {
  name?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: 'Canada' | 'USA';
  postalCode?: string;

  method?: DeliveryMethod;
  store?: Store;

  sameAs?: boolean;
}

export type APIDeliveryInfo = APILocationInfo;

export interface DeliveryInfo extends APIDeliveryInfo {
  invalid: Validation;
}

export type APIBillingInfo = APILocationInfo;

export interface BillingInfo extends APIBillingInfo {
  invalid: Validation;
}

export enum LabelType {
  BarWrapper = 1,
  Circle,
  Rectangle,
}
export const LabelType_keys = enumKeys(LabelType);

export enum LabelSize {
  '1 1/2' = 1,
  '2 1/2',
  '2 x 3',
}
export const LabelSize_keys = enumKeys(LabelSize);

export enum FountainSize {
  Small = 1,
  Large,
}
export const FountainSize_keys = enumKeys(FountainSize);

export enum MoldType {
  New = 1,
  Reorder,
}
export const MoldType_keys = enumKeys(MoldType);

export enum LogoType {
  'Individual Logos' = 1,
  'Gift Boxes',
  'N/A',
}
export const LogoType_keys = enumKeys(LogoType);

export enum BoxSize {
  '1/2 lb' = 1,
  '1 lb',
  '1 1/2 lb',
  '2 lb',
  '3 lb',
  '5 lb',
}
export const BoxSize_keys = enumKeys(BoxSize);

export enum BoxStyle {
  "Charlie's Box" = 1,
  'Black Acetate Box',
  'Black Leatherette Box',
}
export const BoxStyle_keys = enumKeys(BoxStyle);

export interface OnlineOrderItem {
  productID: number | null;
  webName: string | null;
}

export interface OrderFile {
  orderID: number;
  fileName: string;
  contentType: string;
  storageID: string;
  attachmentType: string;
  size: number;
}

export interface DatabaseItem {
  itemID?: number | null;
  orderID?: number | null;
  key: string | null;

  quantity: number | null;

  taxes?: ApplicableTaxes;
}
export interface APIFountainItem<Money = string> extends DatabaseItem {
  price: Money | null;
}

export interface APICustomLabelItem<Money = string> extends DatabaseItem {
  foilSubType?: string | null;
  typeID?: number | null;
  typeName?: string | null;
  price: Money | null;
  freightExempt?: boolean;
  productDiscountable?: Discountable;
}

export interface CorpLogoTypeFoil {
  foilSubType?: string | null;
  typeID?: number | null;
  typeName?: string | null;
}

export enum DiscountOverride {
  ForceOn = 1,
  ForceOff,
}

export interface APICorpLogoItem<Money = string> extends DatabaseItem {
  logoID: number | null;
  logoType: LogoType | null;

  description?: string | null;
  foilSubType?: string | null;
  typeID?: number | null;
  typeName?: string | null;

  priceOverride?: Money | null;
  discountOverride?: DiscountOverride | null;
  productDiscountable?: Discountable;

  boxSize?: BoxSize | null;
  boxStyle?: BoxStyle | null;
  boxContainsOverride?: string | null;
  typeFoils?: Array<CorpLogoTypeFoil>;
  cmasWrap?: boolean;
}

export interface APIOrderSheetItem<Money = string> extends DatabaseItem {
  productID: number | null;
  productName: string | null;
  description: string | null;

  onsale: Money | null;
  price: Money | null;

  typeID?: number | null;
  typeName?: string | null;

  unitID: number | null;
  unitName: string | null;

  priceDiscountable: Discountable;
  productDiscountable: Discountable;
  inDiscountableCategory: boolean;
}

export interface APICartItem<Money = string> extends DatabaseItem {
  productID: number | null;
  productName: string | null;
  productURI: string | null;
  shortDescription: string | null;
  description: string | null;
  isDiabetic: boolean | null;
  onsale: Money | null;
  price: Money | null;
  isFragile: boolean | null;
  productStatus: Status | null;

  minMax: number | null;

  thumbnail: string | null;

  priceStatus: Status | null;

  typeID?: number | null;
  typeName?: string | null;
  chocolateStockStatus: StockStatus | null;
  chocolateTypeStatus: Status | null;
  chocolateMinMax: number | null;

  unitID: number | null;
  unitName: string | null;

  priceDiscountable: Discountable;
  productDiscountable: Discountable;
  inDiscountableCategory: boolean;

  packagingTypeID?: number | null;
  packagingTypeName?: string | null;
  packagingSubType?: string | null;
  packagingTypeStatus: Status | null;

  customizationTypeID?: number | null;
  customizationTypeName?: string | null;
  customizationSubType?: string | null;
  customizationTypeStatus: Status | null;

  foundIn: string | null;

  categoriesIn: number[] | null;
}

export interface EmailedAudit {
  emailAddress: string;
  time: Date;
}

export interface DatabaseOrder<T extends DatabaseItem | unknown = unknown> {
  key?: string;
  orderID?: number; // TODO split into admin order type and user order type
  type: OrderType;
  ticket: string;
  lastActive: number;
  ipnID?: number;
  status: OrderStatus;
  statusTime: number;

  emailedAudit?: EmailedAudit[];

  parentOrderID?: number;
  parentOrderStatus?: OrderStatus;

  account?: UserAccount;

  additionalInformation?: string;
  // TODO promo discountCode?: string;
  gaClientID?: string;
  payment?: PaymentType | null;
  snapshot?: unknown;

  contactInfo?: APIContactInfo;
  deliveryInfo?: APIDeliveryInfo;

  items: T[];
}

export interface APIFountainOrder<Money = string> extends DatabaseOrder<APIFountainItem<Money>> {
  type: OrderType.Fountain;
  fountainSize?: FountainSize | null;
  extraBucket: boolean;
  setup: boolean | null;
  chocolateTypeID?: number | null;
}

export enum DonationWith {
  Company = 1,
  Charity,
}

export enum DonationWork {
  Work = 1,
  Volunteer,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface APIDonationOrder<Money = string> extends DatabaseOrder {
  type: OrderType.Donation;
  // org in contactInfo
  charityNumber?: string;
  fundGroupName?: string;

  // name in contactInfo
  // email in contactInfo
  // phone in contactInfo
  with?: DonationWith | null;
  work?: DonationWork | null;

  letter?: Array<OrderFile>;

  eventDate?: Date | null;
  where?: string;
  people?: string;
  eventType?: string;
  donationPurpose?: string;

  connection?: string;
  whyCharlies?: string;
  recognition?: string;
}

export interface APICustomLabelOrder<Money = string> extends DatabaseOrder<APICustomLabelItem<Money>> {
  type: OrderType.CustomLabel;
  labelType?: LabelType | null;
  background?: string;
  template?: CustomLabelsTemplates | null; // TODO which should be null
  labelSize?: LabelSize | null;

  frontFont?: string;
  frontColor?: string;
  frontText1?: string;
  frontText2?: string;

  backFont?: string;
  backColor?: string;
  backText1?: string;
  backText2?: string;
  backText3?: string;

  artwork?: Array<OrderFile>;
  skipArt?: boolean;

  billingInfo?: APIBillingInfo;
}

export interface APICorpLogoOrder<Money = string> extends DatabaseOrder<APICorpLogoItem<Money>> {
  type: OrderType.CorpLogo;
  moldType?: MoldType | null;
  pieceSizeShape?: string;
  pieceNotSure?: boolean;
  approx?: string;
  newLogoPrice?: Money | null;
  newLogoCustomPackaging?: boolean;
  reviewAudit?: Array<number>;
  forceChristmas?: boolean | null;

  artwork?: Array<OrderFile>;
  skipArt?: boolean;

  logos?: Array<CorpLogo<Money>>;

  billingInfo?: APIBillingInfo;
}

export enum OrderSheetType {
  Normal = 1,
  Fundraising,
}

export enum OrderSheetSeason {
  Christmas = 1,
  Valentines,
  Easter,
}

export interface APIOrderSheetOrder<Money = string> extends DatabaseOrder<APIOrderSheetItem<Money>> {
  type: OrderType.OrderSheet;
  groupName?: string;
  groupValid?: boolean;
  divisionRequired?: boolean;
  division?: string;

  orderSheetType?: OrderSheetType;
  season?: OrderSheetSeason;

  // deliveryInfo: never;
}

export interface APICartOrder<Money = string> extends DatabaseOrder<APICartItem<Money>> {
  type: OrderType.Cart;
  hasCasePack?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OrderSnapshotData = any; //TODO

export interface BackendOrderData {
  orderSheetType?: OrderSheetType;
  season?: OrderSheetSeason;
  snapshot?: OrderSnapshotData;
  fundGroupName?: string;
  correctedEmailSent?: boolean;
}

export interface BackendOrder<T extends DatabaseItem | unknown = unknown> extends DatabaseOrder<T> {
  ticket: string;

  accountID: number;

  data?: BackendOrderData;
}

export interface BlockOrdersStatus {
  blocked: boolean;
  message: string;
}
