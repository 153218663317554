import { Big } from 'big.js';

import { APIFountainItem } from '../checkout';
import { BaseOrderItem } from './base';
import { FountainOrder } from '../order/fountain';
import { cloneDeep } from 'lodash-es';

export class FountainOrderItem extends BaseOrderItem implements APIFountainItem<Big> {
  constructor(
    protected override parent: FountainOrder,
    protected override data?: APIFountainItem,
  ) {
    super(parent, data);
    if (data) {
      this.refreshed(data);
    }
  }

  override refreshed(item: APIFountainItem): void {
    super.refreshed(item);

    if (typeof item.price !== 'undefined') {
      this.price = item.price ? new Big(item.price) : null;
    }
  }

  override export() {
    const exportData = super.export();

    exportData['price'] = this.price ? new Big(this.price).toFixed(2) : null;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }
}
