<ul class="menubar desktopMenuBar">
  <li>&nbsp;</li>

  <li
    class="menu"
    *ngFor="let menu of menus"
    (mouseenter)="mouseEnter($event)"
    (mouseleave)="mouseLeave($event)"
    (touchstart)="touchStart($event, menu)"
    (touchend)="touchEnd($event)"
  >
    <a
      *ngIf="!menu.path"
      (click)="menuTitleClicked($event)"
      >{{ menu.title }}</a
    >
    <a
      *ngIf="menu.path"
      [routerLink]="menu.path"
      ><span (click)="menuItemClicked($event)">{{ menu.title }}</span></a
    >
    <ul *ngIf="menu.menus">
      <li
        class="menuItem"
        *ngFor="let menuItem of menu.menus"
      >
        <a [routerLink]="menuItem.path"
          ><span (click)="menuItemClicked($event)">{{ menuItem.title }}</span></a
        >
      </li>
    </ul>
  </li>

  <li>&nbsp;</li>
</ul>
