import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product } from '../../common/models/product';
import { MainPicture as _MainPicture } from '../../common/models/mainPicture';
import { DDL } from '../../common/helpers/dropdownlist';
import { FrontPageSection as _FrontPageSection } from '../../common/services/frontPage.service';
import { PageService } from '../../common/services/page.service';
import { isElementInViewport } from '../../common/helpers/isElementInViewport';

import { FrontPage } from './frontPage.model';

import { FrontPageResolver } from './frontPage.resolver';

import { Router, Route, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { GtagService, HasCategory, HasListPosition } from '../../common/services/gtag.service';

export interface FrontPageSection extends _FrontPageSection {
  element: JQuery;
  seen: boolean;
}

export interface MainPicture extends _MainPicture {
  displayed: boolean;
  element: JQuery;
}

@Component({
  selector: 'ccf-home-page',
  templateUrl: 'frontPage.component.html',
  styleUrls: ['frontPage.component.less'],
  providers: [],
})
export class FrontPageComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  mainPictures = new DDL([] as MainPicture[], null, 'mainPictureID', null);
  sections: Array<FrontPageSection> = [];
  LIMIT_SECTIONS_DISPLAYED: number = 4;
  MAX_SECTIONS_DISPLAYED: number = 4;

  private PICTURE_TIMEOUT: number | null = null;
  private PICTURE_DELAY: number = 10 * 1000;
  private PICTURE_ANIMATION_TIME: number = 1.5 * 1000;
  private PICTURE_ANIMATING: boolean = false;
  private PICTURE_LOAD_DONE: number = 0;

  private SCROLL_TIMEOUT: number | null = null;
  private SCROLL_DELAY: number = 2 * 1000;
  private SCROLL_LEFT_MOST: number = 0;
  private SCROLL_DONE: number = 0;
  private SCROLL_SPEED: number = 10;

  private SPACE!: number;
  private SCROLL_PRODUCT_WIDTH: number = 180;

  private DEFAULT_PICTURE: string = '/images/static/frontPage/defaultFrontPage';

  pageHeader: string = 'Chocolate & Candy for Every Occasion!';
  pageDescription: string =
    "Charlie's specializes in creating high " +
    'quality chocolates with unique shapes. From ' +
    'Seasonal items to Gift Boxes, or Corporate Logos ' +
    'to Candy - we do it all!';

  constructor(
    private PageService: PageService,
    private GtagService: GtagService,
    private Router: Router,
    private route: ActivatedRoute,
  ) {}

  //TODO POST_LAUNCH IE EDGE lag on scroll

  ngOnInit() {
    this._subscriptions.push(
      this.route.data.subscribe((data) => {
        const page: FrontPage = data['page'] as FrontPage;

        this.sections.splice(0, this.sections.length);
        Array.prototype.push.apply(this.sections, page.sections);

        this.mainPictures.options.splice(0, this.mainPictures.options.length);
        Array.prototype.push.apply(this.mainPictures.options, page.mainPictures);
        this.PICTURE_LOAD_DONE = this.mainPictures.options.length;
        //TODO POST_LAUNCH lazy load all but first image

        for (const section of this.sections) {
          section.selectedProduct = section.products[Math.floor(Math.random() * section.products.length)];
          section.seen = false;
        }

        if (this.sections.length === 0) {
          jQuery('.frontPage .scrolly').hide();
        } else {
          jQuery('.frontPage .scrolly')
            .show()
            .on('mousemove', () => {
              this.resetScroll();
            });
        }

        this.SCROLL_DONE = this.sections.length;

        if (this.sections.length > this.MAX_SECTIONS_DISPLAYED) {
          this.SCROLL_LEFT_MOST = Math.floor(Math.random() * this.sections.length);
        } else {
          this.SCROLL_LEFT_MOST = 0;
        }

        this.mainPictures.selected =
          this.mainPictures.options[Math.floor(Math.random() * this.mainPictures.options.length)];
        this.mainPictures.selected.displayed = true;

        this.mainPictureRotation();
        this.trackResize();
      }),
    );

    const title = this.PageService.getTitle(); //Because this one page has the app title first then the chocolate river stuff
    title.push('Home of the Chocolate');

    this.PageService.setBaseTitle(title);

    window.addEventListener('resize', this.trackResize, self.CCF.supportsPassiveListeners ? { passive: true } : false);
  }

  productClick(section: FrontPageSection): void {
    if (section.selectedProduct) {
      this.GtagService.productClick(
        section.selectedProduct,
        'Front Page',
        this.sections.indexOf(section) + 1,
        section.sectionName,
      );
    }
  }

  ngOnDestroy() {
    jQuery('.frontPage .picSelectors, .frontPage .mainPictures').off('mousemove');
    jQuery('.frontPage .scrolly').off('mousemove');

    if (this.PICTURE_TIMEOUT !== null) {
      clearTimeout(this.PICTURE_TIMEOUT);
      this.PICTURE_TIMEOUT = null;
    }

    if (this.SCROLL_TIMEOUT !== null) {
      clearTimeout(this.SCROLL_TIMEOUT);
      this.SCROLL_TIMEOUT = null;
    }

    this._subscriptions.forEach((subscription) => subscription.unsubscribe());

    window.removeEventListener('resize', this.trackResize, false);

    this.clearAllTimeouts();
  }

  protected timeouts: Array<number> = [];
  clearAllTimeouts(): void {
    this.timeouts.push(this.PICTURE_TIMEOUT || 0);
    this.timeouts.push(this.SCROLL_TIMEOUT || 0);

    for (const timeout of this.timeouts) {
      window.clearTimeout(timeout);
    }
    this.timeouts = [];
  }

  mainPictureRotation() {
    const timeoutFunction = () => {
      this.mainPictureDoRotation();
      if (this.PICTURE_TIMEOUT !== null) {
        clearTimeout(this.PICTURE_TIMEOUT);
        this.PICTURE_TIMEOUT = null;
      }
      this.PICTURE_TIMEOUT = window.setTimeout(timeoutFunction, this.PICTURE_DELAY);
    };

    if (this.mainPictures.options.length <= 1) {
      jQuery('.frontPage .picSelectors').hide();
      jQuery('.frontPage .picSelectors, .frontPage .mainPictures').off('mousemove');
    } else {
      jQuery('.frontPage .picSelectors').show();
      jQuery('.frontPage .picSelectors, .frontPage .mainPictures').on('mousemove', () => {
        if (this.PICTURE_TIMEOUT !== null) {
          clearTimeout(this.PICTURE_TIMEOUT);
          this.PICTURE_TIMEOUT = null;
        }
        this.PICTURE_TIMEOUT = window.setTimeout(timeoutFunction, this.PICTURE_DELAY);
      });
      if (this.PICTURE_TIMEOUT !== null) {
        clearTimeout(this.PICTURE_TIMEOUT);
        this.PICTURE_TIMEOUT = null;
      }
      this.PICTURE_TIMEOUT = window.setTimeout(timeoutFunction, this.PICTURE_DELAY);
    }
  }

  mainPictureDoRotation() {
    if (this.PICTURE_LOAD_DONE === 0) {
      let index = this.mainPictures.options.indexOf(this.mainPictures.selected!);
      index++;

      if (index > this.mainPictures.options.length - 1) {
        index = 0;
      }

      this.showPicture(this.mainPictures.options[index], true);
    }
  }

  mainPictureLoaded(event: Event, mainPicture: MainPicture) {
    const element: HTMLElement = <HTMLElement>(event.target || event.srcElement || event.currentTarget);
    mainPicture.element = jQuery(element).closest('a');
    this.PICTURE_LOAD_DONE--;
  }

  mainPictureError(mainPicture: MainPicture) {
    if (this.mainPictures.selected === mainPicture) {
      mainPicture.imgSrc = this.DEFAULT_PICTURE; //TODO check this
    } else {
      this.mainPictures.options.splice(this.mainPictures.options.indexOf(mainPicture), 1);
    }
    this.PICTURE_LOAD_DONE--;
  }

  showPicture(mainPicture: MainPicture, automatic: boolean = false) {
    if (this.PICTURE_ANIMATING) {
      return;
    }
    this.PICTURE_ANIMATING = true;

    if (this.mainPictures.selected !== mainPicture) {
      const oldPicture: MainPicture | null = this.mainPictures.selected;
      const newPicture: MainPicture = mainPicture;

      newPicture.element.css('z-index', '2');
      newPicture.element.css('opacity', '0');
      newPicture.displayed = true;

      if (oldPicture) {
        oldPicture.element.css('z-index', '1');
        oldPicture.element.css('opacity', '1');
      }

      newPicture.element.animate(
        {
          opacity: 1,
        },
        this.PICTURE_ANIMATION_TIME,
        () => {
          this.PICTURE_ANIMATING = false;
          if (oldPicture) {
            oldPicture.element.css('z-index', '0');
            oldPicture.element.css('opacity', '0');
            oldPicture.displayed = false;
          }
        },
      );

      this.mainPictures.selected = mainPicture;
    }

    if (automatic === false) {
      if (this.PICTURE_TIMEOUT !== null) {
        clearTimeout(this.PICTURE_TIMEOUT);
        this.PICTURE_TIMEOUT = null;
      }

      this.PICTURE_TIMEOUT = window.setTimeout(() => {
        this.mainPictureRotation();
      }, this.PICTURE_DELAY);
    }
  }

  private getScrollIndex(index: number) {
    if (this.sections.length <= 0) {
      throw new Error('Infinite loop prevented in getScrollIndex');
    }

    while (index < 0) {
      index += this.sections.length;
    }

    while (index >= this.sections.length) {
      index -= this.sections.length;
    }
    return index;
  }

  private getScrollSpot(position: number) {
    const freeSpace = this.SPACE - this.SCROLL_PRODUCT_WIDTH * this.MAX_SECTIONS_DISPLAYED;
    const padding = freeSpace / this.MAX_SECTIONS_DISPLAYED / 2;

    return (position + 1) * padding + position * (this.SCROLL_PRODUCT_WIDTH + padding);
  }

  private pageItems: JQuery<HTMLElement> | null = null;
  protected trackResize = () => this._trackResize();
  protected _trackResize(): void {
    const scrolly = jQuery('.frontPage .scrolly');
    scrolly.removeClass('empty');
    scrolly.show().on('mousemove', () => {
      this.resetScroll();
    });
    this.SPACE = jQuery('.frontPage .innerScrolly').width()!;

    /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
    while (true) {
      const freeSpace = this.SPACE - this.SCROLL_PRODUCT_WIDTH * this.MAX_SECTIONS_DISPLAYED;

      if (freeSpace < 0) {
        this.MAX_SECTIONS_DISPLAYED--;
      } else if (freeSpace > this.SCROLL_PRODUCT_WIDTH && this.MAX_SECTIONS_DISPLAYED < this.LIMIT_SECTIONS_DISPLAYED) {
        this.MAX_SECTIONS_DISPLAYED++;
        //let product = this.sections[this.getScrollIndex(this.SCROLL_LEFT_MOST + this.MAX_SECTIONS_DISPLAYED - 1)];
        //product.element.css('left', this.getScrollSpot(this.MAX_SECTIONS_DISPLAYED - 1)) + 'px';
      } else if (this.MAX_SECTIONS_DISPLAYED === 0) {
        scrolly.addClass('empty');
        break;
      } else {
        break;
      }
    }

    if (this.currentMove === null) {
      if (this.SCROLL_DONE === 0) {
        this.scrollSetPositions();

        if (this.SCROLL_TIMEOUT === null) {
          this.SCROLL_TIMEOUT = self.setTimeout(() => {
            this.scrollRotation();
          }, this.SCROLL_DELAY);
        }
      }
    } else {
      if (!this.pageItems) {
        this.pageItems = jQuery('.frontPageItem');
      }

      this.pageItems.clearQueue();
      this.pageItems.stop();
      this.SCROLL_LEFT_MOST += this.currentMove;
      this.scrollSetPositions();
      self.clearTimeout(this.SCROLL_TIMEOUT || 0);
      this.scrollMove(this.currentMove, true);
    }
  }

  private scrollSetPositions() {
    const sectionI = this.SCROLL_LEFT_MOST;
    for (let posI = 0; posI < this.sections.length; posI++) {
      const product = this.sections[this.getScrollIndex(sectionI + posI)];
      if (product.element) {
        product.element.css('left', this.getScrollSpot(posI)) + 'px';
      }
    }
  }

  private scrollRotation() {
    this.scrollLeft();
  }

  private currentMove: number | null = null;
  private scrollMove(direction: number, resume: boolean = false) {
    const sectionI = this.SCROLL_LEFT_MOST;
    let posI: number;
    let posMax: number;

    this.currentMove = direction;

    if (direction === -1) {
      posI = 0;
      posMax = this.MAX_SECTIONS_DISPLAYED;
    } else {
      posI = -1;
      posMax = this.MAX_SECTIONS_DISPLAYED - 1;
    }

    if (this.sections.length < this.MAX_SECTIONS_DISPLAYED) {
      direction = 0;
    }

    let extraTime = 0;
    this.SCROLL_DONE = this.MAX_SECTIONS_DISPLAYED + 1; //TODO use promises
    for (; posI <= posMax; posI++) {
      const scrollIndex: number = this.getScrollIndex(sectionI + posI);
      const nextLoc: number = this.getScrollSpot(posI + direction);

      let curLoc: number;
      if (!resume) {
        curLoc = this.getScrollSpot(posI);
        this.sections[scrollIndex].element.css('left', curLoc + 'px');
      } else {
        curLoc = this.sections[scrollIndex].element.position().left;
      }

      this.sections[scrollIndex].element.animate(
        {
          left: nextLoc + 'px',
        },
        this.SCROLL_SPEED * Math.abs(curLoc - nextLoc),
        () => {
          this.SCROLL_DONE--;
          this.detectSeen();
          if (this.SCROLL_DONE === 0) {
            this.currentMove = null;
          }
        },
      );

      const timeNeeded = this.SCROLL_SPEED * Math.abs(curLoc - nextLoc);
      if (timeNeeded > extraTime) {
        extraTime = timeNeeded;
      }
    }

    this.SCROLL_LEFT_MOST -= direction;
    if (this.SCROLL_LEFT_MOST < 0) {
      this.SCROLL_LEFT_MOST = this.sections.length - 1;
    } else if (this.SCROLL_LEFT_MOST >= this.sections.length) {
      this.SCROLL_LEFT_MOST = 0;
    }

    if (this.SCROLL_TIMEOUT !== null) {
      clearTimeout(this.SCROLL_TIMEOUT);
      this.SCROLL_TIMEOUT = null;
    }

    this.SCROLL_TIMEOUT = window.setTimeout(() => {
      this.scrollRotation();
    }, this.SCROLL_DELAY + extraTime);
  }

  resetScroll(): void {
    if (this.SCROLL_TIMEOUT !== null) {
      clearTimeout(this.SCROLL_TIMEOUT);
      this.SCROLL_TIMEOUT = null;
    }

    this.SCROLL_TIMEOUT = window.setTimeout(() => {
      this.scrollRotation();
    }, this.SCROLL_DELAY);
  }

  detectSeen(): void {
    if (this.SCROLL_DONE === 0) {
      const products: Array<Product & HasListPosition & HasCategory> = [];
      for (let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i];
        if (!section.seen && section.selectedProduct) {
          if (isElementInViewport(section.element[0])) {
            section.seen = true;

            const product: Product & HasListPosition & HasCategory = section.selectedProduct;

            product.listPosition = i + 1;
            product.category = section.sectionName;

            products.push(product);
          }
        }
      }
      if (products.length) {
        this.GtagService.productImpression(products, 'Front Page', null);
      }
    }
  }

  productLoaded(event: Event, section: FrontPageSection) {
    const element: HTMLElement = <HTMLElement>(event.target || event.srcElement || event.currentTarget);
    section.element = jQuery(element).parents('.frontPageItem');

    this.SCROLL_DONE--;
    if (this.SCROLL_DONE === 0) {
      this.scrollSetPositions();

      this.SCROLL_TIMEOUT = window.setTimeout(() => {
        this.scrollRotation();
      }, this.SCROLL_DELAY);
    }
  }

  productImageError(section: FrontPageSection) {
    this.sections.splice(this.sections.indexOf(section), 1);
    this.SCROLL_DONE--;

    if (this.sections.length === 0) {
      jQuery('.frontPage .scrolly').hide();
      if (this.SCROLL_TIMEOUT) {
        window.clearTimeout(this.SCROLL_TIMEOUT);
        this.SCROLL_TIMEOUT = null;
      }
      return;
    }

    if (this.SCROLL_DONE === 0) {
      this.scrollSetPositions();
      this.scrollRotation();
    }
  }

  scrollLeft() {
    if (this.SCROLL_DONE === 0 && this.sections.length > this.MAX_SECTIONS_DISPLAYED) {
      this.scrollMove(-1);
    }
  }

  scrollRight() {
    if (this.SCROLL_DONE === 0 && this.sections.length > this.MAX_SECTIONS_DISPLAYED) {
      this.scrollMove(1);
    }
  }
}

export const PageRoute: Route = {
  path: '',
  component: FrontPageComponent,
  pathMatch: 'full',
  resolve: {
    page: FrontPageResolver,
  },
};
