import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { ValidationCallback } from '..';
import { DialogService } from '../dialog.service';
import { DialogComponent, StaticDialogComponent_Metadata } from '../dialogComponent';
import { StaticImplements } from '../../staticImplements';
import { BaseOrder } from 'src/common/models/order/base';
import { BaseOrderItem } from 'src/common/models/orderItem/base';

export interface SendToData {
  emailAddress: string;
  name: string;
  company: string;
}

export type SendToResult = SendToData | undefined;

export interface SendToParams {
  order: BaseOrder<BaseOrderItem>;
  message: string | SafeHtml;
  validate?: (data: SendToData, callback: ValidationCallback) => boolean;
  no: string;
  yes: string;
}

@Component({
  selector: 'ccf-send-to-dialog',
  templateUrl: 'sendTo.component.html',
  styleUrls: ['sendTo.component.less'],
  providers: [],
})
@StaticImplements(StaticDialogComponent_Metadata)
export class SendToDialogComponent extends DialogComponent<SendToParams, SendToResult> implements OnInit, OnDestroy {
  public static DIALOG_CLASS = 'sendToDialog';
  public static DEFAULT_CONFIG: MatDialogConfig = {};

  emailAddress: string = '';
  name: string = '';
  company: string = '';

  validationMessage: string = '';

  constructor(
    private DialogService: DialogService,
    public override matDialogRef: MatDialogRef<SendToDialogComponent, SendToResult>,
    @Inject(MAT_DIALOG_DATA) public override params: SendToParams,
  ) {
    super(matDialogRef);

    if (params.order.contactInfo.email) {
      this.emailAddress = params.order.contactInfo.email;
    }
    if (params.order.contactInfo.name) {
      this.name = params.order.contactInfo.name;
    }
    if (params.order.contactInfo.orgName) {
      this.company = params.order.contactInfo.orgName;
    }
  }

  no(): void {
    this.closeDialog();
  }

  yes(): void {
    if (this.validate()) {
      this.closeDialog({
        emailAddress: this.emailAddress,
        name: this.name,
        company: this.company,
      });
    }
  }

  validate(): boolean {
    this.validationMessage = '';
    if (typeof this.params.validate === 'function') {
      const data = {
        emailAddress: this.emailAddress,
        name: this.name,
        company: this.company,
      };
      return this.params.validate(data, (message: string) => {
        this.validationMessage = message;
      });
    } else {
      return true;
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.DialogService.closed();
  }

  ngOnInit() {
    this.DialogService.opened();
  }
}
