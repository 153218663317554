import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GalleryImageDirective, GalleryThumbnailDirective } from './gallery.directive';
import { UiSortableDirective } from './sortable.directive';
import { DropzoneDirective } from './dropzone.directive';
import { MouseWheelDirective } from './mouseWheel.directive';
import { ScreenshotDirective } from './screenshot.directive';
import { SvgScalingTextComponent } from './svgScalingText/svgScalingText.component';
import { FractionComponent } from './fraction/fraction.component';
import { JSPopupComponent } from './JSPopup/JSPopup.component';
import { CustomDDLComponent } from './customDDL/customDDL.component';
import { ErrorComponent } from './error/error.component';
import { EmailLinkComponent } from './emailLink/emailLink.component';
import { RenderLabelComponent } from './renderLabel/renderLabel.component';
import { EditorjsComponent } from './editorjs/editorjs.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    GalleryImageDirective,
    GalleryThumbnailDirective,
    UiSortableDirective,
    SvgScalingTextComponent,
    FractionComponent,
    JSPopupComponent,
    DropzoneDirective,
    MouseWheelDirective,
    ScreenshotDirective,
    CustomDDLComponent,
    ErrorComponent,
    EmailLinkComponent,
    RenderLabelComponent,
    EditorjsComponent,
  ],
  exports: [
    GalleryImageDirective,
    GalleryThumbnailDirective,
    UiSortableDirective,
    SvgScalingTextComponent,
    FractionComponent,
    JSPopupComponent,
    DropzoneDirective,
    MouseWheelDirective,
    ScreenshotDirective,
    CustomDDLComponent,
    ErrorComponent,
    EmailLinkComponent,
    RenderLabelComponent,
    EditorjsComponent,
  ],
})
export class DirectivesModule {}
