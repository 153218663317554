import './permission';
import { APIContactInfo, APIDeliveryInfo, APIBillingInfo } from './checkout';
import { PermissionsHad } from './permission';

export enum AccountType {
  Staff = 'Staff',
  Customer = 'Customer',
  Wholesale = 'Wholesale',
}

export enum AccountTypeURL {
  Staff = '/admin/products',
  Wholesale = '/Customer/Messages',
  Customer = '/Customer/Messages',
}

export interface NewUserAccount {
  accountID: number | null;
  username: string;
  email: string;
  accountType: AccountType;
  accountTypeURL: AccountTypeURL;
  defaultURI: string;
  password?: string;
  displayName: string;
  permissions?: PermissionsHad;
  enabled: boolean;
  notes?: string;

  debug?: boolean;

  contactInfo?: APIContactInfo; // TODO check if these can be null
  deliveryInfo?: APIDeliveryInfo;
  billingInfo?: APIBillingInfo;

  lastOrder?: Date | null;

  fake?: boolean;
}

export interface UserAccountSave extends NewUserAccount {
  permissionIDs: number[];
}

export interface UserAccount extends NewUserAccount {
  accountID: number;
}
