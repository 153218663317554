import { Injectable } from '@angular/core';

import { DiscountsService } from './discounts.service';
import { ConfigService } from './config.service';
import { FreightService } from './freight.service';
import { APIService } from './api.service';

import { Deferred } from '../helpers/deferred';
import {
  BackendOrder,
  OrderType,
  APIOrderSheetItem,
  OrderSheetType,
  OrderSheetSeason,
  OrderStatus,
  DatabaseOrder,
  DatabaseItem,
} from '../models/checkout';
import { TaxesService } from './taxes.service';
import { Router } from '@angular/router';
import { GlobalErrorHandler } from './globalErrorHandler';
import { DialogService } from '../helpers/dialogs/dialog.service';
import { BaseOrder, OrderResponseObject } from '../models/order/base';
import { initializeOrder } from '../models/order';
import { APIDataLayer } from '../dataLayer/APIDataLayer';
import { DataLayer } from '../dataLayer';
import { OrderSheetOrder } from '../models/order/ordersheet';
import { ValidationCallback } from '../helpers/dialogs';
import {
  SendToParams,
  SendToResult,
  SendToDialogComponent,
  SendToData,
} from '../helpers/dialogs/sentTo/sendTo.component';
import { Big } from 'big.js';
import { AccountType } from '../models/account';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './auth.service';
import { BaseOrderItem } from '../models/orderItem/base';

export class TemplateHTML {
  private content: string;

  constructor(value: string) {
    try {
      this.content = atob(value);
    } catch (e) {
      this.content = value;
    }
  }

  clone(): TemplateHTML {
    return new TemplateHTML(this.content);
  }

  base64(): string {
    return 'data:text/html;base64,' + btoa(this.content);
  }

  html(): string {
    return this.content;
  }

  // TODO these are duplicated in php
  removeRegion(regionName: string): this {
    this.content = this.content.replace(
      new RegExp(`(<[^>]*(start=["']${regionName}["'])[\\s\\S]*?|<[^>]*)end=["']${regionName}["'][^>]*>`, 'g'),
      '',
    );

    return this;
  }

  removeAttrs(): this {
    this.content = this.content.replace(/((start|end)=("|')[^"']*?("|'))/g, '');
    return this;
  }
}

export interface RenderData<T = TemplateHTML> {
  default: T;
  paypal?: T;
  corp_done?: T;
  corp_clarify?: T;
}

export interface BackendOrderDump {
  orders: BackendOrder[];
  total: number;
  perPage: number;
}

export interface PossessionResult {
  key?: string;
  error?: string;
}

export interface AllCarts {
  [key: string]: OrderResponseObject<DatabaseOrder<DatabaseItem>>;
}

export interface OrderFilters {
  type?: OrderType;
  groupID?: number;
  completedOnly?: boolean;
  quotesOnly?: boolean;
  limit: number;
  orderID?: number;
  year?: string;
  accountID?: number;
  showDonations?: boolean;
}

export interface OrderAuditItemData {
  itemKey: string | undefined;
  fields?: { [key: string]: unknown };
}
export interface OrderAuditItem<T> {
  orderAuditID: number;
  time: Date;
  accountID: number | null;
  username: string | null;
  accountType: AccountType | null;
  event: string;
  rawData: string;
  data: T;
  html: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  protected CACHE_TIME: number = 0; //
  protected CACHE_TIME_itemsForOrder: number = 1 * 24 * 60 * 60 * 1000; // 1 day

  itemsForOrder: { [orderID: number]: DatabaseItem[] } = {};
  itemsForOrder_cacheTime: { [orderID: number]: number } = {};

  protected order: { [key: string]: BaseOrder<BaseOrderItem> } = {};

  protected dataLayer: DataLayer;

  constructor(
    private DiscountsService: DiscountsService,
    private ConfigService: ConfigService,
    private FreightService: FreightService,
    private TaxesService: TaxesService,
    private ErrorHandler: GlobalErrorHandler,
    private APIService: APIService,
    private Router: Router,
    private DialogService: DialogService,
    private DomSanitizer: DomSanitizer,
    private AuthService: AuthService,
  ) {
    this.dataLayer = new APIDataLayer(
      APIService,
      DiscountsService,
      TaxesService,
      ConfigService,
      FreightService,
      ErrorHandler,
    );
  }
  expireCache() {
    for (const key in this.itemsForOrder_cacheTime) {
      if (Object.prototype.hasOwnProperty.call(this.itemsForOrder_cacheTime, key)) {
        this.itemsForOrder_cacheTime[key] = 0;
      }
    }
  }

  expireOrders(): void {
    for (const key in this.order) {
      if (Object.prototype.hasOwnProperty.call(this.order, key)) {
        this.order[key].lastActive = 0;
      }
    }
  }

  detachAll(): Promise<unknown> {
    const allPromises: Promise<unknown>[] = [];

    for (const key in this.order) {
      if (Object.prototype.hasOwnProperty.call(this.order, key)) {
        allPromises.push(
          this.APIService.queueRequest<boolean>({
            endPoint: 'orders',
            method: 'detach',
            data: {
              key: key,
            },
          }),
        );
      }
    }

    this.APIService.processRequests();

    return Promise.all(allPromises);
  }

  setStatus(orderID: number, status: OrderStatus): Promise<void> {
    const deferred = new Deferred<void>();

    this.APIService.queueRequest<true | string>({
      endPoint: 'orders',
      method: 'setStatus',
      data: {
        orderID: orderID,
        status: status,
      },
    })
      .then((result: true | string) => {
        if (result === true) {
          deferred.resolve();
        } else {
          deferred.reject(result);
        }
      })
      .catch((reason: unknown) => {
        // TODO not an any
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  setAccountID(orderID: number, accountID: number | null): Promise<void> {
    const deferred = new Deferred<void>();

    this.APIService.queueRequest<true | string>({
      endPoint: 'orders',
      method: 'setAccountID',
      data: {
        orderID: orderID,
        accountID: accountID,
      },
    })
      .then((result: true | string) => {
        if (result === true) {
          deferred.resolve();
        } else {
          deferred.reject(result);
        }
      })
      .catch((reason: unknown) => {
        // TODO not an any
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  generateNewEmail(orderID: number): Promise<void> {
    const deferred = new Deferred<void>();

    this.APIService.queueRequest<true | string>({
      endPoint: 'orders',
      method: 'generateNewEmail',
      data: {
        orderID: orderID,
      },
    })
      .then((result: true | string) => {
        if (result === true) {
          deferred.resolve();
        } else {
          deferred.reject(result);
        }
      })
      .catch((reason: unknown) => {
        // TODO not an any
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  // TODO move ?
  getAuditLog(orderID: number): Promise<OrderAuditItem<unknown>[]> {
    const deferred = new Deferred<OrderAuditItem<unknown>[]>();

    this.APIService.queueRequest<OrderAuditItem<unknown>[]>({
      endPoint: 'audits',
      method: 'getOrderLog',
      data: {
        orderID: orderID,
      },
    })
      .then((results: OrderAuditItem<unknown>[]) => {
        deferred.resolve(results);
      })
      .catch((reason: unknown) => {
        // TODO not an any
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  getItemsForOrder<T extends DatabaseItem>(orderID: number): Promise<T[]> {
    const deferred = new Deferred<T[]>();

    if (
      typeof this.itemsForOrder[orderID] !== 'undefined' &&
      (this.itemsForOrder_cacheTime[orderID] || 0) + this.APIService.getCacheTime(this.CACHE_TIME_itemsForOrder) >=
        new Date().getTime()
    ) {
      deferred.resolve(this.itemsForOrder[orderID] as T[]);
    } else {
      this.APIService.queueRequest<T[]>({
        endPoint: 'orders',
        method: 'itemsByOrderID',
        data: {
          orderID: orderID,
        },
      })
        .then((items: T[]) => {
          this.itemsForOrder[orderID] = items;
          this.itemsForOrder_cacheTime[orderID] = new Date().getTime();
          deferred.resolve(this.itemsForOrder[orderID] as T[]);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          // TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  isPossessing: boolean = false;
  isQuoting: boolean = false;
  focusedOrderKey?: string;
  focusedOrder?: BaseOrder<BaseOrderItem>;

  keyFromOrder(order: BackendOrder) {
    switch (order.type) {
      case OrderType.Cart:
        return 'T_cart';
      case OrderType.OrderSheet:
        throw new Error(`Cannot find key for OrderSheets yet`);
      case OrderType.CorpLogo:
        return 'T_corplogo';
      case OrderType.CustomLabel:
        return 'T_customlabel';
      case OrderType.Fountain:
        return 'T_fountain';
    }
    throw new Error(`Cannot find key for order (${order.type})`);
  }

  gotoCheckout<T extends BaseOrder<BaseOrderItem>>(order: T): void {
    if (order.type) {
      let orderLoadURL: string;
      if (order.type === OrderType.Cart) {
        orderLoadURL = '/Cart';
      } else if (order.type === OrderType.CorpLogo) {
        orderLoadURL = '/Corporate-Logos/Order';
      } else if (order.type === OrderType.CustomLabel) {
        orderLoadURL = '/Custom-Labels/Order';
      } else if (order.type === OrderType.Fountain) {
        orderLoadURL = '/Chocolate-Fountains/Order';
      } else if (order.type === OrderType.OrderSheet) {
        const orderSheet = order as unknown as OrderSheetOrder;
        orderLoadURL = '/';
        if (orderSheet.orderSheetType === OrderSheetType.Normal) {
          orderLoadURL += 'OrderSheet/';
        } else if (orderSheet.orderSheetType === OrderSheetType.Fundraising) {
          orderLoadURL += 'Fundraising/';
        } else {
          this.ErrorHandler.reportError(
            new Error(
              `Cannot goto checkout page for '${OrderType[order.type]}' Order ` +
                `is missing code for orderSheetType '${orderSheet.orderSheetType}'`,
            ),
          );
          return;
        }

        if (orderSheet.season === OrderSheetSeason.Christmas) {
          orderLoadURL += 'Christmas';
        } else if (orderSheet.season === OrderSheetSeason.Valentines) {
          orderLoadURL += 'Valentines';
        } else if (orderSheet.season === OrderSheetSeason.Easter) {
          orderLoadURL += 'Easter';
        } else {
          this.ErrorHandler.reportError(
            new Error(
              `Cannot goto checkout page for '${OrderType[order.type]}' Order ` +
                `is missing code for season '${orderSheet.season}'`,
            ),
          );
          return;
        }

        orderLoadURL += '/Order';
      } else {
        this.ErrorHandler.reportError(new Error(`Cannot goto checkout page for '${OrderType[order.type]}' Order`));
        return;
      }

      this.Router.navigateByUrl(orderLoadURL);
    }
  }

  getMineCompleted(page: number) {
    const deferred: Deferred<BackendOrderDump> = new Deferred();

    this.APIService.queueRequest<BackendOrderDump>({
      endPoint: 'orders',
      method: 'getMineCompleted',
      data: {
        page: page,
      },
    }).then((data: BackendOrderDump) => {
      deferred.resolve(data);
    });

    return deferred.promise;
  }

  copyMyOrder<T extends BaseOrder<BaseOrderItem>>(order: BackendOrder) {
    const deferred: Deferred<T> = new Deferred();

    this.APIService.queueRequest<string>({
      endPoint: 'orders',
      method: 'copyMyOrder',
      data: {
        orderID: order.orderID,
      },
    }).then((key: string) => {
      if (key.indexOf('key:') === 0) {
        this.loadCartAndRefresh(key).then((newOrder) => {
          deferred.resolve(newOrder as T);
        });

        this.APIService.processRequests();
      } else {
        throw new Error(key);
      }
    });

    return deferred.promise;
  }

  acceptOrder(ticket: string): void {
    this.APIService.queueRequest<PossessionResult>({
      endPoint: 'orders',
      method: 'acceptOrder',
      data: {
        ticket: ticket,
      },
    }).then((result: PossessionResult) => {
      if (result.error) {
        if (result.error === 'You must be logged in as Staff to finalize an order.') {
          const dialogRef = this.AuthService.promptLogin({
            message: result.error,
          });

          dialogRef
            .afterClosed()
            .toPromise()
            .then((loggedIn) => {
              if (loggedIn === true) {
                this.acceptOrder(ticket);
                this.APIService.processRequests();
              }
            });
        } else {
          this.DialogService.alert(result.error);
        }
      } else if (result.key) {
        if (this.order[result.key]) {
          this.order[result.key].reset();
        }
        this.loadCartAndRefresh(result.key).then((order) => {
          // Do nothing
        });

        this.APIService.processRequests();
      }
    });
  }

  possess<T extends BaseOrder<BaseOrderItem>>(ticket: string): Promise<T> {
    const deferred: Deferred<T> = new Deferred();

    let ticketOrID: string | number = ticket;
    try {
      ticketOrID = parseInt(new Big(ticketOrID).toString(), 10);
    } catch (e) {
      // Do nothing
    }

    this.APIService.queueRequest<PossessionResult>({
      endPoint: 'orders',
      method: 'possess',
      data: {
        ticketOrID: ticketOrID,
      },
    }).then((result: PossessionResult) => {
      if (result.error) {
        deferred.reject(result.error);
      } else if (result.key) {
        if (this.order[result.key]) {
          this.order[result.key].reset();
        }
        this.loadCartAndRefresh<T>(result.key).then((order: T) => {
          deferred.resolve(order);
        });

        this.APIService.processRequests();
      }
    });

    return deferred.promise;
  }

  protected loadingAll: boolean = false;
  loadAllCarts(): Promise<unknown> {
    const deferred = new Deferred();

    this.loadingAll = true;

    this.APIService.queueRequest<AllCarts>({
      endPoint: 'orders',
      method: 'getAllMine',
    })
      .then((data: AllCarts) => {
        const allPromises: Promise<unknown>[] = [];

        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (!this.order[key]) {
              this.order[key] = initializeOrder(data[key].order!.type, this.dataLayer);
            }
            data[key].order!.key = key;

            allPromises.push(
              this.order[key].parse(data[key], 'orders.getAllMine').then(() => {
                if (this.pendingCartRequests[key]) {
                  for (const def of this.pendingCartRequests[key]) {
                    def.resolve(this.order[key]);
                  }
                  delete this.pendingCartRequests[key];
                }

                if (this.watchedCartRequests[key]) {
                  for (const def of this.watchedCartRequests[key]) {
                    def.resolve(this.order[key]);
                  }
                  delete this.watchedCartRequests[key];
                }
              }),
            );
          }
        }

        Promise.all(allPromises).then(() => {
          this.loadingAll = false;

          const keys = Object.keys(this.pendingCartRequests);
          for (const key of keys) {
            this.handleLoad(key); // TODO this creates extra request we were trying to get rid of
          }

          if (keys.length) {
            this.APIService.processRequests();
          }
        });
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  protected pendingCartRequests: {
    [key: string]: Deferred<any>[];
  } = {};
  protected watchedCartRequests: {
    [key: string]: Deferred<any>[];
  } = {};
  loadCart<T extends BaseOrder<BaseOrderItem>>(key: string) {
    return this._loadCart<T>(key, false);
  }

  loadCartAndRefresh<T extends BaseOrder<BaseOrderItem>>(key: string) {
    return this._loadCart<T>(key, true);
  }

  private _loadCart<T extends BaseOrder<BaseOrderItem>>(key: string, refresh: boolean) {
    const deferred = new Deferred<T>();

    if (typeof this.order[key] !== 'undefined') {
      const cart = this.order[key];
      if (refresh) {
        cart.load().then(() => {
          cart.fullRefresh();
          deferred.resolve(cart as T);
        });
      } else {
        deferred.resolve(cart as T);
      }
    } else {
      if (typeof this.pendingCartRequests[key] !== 'undefined') {
        this.pendingCartRequests[key].push(deferred);
      } else {
        this.pendingCartRequests[key] = [deferred];

        if (!this.loadingAll) {
          this.handleLoad(key);
        }
      }
    }
    return deferred.promise;
  }

  private handleLoad(key: string): void {
    this.APIService.queueRequest<DatabaseOrder<DatabaseItem>>({
      endPoint: 'orders',
      method: 'getMine',
      data: {
        key: key,
      },
    }).then((data: OrderResponseObject<DatabaseOrder<DatabaseItem>>) => {
      if (data.order) {
        const order = initializeOrder(data.order.type, this.dataLayer);
        data.order.key = key;
        order.parse(data, 'orders.getMine').then(() => {
          this.order[key] = order;
          for (const def of this.pendingCartRequests[key]) {
            def.resolve(order);
          }
          delete this.pendingCartRequests[key];

          if (this.watchedCartRequests[key]) {
            for (const def of this.watchedCartRequests[key]) {
              def.resolve(order);
            }
            delete this.watchedCartRequests[key];
          }
        });
      } else {
        throw new Error('orders.getMine returned no order');
      }
    });
    /* .catch((reason: unknown) => {
      deferred.reject(reason);
      //TODO should do something globally ?
    });*/
  }

  getWhenReady<T extends BaseOrder<BaseOrderItem>>(key: string): Promise<T> {
    const deferred = new Deferred<T>();

    if (typeof this.order[key] !== 'undefined') {
      deferred.resolve(this.order[key] as T);
    } else {
      if (typeof this.pendingCartRequests[key] !== 'undefined') {
        this.pendingCartRequests[key].push(deferred);
      } else if (typeof this.watchedCartRequests[key] !== 'undefined') {
        this.watchedCartRequests[key].push(deferred);
      } else {
        this.watchedCartRequests[key] = [deferred];
      }
    }
    return deferred.promise;
  }

  emailOrder(order: BaseOrder<BaseOrderItem>, key: string): Promise<boolean> {
    const deferred: Deferred<boolean> = new Deferred<boolean>();

    let message: string;
    if (this.isQuoting) {
      message = 'Enter email address to send copy of quote to:';
    } else {
      message = 'Enter email address to send this order to:';
    }

    if (order.emailedAudit.length > 0) {
      message += '<div style="font-size: 11px;color: green;">';
      for (const audit of order.emailedAudit) {
        message +=
          '<div>' + `${audit.emailAddress} - ${formatDate(audit.time, 'MM/dd/yy hh:mm:ss a', 'en-US')}` + '</div>';
      }
    }

    const dialogRef = this.DialogService.show<SendToParams, SendToResult, SendToDialogComponent>(
      SendToDialogComponent,
      {
        order: order,
        message: this.DomSanitizer.bypassSecurityTrustHtml(message),
        validate: (data: SendToData, callback: ValidationCallback) => {
          if (data.emailAddress.indexOf('@') <= 0) {
            callback('Please enter a valid email');
            return false;
          }
          return true;
        },
        no: 'Cancel',
        yes: 'Send',
      },
    );

    dialogRef
      .afterClosed()
      .toPromise()
      .then((result: SendToResult) => {
        if (typeof result !== 'undefined') {
          const email = result.emailAddress.trim();
          const name = result.name.trim();
          const company = result.company.trim();

          const allPromises: Promise<unknown>[] = [];

          if (order) {
            const changed: Array<string> = [];
            if (!order.contactInfo.email) {
              order.contactInfo.email = email;
              changed.push('contactInfo.email');
            }
            if (!order.contactInfo.name && name) {
              order.contactInfo.name = name;
              changed.push('contactInfo.name');
            }
            if (!order.contactInfo.orgName && company) {
              order.contactInfo.orgName = company;
              changed.push('contactInfo.orgName');
            }
            allPromises.push(order.save(changed));

            this.APIService.processRequests();
          }

          Promise.all(allPromises).then(() => {
            this.sendOrderEmail(key, email)
              .then(() => {
                deferred.resolve(true);
              })
              .catch((errorMessage: string) => {
                this.DialogService.alert(errorMessage);
                this.ErrorHandler.reportError(new Error(errorMessage), undefined, true);

                deferred.reject();
              });

            this.APIService.processRequests();
          });
        } else {
          deferred.resolve(false);
        }
      });

    return deferred.promise;
  }

  public detachFocusedOrder(): Promise<void> {
    const deferred = new Deferred<void>();

    if (this.focusedOrderKey) {
      const key = this.focusedOrderKey;
      this.detach(key).then(() => {
        this.loadCartAndRefresh(key).then(() => {
          this.releasedFocusedOrder();
          deferred.resolve();
        });
        this.APIService.processRequests();
      });

      this.APIService.processRequests();
    } else {
      deferred.resolve();
    }

    return deferred.promise;
  }

  public releasedFocusedOrder(): void {
    delete this.focusedOrder;
    delete this.focusedOrderKey;
    this.isPossessing = false;
    this.isQuoting = false;
    localStorage.removeItem('T_possessed');
    localStorage.removeItem('T_quote');
  }

  private sendOrderEmail(key: string, email: string): Promise<unknown> {
    const deferred = new Deferred<void>();
    let allPromises: Promise<unknown>[] = [];

    if (typeof this.order[key] === 'undefined') {
      allPromises.push(this.loadCart(key));
    }

    this.APIService.processRequests();

    Promise.all(allPromises).then(() => {
      allPromises = [];

      this.APIService.queueRequest<OrderResponseObject<DatabaseOrder<DatabaseItem>> | string>({
        endPoint: 'orders',
        method: 'emailOrder',
        data: {
          key: key,
          email: email,
          lastActive: this.order[key].lastActive,
        },
      }).then((result: OrderResponseObject<DatabaseOrder<DatabaseItem>> | string) => {
        if (typeof result === 'string') {
          deferred.reject(result);
        } else {
          this.order[key].parse(result, 'orderService.sendOrderEmail').then(() => {
            deferred.resolve();
          });
        }
      });

      this.APIService.processRequests();
    });

    this.APIService.processRequests();

    return deferred.promise;
  }

  confirm(ticket: string): Promise<unknown> {
    const deferred = new Deferred<void>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'orders',
      method: 'confirm',
      data: {
        ticket: ticket,
      },
    }).then((result: boolean) => {
      if (result !== true) {
        // TODO not sure they need to know deferred.reject('Confirm failure! TODO');
        deferred.resolve();
      } else {
        deferred.resolve();
      }
    });

    this.APIService.processRequests();

    return deferred.promise;
  }

  detach(key: string): Promise<unknown> {
    const deferred = new Deferred<void>();
    let allPromises: Array<Promise<unknown>> = [];

    if (key === this.focusedOrderKey) {
      this.releasedFocusedOrder();
    }

    if (typeof this.order[key] === 'undefined') {
      allPromises.push(this.loadCart(key));
    }

    this.APIService.processRequests();

    Promise.all(allPromises).then(() => {
      allPromises = [];

      this.APIService.queueRequest<boolean>({
        endPoint: 'orders',
        method: 'detach',
        data: {
          key: key,
        },
      }).then((result: boolean) => {
        if (result !== true) {
          deferred.reject("Detach failure, shouldn't be possible");
        } else {
          this.order[key].reset();
          deferred.resolve();
        }
      });

      this.APIService.processRequests();
    });

    this.APIService.processRequests();

    return deferred.promise;
  }

  recent(filters: OrderFilters) {
    const deferred = new Deferred<Array<BackendOrder>>();

    this.APIService.queueRequest<Array<BackendOrder>>({
      endPoint: 'orders',
      method: 'recent',
      data: {
        filters: filters,
      },
    })
      .then((order: Array<BackendOrder>) => {
        deferred.resolve(order);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  getExportData(orderIDs: Array<number>) {
    const deferred = new Deferred<Array<APIOrderSheetItem>>();

    this.APIService.queueRequest<Array<APIOrderSheetItem>>({
      endPoint: 'orders',
      method: 'exportData',
      data: {
        orderIDs: orderIDs,
      },
    })
      .then((items: Array<APIOrderSheetItem>) => {
        deferred.resolve(items);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }

  render(ticket: string, key: string) {
    const deferred = new Deferred<RenderData>();

    this.APIService.queueRequest<RenderData<string>>({
      endPoint: 'orders',
      method: 'render',
      data: {
        ticket: ticket,
        key: key,
      },
    })
      .then((dataURLs: RenderData<string>) => {
        const templates: RenderData<TemplateHTML> = {} as any; // TODO any

        for (const key in dataURLs) {
          if (Object.prototype.hasOwnProperty.call(dataURLs, key) && dataURLs[key as keyof RenderData<string>]) {
            templates[key as keyof RenderData<TemplateHTML>] = new TemplateHTML(
              dataURLs[key as keyof RenderData<string>]!,
            );
          }
        }
        deferred.resolve(templates);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        // TODO should do something globally ?
      });

    return deferred.promise;
  }
}
