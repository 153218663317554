import { Component, OnInit } from '@angular/core';

import { Route } from '@angular/router';
import { ConfigService } from '../../common/services/config.service';
import { APIService } from '../../common/services/api.service';

@Component({
  selector: 'ccf-locations-and-hours',
  templateUrl: 'locations.component.html',
  styleUrls: ['locations.component.less'],
  providers: [],
})
//TODO file name wrong for class
export class LocationsPageComponent implements OnInit {
  holidayHours: boolean = false;

  constructor(
    public ConfigService: ConfigService,
    public APIService: APIService,
  ) {}

  ngOnInit() {
    this.ConfigService.getHolidayHoursEnabled().then((holidayHours: boolean) => {
      this.holidayHours = holidayHours; //TODO POST_LAUNCH this should be a subscription based thing
    });

    this.APIService.processRequests();
  }
}

export const PageRoute: Route = {
  path: 'Locations-and-Hours',
  component: LocationsPageComponent,
  pathMatch: 'full',
  data: {
    title: 'Store Locations & Hours',
  },
};
