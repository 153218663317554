import { Component } from '@angular/core';
import { Route } from '@angular/router';

@Component({
  selector: 'ccf-contact-thank-you',
  templateUrl: 'thankYou.component.html',
  styleUrls: ['thankYou.component.less'],
  providers: [],
})
//TODO file name page should be in file name
export class ContactThankYouComponent {}

export const PageRoute: Route = {
  path: 'Contact-Us/Thank-You',
  component: ContactThankYouComponent,
  pathMatch: 'full',
  data: {
    title: ['Thank You', 'Contact Us'],
  },
};
