<div class="contactPage pageContent">
  <form
    ngNoForm
    class="contactFields"
  >
    <h2>Contact Us:</h2>
    <div
      class="cName"
      [class.validationError]="invalid['name']"
    >
      <span>Name</span>
      <div class="entry">
        <input
          [(ngModel)]="model.name"
          class="charliesInput"
          (change)="revalidate()"
          name="name"
          autocomplete="name"
        />
        <div class="validationMessage">Your name is required.</div>
      </div>
    </div>

    <div
      class="cPhone"
      [class.validationError]="invalid['phone']"
    >
      <span>Phone&nbsp;Number</span>
      <div class="entry">
        <div class="validation"><div></div></div>
        <input
          [(ngModel)]="model.phone"
          (change)="revalidate()"
          class="charliesInput"
          name="tel"
          autocomplete="tel"
        />
        <div class="validationMessage">Please enter a valid Phone Number.</div>
      </div>
    </div>

    <div
      class="cEmail"
      [class.validationError]="invalid['email']"
    >
      <span>E-mail</span>
      <div class="entry">
        <input
          [(ngModel)]="model.email"
          (change)="revalidate()"
          class="charliesInput"
          name="email"
          autocomplete="email"
        />
        <div class="validationMessage">Please enter a valid Email Address.</div>
      </div>
    </div>

    <div
      class="cComments"
      [class.validationError]="invalid['comments']"
    >
      <span>Comment</span>
      <div class="entry">
        <textarea
          [(ngModel)]="model.comments"
          (change)="revalidate()"
          name="comments"
          class="charliesTextarea"
        ></textarea>
        <div class="validationMessage">You must provide a comment.</div>
      </div>
    </div>

    <div>
      <button
        type="button"
        class="charliesButtonRound"
        (click)="submit()"
      >
        Send
      </button>
    </div>
  </form>

  <div class="phoneLocations">
    <h2>Stores:</h2>
    <div class="phoneNumbers">
      <br />
      <span class="contactHeader"><b>Burnaby</b></span
      ><!--TODO click='gaClicked("phone", "6044378221")' -->
      <span class="contactInfo"><a href="tel:6044378221">(604) 437-8221</a></span>
      <br />
      <span class="contactHeader address">3746 Canada Way</span
      ><!--TODO click='gaClicked("map", "burnaby")' -->
      <span class="contactInfo"
        ><a
          target="_blank"
          href="https://www.google.ca/maps/place/Charlie's+Chocolate+Factory+Ltd/@49.253633,-123.021019,16.35z/data=!4m2!3m1!1s0x548676dbba6831d3:0x9c734eaced2b1c84!6m1!1e1?hl=en"
          ><img
            class="locationImage"
            src="/images/static/location-with-map-icon.png" /></a
      ></span>
      <br /><br />
      <br /><br />
      <span class="contactHeader"><b>Port Coquitlam</b></span
      ><!--TODO click='gaClicked("phone", "6049413811") -->
      <span class="contactInfo"><a href="tel:6049413811">(604) 941-3811</a></span>
      <br />
      <span class="contactHeader address">2-1770 McLean Ave</span
      ><!--TODO click='gaClicked("map", "poco")' -->
      <span class="contactInfo"
        ><a
          target="_blank"
          href="https://www.google.ca/maps/place/Charlie's+Chocolate+Factory/@49.2540503,-122.7670132,16.1z/data=!4m5!1m2!2m1!1sCharlie's+Chocolate+Factory+Ltd,+McLean+Avenue,+Port+Coquitlam,+BC!3m1!1s0x0000000000000000:0xcb26513a3f10d5bb?hl=en"
          ><img
            class="locationImage"
            src="/images/static/location-with-map-icon.png" /></a
      ></span>
    </div>
  </div>

  <div class="emailAddresses">
    <h2>Email Us:</h2>
    <span class="contactHeader">General Enquiries</span>
    <span class="contactInfo"><ccf-email-link emailAddress="General"></ccf-email-link></span>
    <br /><br />
    <span class="contactHeader">Order Desk</span>
    <span class="contactInfo"><ccf-email-link emailAddress="Orders"></ccf-email-link></span>
    <br /><br />
    <span class="contactHeader">Accounts Receivable</span>
    <span class="contactInfo"><ccf-email-link emailAddress="Accounts Receivable"></ccf-email-link></span>
    <br /><br />
    <span class="contactHeader">Accounts Payable</span>
    <span class="contactInfo"><ccf-email-link emailAddress="Accounts Payable"></ccf-email-link></span>
  </div>
  <br />
</div>
