/* eslint-disable @typescript-eslint/no-explicit-any */
interface ObjectWithStringIndex {
  [key: string]: any;
}
interface ObjectWithNumberIndex {
  [key: number]: any;
}

type ClearAbleObject = any[] | ObjectWithStringIndex | ObjectWithNumberIndex;

export const clear = <T extends ClearAbleObject>(thing: T): void => {
  if (Array.isArray(thing)) {
    thing.splice(0, thing.length);
  } else if (typeof thing === 'object') {
    for (const key in thing) {
      if (Object.prototype.hasOwnProperty.call(thing, key)) {
        delete thing[key];
      }
    }
  } else {
    throw new Error('clear failed on type (' + typeof thing + ')');
  }
};
