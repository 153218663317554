import { Big } from 'big.js';

import { FreightLookupData } from '../../services/freight.service';

import {
  OrderType,
  DeliveryMethod,
  PaymentType,
  OrderSheetType,
  APIOrderSheetOrder,
  OrderSheetSeason,
} from '../checkout';

import { BaseOrder, OrderResponseObject, DiscountSubtotals } from './base';
import { OrderSheetOrderItem } from '../orderItem/ordersheet';
import { Deferred } from '../../helpers/deferred';
import { cloneDeep } from 'lodash-es';

export class OrderSheetOrder extends BaseOrder<OrderSheetOrderItem> implements APIOrderSheetOrder<Big> {
  type = OrderType.OrderSheet as const;

  //TODO track season

  season?: OrderSheetSeason = undefined;
  orderSheetType?: OrderSheetType = undefined;
  groupName?: string = undefined;
  groupValid: boolean = false;
  divisionRequired: boolean = false;
  division?: string = undefined;

  override reset(): void {
    super.reset();

    this.season = undefined;
    this.orderSheetType = undefined;
    this.groupName = undefined;
    this.groupValid = false;
    this.divisionRequired = false;
    this.division = undefined;
  }

  override async parse(
    data: OrderResponseObject<APIOrderSheetOrder>,
    calledFrom: string,
    allDone?: Promise<void>,
  ): Promise<void> {
    const doneHere = new Deferred<void>();

    await super.parse(data, calledFrom, doneHere.promise);

    this.freeze();

    if (data.order) {
      const order = data.order;

      if (typeof order.groupName !== 'undefined') {
        this.groupName = order.groupName;
      }
      if (typeof order.groupValid !== 'undefined') {
        this.groupValid = !!order.groupValid;
      }
      if (typeof order.divisionRequired !== 'undefined') {
        this.divisionRequired = !!order.divisionRequired;
      }
      if (typeof order.division !== 'undefined') {
        this.division = order.division;
      }
    }

    this.unfreeze();

    const done = async () => {
      await this.calculate();

      doneHere.resolve();
    };

    if (allDone) {
      allDone.then(() => {
        done();
      });
    } else {
      await done();
    }
  }

  override export() {
    const exportData = super.export();

    exportData['groupName'] = this.groupName;
    exportData['groupValid'] = this.groupValid;
    exportData['divisionRequired'] = this.divisionRequired;
    exportData['division'] = this.division;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }
  //protected async preCalc(): Promise<void>

  protected override async calcFreight(subtotal: Big, itemDiscount: Big, saleTotal: Big): Promise<void> {
    const allPromises: Promise<unknown>[] = [];

    if (
      this.payment !== PaymentType.TooLarge &&
      this.deliveryInfo.method === DeliveryMethod.Ship &&
      this.deliveryInfo.postalCode &&
      this.deliveryInfo.country === 'Canada'
    ) {
      const freightSubtotal = subtotal.minus(itemDiscount).plus(saleTotal);
      allPromises.push(
        this.dataLayer.freight
          .lookup(freightSubtotal, this.deliveryInfo.postalCode)
          .then((freight: FreightLookupData | null) => {
            if (freight) {
              this.totalFreight = freight.amount.round(2);
              this.freightTaxRate = freight.tax;
              this.freightTaxName = freight.taxName;
            }
          }),
      );

      allPromises.push(
        this.dataLayer.freight.lookupTime(this.deliveryInfo.postalCode).then((shippingTime: string | null) => {
          if (shippingTime) {
            this.shippingTime = shippingTime;
          }
        }),
      );
    }
    this.dataLayer.processRequests();
    await Promise.all(allPromises);
  }

  //protected async calcLocation(): Promise<void>;

  //protected async postCalc(): Promise<void>

  //protected async getDiscounts(discountSubtotals: DiscountSubtotals): Promise<void>
  protected override async getDiscounts(discountSubtotals: DiscountSubtotals): Promise<void> {
    if (this.orderSheetType === OrderSheetType.Normal) {
      return super.getDiscounts(discountSubtotals);
    }
  }

  protected newOrderItem(): OrderSheetOrderItem {
    return new OrderSheetOrderItem(this);
  }
}
