import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';

import { APIService } from '../../common/services/api.service';

import { WholesalePage } from './wholesale.model';
import { UserAccount } from '../../common/models/account';

@Injectable({
  providedIn: 'root',
})
export class WholesalePageResolver implements Resolve<WholesalePage> {
  constructor(
    private APIService: APIService,
    private Router: Router,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<WholesalePage> {
    const page: WholesalePage = {
      loggedIn: false,
      isWholesale: false,
    };

    const userData: UserAccount | null | undefined = await this.APIService.authenticatedData();

    if (typeof userData !== 'undefined') {
      page.loggedIn = userData !== null;
      page.isWholesale = userData !== null && userData.accountType === 'Wholesale';
    }

    return page;
  }
}
