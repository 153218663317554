import { Primitive } from 'ts-essentials';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type isPrimitiveMatcher<T> = RegExp;

const PRIMITIVE_ALL_MATCHER: isPrimitiveMatcher<Primitive> = /string|number|boolean|bigint|symbol|undefined|null/;

export function isPrimitive<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thing: any,
  matcher: isPrimitiveMatcher<T> = PRIMITIVE_ALL_MATCHER,
): thing is T {
  return matcher.test(typeof thing);
}
