import { Injectable } from '@angular/core';

import { Big } from 'big.js';

import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';

import { cloneDeep } from 'lodash-es';
import { SQLVolumeDiscount } from '../dataLayer/orders';
import { APIDiscount } from '../models/discount';

export interface DiscountStorageWithCache {
  value: APIDiscount | null;
  __cacheTime?: number;
}

@Injectable({
  providedIn: 'root',
})
export class DiscountsService {
  protected CACHE_TIME_codes: number = 1000 * 60 * 10; //10 Minutes
  protected codes: { [code: string]: DiscountStorageWithCache } = {};
  protected discountsEnabled_cacheTime: number = 0;
  protected discountsEnabled: boolean = false;

  protected CACHE_TIME_volume: number = 1000 * 60 * 60 * 24; //1 day
  protected VolumeDiscounts_cacheTime: number = 0;
  protected VolumeDiscounts: Array<SQLVolumeDiscount> = [];

  constructor(protected APIService: APIService) {}

  expireCache(): void {
    this.VolumeDiscounts_cacheTime = 0;

    for (const code in this.codes) {
      if (Object.prototype.hasOwnProperty.call(this.codes, code)) {
        this.codes[code].__cacheTime = 0;
      }
    }
  }

  allCodes(): Promise<Array<APIDiscount>> {
    const deferred = new Deferred<Array<APIDiscount>>();

    this.APIService.queueRequest<Array<APIDiscount>>({
      endPoint: 'discounts',
      method: 'codes',
    })
      .then((discounts: Array<APIDiscount>) => {
        deferred.resolve(discounts);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  refreshVolumeDiscounts(): Promise<void> {
    const deferred = new Deferred<void>();

    if (this.VolumeDiscounts_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME_volume) > new Date().getTime()) {
      deferred.resolve();
    } else {
      this.APIService.queueRequest<Array<SQLVolumeDiscount>>({
        endPoint: 'discounts',
        method: 'volume',
      })
        .then((discounts: Array<SQLVolumeDiscount>) => {
          for (const discount of discounts) {
            discount.amount = new Big(discount.amount);
            discount.bottom = new Big(discount.bottom);
            discount.top = new Big(discount.top);
          }

          this.VolumeDiscounts = discounts;
          this.VolumeDiscounts_cacheTime = new Date().getTime();
          deferred.resolve();
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  getdiscountsEnabled(): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    if (this.discountsEnabled_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME_codes) > new Date().getTime()) {
      deferred.resolve(this.discountsEnabled);
    } else {
      this.APIService.queueRequest<boolean>({
        endPoint: 'discounts',
        method: 'enabled',
      })
        .then((discountsEnabled: boolean) => {
          this.discountsEnabled = discountsEnabled;
          this.discountsEnabled_cacheTime = new Date().getTime();
          deferred.resolve(this.discountsEnabled);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  getVolumeDiscount(amount: Big, nextStep: boolean = false): Promise<SQLVolumeDiscount | null> {
    const deferred = new Deferred<SQLVolumeDiscount | null>();

    const zero = new Big('0');

    this.refreshVolumeDiscounts()
      .then(() => {
        let match: SQLVolumeDiscount | null = null;
        for (const volume of this.VolumeDiscounts) {
          if (amount.lt(volume.bottom)) {
            //Too low
            if (nextStep) {
              match = volume;
            }
            break;
          } else if (!volume.top || zero.eq(volume.top) || amount.lte(volume.top)) {
            // Just right
            if (!nextStep) {
              match = volume;
              break;
            }
          } else {
            //Too high (can't get here)
          }
        }

        deferred.resolve(match);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getCode(code: string): Promise<APIDiscount | null> {
    const deferred = new Deferred<APIDiscount | null>();

    if (
      typeof this.codes[code] !== 'undefined' &&
      (this.codes[code].__cacheTime || 0) + this.APIService.getCacheTime(this.CACHE_TIME_codes) > new Date().getTime()
    ) {
      deferred.resolve(cloneDeep(this.codes[code].value));
    } else {
      this.APIService.queueRequest<APIDiscount>({
        endPoint: 'discounts',
        method: 'code',
        uniqueKey: code,
        data: {
          code: code,
        },
      })
        .then((discount: APIDiscount | null) => {
          const storage: DiscountStorageWithCache = {
            value: discount,
            __cacheTime: new Date().getTime(),
          };
          this.codes[code] = storage;
          deferred.resolve(cloneDeep(discount));
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  saveCode(discount: APIDiscount): Promise<false | number> {
    const deferred = new Deferred<false | number>();

    this.APIService.queueRequest<false | number>({
      endPoint: 'discounts',
      method: 'saveCode',
      data: {
        discountID: discount.discountID,
        enabled: discount.enabled,
        code: discount.code,
        target: discount.target,
        effect: discount.effect,
        effectAmount: discount.effectAmount,
        min: discount.min,
        message: discount.message,
      },
    })
      .then((result: false | number) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
