<div class="aboutPage pageContent">
  <h1>About the Company</h1>

  <p>
    <img
      src="/images/static/charlie-ellen-colin-oct07.jpg"
      alt="Charlie, Ellen &amp; Colin"
      hspace="10"
      align="right"
    />
    <strong><em>Charlie's Chocolate Factory</em></strong> is a family-owned and operated business. We are located in the
    Vancouver lower mainland area with our office and main retail store in Burnaby, and our satellite retail store in
    Port Coquitlam, B.C.
  </p>
  <p style="margin-top: 2em">
    In 1970, after several years of being employed by others, Charlie began the business he loves. Initially, he started
    with a small product line of boxed and bulk assorted chocolates out of a small storefront.
  </p>
  <p style="margin-top: 2em">
    Charlie's Chocolate Factory has had the opportunity to open a second retail/warehouse location Port Coquitlam, and
    the original store in Burnaby has been expanded to a much larger retail storefront and showroom, with our &quot;one
    and only&quot; chocolate river that flows with real chocolate!
  </p>

  <h2 style="margin-top: 1em">Today at Charlie's we also offer:</h2>
  <ul class="list">
    <li>chocolates for diabetics, sweetened with Maltitol</li>
    <li>the largest selection of molded items around</li>
    <li>a wide and wonderful selection of wedding/party favors</li>
    <li>rentals of Chocolate Fountains -- perfect for any special event!</li>
    <li>gifts for any occasion</li>
    <li>seasonal highlights for special occasions and favorite holidays</li>
    <li>supplies for do-it-yourself candy-making</li>
    <li>
      and our specialty... <strong>Corporate logos </strong>recreated in our fine Belgian &quot;Callebaut&quot;
      chocolate.
    </li>
  </ul>

  <h2>Our Mission</h2>
  <p>
    In keeping with our award-winning reputation, and our desire to provide quality products at affordable prices, we
    continue to use &quot;Callebaut&quot; chocolate which we import directly from Belgium. Our mission is happy
    customers!
  </p>
  <p>Thank you for visiting with us, we appreciate your business!</p>
</div>
