import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import { OrderType, PaymentType, OrderStatus } from '../models/checkout';
import { CartOrderItem } from '../models/orderItem/cart';
import { OrderSheetOrderItem } from '../models/orderItem/ordersheet';
import { CustomLabelOrderItem } from '../models/orderItem/customLabel';
import { CorpLogoOrderItem } from '../models/orderItem/corpLogo';
import { FountainOrderItem } from '../models/orderItem/fountain';
import { APIService } from './api.service';
import { UserAccount, AccountType } from '../models/account';
import { Big } from 'big.js';
import { BaseOrder } from '../models/order/base';
import { BaseOrderItem } from '../models/orderItem/base';

export interface ProductData {
  id?: string;
  name: string;
  list_name?: string;
  brand?: string;
  isDiabetic?: string;
  'Packaging Type'?: string;
  'Customization Type'?: string;
  Unit?: string;
  category?: string;
  variant?: string;
  price?: string;
  quantity?: number;
  coupon?: string;
  list_position?: number;
}

export interface PromotionData {
  id?: string;
  name: string;
  creative_name?: string;
  creative_slot?: string;
}

export interface ActionData {
  transaction_id?: string;
  affiliation?: string;
  value?: string;
  revenue?: string;
  tax?: string;
  shipping?: string;
  items?: Array<ProductData>;
  checkout_step?: number;
  checkout_option?: string;
  'Order Type'?: string;
  currency?: string;
  coupon?: string;
}

export interface HasListPosition {
  listPosition?: number;
}

export interface HasCategory {
  category?: string;
}

export type Events =
  | 'select_content'
  | 'view_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'set_checkout_option'
  | 'purchase'
  | 'refund'
  | 'view_promotion';

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  public static GA_TRACKING_ID: string = 'UA-40714984-1';
  protected accountData: UserAccount | null = null;

  constructor(private APIService: APIService) {
    window.gtag('config', GtagService.GA_TRACKING_ID, {
      send_page_view: false,
      link_attribution: true,
      custom_map: {
        dimension1: 'isDiabetic',
        dimension2: 'Packaging Type',
        dimension3: 'Customization Type',
        dimension4: 'Order Type',
        dimension5: 'Unit',
      },
    });

    const gtag_SUPER = window.gtag;
    window.gtag = (...args: unknown[]) => {
      if (this.APIService.isMasked || (this.accountData && this.accountData.accountType === AccountType.Staff)) {
        return;
      }
      gtag_SUPER.apply(window, args);
    };

    this.APIService.authenticatedDataStream().subscribe((data?: UserAccount | null) => {
      if (typeof data !== 'undefined') {
        this.accountData = data;
      }
    });
  }

  productImpression(
    products: Array<Product & HasListPosition & HasCategory>,
    list: string | null,
    category?: string | null,
  ): void {
    const productDatas: Array<ProductData> = [];
    for (const product of products) {
      const productData = this.getDataFromProduct(product);
      if (productData) {
        productDatas.push(productData);
      }

      if (list) {
        productData.list_name = list;
      }

      if (typeof product.listPosition !== 'undefined') {
        productData.list_position = product.listPosition;
      }

      if (typeof product.category !== 'undefined') {
        productData.category = product.category;
      }

      if (category) {
        productData.category = this.fixCategory(category);
      }
    }

    if (productDatas.length) {
      window.gtag('event', 'view_item_list', {
        items: productDatas,
      });
    }
  }

  promotionImpression(impressions: Array<PromotionData>): void {
    if (impressions.length) {
      window.gtag('event', 'view_promotion', {
        promotions: impressions,
      });
    }
  }

  productClick(product: Product, list: string | null, listPosition: number | null, category: string | null): void {
    const productData = this.getDataFromProduct(product);
    if (productData) {
      if (list) {
        productData.list_name = list;
      }

      if (listPosition !== null) {
        productData.list_position = listPosition;
      }

      if (category) {
        productData.category = this.fixCategory(category);
      }

      window.gtag('event', 'select_content', {
        content_type: 'product',
        items: [productData],
      });
    }
  }

  promotionClick(promotions: Array<PromotionData>): void {
    if (promotions.length) {
      window.gtag('event', 'select_content', {
        promotions: promotions,
      });
    }
  }

  productDetailsView(product: Product, list: string | null, category: string | null): void {
    const productData = this.getDataFromProduct(product);
    if (productData) {
      if (list) {
        productData.list_name = list;
      }

      if (category) {
        productData.category = this.fixCategory(category);
      }

      window.gtag('event', 'view_item', {
        items: [productData],
      });
    }
  }

  addToCart<T extends BaseOrderItem>(amount: number, item: T, list: string | null): void {
    const product = this.getDataFromItem(item);
    if (product) {
      product.quantity = amount;
      if (list) {
        product.list_name = list;
      }

      window.gtag('event', 'add_to_cart', {
        items: [product],
      });
    }
  }

  removeFromCart<T extends BaseOrderItem>(amount: number, item: T, list: string | null): void {
    const product = this.getDataFromItem(item);
    if (product) {
      product.quantity = amount;
      if (list) {
        product.list_name = list;
      }

      window.gtag('event', 'remove_from_cart', {
        items: [product],
      });
    }
  }

  resetCheckout(): void {
    this.lastOrder = undefined;
    this.lastStep = 0;
  }

  private lastOrder?: BaseOrder<BaseOrderItem>;
  private lastStep: number = 0;
  checkoutProgress<T extends BaseOrder<BaseOrderItem>>(order: T, step: number): void {
    if (order && order.status !== OrderStatus.NotUsed && !(order === this.lastOrder && step === this.lastStep)) {
      const data: ActionData = this.getDataFromOrder(order);
      if (step) {
        this.lastStep = data.checkout_step = step;
      }

      if (order === this.lastOrder) {
        window.gtag('event', 'checkout_progress', data);
      } else {
        this.lastOrder = order;
        window.gtag('event', 'begin_checkout', data);
      }
    }
  }

  purchase<T extends BaseOrder<BaseOrderItem>>(order: T, ticket: string): void {
    const data: ActionData = this.getDataFromOrder(order);
    data.transaction_id = ticket;

    window.gtag('event', 'purchase', data);

    let saleValue: Big = new Big(0);
    if (order.totalCost && order.totalCost.gt(0)) {
      saleValue = order.totalCost;
    } else if (order.subtotal && order.subtotal.gt(0)) {
      saleValue = order.subtotal;
    }
    window.gtag('event', 'goal', {
      event_category: 'Purchase',
      event_label: OrderType[order.type],
      value: parseFloat(saleValue.toFixed(2)),
    });
  }

  private fixCategory(category: string): string {
    return category.replace(/^\/(products|Search)\//, '');
  }

  private getDataFromProduct(product: Product): ProductData {
    const productData: ProductData = {
      id: product.productID.toString(),
      name: product.productName,
      // TODO this is the wrong value
      isDiabetic: product.productName,
    };

    return productData;
  }

  private getDataFromItem<T extends BaseOrderItem>(item: T): ProductData | void {
    let product: ProductData;
    if (item instanceof CartOrderItem || item instanceof OrderSheetOrderItem) {
      if (!item.productID) {
        return;
      }

      const qty = item.quantity || 0;
      if (qty <= 0) {
        return;
      }

      product = {
        id: item.productID.toString(),
        name: item.productName!,
        quantity: qty,
      };

      if (item.price) {
        product.price = item.price.toFixed(2);
      }

      if (item.unitName) {
        product.Unit = item.unitName;
      }

      if (item.typeName) {
        product.variant = item.typeName;
      }

      if (item instanceof CartOrderItem) {
        if (item.shortDescription) {
          product.name += ' - ' + item.shortDescription;
        }

        if (item.isDiabetic !== null) {
          product['isDiabetic'] = item.isDiabetic ? 'Yes' : 'No';
        }
        if (item.packagingTypeName) {
          product['Packaging Type'] = item.packagingTypeName;
          if (item.packagingSubType) {
            product['Packaging Type'] += ' - ' + item.packagingSubType;
          }
        }

        if (item.customizationTypeName) {
          product['Customization Type'] = item.customizationTypeName;
          if (item.customizationSubType) {
            product['Customization Type'] += ' - ' + item.customizationSubType;
          }
        }

        if (item.foundIn) {
          product.category = this.fixCategory(item.foundIn);
        }
      }
    } else if (item instanceof CustomLabelOrderItem) {
      const qty = item.quantity || 0;
      if (qty <= 0) {
        return;
      }

      let name: string;
      if (item.key === 'labels') {
        name = 'Chocolate Label';
      } else if (item.key === 'setup') {
        name = 'Setup Charge';
      } else {
        name = 'Chocolate Bar';
      }

      product = {
        name: name,
        quantity: qty,
        category: 'Custom Labels',
      };

      if (item.typeName) {
        product['variant'] = item.typeName;
      }

      if (item.foilSubType) {
        product['Packaging Type'] = 'Foil Wrapper - ' + item.foilSubType;
      }
    } else if (item instanceof CorpLogoOrderItem) {
      return; //TODO corplogos price values //TODO HERE
    } else if (item instanceof FountainOrderItem) {
      return;
    } else {
      throw new Error('Order Type not set, this should not be possible');
    }

    return product;
  }

  private getDataFromOrder<T extends BaseOrder<BaseOrderItem>>(order: T): ActionData {
    const data: ActionData = {
      currency: 'CAD',
      'Order Type': OrderType[order.type],
      items: [],
    };

    /*TODO promo if (order.discount) {
      data.coupon = order.discount.code;
    }*/

    for (const item of order.items) {
      const product = this.getDataFromItem(item);
      if (product) {
        data.items!.push(product);
      }
    }

    data.affiliation = 'Website';
    if (order.type !== OrderType.CorpLogo && typeof order.payment !== 'undefined' && order.payment !== null) {
      data.affiliation += ' - ';
      switch (order.payment) {
        case PaymentType.Instore:
          data.affiliation += 'In Store Payment';
          break;
        case PaymentType.Paypal:
          data.affiliation += 'Paypal';
          break;
        case PaymentType.Interac:
          data.affiliation += 'Interac';
          break;
        case PaymentType.PaymentRequest:
          data.affiliation += 'Payment Request';
          break;
        case PaymentType.TooLarge:
          data.affiliation += 'Large Order';
          break;
        case PaymentType.CasePack:
          data.affiliation += 'Has Case Pack';
          break;
        case PaymentType.CallIn:
          data.affiliation += 'Call In';
          break;
        case PaymentType.PaypalRequest:
          data.affiliation += 'Paypal Request';
          break;
        case PaymentType.Wholesale:
          data.affiliation += 'Wholesale';
          break;
        default:
          self.setTimeout(() => {
            throw new Error(
              'GA Missing Payment Type (' + JSON.stringify(order.payment) + ') OrderID: ' + order.orderID,
            );
          });
      }
    }

    if (order.totalFreight && order.totalFreight.gt(0)) {
      data.shipping = order.totalFreight.toFixed(2);
    }

    if (order.totalTax && order.totalTax.gt(0)) {
      data.tax = order.totalTax.toFixed(2);
    }

    if (order.totalCost && order.totalCost.gt(0)) {
      data.value = order.totalCost.toFixed(2);
      data.revenue = order.totalCost.toFixed(2);
    }

    return data;
  }

  pageView(pageTitle: string, path: string): void {
    window.gtag('config', GtagService.GA_TRACKING_ID, {
      page_title: pageTitle,
      page_path: path,
    });
  }
}
