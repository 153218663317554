<span *ngFor="let orderData of orders">
  <a
    class="cart Type{{ OrderType[orderData.order.type] }}"
    [routerLink]="orderData.uri"
    *ngIf="orderData.shouldDisplay()"
    [class.cartHasStuff]="
      orderData.order.items.length ||
      orderData.order.type !== OrderType.Cart ||
      orderData.order.isPossessed ||
      orderData.order.status === OrderStatus.Quote
    "
  >
    <img
      src="/images/static/cart.png"
      class="cartEmpty"
      alt=""
    />
    <img
      src="/images/static/cart_full.png"
      class="cartFull"
      alt=""
    />
    <span class="topTexts">
      <br />
      <span class="cartEmptyMessage">
        <span [hidden]="!(orderData.order.status !== OrderStatus.Quote)">View&nbsp;Cart</span>
        <span [hidden]="!(orderData.order.status === OrderStatus.Quote)">Quote</span>
      </span>
      <span class="cartNotEmptyMessage">
        <span [hidden]="!(orderData.order.status !== OrderStatus.Quote)">{{ orderData.text }}</span>
        <span [hidden]="!(orderData.order.status === OrderStatus.Quote)">Quote</span>
      </span>
    </span>
  </a>
</span>
