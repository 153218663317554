import { Component } from '@angular/core';
import { Route } from '@angular/router';

@Component({
  selector: 'ccf-our-story',
  templateUrl: 'ourStory.component.html',
  styleUrls: ['ourStory.component.less'],
  providers: [],
})
//TODO file name wrong for class
export class OurStoryPageComponent {}

export const PageRoute: Route = {
  path: 'Our-Story',
  component: OurStoryPageComponent,
  pathMatch: 'full',
  data: {
    title: 'Our Story',
  },
};
