import { Component, OnInit, Input } from '@angular/core';
import { Menu } from '../../common/models/menu';
import { MenuService } from '../../common/services/menu.service';
import { APIService } from '../../common/services/api.service';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ccf-mobile-menu',
  templateUrl: 'mobileMenu.component.html',
  styleUrls: ['mobileMenu.component.less'],
})
export class MobileMenuComponent implements OnInit {
  private _subscriptions: Subscription[] = [];

  public isOpen: boolean = false;

  public inline: boolean = false;
  @Input('inline') set setInput(value: boolean) {
    this.inline = value;

    if (this.inline) {
      this.isOpen = true;
    }
  }

  menus: Array<Menu> = [];
  private ANIMATION_DURATION: number = 500;

  constructor(
    protected APIService: APIService,
    private MenuService: MenuService,
    private Router: Router,
  ) {}

  ngOnInit() {
    this._subscriptions.push(
      // save or restore scroll position on route change
      this.Router.events.pipe(pairwise()).subscribe(([prevRouteEvent, currRouteEvent]) => {
        if (currRouteEvent instanceof NavigationEnd) {
          this.isOpen = false;
        }
      }),
    );

    this.MenuService.getMobileMenus().then((menus: Array<Menu>) => {
      this.menus.splice(0, this.menus.length);
      Array.prototype.push.apply(this.menus, menus);
    });

    this.APIService.processRequests();
  }

  toggle(): void {
    if (!this.inline) {
      this.isOpen = !this.isOpen;
      this.colapseAll();
    }
  }

  off(): void {
    if (!this.inline) {
      this.isOpen = false;
      this.colapseAll();
    }
  }

  menuClicked(): void {
    this.off();
  }

  colapseAll(): void {
    const expandie = jQuery('#mobileLightMenu .expanded > .expandie');

    expandie.animate(
      {
        height: 'hide',
      },
      this.ANIMATION_DURATION,
    );

    expandie.parent().removeClass('expanded');
  }

  private lastOpened: HTMLElement | null = null;
  expand($event: JQuery.TriggeredEvent | Event) {
    this.colapseAll();
    if (this.lastOpened !== $event.target) {
      this.lastOpened = $event.target;

      const expandie = jQuery($event.target).parent().find('.expandie');

      expandie.animate(
        {
          height: 'show',
        },
        this.ANIMATION_DURATION,
      );

      expandie.parent().addClass('expanded');
    }
  }
}
