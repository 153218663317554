import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Job, JobBase, JobLegacy } from '../../common/models/job';
import { DialogComponent, StaticDialogComponent_Metadata } from '../../common/helpers/dialogs/dialogComponent';
import { StaticImplements } from 'src/common/helpers/staticImplements';
import { OutputData } from '@editorjs/editorjs';
import { render } from 'src/common/directives/editorjs/render';

export interface JobDialogParams {
  job: Job;
}

export type JobDialogResult = undefined;

@Component({
  selector: 'ccf-job-dialog',
  templateUrl: 'jobDialog.component.html',
  styleUrls: ['jobDialog.component.less'],
  providers: [],
})
@StaticImplements(StaticDialogComponent_Metadata)
export class JobDialogComponent extends DialogComponent<JobDialogParams, JobDialogResult> {
  public static DIALOG_CLASS = 'jobsDialog';
  public static DEFAULT_CONFIG: MatDialogConfig = {
    autoFocus: false,
  };

  job: JobBase;
  content?: string;
  legacy?: JobLegacy;

  constructor(
    public MatDialogRef: MatDialogRef<JobDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public override params: JobDialogParams,
  ) {
    super(MatDialogRef);

    this.job = params.job;
    if (typeof params.job.content === 'string') {
      this.content = render(params.job.content);
    } else {
      this.legacy = params.job;
    }
  }
}
