<div class="pageContent {{ mode }} {{ ticketKey }}">
  <div [hidden]="!(mode === 'list')">
    <br />
    <a
      target="_blank"
      href="/Thank-You?mode="
      >Default</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?ticketKey=T_fountain"
      >Fountain</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?mode=Interac"
      >Interac</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?mode=Instore"
      >Instore, TooLarge, PaymentRequest, CasePack</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?mode=Paypal"
      >Paypal</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?mode=Wholesale"
      >Wholesale</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?ticketKey=T_corplogo"
      >CorpLogos</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?ticketKey=T_customlabel"
      >CustomLabels</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?ticketKey=T_ordersheet"
      >Ordersheet</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?ticketKey=T_donation"
      >Donation</a
    ><br /><br />
    <a
      target="_blank"
      href="/Thank-You?confirmed=testing"
      >Customer Confirmed</a
    ><br /><br />
  </div>

  <div [hidden]="!default">
    <h1>Thank you!</h1>
    <p><a [routerLink]="['/']">*Click here to return to homepage*</a></p>
  </div>

  <div
    class="fountain"
    [hidden]="!(ticketKey === 'T_fountain')"
  >
    <h2>Thank you for your order.</h2>
    <p>A copy of your order has been sent to the email address provided.</p>
    <p>
      If you are paying by <strong>Visa</strong> or<strong> Mastercard</strong> please remember to phone our store at
      <strong>604-437-8221</strong><br />
      during regular business hours to provide payment details.
    </p>
    <p>
      If you are paying by <strong>E-transfer</strong> we will email you back with your total &amp; where to send the
      money.
    </p>
  </div>

  <div
    class="interac"
    [hidden]="!(mode === 'Interac')"
  >
    <h1>Thank you for your order.</h1>

    <p>A copy of your order has been sent to you at the email address provided with your total due.</p>

    <p class="important">
      <strong>
        Please remember send the E-Transfer to<br />
        <ccf-email-link emailAddress="Orders"></ccf-email-link>
      </strong>
    </p>

    <p>(Account is set up for Automatic deposits. No password is needed.)</p>

    <p>
      <strong><em>Your order will not be shipped until the payment has been received.</em></strong>
    </p>
  </div>

  <div
    class="order"
    [hidden]="!(mode === 'Instore' || mode === 'TooLarge' || mode === 'PaymentRequest' || mode === 'CasePack')"
  >
    <h1>Thank you for your order.</h1>
    <p>A copy of your order has been sent to the email address provided.</p>
  </div>

  <div
    class="donations"
    [hidden]="!(ticketKey === 'T_donation')"
  >
    <h1>Thank you</h1>
    <p class="larger">You request has been submitted &amp; will be reviewed.</p>
  </div>

  <div
    class="paypal"
    [hidden]="!(mode === 'Paypal')"
  >
    <h1>Thank you for your order.</h1>
    <p>A copy of your order has been sent to the email address provided.</p>
  </div>

  <div
    class="wholesale"
    [hidden]="!(mode === 'Wholesale')"
  >
    <h1>Thank you for your order.</h1>
    <p>A copy of your order has been sent to the email address provided.</p>
  </div>

  <div
    class="confirmed"
    [hidden]="!confirmed"
  >
    <h1>Thank you for confirming your order.</h1>

    <p class="larger">We will now submit the order to Production.</p>

    <p class="larger">
      Once the order is completed, the final Invoice will be created. The order will then be shipped OR you will be
      advised that it's ready for pick up.
    </p>
  </div>

  <div
    class="corplogos"
    [hidden]="!(ticketKey === 'T_corplogo')"
  >
    <h1>Thank you for your order.</h1>
    <p class="larger">A copy of the Order Form will be emailed to you after you click submit.</p>
    <p class="larger">
      Charlie's staff will review the order &amp; if there are any problems with your 'Date Request', we will be in
      contact. <br />In addition, the Logo mold details &amp; Price will be attached to the order. <br /><br />
      You will then receive an 'Order Confirmation' or a 'Request to Confirm'.
      <br />
      The 'Request to Confirm' requires your review &amp; acceptance before the order goes into production.
    </p>
    <p class="larger">
      For new Mold Developments: A layout proof will be sent to you for approval first - following your
      <br />
      approval, the mold will then be started.
    </p>
    <br />
    <br />
  </div>

  <div
    class="customlabels"
    [hidden]="!(ticketKey === 'T_customlabel')"
  >
    <h1>Thank you for your order.</h1>
    <p>A copy of your order has been sent to the email address provided.</p>

    <br />

    <p class="larger">
      Charlie's will not start this order until we confirm that we have all the details needed &amp; can complete your
      order by the 'Requested Date'.
    </p>

    <p class="larger">
      A layout proof will be sent to you for approval first – following your approval, the labels/order will then be
      started. Charlie's will not start anything until approval of the label is given &amp; payment is received.
    </p>
    <br />
    <br />
  </div>

  <div
    class="ordersheet"
    [hidden]="!(ticketKey === 'T_ordersheet')"
  >
    <br />

    <h1>Thank you for submitting your order.</h1>

    <br />

    As soon as we're available, we will finalize the total for you &amp; email you details about how to pay.<br />
    <br />
    Alternatively, if you would like to pay by <strong>E-transfer</strong>, please let us know &amp; we will send you
    the information <br />
    needed for that method of payment.<br />
    <br />
    <strong>Your order will not be shipped until payment has been received.</strong>
    <br />
    <br />
  </div>
</div>
