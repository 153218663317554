<div
  class="mobileMenuContainer"
  [class.isInline]="inline"
  [class.isOpen]="isOpen"
>
  <div
    class="mobileDarkMenu"
    (click)="off()"
  >
    &nbsp;
  </div>
  <div
    class="mobileMenuTab"
    (click)="toggle()"
  >
    &nbsp;
  </div>
  <div id="mobileLightMenu">
    <div class="mobileMenu">
      <div class="expandable">
        <div class="expander">
          <a [routerLink]="'/products/Best-Sellers'">Best Sellers</a>
        </div>
      </div>
      <div
        *ngFor="let menu of menus"
        class="expandable"
        (click)="expand($event)"
      >
        <div class="expander">{{ menu.title }}</div>

        <div class="expandie">
          <a
            *ngFor="let menuItem of menu.menus"
            (click)="menuClicked()"
            [routerLink]="menuItem.path"
            >{{ menuItem.title }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
