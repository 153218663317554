import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute, Route, Router } from '@angular/router';

import { Job, JobBase } from '../../common/models/job';

import { JobsPage } from './jobsPage.model';
import { JobsPageResolver } from './jobsPage.resolver';

import { JobDialogComponent, JobDialogParams, JobDialogResult } from './jobDialog.component';

import { UriMatcher } from '../../common/helpers/uriMatcher';

import { Subscription } from 'rxjs';
import { GlobalErrorHandler } from '../../common/services/globalErrorHandler';
import { APIService } from '../../common/services/api.service';
import { UserAccount, AccountType } from '../../common/models/account';
import { DialogService } from 'src/common/helpers/dialogs/dialog.service';

export interface JobWithPotition extends JobBase {
  position?: 'left' | 'center' | 'right';
  externalLink?: string;
  routerLink?: string[];
  legacy?: boolean;
}

@Component({
  selector: 'ccf-jobs-page',
  templateUrl: 'jobsPage.component.html',
  styleUrls: ['jobsPage.component.less'],
  providers: [],
})
export class JobsPageComponent implements OnInit, OnDestroy {
  protected _subscriptions: Subscription[] = [];

  jobs: Array<JobWithPotition> = [];
  hasVisibleJobs: boolean = false;
  enabledJobs: number = 0;
  allJobs: number = 0;

  posting?: string;
  selectedJob: JobWithPotition | null = null;

  isStaff: boolean = false;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private DialogService: DialogService,
    private ErrorHandler: GlobalErrorHandler,
    private Router: Router,
    private APIService: APIService,
  ) {}

  private pageReady(): void {
    if (this.jobs.length && this.posting) {
      this.posting = this.posting.toLocaleLowerCase();

      for (const job of this.jobs) {
        if ((job.enabled || this.isStaff) && job.uriName && job.uriName.toLocaleLowerCase() === this.posting) {
          this.selectedJob = job;
          break;
        }
      }

      if (this.selectedJob) {
        const dialogRef = this.DialogService.show<JobDialogParams, JobDialogResult, JobDialogComponent>(
          JobDialogComponent,
          {
            job: this.selectedJob as Job,
          },
        );

        void dialogRef
          .afterClosed()
          .toPromise()
          .then((result: JobDialogResult) => {
            this.Router.navigateByUrl('/Job-Postings');
          });
      } else {
        this.Router.navigateByUrl('/Job-Postings');
      }
    }
  }

  ngOnInit() {
    let numTriggered: number = 0;

    const triggered = () => {
      numTriggered++;
      if (numTriggered === 2) {
        numTriggered = 0;
        setTimeout(() => this.pageReady());
      }
    };

    this._subscriptions.push(
      this.APIService.authenticatedDataStream().subscribe((userData?: UserAccount | null) => {
        if (typeof userData !== 'undefined') {
          this.isStaff = userData !== null && userData.accountType === AccountType.Staff;
          this.hasVisibleJobs = (this.isStaff && this.allJobs > 0) || this.enabledJobs > 0;
        }
      }),
    );

    this._subscriptions.push(
      this.ActivatedRoute.data.subscribe((data) => {
        const page: JobsPage = data['page'];

        this.isStaff = page.isStaff;
        this.jobs = page.jobs;

        this.allJobs = 0;
        this.enabledJobs = 0;

        let jobsLen = this.jobs.length;
        for (let i = 0; i < jobsLen; i++) {
          const job = this.jobs[i];

          if (job.blurb[0] !== '{') {
            job.legacy = true;
          }

          if (job.uriName === null) {
            this.ErrorHandler.reportError(new Error(`Job: ${job.title} has no uri name.`), undefined, true);
            this.jobs.splice(i, 1);
            i--;
            jobsLen--;
            continue;
          }

          if (job.uriName.indexOf('http') === 0) {
            job.externalLink = job.uriName;
          } else {
            job.routerLink = ['/Job-Postings', job.uriName];
          }

          if (i % 2 === 0) {
            if (i === jobsLen - 1) {
              job.position = 'center';
            } else {
              job.position = 'left';
            }
          } else {
            job.position = 'right';
          }

          this.allJobs++;
          if (job.enabled) {
            this.enabledJobs++;
          }
        }

        this.hasVisibleJobs = (this.isStaff && this.allJobs > 0) || this.enabledJobs > 0;

        triggered();
      }),
    );

    this._subscriptions.push(
      this.ActivatedRoute.params.subscribe((params) => {
        this.posting = params['posting'] || undefined;
        triggered();
      }),
    );

    this.APIService.processRequests();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

export const PageRoute: Route = {
  matcher: UriMatcher,
  component: JobsPageComponent,
  pathMatch: 'full',
  resolve: {
    page: JobsPageResolver,
  },
  data: {
    path: 'Job-Postings/?:posting',
    title: 'Job Postings',
    _name: 'JobsPageComponent',
  },
};
