import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { OrdersService } from '../../common/services/orders.service';
import { APIService } from '../../common/services/api.service';
import { Route, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ccf-thank-you',
  templateUrl: 'thankyou.component.html',
  styleUrls: ['thankyou.component.less'],
  providers: [],
})
//TODO file name wrong for class should be ThankYouPageComponent
export class ThankYouComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  mode: string = '';
  ticketKey: string = '';
  confirmed: string = '';
  default: boolean = false;

  constructor(
    private Location: Location,
    private OrdersService: OrdersService,
    protected APIService: APIService,
    private ActivatedRoute: ActivatedRoute,
  ) {}

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit() {
    let key: string | undefined; //This is T_<cartString>_<data>

    this._subscriptions.push(
      this.ActivatedRoute.queryParams.subscribe((params) => {
        if (params['mode']) {
          this.mode = params['mode'];
        }
        if (params['confirmed']) {
          this.confirmed = params['confirmed'];
        }
        if (params['ticketKey']) {
          //TODO what am i doing
          this.ticketKey = (<string>params['ticketKey']).split('_').slice(0, 2).join('_');
        }
        if (params['key']) {
          key = params['key'];
          if (key === 'T_customlabel' || key === 'T_corplogo' || key === 'T_ordersheet') {
            this.mode = '';
          }
        }
      }),
    );

    if (key) {
      this.OrdersService.detach(key);
    }

    if (this.confirmed && this.confirmed !== 'testing') {
      this.OrdersService.confirm(this.confirmed);
    }

    this.default = !(this.confirmed || this.mode || this.ticketKey);

    if ((this.confirmed || this.mode || this.ticketKey) && this.mode !== 'list') {
      this.Location.replaceState(this.Location.path().split('?')[0]);
    }
  }
}

export const PageRoute: Route = {
  path: 'Thank-You',
  component: ThankYouComponent,
  pathMatch: 'full',
  data: {
    title: 'Thank You',
  },
};
