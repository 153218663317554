<div class="messageSpace">
  <div
    class="updatedAlert"
    [class.updated]="hasUpdated"
  >
    <a
      class="linkLink"
      (click)="refresh()"
      >Refresh</a
    >
    to get the latest updates to the website.
    <a
      class="linkLink"
      (click)="dismiss()"
      >Dismiss</a
    >
  </div>
  <div
    class="errorAlert"
    [class.errored]="hasErrored"
  >
    Something has gone wrong. If you encounter problems please try
    <a
      class="linkLink"
      (click)="refresh()"
      >Refreshing</a
    >. If problems persist feel free to
    <ccf-email-link
      emailAddress="General"
      displayText="Contact Us"
    />.
    <!--TODO logged staff with website_debug in should display actual error details-->
  </div>
</div>
