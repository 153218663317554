import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Deferred } from '../helpers/deferred';
import { PermissionsHad } from '../models/permission';

import { BehaviorSubject } from 'rxjs';
import { UserAccount, AccountType, AccountTypeURL } from '../models/account';
import { isEqual } from 'lodash-es';
import { GlobalErrorHandler } from './globalErrorHandler';
import { betterStringify } from '../helpers/betterStringify';

const UNSENT_REQUEST_TIMEOUT = 5000;
const ADMIN_CACHE_TIME = 1000;

export class APIErrorNoResponse extends Error {
  constructor(public key: string) {
    super(`No API response to request ${key}`);
    this.name = 'APIErrorNoResponse';
  }
}

export class APIResponseHasErrors extends Error {
  constructor(
    public key: string,
    public errors: APIError[],
  ) {
    super(`API Request ${key} had errors "${errors.map((e) => e.toString()).join('" "')}"`);
    this.name = 'APIResponseHasErrors';
  }
}

export class APIError extends Error {
  constructor(
    public typeName: string,
    message: string,
    public originalStack?: string,
  ) {
    super(message);
    this.name = `APIError-${typeName}`;
  }
}

export const generate_API_REQUEST_KEY = (endPoint: string, method: string, uniqueKey: string): string => {
  return `${endPoint}|${method}|${uniqueKey}`;
};

export const generate_API_UNIQUE_KEY = (data?: any): string => {
  let keyBuilder: Record<any, unknown> = {};

  const dataType = typeof data;
  if (dataType === 'undefined' || data === null) {
    keyBuilder = {};
  } else if (dataType === 'object') {
    if (Array.isArray(data)) {
      keyBuilder['!array!'] = data;
    } else {
      keyBuilder = data;
    }
  } else {
    keyBuilder[`!${dataType}!`] = betterStringify(data);
  }

  const keys = Object.keys(keyBuilder);
  keys.sort();

  const uniqueKeyParts: string[] = [];
  for (const key of keys) {
    uniqueKeyParts.push(key + ':' + betterStringify(keyBuilder[key]));
    //TODO objects could break this but rarely, when they are multi layered
  }

  return uniqueKeyParts.length ? uniqueKeyParts.join('|') : '';
};

export interface APIRequestData {
  [key: string]: any;
}

export interface Rejection<T> {
  errors: Array<SerializedAPIError>;
  data?: T;
}

export interface APIRequest<P extends APIRequestData> {
  endPoint: string;
  method: string;
  data?: P;
  uniqueKey?: string;
}
//TODO why is there a P
export interface SubmittedAPIRequest<T, P extends APIRequestData> extends APIRequest<P> {
  API_REQUEST_groupID: number | null;
  API_REQUEST_KEY: string;

  data: P;
  uniqueKey: string;
}

export interface SerializedAPIError {
  typeName: string;
  message: string;
  stack?: string;
}

export interface EndPointMethodResponse<T> {
  data: T;
  errors?: SerializedAPIError[];
}

export interface EndPointResponse {
  methods: {
    [method: string]: { [key: string]: EndPointMethodResponse<unknown> };
  };
}

export interface APIStatData {
  app: number;
  check?: number;
}

export interface APIResponse {
  //TODO make sure backend isn't sending old params
  errors: SerializedAPIError[]; //TODO no global errors
  _sql_debug_buffer: string[];
  endPoints: { [endPoint: string]: EndPointResponse };
  accountData?: UserAccount;
  permissions?: PermissionsHad;
  logoutReason?: string;
  stat?: APIStatData;
}

export interface EndPointRequest {
  methods: { [method: string]: { [key: string]: any } };
}

export interface RawAPIRequest {
  endPoints: { [endPoint: string]: EndPointRequest };
}

export const removeFromRequest = (
  request: RawAPIRequest,
  endPointKey: string,
  methodKey: string,
  uniqueKey: string,
): void => {
  if (typeof request.endPoints[endPointKey] !== 'undefined') {
    const methods = request.endPoints[endPointKey].methods;
    if (typeof methods[methodKey] !== 'undefined') {
      const uniqueKeys = methods[methodKey];
      if (uniqueKeys[uniqueKey]) {
        delete uniqueKeys[uniqueKey];
      }

      if (Object.keys(uniqueKeys).length === 0) {
        delete methods[methodKey];
      }
    }

    if (Object.keys(methods).length === 0) {
      delete request.endPoints[endPointKey];
    }
  }
};

@Injectable({
  providedIn: 'root',
})
export class APIService {
  protected requestQueue: Array<SubmittedAPIRequest<any, any>> = [];
  protected pendingAuthRequests: Array<SubmittedAPIRequest<any, any>> = [];

  protected statCheck: number | null = null;
  protected statTimeout: number = 0;
  protected mode!: 'app';
  public statStream: BehaviorSubject<number | undefined>;

  protected lastResponseTime: number = 0;
  protected CACHE_TIME_AUTH: number = 5 * 60 * 1000; // 5 minutes
  protected _loginRequired: BehaviorSubject<boolean | undefined>;
  protected _realAccountData: BehaviorSubject<UserAccount | null | undefined>;
  protected _accountData: BehaviorSubject<UserAccount | null | undefined>;
  protected _permissions: BehaviorSubject<PermissionsHad | undefined>;

  protected requestTimeout: number | null = null;

  protected requestGroupID: number = 0;
  protected inflightRequests: { [key: string]: Deferred<unknown> } = {};
  protected pendingRequests: {
    [key: string]: Array<SubmittedAPIRequest<any, any>>;
  } = {};

  public isAdmin: boolean = false;

  protected preloadData?: APIResponse;
  protected preloadTimer: number = 0;
  protected preloadDuration: number = 30 * 1000; // 30 Seconds

  isMasked: boolean = false;
  public unMasked(): BehaviorSubject<UserAccount | null | undefined> {
    return this._realAccountData;
  }

  public authenticatedDataStream(processRequests: boolean = true): BehaviorSubject<UserAccount | null | undefined> {
    if (this.lastResponseTime + this.CACHE_TIME_AUTH < new Date().getTime()) {
      void this.queueRequest<boolean>({
        endPoint: 'authenticate',
        method: 'authenticated',
      });

      if (processRequests) {
        this.processRequests();
      }
    }

    return this._accountData;
  }

  public authenticatedData(processRequests: boolean = true): Promise<UserAccount | null | undefined> {
    const deferred = new Deferred<UserAccount | null | undefined>();

    if (this.lastResponseTime + this.CACHE_TIME_AUTH < new Date().getTime()) {
      void this.queueRequest<boolean>({
        endPoint: 'authenticate',
        method: 'authenticated',
      }).then(() => {
        deferred.resolve(this._accountData.value);
      });

      if (processRequests) {
        this.processRequests();
      }
    } else {
      deferred.resolve(this._accountData.value);
    }

    return deferred.promise;
  }

  public permissionsStream(processRequests: boolean = true): BehaviorSubject<PermissionsHad | undefined> {
    if (this.lastResponseTime + this.CACHE_TIME_AUTH < new Date().getTime()) {
      void this.queueRequest<boolean>({
        endPoint: 'authenticate',
        method: 'authenticated',
      });

      if (processRequests) {
        this.processRequests();
      }
    }

    return this._permissions;
  }

  public permissions(): Promise<PermissionsHad | undefined> {
    const result = new Deferred<PermissionsHad | undefined>();

    if (this.lastResponseTime + this.CACHE_TIME_AUTH < new Date().getTime()) {
      this.queueRequest<boolean>({
        endPoint: 'authenticate',
        method: 'authenticated',
      })
        .then(() => {
          result.resolve(this._permissions.value);
        })
        .catch((reason) => {
          result.reject(reason);
        });
    } else {
      result.resolve(this._permissions.value);
    }

    return result.promise;
  }

  public loginRequiredStream(): BehaviorSubject<boolean | undefined> {
    return this._loginRequired;
  }

  public loginRequired(): boolean | undefined {
    return this._loginRequired.value;
  }

  constructor(
    protected httpClient: HttpClient,
    protected ErrorHandler: GlobalErrorHandler,
  ) {
    this._loginRequired = new BehaviorSubject<boolean | undefined>(undefined);
    this._accountData = new BehaviorSubject<UserAccount | null | undefined>(undefined);
    this._realAccountData = new BehaviorSubject<UserAccount | null | undefined>(undefined);
    this._permissions = new BehaviorSubject<PermissionsHad | undefined>(undefined);
    this.statStream = new BehaviorSubject<number | undefined>(undefined);

    if (window.APP_STAT) {
      this.mode = 'app';
      this.statStream.next(window.APP_STAT);
    }

    if (typeof window.API_PRELOAD !== 'undefined') {
      this.preloadData = window.API_PRELOAD;
      delete window.API_PRELOAD;
    }
  }

  /* istanbul ignore next */
  reload(): void {
    window.location.reload(); //TODO replace this with nice message suggesting they reload //TODO appearnetly this doesn't work anymore
  }

  private doStatCheck(response: APIResponse): void {
    window.clearTimeout(this.statTimeout);
    this.statTimeout = 0;

    if (response.stat) {
      if (this.statStream.value !== response.stat[this.mode]) {
        this.statStream.next(response.stat[this.mode]);
      }

      if (response.stat.check) {
        this.statCheck = response.stat.check;

        this.statTimeout = window.setTimeout(() => {
          // Piggy backs on auth check
          void this.queueRequest<boolean>({
            endPoint: 'authenticate',
            method: 'authenticated',
          });

          this.processRequests();
        }, this.statCheck * 1000);
      }
    }
  }

  public ADMIN_CACHE_TIME = ADMIN_CACHE_TIME;
  public getCacheTime(cacheTime: number): number {
    if (this.isAdmin) {
      return this.ADMIN_CACHE_TIME;
    }
    return cacheTime;
  }

  trackRequests(): void {
    if (this.requestTimeout) {
      window.clearTimeout(this.requestTimeout);
      this.requestTimeout = null;
    }

    this.requestTimeout = window.setTimeout(() => {
      const lostRequests: Array<string> = [];
      for (const request of this.requestQueue) {
        lostRequests.push(request.API_REQUEST_KEY);
      }
      throw Error('The following requests were not sent: \n' + lostRequests.join('\n'));
    }, UNSENT_REQUEST_TIMEOUT);
  }

  requeuePendingAuth(): void {
    Array.prototype.push.apply(this.requestQueue, this.pendingAuthRequests.splice(0, this.pendingAuthRequests.length));

    this.trackRequests();
  }

  doRequest<T>(userRequest: APIRequest<APIRequestData>): Promise<T> {
    const [promise, request, merged] = this.makeRequest<T, APIRequestData>(userRequest);

    if (!merged) {
      this.processRequests([request]);
    }

    return promise;
  }

  queueRequest<T>(userRequest: APIRequest<APIRequestData>): Promise<T> {
    const [promise, request, merged] = this.makeRequest<T, APIRequestData>(userRequest);

    if (!merged) {
      this.requestQueue.push(request);
      this.trackRequests();
    }

    return promise;
  }

  private makeRequest<T, P extends APIRequestData>(
    userRequest: APIRequest<P>,
  ): [Promise<T>, SubmittedAPIRequest<T, P>, boolean] {
    const request: SubmittedAPIRequest<T, P> = Object.assign(
      {
        API_REQUEST_groupID: null,
        API_REQUEST_KEY: '',
        data: {},
        uniqueKey: '',
      },
      userRequest,
    );

    if (typeof userRequest.uniqueKey === 'undefined') {
      // TODO generate warning when no key is specified
      request.uniqueKey = generate_API_UNIQUE_KEY(request.data);
    }
    request.API_REQUEST_KEY = generate_API_REQUEST_KEY(request.endPoint, request.method, request.uniqueKey);

    let inflight = this.inflightRequests[request.API_REQUEST_KEY] as Deferred<T>;
    const merged = typeof inflight !== 'undefined';
    if (!merged) {
      inflight = (this.inflightRequests[request.API_REQUEST_KEY] = new Deferred<unknown>()) as Deferred<T>;
      // Self cleanup not needed because done when resolved
    }

    return [inflight.promise, request, merged];
  }

  // TODO this will replace processRequests
  async resolveAPIQueue(): Promise<void>;
  async resolveAPIQueue(requests: SubmittedAPIRequest<unknown, APIRequestData>[]): Promise<void>;
  async resolveAPIQueue<T>(promise: Promise<T>): Promise<T>;
  async resolveAPIQueue(...args: unknown[]): Promise<unknown> {
    this.processRequests(args.length === 1 && Array.isArray(args[0]) ? args[0] : undefined);
    if (typeof args[0] !== 'undefined') {
      return args[0];
    }
    return;
  }

  // TODO this will be an internal function only
  processRequests(requests?: Array<SubmittedAPIRequest<unknown, APIRequestData>>): void {
    this.requestGroupID++;
    const currentGroupID = this.requestGroupID;

    if (this.requestTimeout) {
      window.clearTimeout(this.requestTimeout);
      this.requestTimeout = null;
    }

    const request: RawAPIRequest = {
      endPoints: {},
    };

    if (typeof requests === 'undefined') {
      requests = this.requestQueue;
    }

    /* if (requests.length === 0) {
      if (window.location.hostname !== 'charlieschocolatefactory.com') {
        let here = (new Error().stack || '').split('\n').slice(1).join('\n');
        window.console && console.debug('Process Requests called with nothing in queue from:\n' + here);
      }
    }*/

    while (requests.length > 0) {
      const item: SubmittedAPIRequest<unknown, APIRequestData> | undefined = requests.shift();
      if (typeof item === 'undefined') {
        continue;
      }

      item.API_REQUEST_groupID = currentGroupID;

      if (typeof request.endPoints[item.endPoint] === 'undefined') {
        request.endPoints[item.endPoint] = {
          methods: {},
        };
      }
      const endPoint = request.endPoints[item.endPoint];

      if (typeof endPoint.methods[item.method] === 'undefined') {
        endPoint.methods[item.method] = {};
      }
      const method = endPoint.methods[item.method];

      if (typeof method[item.uniqueKey] === 'undefined') {
        method[item.uniqueKey] = item.data;
      }

      if (typeof this.pendingRequests[item.API_REQUEST_KEY] === 'undefined') {
        this.pendingRequests[item.API_REQUEST_KEY] = [];
      }
      if (this.pendingRequests[item.API_REQUEST_KEY].indexOf(item) === -1) {
        this.pendingRequests[item.API_REQUEST_KEY].push(item);
      }
    }

    if (Object.keys(request.endPoints).length === 0) {
      return;
    }

    const responseHanlder = (response: APIResponse, preloading: 'first' | 'yes' | 'no' = 'no'): void => {
      //TODO errors

      if (typeof response === 'undefined') {
        return; //This happens when requests are cancelled i think TODO
      }

      if (preloading === 'no') {
        this.doStatCheck(response);
      }

      this.lastResponseTime = new Date().getTime();

      const keys = Object.keys(response);
      const isErrorOnly = keys.length === 1 && keys.indexOf('errors') !== -1; //TODO what does this do ???

      if (!isErrorOnly && (preloading === 'no' || preloading === 'first')) {
        //Easier to read this way
        if (response.accountData) {
          response.accountData.accountTypeURL = AccountTypeURL[response.accountData.accountType];

          if (!isEqual(this._realAccountData.value, response.accountData)) {
            // Wont spam updates to subscribers
            this._realAccountData.next(response.accountData);
            if (this.isMasked && response.accountData.accountType !== AccountType.Staff) {
              this.isMasked = false;
            }
            if (!this.isMasked) {
              this._accountData.next(response.accountData);
            }
          }
        } else if (this._realAccountData.value !== null) {
          this._realAccountData.next(null);
          this._accountData.next(null);

          if (response.logoutReason) {
            setTimeout(() => alert(response.logoutReason));
          }
        }

        if (response.permissions) {
          if (!isEqual(this._permissions.value, response.permissions)) {
            this._permissions.next(response.permissions);
          }
        } else if (!isEqual(this._permissions.value, {})) {
          this._permissions.next({});
        }
      }

      for (const endPointKey in response.endPoints) {
        /* istanbul ignore else */
        if (Object.prototype.hasOwnProperty.call(response.endPoints, endPointKey)) {
          const endPoint = response.endPoints[endPointKey];
          for (const methodKey in endPoint.methods) {
            /* istanbul ignore else */
            if (Object.prototype.hasOwnProperty.call(endPoint.methods, methodKey)) {
              const method = endPoint.methods[methodKey];
              for (const uniqueKey in method) {
                /* istanbul ignore else */
                if (Object.prototype.hasOwnProperty.call(method, uniqueKey)) {
                  removeFromRequest(request, endPointKey, methodKey, uniqueKey);

                  const result = method[uniqueKey].data;
                  const errors = method[uniqueKey].errors;
                  const key = generate_API_REQUEST_KEY(endPointKey, methodKey, uniqueKey);

                  let loginRequired = false;

                  if (errors) {
                    const missingPermissions = [];
                    for (const error of errors) {
                      if (error.typeName === 'LOGIN_REQUIRED') {
                        loginRequired = true;
                        this._loginRequired.next(true);
                        break;
                      } else if (error.typeName === 'PERMISSION_REQUIRED') {
                        missingPermissions.push(error.message);
                      } else {
                        alert('Error ' + error.typeName + ': ' + error.message); // TODO
                      }
                    }
                    if (missingPermissions.length > 0) {
                      alert('Missing Permission(s): ' + missingPermissions.join(',')); // TODO
                    }

                    if (loginRequired) {
                      // Get rid of it if its not fixable by logging in
                      Array.prototype.push.apply(this.pendingAuthRequests, this.pendingRequests[key]);
                      delete this.pendingRequests[key];
                      continue;
                    }
                  }
                  delete this.pendingRequests[key];

                  // Cannot get here with loginRequired = false due to above logic
                  if (typeof this.inflightRequests[key] !== 'undefined') {
                    if (errors) {
                      this.inflightRequests[key].reject(
                        new APIResponseHasErrors(
                          key,
                          errors.map((e) => new APIError(e.typeName, e.message, e.stack)),
                        ),
                      );
                    } else {
                      this.inflightRequests[key].resolve(result);
                    }
                    delete this.inflightRequests[key];
                  }
                }
              }
            }
          }
        }
      }

      if (preloading === 'no') {
        //let unansweredRequests: Array<string> = [];
        for (const key in this.pendingRequests) {
          /* istanbul ignore else */
          if (Object.prototype.hasOwnProperty.call(this.pendingRequests, key)) {
            const firstRequest = this.pendingRequests[key][0];
            if (firstRequest.API_REQUEST_groupID === currentGroupID) {
              const error = new APIErrorNoResponse(key);
              this.inflightRequests[key].reject(error);
              //TODO remove this when everyone handles errors correctly
              this.ErrorHandler.reportError(error, 'reported from APIService for now');
              //unansweredRequests.push(firstRequest.endPoint + '|' + firstRequest.method + '|' + firstRequest.uniqueKey);
              delete this.pendingRequests[key];
              delete this.inflightRequests[key];
            }
          }
        }

        /*if (unansweredRequests.length > 0) {
          this.ErrorHandler.reportError(
            new Error('The following requests went unanswered: \n' + unansweredRequests.join('\n'))
          );
        }*/
      }
    };

    if (this.preloadData) {
      let firstPreloadData: boolean = false;
      if (this.preloadTimer === 0) {
        firstPreloadData = true;
        this.preloadTimer = Date.now() + this.getCacheTime(this.preloadDuration);
      }
      if (this.preloadTimer && this.preloadTimer >= Date.now()) {
        responseHanlder(this.preloadData, firstPreloadData ? 'first' : 'yes');
      } else {
        delete this.preloadData;
      }
    }

    if (Object.keys(request.endPoints).length === 0) {
      return;
    }

    let timesTried: number = 0;
    const sendRequest = (requestJSON: string): void => {
      timesTried++;
      this.httpClient
        .post<APIResponse>('/api/api.php', requestJSON, {
          headers: { 'Content-Type': 'application/json' },
        })
        .subscribe(responseHanlder, (errorResponse: HttpErrorResponse) => {
          let errorJSON: string;
          try {
            errorJSON = betterStringify(errorResponse);
          } catch (e) {
            errorJSON = 'Could not convert to json';
            setTimeout(() => {
              // this.ErrorHandler.reportError(e, undefined, true);
              throw e;
            }, 500);
          }

          if (errorResponse.status === 0 && errorResponse.statusText === 'Unknown Error') {
            if (timesTried < 5) {
              this.trackRequests();
              setTimeout(() => {
                sendRequest(requestJSON);
                /* this.ErrorHandler.reportError(
                    new Error(`Request has been resent ${timesTried - 1}. #${new Date().getTime()}`),
                    '->' + requestJSON + '\n<-' + errorJSON,
                    true
                  );*/
              }, 500 * timesTried);
              return;
            } else {
              throw new Error(
                'Request ran out of tries\n' +
                  errorResponse.name +
                  '\n' +
                  errorResponse.message +
                  '\n->' +
                  requestJSON +
                  '\n<-' +
                  errorJSON,
              );
            }
          }

          const error = new Error(errorResponse.name + '\n' + errorResponse.message);
          setTimeout(() => {
            this.ErrorHandler.reportError(error, '->' + requestJSON + '\n<-' + errorJSON);
          }, 500);

          this.pendingRequests = {};
          for (const key in this.inflightRequests) {
            /* istanbul ignore else */
            if (Object.prototype.hasOwnProperty.call(this.inflightRequests, key)) {
              this.inflightRequests[key].reject(error);
              delete this.inflightRequests[key];
            }
          }

          if (
            // TODO this code needs to be fixed and tested
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            typeof errorResponse !== 'undefined' &&
            errorResponse.error &&
            typeof errorResponse.error.text === 'string' &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (errorResponse.error.text as string).indexOf('<title>Something went wrong</title>') !== -1
          ) {
            // Server side error already logged this
          } else {
            throw new Error(
              errorResponse.name + '\n' + errorResponse.message + '\n->' + requestJSON + '\n<-' + errorJSON,
            );
            // throw error;
          }
        });
    };

    sendRequest(JSON.stringify(request));
  }
}
