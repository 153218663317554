import { ProductType } from './type';
import { Price } from './price';
import { ProductImageData as ImageData } from './image';
import { ApplicableTaxes } from './tax';
import { Category } from './category';
import { sortNumbersASC } from '../helpers/sorting';
import { enumValues } from '../helpers/enum';
import { isEqual } from 'lodash';
import { betterStringify } from '../helpers/betterStringify';

export interface ProductSeen {
  seen?: boolean | (() => boolean);
}

export enum Status {
  Available = 0,
  OutOfStock,
  NextSeason,
  AvailableSoon,
}
export enum StatusShort {
  A = Status.Available,
  OS = Status.OutOfStock,
  NS = Status.NextSeason,
  AS = Status.AvailableSoon,
}

export const StatusPrority_MostAvailable: Status[] = [
  Status.Available,
  Status.AvailableSoon,
  Status.NextSeason,
  Status.OutOfStock,
];
export const StatusPrority_LeastAvailable: Status[] = [
  Status.OutOfStock,
  Status.NextSeason,
  Status.AvailableSoon,
  Status.Available,
];

export const AllStatuses: Status[] = enumValues(Status);
AllStatuses.sort();

if (!isEqual(AllStatuses, enumValues(StatusShort).sort())) {
  window.reportError(
    new Error(`StatusShort does not have the correct number of Statuses`),
    `Status: ${betterStringify(Status)}\nStatusShort: ${betterStringify(StatusShort)}`,
  );
}

if (!isEqual(AllStatuses, StatusPrority_MostAvailable.slice().sort())) {
  window.reportError(
    new Error(`StatusPrority_MostAvailable does not have the correct number of Statuses`),
    `Status: ${betterStringify(Status)}\nStatusPrority_MostAvailable: ${betterStringify(StatusPrority_MostAvailable)}`,
  );
}
if (!isEqual(AllStatuses, StatusPrority_LeastAvailable.slice().sort())) {
  window.reportError(
    new Error(`StatusPrority_LeastAvailable does not have the correct number of Statuses`),
    `Status: ${betterStringify(Status)}\nStatusPrority_LeastAvailable: ${betterStringify(
      StatusPrority_LeastAvailable,
    )}`,
  );
}

export namespace Status {
  export const isOutOfStock = (status: Status, isWholesale: boolean = false): boolean => {
    return !(status === Status.Available || (isWholesale && status === Status.AvailableSoon));
  };

  export const proritize = (statuses: Status[], prority: Status[], fallback: Status = Status.OutOfStock): Status => {
    const ranking = [...statuses].filter((s) => prority.indexOf(s) !== -1);
    ranking.sort((a, b) => {
      return sortNumbersASC(prority.indexOf(a), prority.indexOf(b));
    });

    if (ranking.length === 0) {
      return fallback;
    }
    return ranking[0];
  };
}

export enum Discountable {
  Auto = 0,
  Enabled,
  Disabled,
  Manual,
}

export namespace Discountable {
  export const isDiscountable = function (
    price: Discountable | undefined,
    product: Discountable,
    category: boolean,
  ): boolean {
    if (product === Discountable.Auto) {
      return category;
    } else if (product === Discountable.Manual) {
      return price === Discountable.Enabled;
    } else {
      return product === Discountable.Enabled;
    }
  };
}

export interface Product<T = string> {
  productID: number;
  productName: string;

  shortDescription: string;
  description: string;

  approx: string | null; //TODO get rid of these
  //TODO future weight, size

  discountable: Discountable;
  inDiscountableCategory: boolean;
  isDiabetic: boolean;
  keywords: Array<string>;
  noSearch: boolean;

  types: Array<ProductType>;
  customizationTypes: Array<ProductType>;
  packagingTypes: Array<ProductType>;

  categoriesIn: Array<number>;
  categories?: Array<Category>;

  prices: Array<Price<T>> | null;
  previewPrice: Price<T> | null;

  status: Status;

  thumbnail: string;
  fullsize: string;
  image: string;
  imageData: ImageData;

  staffNotes: string;
  comment: string;

  updated: number;

  isFragile: boolean | null;

  taxes: ApplicableTaxes;

  boxLocation: string | null;
  supplierID: number | null;
  supplierName: string | null;
}

// tslint:disable: no-bitwise
export enum AllergenLocation {
  None = 0,
  Product = 1 << 0,
  SameLine = 1 << 1,
  Facility = 1 << 2,
}
// tslint:enable: no-bitwise

export type SQL_Boolean = 0 | 1;

export interface Allergens {
  productID: number;
  typeID: number | null;
  // Anything that has ID wont be detected as changed

  vegan: SQL_Boolean;
  celiac: SQL_Boolean;
  gelatinFree: SQL_Boolean;

  milk: AllergenLocation;
  peanut: AllergenLocation;
  treeNuts: AllergenLocation;
  soy: AllergenLocation;
  eggs: AllergenLocation;
  wheat: AllergenLocation;
  sulphites: AllergenLocation;

  repackaged: SQL_Boolean;
}

export type ProductAllergensMap<T = Allergens> = { [typeID: number]: T };
