import { Big } from 'big.js';

import { clear } from '../../helpers/clearIt';

import { OrderType, OrderFile, LabelType, LabelSize, APICustomLabelOrder, CustomLabelsTemplates } from '../checkout';

import { CustomLabelOrderItem } from '../orderItem/customLabel';
import { BaseOrder, OrderResponseObject } from './base';
import { DataLayer, ProcessRequests } from '../../dataLayer';
import { Deferred } from '../../helpers/deferred';
import { ApplicableTaxes } from '../tax';
import md5 from 'md5';
import { cloneDeep } from 'lodash-es';
import { Discountable } from '../product';

export class CustomLabelOrder extends BaseOrder<CustomLabelOrderItem> implements APICustomLabelOrder<Big> {
  type = OrderType.CustomLabel as const;

  labelType: LabelType | null = null;
  background: string = '';
  template: CustomLabelsTemplates | null = null;
  labelSize: LabelSize | null = null;

  frontFont: string = '';
  frontColor: string = '';
  frontText1: string = '';
  frontText2: string = '';

  backFont: string = '';
  backColor: string = '';
  backText1: string = '';
  backText2: string = '';
  backText3: string = '';

  artwork: Array<OrderFile> = [];
  skipArt: boolean = false;

  GST_HST!: ApplicableTaxes;

  constructor(protected override dataLayer: DataLayer) {
    super(dataLayer);

    this.dataLayer.tax.filter('^(GST|HST)$').then((taxes: ApplicableTaxes) => {
      this.GST_HST = taxes;
    });
    this.dataLayer.processRequests();
  }

  override reset(): void {
    super.reset();

    this.labelType = null;
    this.background = '';
    this.template = null;
    this.labelSize = null;

    this.frontFont = '';
    this.frontColor = '';
    this.frontText1 = '';
    this.frontText2 = '';

    this.backFont = '';
    this.backColor = '';
    this.backText1 = '';
    this.backText2 = '';
    this.backText3 = '';

    clear(this.artwork);
    this.skipArt = false;
  }

  override async parse(
    data: OrderResponseObject<APICustomLabelOrder>,
    calledFrom: string,
    allDone?: Promise<void>,
  ): Promise<void> {
    const doneHere = new Deferred<void>();

    await super.parse(data, calledFrom, doneHere.promise);

    this.freeze();

    if (data.order) {
      const order = data.order;

      if (typeof order.artwork !== 'undefined') {
        this.artwork = order.artwork;
      }

      if (typeof order.labelType !== 'undefined') {
        this.labelType = order.labelType;
      }
      if (typeof order.background !== 'undefined') {
        this.background = order.background;
      }
      if (typeof order.template !== 'undefined') {
        this.template = order.template;
      }
      if (typeof order.labelSize !== 'undefined') {
        this.labelSize = order.labelSize;
      }

      if (typeof order.frontFont !== 'undefined') {
        this.frontFont = order.frontFont;
      }
      if (typeof order.frontColor !== 'undefined') {
        this.frontColor = order.frontColor;
      }
      if (typeof order.frontText1 !== 'undefined') {
        this.frontText1 = order.frontText1;
      }
      if (typeof order.frontText2 !== 'undefined') {
        this.frontText2 = order.frontText2;
      }

      if (typeof order.backFont !== 'undefined') {
        this.backFont = order.backFont;
      }
      if (typeof order.backColor !== 'undefined') {
        this.backColor = order.backColor;
      }
      if (typeof order.backText1 !== 'undefined') {
        this.backText1 = order.backText1;
      }
      if (typeof order.backText2 !== 'undefined') {
        this.backText2 = order.backText2;
      }
      if (typeof order.backText3 !== 'undefined') {
        this.backText3 = order.backText3;
      }

      if (typeof order.skipArt !== 'undefined') {
        this.skipArt = order.skipArt;
      }

      this.checkItems();
    }

    this.unfreeze();

    const done = async () => {
      await this.calculate();

      doneHere.resolve();
    };

    if (allDone) {
      allDone.then(() => {
        done();
      });
    } else {
      await done();
    }
  }

  override export() {
    const exportData = super.export();

    exportData['artwork'] = this.artwork;

    exportData['labelType'] = this.labelType;
    exportData['background'] = this.background;
    exportData['template'] = this.template;
    exportData['labelSize'] = this.labelSize;

    exportData['frontFont'] = this.frontFont;
    exportData['frontColor'] = this.frontColor;
    exportData['frontText1'] = this.frontText1;
    exportData['frontText2'] = this.frontText2;

    exportData['backFont'] = this.backFont;
    exportData['backColor'] = this.backColor;
    exportData['backText1'] = this.backText1;
    exportData['backText2'] = this.backText2;
    exportData['backText3'] = this.backText3;

    exportData['skipArt'] = this.skipArt;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }

  labelsItem?: CustomLabelOrderItem;
  setupItem?: CustomLabelOrderItem;

  public checkItems(): Promise<void> {
    //TODO bug this re-adds too many times appearently ???
    const deferred = new Deferred<void>();

    let allPromises: Promise<unknown>[] = [];
    if (this.labelType !== null) {
      this.labelsItem = undefined;
      this.setupItem = undefined;

      let totalLabels: number = 0;

      this.freeze();

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        item.taxes = this.GST_HST;
        if (item.key === 'labels') {
          item.freightExempt = true;
          this.labelsItem = item;
          this.items.splice(i, 1);
          if (this.labelType !== LabelType.BarWrapper && this.labelsItem && this.labelsItem.quantity) {
            totalLabels = this.labelsItem.quantity;
          }
          i--;
        } else if (item.key === 'setup') {
          item.freightExempt = true;
          this.setupItem = item;
          this.items.splice(i, 1);
          i--;
        } else if (this.labelType !== LabelType.BarWrapper) {
          if (item.itemID) {
            allPromises.push(this.removeItem(item));
          } else {
            allPromises.push(this._removeItem(item));
            i--;
          }
        } else {
          item.priceDiscountable = Discountable.Enabled;
          totalLabels += item.quantity || 0;
        }
      }

      const count = this.items.length;

      if (!this.labelsItem) {
        this.labelsItem = new CustomLabelOrderItem(this, {
          key: 'labels',
          quantity: null,
          price: '1.25',
          freightExempt: true,
          typeID: null,
          foilSubType: null,
          taxes: this.GST_HST,
        });
        if (this.setupItem) {
          this._addItem(this.labelsItem, this.items.indexOf(this.setupItem));
        } else {
          this._addItem(this.labelsItem);
        }
      } else {
        this.items.push(this.labelsItem);
      }
      const labelsOrig = this.labelsItem.quantity;
      this.labelsItem.quantity = totalLabels || null;
      if ((labelsOrig || this.labelsItem.quantity) && labelsOrig !== this.labelsItem.quantity) {
        allPromises.push(this.saveItem(this.labelsItem, 'quantity', ProcessRequests.Pool));
      }
      this.adjustLabelPrice();

      if (!this.setupItem) {
        this.setupItem = new CustomLabelOrderItem(this, {
          key: 'setup',
          quantity: null,
          price: '15',
          freightExempt: true,
          typeID: null,
          foilSubType: null,
          taxes: this.GST_HST,
        });
        this._addItem(this.setupItem);
      } else {
        this.items.push(this.setupItem);
      }
      const setupOrig = this.setupItem.quantity;
      this.setupItem.quantity = totalLabels > 99 ? 0 : 1;
      if ((setupOrig || this.setupItem.quantity) && setupOrig !== this.setupItem.quantity) {
        allPromises.push(this.saveItem(this.setupItem, 'quantity', ProcessRequests.Pool));
      }

      if (this.labelType === LabelType.BarWrapper) {
        if (count < 1) {
          const item = this.newOrderItem();
          item.productDiscountable = Discountable.Enabled;

          let index: number | null = null;
          if (this.labelsItem) {
            index = this.items.indexOf(this.labelsItem);
          } else if (this.setupItem) {
            index = this.items.indexOf(this.setupItem);
          }

          this._addItem(item, index);
        }
      }
    }

    this.dataLayer.processRequests();

    Promise.all(allPromises).then(() => {
      allPromises = [];

      //TODO this might be duplicated logic
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (item.key === 'setup' && i !== this.items.length - 1) {
          this.items.splice(i, 1);
          this.items.splice(this.items.length, 0, item);
          i--;
        } else if (item.key === 'labels' && i !== this.items.length - 2) {
          this.items.splice(i, 1);
          this.items.splice(this.items.length - 1, 0, item);
          i--;
        }
      }

      this.unfreeze();

      this.calculate().then(() => {
        deferred.resolve();
      });
    });

    return deferred.promise;
  }

  public adjustLabelPrice(): void {
    if (this.labelsItem) {
      if (this.labelType === LabelType.BarWrapper) {
        if (this.labelsItem.quantity === null || this.labelsItem.quantity < 50) {
          this.labelsItem.price = new Big('1.25');
        } else if (this.labelsItem.quantity < 100) {
          this.labelsItem.price = new Big('1.00');
        } else if (this.labelsItem.quantity < 250) {
          this.labelsItem.price = new Big('0.75');
        } else {
          this.labelsItem.price = new Big('0.55');
        }
      } else {
        if (this.labelSize === LabelSize['1 1/2']) {
          if (this.labelsItem.quantity === null || this.labelsItem.quantity < 50) {
            this.labelsItem.price = new Big('0.27');
          } else if (this.labelsItem.quantity < 100) {
            this.labelsItem.price = new Big('0.22');
          } else if (this.labelsItem.quantity < 250) {
            this.labelsItem.price = new Big('0.16');
          } else {
            this.labelsItem.price = new Big('0.12');
          }
        } else if (this.labelSize === LabelSize['2 1/2'] || this.labelSize === null) {
          if (this.labelsItem.quantity === null || this.labelsItem.quantity < 50) {
            this.labelsItem.price = new Big('0.51');
          } else if (this.labelsItem.quantity < 100) {
            this.labelsItem.price = new Big('0.41');
          } else if (this.labelsItem.quantity < 250) {
            this.labelsItem.price = new Big('0.30');
          } else {
            this.labelsItem.price = new Big('0.23');
          }
        } else if (this.labelSize === LabelSize['2 x 3']) {
          if (this.labelsItem.quantity === null || this.labelsItem.quantity < 50) {
            this.labelsItem.price = new Big('0.54');
          } else if (this.labelsItem.quantity < 100) {
            this.labelsItem.price = new Big('0.43');
          } else if (this.labelsItem.quantity < 250) {
            this.labelsItem.price = new Big('0.32');
          } else {
            this.labelsItem.price = new Big('0.24');
          }
        } else {
          this.labelsItem.price = null;
        }
      }
    }
  }

  //protected async preCalc(): Promise<void>

  //protected async calcFreight(subtotal: Big, itemDiscount: Big, saleTotal: Big): Promise<void>

  //protected async calcLocation(): Promise<void>;

  //protected async postCalc(): Promise<void>

  //protected async getDiscounts(discountSubtotals: DiscountSubtotals): Promise<void>

  //refreshed(order: APICartOrder): Promise<unknown>

  protected newOrderItem(): CustomLabelOrderItem {
    const key = md5(new Date().getTime().toString() + Math.random()).toString();

    const item = new CustomLabelOrderItem(this, {
      key: key,
      quantity: null,
      price: '3.75',
      typeID: null,
      foilSubType: null,
      taxes: this.GST_HST,
    });
    return item;
  }

  public addNewItem(): void {
    const item = this.newOrderItem();
    item.productDiscountable = Discountable.Enabled;

    this.items.push(item);

    this.checkItems();
  }
}
