import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalErrorHandler } from '../../services/globalErrorHandler';
import { Subscription } from 'rxjs';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'ccf-error-alert',
  templateUrl: 'error.component.html',
  styleUrls: ['error.component.less'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  // TODO detect failure after refreshing

  hasErrored: boolean = false;
  hasUpdated: boolean = false;

  constructor(
    protected errorHandler: GlobalErrorHandler,
    protected apiService: APIService,
  ) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this.errorHandler.errorsStream().subscribe(() => {
        this.hasErrored = true;
        // TODO add more
      }),
    );

    this._subscriptions.push(
      this.apiService.statStream.subscribe((value) => {
        if (value) {
          this.hasUpdated = true;
          // TODO add more
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  refresh(): void {
    self.location.reload();
  }

  dismiss(): void {
    this.hasUpdated = false;
  }
}
