import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Deferred } from '../helpers/deferred';
import { MessageAudience, CCFMessage } from '../models/messages';
import { AccountType } from '../models/account';

export interface MessagesFilter {
  audience?: MessageAudience;
  dateFrom?: string;
  dateTo?: string;
  subject?: string;

  page?: number;
  items: number;
}

export interface MessageResponse {
  userID?: number;
  username?: string;
  orderID?: number;
  seen: boolean;
  viewed: boolean;
}

export interface UserMessage {
  messageID: number;
  subject: string;
  content: string;
  seen: boolean;
  viewed: boolean;
  sent: Date;
}

export interface MessageSaveData {
  messageID: number | null;
  audiences: MessageAudience[];
  subject: string;
  sent: string;
  expire: string | null;
  content: string;
  makeAsNew: boolean;
}

export interface CCFMessageDataDump<Message = CCFMessage> {
  messages: Message[];

  total: number;
  perPage: number;
}

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  protected CACHE_TIME: number = 10 * 60 * 1000; //10 mins
  protected cacheTime_myMessages: number = 0;
  protected myMessages: UserMessage[] = [];

  protected specialMessages: CCFMessage[] = [];
  protected specialMessages_cacheTime: number = 0;
  protected CACHE_TIME_specialMessages: number = 10 * 1000; //1 * 60 * 60 * 1000; //1 hour

  constructor(private APIService: APIService) {}

  expireCache() {
    this.cacheTime_myMessages = 0;
    this.specialMessages_cacheTime = 0;
  }

  get(filter: MessagesFilter) {
    const deferred = new Deferred<CCFMessageDataDump>();

    this.APIService.queueRequest<CCFMessageDataDump>({
      endPoint: 'messages',
      method: 'get',
      data: {
        filter: filter,
      },
    })
      .then((data: CCFMessageDataDump) => {
        for (const message of data.messages) {
          message.sent = new Date(message.sent);
          if (message.expire) {
            message.expire = new Date(message.expire);
          }
        }
        deferred.resolve(data);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  save(message: MessageSaveData) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean | string>({
      endPoint: 'messages',
      method: 'save',
      data: {
        message: message,
      },
    })
      .then((result: boolean | string) => {
        if (result === true) {
          deferred.resolve(result);
        } else {
          deferred.reject(result);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  getResponses(messageID: number) {
    const deferred = new Deferred<MessageResponse[]>();

    this.APIService.queueRequest<MessageResponse[]>({
      endPoint: 'messages',
      method: 'getResponses',
      data: {
        messageID: messageID,
      },
    })
      .then((result: MessageResponse[]) => {
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    this.APIService.processRequests();

    return deferred.promise;
  }

  loadSpecial() {
    const deferred = new Deferred<CCFMessage[]>();

    if (
      this.specialMessages_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME_specialMessages) >
      new Date().getTime()
    ) {
      deferred.resolve(this.specialMessages);
    } else {
      this.APIService.queueRequest<CCFMessage[]>({
        endPoint: 'messages',
        method: 'getSpecialMessages',
      })
        .then((messages: CCFMessage[]) => {
          this.specialMessages_cacheTime = new Date().getTime();

          this.specialMessages = messages;
          deferred.resolve(this.specialMessages);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  getMessagesForMe() {
    const deferred = new Deferred<UserMessage[]>();

    if (this.cacheTime_myMessages + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime()) {
      deferred.resolve(this.myMessages);
    } else {
      let type: AccountType | null = null;
      const stream = this.APIService.authenticatedDataStream();
      if (stream.value) {
        type = stream.value.accountType;
      }

      this.APIService.queueRequest<UserMessage[]>({
        endPoint: 'messages',
        method: 'getMessagesForMe',
        data: {
          type: type,
        },
      })
        .then((messages: UserMessage[]) => {
          this.cacheTime_myMessages = new Date().getTime();

          this.myMessages = messages;
          deferred.resolve(this.myMessages);
        })
        .catch((reason: unknown) => {
          deferred.reject(reason);
          //TODO should do something globally ?
        });
    }

    return deferred.promise;
  }

  setMessagesSeen(messages: UserMessage[]) {
    const deferred = new Deferred<boolean>();

    const messageIDs: number[] = [];
    for (const message of messages) {
      messageIDs.push(message.messageID);
    }

    this.APIService.queueRequest<boolean>({
      endPoint: 'messages',
      method: 'markAsSeen',
      data: {
        messageIDs: messageIDs,
      },
    })
      .then((result: boolean) => {
        if (result === true) {
          for (const message of messages) {
            message.seen = true;
          }
        }

        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  setMessagesViewed(messages: UserMessage[], viewed: boolean) {
    const deferred = new Deferred<boolean>();

    const messageIDs: number[] = [];
    for (const message of messages) {
      messageIDs.push(message.messageID);
    }

    this.APIService.queueRequest<boolean>({
      endPoint: 'messages',
      method: 'markAsViewed',
      data: {
        messageIDs: messageIDs,
        viewed: viewed,
      },
    })
      .then((result: boolean) => {
        if (result === true) {
          for (const message of messages) {
            message.viewed = viewed;
          }
        }

        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  remove(message: CCFMessage) {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'messages',
      method: 'remove',
      data: {
        messageID: message.messageID,
      },
    })
      .then((result: boolean) => {
        if (result) {
          deferred.resolve(result);
        } else {
          deferred.reject('Remove failed');
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
