<div class="pageContent">
  <div *ngIf="loggedIn">You are logged in.</div>
  <div *ngIf="!loggedIn">
    <div
      class="prompt"
      [hidden]="!params.message"
      [innerHTML]="params.message"
    ></div>

    <div
      class="field"
      [class.validationError]="invalid['username']"
    >
      <div class="label">Username</div>
      <div class="entry">
        <input
          class="charliesInput"
          name="username"
          [(ngModel)]="username"
          (change)="revalidate()"
        />
        <div class="validationMessage">{{ invalid['username'] }}</div>
      </div>
    </div>

    <div
      class="field"
      [class.validationError]="invalid['password']"
    >
      <div class="label">Password</div>
      <div class="entry">
        <input
          class="charliesInput"
          name="password"
          [(ngModel)]="password"
          (change)="revalidate()"
          type="password"
        />
        <div class="validationMessage">{{ invalid['password'] }}</div>
      </div>
    </div>

    <div class="error">{{ message }}</div>

    <button
      type="button"
      class="no"
      (click)="no()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="yes"
      (click)="yes()"
    >
      Login
    </button>
  </div>
</div>
