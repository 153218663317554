import { Big } from 'big.js';

import { FreightLookupData } from '../../services/freight.service';
import { OrderType, DeliveryMethod, PaymentType, APICartOrder } from '../checkout';

import { CartOrderItem } from '../orderItem/cart';
import { BaseOrder, OrderResponseObject } from './base';
import { Deferred } from '../../helpers/deferred';

export interface ItemFilter {
  productID?: number[];
  unitID?: number[];
  typeID?: number[];
}

export class CartOrder extends BaseOrder<CartOrderItem> implements APICartOrder<Big> {
  type = OrderType.Cart as const;

  override reset(): void {
    super.reset();
  }

  override async parse(
    data: OrderResponseObject<APICartOrder>,
    calledFrom: string,
    allDone?: Promise<void>,
  ): Promise<void> {
    const doneHere = new Deferred<void>();

    await super.parse(data, calledFrom, doneHere.promise);

    this.freeze();

    //OrderType specific

    this.unfreeze();

    const done = async () => {
      await this.calculate();

      doneHere.resolve();
    };

    if (allDone) {
      allDone.then(() => {
        done();
      });
    } else {
      await done();
    }
  }

  //export function not needed at this time

  hasMax: boolean = false;
  protected override async preCalc(): Promise<void> {
    this.hasMax = false;
    for (const item of this.items) {
      if (item.minMax !== null && item.minMax >= 0) {
        this.hasMax = true;
        break;
      }
    }
  }

  protected override async calcFreight(subtotal: Big, itemDiscount: Big, saleTotal: Big): Promise<void> {
    const allPromises: Promise<unknown>[] = [];

    if (
      this.payment !== PaymentType.TooLarge &&
      !this.isWholesale &&
      this.deliveryInfo.method === DeliveryMethod.Ship &&
      this.deliveryInfo.postalCode &&
      this.deliveryInfo.country === 'Canada'
    ) {
      const freightSubtotal = subtotal.minus(itemDiscount).plus(saleTotal); //This is correct
      allPromises.push(
        this.dataLayer.freight
          .lookup(freightSubtotal, this.deliveryInfo.postalCode)
          .then((freight: FreightLookupData | null) => {
            if (freight) {
              this.totalFreight = freight.amount.round(2);
              this.freightTaxRate = freight.tax;
              this.freightTaxName = freight.taxName;
            }
          }),
      );

      allPromises.push(
        this.dataLayer.freight.lookupTime(this.deliveryInfo.postalCode).then((shippingTime: string | null) => {
          if (shippingTime) {
            this.shippingTime = shippingTime;
          }
        }),
      );
    }
    this.dataLayer.processRequests();
    await Promise.all(allPromises);
  }

  //protected async calcLocation(): Promise<void>;

  //protected async postCalc(): Promise<void>

  //protected async getDiscounts(discountSubtotals: DiscountSubtotals): Promise<void>

  override updateEstimation(): void {
    if (this.isWholesale) {
      this.isEstimated = true;
    } else {
      super.updateEstimation();
    }
  }

  protected newOrderItem(): CartOrderItem {
    return new CartOrderItem(this);
  }

  countItems(filter: ItemFilter): number {
    let total: number = 0;
    for (const item of this.items) {
      const match: boolean = !!(
        (!filter.productID ||
          (filter.productID && item.productID && filter.productID.indexOf(item.productID) !== -1)) &&
        (!filter.unitID || (filter.unitID && item.unitID && filter.unitID.indexOf(item.unitID) !== -1)) &&
        (!filter.typeID || (filter.typeID && item.typeID && filter.typeID.indexOf(item.typeID) !== -1))
      );

      if (match && item.quantity) {
        total += item.quantity;
      }
    }

    return total;
  }
}
