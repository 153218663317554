import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Category } from '../models/category';
import { Deferred } from '../helpers/deferred';
import { SectionsService } from './sections.service';
import { Discountable } from '../models/product';
import { GlobalErrorHandler } from './globalErrorHandler';

export interface CategorySaveData {
  categoryID: number | null;
  sectionID: number;
  categoryName: string;
  manualOrder: boolean;
  discountable: Discountable;
  urlName: string | null;
  parentCategory: number | null;
  description: string;
  noSearch: boolean;
  hide: boolean;
  hideSeeAll: boolean;
  footer: boolean;
  madeToOrder: boolean;
}

export interface CategoryCompare {
  total: number;
  similar: number;
  extra: number;
}

export interface ProductIDOnly {
  productID: number;
}

export interface RawCompareData {
  base: Array<ProductIDOnly>;
  with: Array<ProductIDOnly>;
}

export type CompareAction = 'Report' | 'Add' | 'Remove';

const sortCategories = (a: Category, b: Category): number => {
  return a.order - b.order;
};

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  protected CACHE_TIME: number = 20 * 60 * 1000; //20 Minutes
  protected categories: { [categoryID: number]: Category } = {};
  protected categoriesByURI: { [sectionURI_categoryURI: string]: Category } = {};
  protected sections: { [sectionID: number]: Array<Category> } = {};
  protected sectionsByURI: { [sectionURI: string]: Array<Category> } = {};
  protected sectionsByName: { [sectionName: string]: Array<Category> } = {};

  protected allCategories: Array<Category> = [];
  protected allCategories_cacheTime: number = 0;

  public hasData: boolean = false;
  public cacheValid(): boolean {
    return this.allCategories_cacheTime + this.APIService.getCacheTime(this.CACHE_TIME) > new Date().getTime();
  }

  constructor(
    private APIService: APIService,
    private SectionsService: SectionsService,
    private GlobalErrorHandler: GlobalErrorHandler,
  ) {}

  expireCache(): void {
    this.allCategories_cacheTime = 0;
  }

  private cacheCategory(category: Category, sectionURI?: string): Category {
    if (typeof this.categories[category.categoryID] === 'undefined') {
      this.categories[category.categoryID] = category;
    } else if (category !== this.categories[category.categoryID]) {
      const cateRef = this.categories[category.categoryID];
      for (const prop in cateRef) {
        if (Object.prototype.hasOwnProperty.call(cateRef, prop) && prop !== 'section') {
          //this property is not set by this service atm so i skip it
          delete cateRef[prop as keyof Category]; //TODO better typing
        }
      }
      Object.assign(cateRef, category);
      category = cateRef;
    }

    if (typeof sectionURI !== 'undefined' && category.urlName) {
      this.categoriesByURI[sectionURI.toLowerCase() + '_' + category.urlName.toLowerCase()] = category;
    }

    return this.categories[category.categoryID];
  }

  private mapChildren(): void {
    for (const categoryID in this.categories) {
      if (Object.prototype.hasOwnProperty.call(this.categories, categoryID)) {
        const category = this.categories[categoryID];
        category.parent = null;
        category.children = [];
      }
    }

    const keys = Object.keys(this.categories).map((str: string) => {
      return parseInt(str, 10);
    });
    keys.sort((a: number, b: number) => {
      const aCategory = this.categories[a];
      const bCategory = this.categories[b];
      if (aCategory.order === bCategory.order) {
        return a - b;
      } else {
        return aCategory.order - bCategory.order;
      }
    });

    for (const categoryID of keys) {
      if (Object.prototype.hasOwnProperty.call(this.categories, categoryID)) {
        const category = this.categories[categoryID];
        if (category.parentCategory) {
          category.parent = this.categories[category.parentCategory];
          if (typeof category.parent.children === 'undefined') {
            category.parent.children = [];
          }
          category.parent.children.push(category);
        }
      }
    }
  }

  private removeOldURIs(): void {
    for (const key in this.categoriesByURI) {
      /* istanbul ignore else */
      if (Object.prototype.hasOwnProperty.call(this.categoriesByURI, key)) {
        const category = this.categoriesByURI[key];
        const category_key = key.split('_', 2)[1];
        if (category.urlName === null || category.urlName.toLowerCase() !== category_key) {
          delete this.categoriesByURI[key];
        }
      }
    }
  }

  private inflight_reloadCategories?: Deferred<void>;
  private reloadCategories(): Promise<unknown> {
    if (this.inflight_reloadCategories) {
      return this.inflight_reloadCategories.promise;
    }
    const deferred = (this.inflight_reloadCategories = new Deferred<void>());

    this.APIService.queueRequest<Array<Category>>({
      endPoint: 'categories',
      method: 'all',
      uniqueKey: '',
    })
      .then(async (categories: Array<Category>) => {
        //TODO should this reference been maintained ?

        this.categoriesByURI = {};
        this.sections = {};
        this.sectionsByURI = {};
        this.sectionsByName = {};
        this.allCategories = [];

        const sections = this.SectionsService.all();
        this.APIService.processRequests();
        for (const section of await sections) {
          const categoryArray = (this.sectionsByName[section.sectionName] = this.sections[section.sectionID] = []);
          if (section.urlName) {
            this.sectionsByURI[section.urlName] = categoryArray;
          }
        }

        this.allCategories_cacheTime = new Date().getTime();

        for (let i = 0; i < categories.length; i++) {
          const section = this.SectionsService.byIDSync(categories[i].sectionID);
          const category = (categories[i] = this.cacheCategory(
            categories[i],
            section && section.urlName ? section.urlName : undefined,
          ));

          if (section) {
            //TODO should be error here
            this.sections[section.sectionID].push(category);
          }
          this.allCategories.push(category);
        }

        for (const section of await sections) {
          this.sections[section.sectionID].sort(sortCategories);
        }

        this.removeOldURIs();
        this.mapChildren();

        this.hasData = true;
        deferred.resolve();
        delete this.inflight_reloadCategories;
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        delete this.inflight_reloadCategories;
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  all(): Promise<Array<Category>> {
    const deferred = new Deferred<Array<Category>>();

    if (this.cacheValid()) {
      deferred.resolve(this.allCategories);
    } else {
      this.reloadCategories()
        .then(() => {
          deferred.resolve(this.allCategories);
        })
        .catch((reason: string) => {
          deferred.reject(reason);
        });
    }

    return deferred.promise;
  }

  byURI(sectionURI: string, categoryURI: string): Promise<Category> {
    const deferred = new Deferred<Category>();

    const key = sectionURI.toLowerCase() + '_' + categoryURI.toLowerCase();

    if (this.cacheValid()) {
      if (typeof this.categoriesByURI[key] === 'undefined') {
        deferred.reject(`Category Not Found byURI (sectionURI: ${sectionURI}, categoryURI: ${categoryURI})`);
      } else {
        deferred.resolve(this.categoriesByURI[key]);
      }
    } else {
      this.reloadCategories()
        .then(() => {
          if (typeof this.categoriesByURI[key] === 'undefined') {
            deferred.reject(
              `Category Not Found reloadCategories (sectionURI: ${sectionURI}, categoryURI: ${categoryURI})`,
            );
          } else {
            deferred.resolve(this.categoriesByURI[key]);
          }
        })
        .catch((reason: string) => {
          deferred.reject(reason);
        });
    }

    return deferred.promise;
  }

  byIDSync(categoryID: number): Category | undefined {
    if (!this.hasData) {
      this.GlobalErrorHandler.reportError(
        new Error(`CategoriesService.byIDSync(${categoryID}) called before any data is loaded`),
      );
    }
    if (typeof this.categories[categoryID] === 'undefined') {
      this.GlobalErrorHandler.reportError(
        new Error(`CategoriesService.byIDSync(${categoryID}) did not find a category`),
        JSON.stringify(Object.keys(this.categories)),
        true,
      );
    }

    return this.categories[categoryID];
  }

  byID(categoryID: number): Promise<Category> {
    const deferred = new Deferred<Category>();

    if (this.cacheValid()) {
      if (typeof this.categories[categoryID] === 'undefined') {
        deferred.reject(new Error(`Category Not Found byID (categoryID: ${categoryID})`));
      } else {
        deferred.resolve(this.categories[categoryID]);
      }
    } else {
      this.reloadCategories()
        .then(() => {
          if (typeof this.categories[categoryID] === 'undefined') {
            deferred.reject(new Error(`Category Not Found byID-reload (categoryID: ${categoryID})`));
          } else {
            deferred.resolve(this.categories[categoryID]);
          }
        })
        .catch((reason: Error) => {
          deferred.reject(reason);
        });
    }

    return deferred.promise;
  }

  bySectionID(sectionID: number): Promise<Array<Category>> {
    const deferred = new Deferred<Array<Category>>();

    if (this.cacheValid()) {
      if (typeof this.sections[sectionID] === 'undefined') {
        deferred.reject(`CategoriesService.bySectionID(${sectionID}) - sectionID not found`);
      } else {
        deferred.resolve(this.sections[sectionID]);
      }
    } else {
      this.reloadCategories()
        .then(() => {
          if (typeof this.sections[sectionID] === 'undefined') {
            deferred.reject(`CategoriesService.bySectionID(${sectionID}) - sectionID not found after reload`);
          } else {
            deferred.resolve(this.sections[sectionID]);
          }
        })
        .catch((reason: string) => {
          deferred.reject(reason);
        });
    }

    return deferred.promise;
  }

  bySectionURI(sectionURI: string): Promise<Array<Category>> {
    const deferred = new Deferred<Array<Category>>();

    if (this.cacheValid()) {
      if (typeof this.sectionsByURI[sectionURI] === 'undefined') {
        deferred.reject(`CategoriesService.bySectionURI(${sectionURI}) - sectionURI not found`);
      } else {
        deferred.resolve(this.sectionsByURI[sectionURI]);
      }
    } else {
      this.reloadCategories()
        .then(() => {
          if (typeof this.sectionsByURI[sectionURI] === 'undefined') {
            deferred.reject(`CategoriesService.bySectionURI(${sectionURI}) - sectionURI not found after reload`);
          } else {
            deferred.resolve(this.sectionsByURI[sectionURI]);
          }
        })
        .catch((reason: string) => {
          deferred.reject(reason);
        });
    }

    return deferred.promise;
  }

  bySectionName(sectionName: string): Promise<Array<Category>> {
    const deferred = new Deferred<Array<Category>>();

    if (this.cacheValid()) {
      if (typeof this.sectionsByName[sectionName] === 'undefined') {
        deferred.reject(`CategoriesService.bySectionName(${sectionName}) - sectionName not found`);
      } else {
        deferred.resolve(this.sectionsByName[sectionName]);
      }
    } else {
      this.reloadCategories()
        .then(() => {
          if (typeof this.sectionsByName[sectionName] === 'undefined') {
            deferred.reject(`CategoriesService.bySectionName(${sectionName}) - sectionName not found after reload`);
          } else {
            deferred.resolve(this.sectionsByName[sectionName]);
          }
        })
        .catch((reason: string) => {
          deferred.reject(reason);
        });
    }

    return deferred.promise;
  }

  save(method: 'create' | 'update', data: CategorySaveData): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'categories',
      method: method,
      data: data,
    })
      .then((result: boolean) => {
        this.expireCache();
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  delete(category: Category): Promise<void> {
    const deferred = new Deferred<void>();

    this.APIService.queueRequest({
      endPoint: 'categories',
      method: 'delete',
      data: {
        categoryID: category.categoryID,
      },
    })
      .then(() => {
        this.expireCache();
        deferred.resolve();
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  compare(baseCategoryID: number, withCategoryID: number, action: CompareAction = 'Report'): Promise<CategoryCompare> {
    const deferred = new Deferred<CategoryCompare>();

    this.APIService.queueRequest<RawCompareData | false | string>({
      endPoint: 'categories',
      method: 'compare',
      data: {
        baseCategoryID: baseCategoryID,
        withCategoryID: withCategoryID,
        action: action,
      },
    })
      .then((rawCompareData: RawCompareData | false | string) => {
        if (rawCompareData === false) {
          deferred.reject('FAILED');
        } else if (typeof rawCompareData === 'string') {
          deferred.reject(rawCompareData);
        } else {
          const compareData = {
            total: rawCompareData.with.length,
            extra: 0,
            similar: 0,
          };

          const baseItems: Array<number> = [];
          for (const item of rawCompareData.base) {
            baseItems.push(item.productID);
          }

          for (const item of rawCompareData.with) {
            const productID = item.productID;

            if (baseItems.indexOf(productID) !== -1) {
              compareData.similar++;
            } else {
              compareData.extra++;
            }
          }

          deferred.resolve(compareData);
        }
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }

  reorder(category: Category, before: Category | null, after: Category | null): Promise<boolean> {
    const deferred = new Deferred<boolean>();

    this.APIService.queueRequest<boolean>({
      endPoint: 'categories',
      method: 'reorder',
      data: {
        categoryID: category.categoryID,
        before: before ? before.categoryID : null,
        after: after ? after.categoryID : null,
        sectionID: category.sectionID,
      },
    })
      .then((result: boolean) => {
        this.expireCache();
        deferred.resolve(result);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
        //TODO should do something globally ?
      });

    return deferred.promise;
  }
}
