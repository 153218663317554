export const isElementInViewport = (el: Element, percent: number = 0): boolean => {
  const rect = el.getBoundingClientRect();
  const height =
    window.innerHeight ||
    (typeof document.documentElement !== 'undefined' && document.documentElement.clientHeight) ||
    0;
  const width =
    window.innerWidth || (typeof document.documentElement !== 'undefined' && document.documentElement.clientWidth) || 0;

  const heightPercent = (percent / 100.0) * (rect.bottom - rect.top);
  const widthPercent = (percent / 100.0) * (rect.right - rect.left);

  return !(
    rect.bottom - heightPercent < 0 ||
    rect.right - widthPercent < 0 ||
    rect.top + heightPercent > height ||
    rect.left + widthPercent > width
  );
};
