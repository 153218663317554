import { Big } from 'big.js';
import { APICustomLabelItem } from '../checkout';
import { BaseOrderItem } from './base';
import { CustomLabelOrder } from '../order/customLabel';
import { cloneDeep } from 'lodash-es';

export class CustomLabelOrderItem extends BaseOrderItem implements APICustomLabelItem<Big> {
  foilSubType: string | null = null;
  typeID: number | null = null;
  typeName: string | null = null;
  freightExempt: boolean = false;

  constructor(
    protected override parent: CustomLabelOrder,
    protected override data?: APICustomLabelItem,
  ) {
    super(parent, data);

    if (data) {
      this.refreshed(data);
    }
  }

  override refreshed(item: APICustomLabelItem): void {
    if (typeof item.foilSubType !== 'undefined') {
      this.foilSubType = item.foilSubType;
    }
    if (typeof item.typeID !== 'undefined') {
      this.typeID = item.typeID;

      if (typeof item.typeName !== 'undefined') {
        this.typeName = item.typeName;
      }
    }

    if (typeof item.foilSubType !== 'undefined') {
      this.foilSubType = item.foilSubType;
    }
    if (typeof item.freightExempt !== 'undefined') {
      this.freightExempt = item.freightExempt;
    }

    if (typeof item.price !== 'undefined') {
      this.price = item.price ? new Big(item.price) : null;
    }
    if (typeof item.taxes !== 'undefined') {
      this.taxes = item.taxes;
    }
    if (typeof item.productDiscountable !== 'undefined') {
      this.productDiscountable = item.productDiscountable;
    }

    super.refreshed(item);
  }

  override export() {
    const exportData = super.export();

    exportData['foilSubType'] = this.foilSubType;
    exportData['typeID'] = this.typeID;

    exportData['typeName'] = this.typeName;

    exportData['foilSubType'] = this.foilSubType;
    exportData['freightExempt'] = this.freightExempt;

    exportData['price'] = this.price ? new Big(this.price).toFixed(2) : null;

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date
  }
}
