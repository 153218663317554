import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';

import { DirectivesModule } from 'src/common/directives';
import { PipesModule } from 'src/common/pipes';
import { DialogsModule } from 'src/common/helpers/dialogs';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactInfoComponent } from './checkout/contactInfo.component';
import { DeliveryComponent } from './checkout/delivery.component';
import { PaymentComponent } from './checkout/payment.component';
import { PayPalComponent } from './checkout/paypal.component';
import { CartSummaryComponent } from './cart/summary.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ProductComponent } from './products/subcomponents/product.component';
import { ProductAddComponent } from './products/subcomponents/productAdd.component';
import { PageNotFoundComponent } from './error/pageNotFound.component';
import { CustomerMenuComponent } from './customer/menu/menu.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryDialogComponent } from './gallery/galleryDialog.component';
import { DialogService } from 'src/common/helpers/dialogs/dialog.service';
import { AddItemComponent } from './customer/orders/addItem.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    HttpClientModule,
    DialogsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,

    RouterModule.forChild([]),
  ],
  declarations: [
    CheckoutComponent,
    ContactInfoComponent,
    DeliveryComponent,
    PaymentComponent,
    PayPalComponent,

    PageNotFoundComponent,

    CartSummaryComponent,
    BreadcrumbsComponent,
    ProductComponent,
    ProductAddComponent,

    GalleryComponent,
    GalleryDialogComponent,

    CustomerMenuComponent,

    AddItemComponent,
  ],
  providers: [MatDatepickerModule, DialogService],
  exports: [
    //imports
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    HttpClientModule,
    DialogsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    RouterModule,

    //declarations
    CheckoutComponent,
    ContactInfoComponent,
    DeliveryComponent,
    PaymentComponent,
    PayPalComponent,
    PageNotFoundComponent,
    CartSummaryComponent,
    BreadcrumbsComponent,
    ProductComponent,
    ProductAddComponent,
    CustomerMenuComponent,
    GalleryComponent,
    GalleryDialogComponent,
    AddItemComponent,
  ],
})
export class SharedModule {}
