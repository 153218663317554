import { Big } from 'big.js';

import { BaseOrderItem } from '../orderItem/base';

import { Deferred } from '../../helpers/deferred';
import { getProp } from '../../helpers/getProp';
import { clear } from '../../helpers/clearIt';

import {
  DatabaseOrder,
  DatabaseItem,
  OrderType,
  OrderStatus,
  DeliveryMethod,
  PaymentType,
  ContactInfo,
  DeliveryInfo,
  OrderFile,
  EmailedAudit,
} from '../checkout';

import { RealLocation } from '../tax';
import { DiscountEffect, DiscountTarget, Discount } from '../discount';
import { Subject } from 'rxjs';
import { Validation } from '../validation';
import { DataLayer, ProcessRequests } from '../../dataLayer';
import { OrderAdvanceData, SQLRawItem, SQLRawFile, SQLVolumeDiscount } from '../../../common/dataLayer/orders';
import { CorpLogo } from '../corpLogo';
import { cloneDeep } from 'lodash-es';
import { TypedError } from '../typedError';

export type OrderResponse<T extends DatabaseOrder> = OrderResponseObject<T> | OrderResponseError;

export interface OrderResponseError {
  message: string;
}

export interface OrderResponseObject<T extends DatabaseOrder> {
  outcome?: string;
  item?: SQLRawItem;
  file?: SQLRawFile;
  logo?: CorpLogo;
  order?: T;
  ticket?: string;
  reset?: boolean;
  outofdate?: boolean;
}

export type DiscountSubtotals = {
  [index in DiscountTarget]: Big;
};

export abstract class BaseOrder<T extends BaseOrderItem> implements DatabaseOrder {
  orderID!: number;
  ticket!: string;
  lastActive: number = 0;

  isPossessed: boolean = false;

  abstract type: OrderType;
  locationID: number | null = null;

  contactInfo: ContactInfo = {
    invalid: {},
  };
  deliveryInfo: DeliveryInfo = {
    country: 'Canada',
    invalid: {},
  };
  gaClientID?: string;
  additionalInformation?: string;

  status: OrderStatus = OrderStatus.NotUsed;
  statusTime: number = new Date().getTime();

  emailedAudit: EmailedAudit[] = [];

  payment: PaymentType | null = null;

  invalid: Validation;

  subtotal?: Big | null;
  saleTotal?: Big | null;
  totalCost?: Big | null;
  totalTax?: Big | null;

  totalFreight?: Big | null;
  freightTaxRate?: Big;
  freightTaxName?: string;

  shippingTime?: string;

  hasCasePack?: boolean = false;
  casePackMessage: string = '(Not Including Case Items)';

  hasFragile?: boolean = false;
  USAUnderstood?: boolean = false;
  isEstimated: boolean = true;

  //TODO promo
  //discountCode?: string;
  //discount: APIDiscount | null = null;
  totalDiscount?: Big;
  discountedSubTotal?: Big;

  discounts: Array<Discount> = [];

  taxSums: { [taxName: string]: Big } = {};

  items: Array<T> = [];
  itemsByKey: { [id: string]: T } = {};

  forceTBD: boolean = false;

  isWholesale: boolean = false;

  public key: string = '';

  constructor(protected dataLayer: DataLayer) {
    this.invalid = {};
    this.deliveryInfo.invalid = this.contactInfo.invalid = this.invalid;
  }

  //TODO
  /*public export(filter?: string[]): any {

  }*/

  export(): Record<string, unknown> {
    const exportData: Record<string, unknown> = {};

    exportData['key'] = this.key;
    exportData['orderID'] = this.orderID;
    exportData['type'] = this.type;
    exportData['lastActive'] = this.lastActive;

    exportData['contactInfo'] = this.contactInfo;
    exportData['deliveryInfo'] = this.deliveryInfo;

    exportData['additionalInformation'] = this.additionalInformation;
    exportData['status'] = this.status;
    exportData['statusTime'] = this.statusTime;
    exportData['emailedAudit'] = this.emailedAudit;

    //TODO promo exportData['discountCode'] = this.discountCode;

    exportData['gaClientID'] = this.gaClientID;
    exportData['payment'] = this.payment;

    exportData['items'] = [];
    for (const item of this.items) {
      //TODO extensive unit testing
      (exportData['items'] as unknown[]).push(item.export());
    }

    return cloneDeep(exportData); //This prevents any expectation that this data stays up to date (cause it wouldn't)
  }

  async parse(
    data: OrderResponseObject<DatabaseOrder<DatabaseItem>>,
    calledFrom: string,
    allDone?: Promise<void>,
  ): Promise<void> {
    this.freeze();

    if (data.reset) {
      this.reset();
    }

    if (data.order) {
      const order = data.order;

      if (typeof order.key !== 'undefined') {
        this.key = order.key;
      }

      if (typeof order.orderID !== 'undefined') {
        this.orderID = order.orderID;
      }

      if (typeof order.emailedAudit !== 'undefined') {
        if (order.emailedAudit === null) {
          order.emailedAudit = [];
        }
        for (const audit of order.emailedAudit) {
          audit.time = new Date(audit.time);
        }
        this.emailedAudit = order.emailedAudit;
      }

      if (typeof order.type !== 'undefined' && this.type !== order.type) {
        this.dataLayer.errors.reportError(
          new Error(`Database returned type (${order.type}) but object has (${this.type})`),
          undefined,
          true,
        );
      }

      if (typeof order.lastActive !== 'undefined') {
        this.lastActive = order.lastActive;
      }

      if (typeof order.contactInfo !== 'undefined') {
        if (order.contactInfo) {
          Object.assign(this.contactInfo, order.contactInfo);
        } else {
          clear(this.contactInfo);
        }
      }
      this.contactInfo.invalid = this.invalid;

      if (typeof order.deliveryInfo !== 'undefined') {
        if (order.deliveryInfo) {
          Object.assign(this.deliveryInfo, order.deliveryInfo);
        } else {
          clear(this.deliveryInfo);
        }
      }
      this.deliveryInfo.invalid = this.invalid;
      this.deliveryInfo.country = this.deliveryInfo.country || 'Canada';

      if (typeof order.additionalInformation !== 'undefined') {
        this.additionalInformation = order.additionalInformation;
      }

      if (typeof order.status !== 'undefined') {
        this.status = order.status;
      }
      if (typeof order.statusTime !== 'undefined') {
        this.statusTime = order.statusTime;
      }
      //if (typeof order.discountCode !== 'undefined') {
      //TODO promo this.discountCode = order.discountCode;

      if (typeof order.gaClientID !== 'undefined') {
        this.gaClientID = order.gaClientID;
      }

      if (typeof order.payment !== 'undefined') {
        this.payment = order.payment;
      }

      if (typeof order.items !== 'undefined') {
        for (let i = 0; i < order.items.length; ) {
          //TODO extensive unit testing
          const newItem = order.items[i];

          let live: T; //TODO this code removes and re-adds things could be better

          if (this.items.length > i) {
            const oldItem = this.items[i];
            if (oldItem.itemID === newItem.itemID) {
              oldItem.refreshed(newItem);
            } else if (typeof this.itemsByKey[newItem.key!] === 'undefined') {
              live = this.newOrderItem();
              this.items.splice(i, 0, live);
              this.itemsByKey[newItem.key!] = live;
              live.refreshed(newItem);
            } else {
              this._removeItem(oldItem);
              continue;
            }
          } else {
            live = this.newOrderItem();
            this.items.push(live);
            this.itemsByKey[newItem.key!] = live;
            live.refreshed(newItem);
          }
          i++;
        }

        //This removes any trailing non matching items
        // TODO i don't know if this works
        const leftovers = this.items.splice(order.items.length);
        for (const item of leftovers) {
          this._removeItem(item);
        }
      }

      this.unfreeze();
    }

    if (typeof this.key === 'undefined') {
      throw new Error('Key must be set for each order');
    }
    if (typeof this.type === 'undefined') {
      throw new Error('Key must be set for each order');
    }

    const done = () => {
      if (data.outofdate) {
        this.wasOutOfDateSubject.next(calledFrom);
      }

      this.updatedSubject.next(calledFrom);
    };

    if (allDone) {
      allDone.then(() => {
        done();
      });
    } else {
      done();
    }
  }

  async setAsQuote(): Promise<OrderResponseObject<DatabaseOrder>> {
    const data = await this.dataLayer.order.setAsQuote(this, ProcessRequests.Now);
    await this.parse(data, 'order.setAsQuote');
    return data;
  }

  async load(): Promise<OrderResponseObject<DatabaseOrder>> {
    const data = await this.dataLayer.order.load(this, ProcessRequests.Now);
    await this.parse(data, 'order.load');
    return data;
  }

  fullRefresh(): void {
    this.wasOutOfDateSubject.next('Full Refresh'); //TODO bad name
  }

  reset(): void {
    this.orderID = undefined!;
    this.ticket = undefined!;

    this.lastActive = 0;

    this.isPossessed = false;

    clear(this.invalid);

    clear(this.contactInfo);
    this.contactInfo.invalid = this.invalid;

    clear(this.deliveryInfo);
    this.deliveryInfo.country = 'Canada';
    this.deliveryInfo.invalid = this.invalid;

    this.additionalInformation = undefined;
    this.gaClientID = undefined;
    //this.type = null; No cause this doesn't change
    clear(this.items);

    this.status = OrderStatus.NotUsed;
    this.statusTime = new Date().getTime();

    this.emailedAudit = [];

    this.subtotal = undefined;
    this.saleTotal = undefined;
    this.totalCost = undefined;
    this.totalTax = undefined;

    this.shippingTime = undefined;

    this.hasCasePack = undefined;
    this.hasFragile = undefined;
    this.USAUnderstood = undefined;
    this.isEstimated = true;

    clear(this.discounts);

    /*TODO promo this.discountCode = undefined;

    this.discount = null;
    this.volumeDiscount = null;
    this.volumeNextDiscount = null;*/
    this.totalDiscount = undefined;
    this.discountedSubTotal = undefined;

    this.totalFreight = undefined;
    this.freightTaxRate = undefined;
    this.freightTaxName = undefined;

    this.payment = null;

    this.forceTBD = false;

    clear(this.taxSums);

    clear(this.itemsByKey);

    clear(this.pendingSaveDeferred);
    clear(this.pendingSaveData);
    this.saving = false;

    this.frozen = false;
    this.calcing = false;

    clear(this.pendingSaveItemDeferred);
    clear(this.pendingSaveItemData);
    clear(this.pendingSaveItemObjects);
    this.savingItem = false;

    this.wasResetSubject.next('');
  }

  protected wasResetSubject: Subject<string> = new Subject<string>();
  wasReset(): Subject<string> {
    return this.wasResetSubject;
  }

  protected wasOutOfDateSubject: Subject<string> = new Subject<string>();
  wasOutOfDate(): Subject<string> {
    return this.wasOutOfDateSubject;
  }

  protected updatedSubject: Subject<string> = new Subject<string>();
  updated(): Subject<string> {
    return this.updatedSubject;
  }
  itemWasUpdated(): void {
    this.updatedSubject.next('Item Was Updated');
  }
  fakeChanged(): void {
    //TODO hack to make validation work for USA shipping
    this.updatedSubject.next('Fake Changed');
  }

  protected savingSubject: Subject<void> = new Subject();
  saved(): Subject<void> {
    return this.savingSubject;
  }

  async savePending(processRequests: ProcessRequests = ProcessRequests.Now): Promise<void> {
    return this.save(undefined, processRequests);
  }

  queueSave(fields: string | Array<string>): void {
    if (typeof fields === 'string') {
      fields = [fields];
    }

    for (const fieldName of fields) {
      const value = getProp(this, fieldName);
      if (typeof value === 'undefined') {
        throw new Error(`"${fieldName}" prop is undefined.`);
      }
      this.pendingSaveData[fieldName] = value;
    }
  }

  protected pendingSaveDeferred: Array<Deferred<void>> = [];
  protected pendingSaveData: { [key: string]: unknown } = {};
  protected saving: boolean = false;
  async save(fields?: string | Array<string>, processRequests: ProcessRequests = ProcessRequests.Now): Promise<void> {
    const deferred = new Deferred<void>();

    if (fields) {
      this.queueSave(fields);
    }

    this.pendingSaveDeferred.push(deferred);

    if (!this.saving) {
      //TODO this all doesn't work ... lastUpdated needs to be sync'd across all cart saves
      this.saving = true;

      const saveDeferred = this.pendingSaveDeferred;
      this.pendingSaveDeferred = [];

      const saveData = this.pendingSaveData;
      this.pendingSaveData = {};

      // TODO this should do something
      // const failure = () => {
      //   this.saving = false;
      //   for (const def of saveDeferred) {
      //     def.reject();
      //   }
      // };

      if (Object.keys(saveData).length !== 0) {
        const result = await this.dataLayer.order.save(this, saveData, processRequests);

        await this.parse(result, 'order.save');
      }

      this.saving = false;
      for (const def of saveDeferred) {
        def.resolve();
      }

      this.savingSubject.next();

      if (Object.keys(this.pendingSaveData).length > 0) {
        this.savePending();
      }
    }

    if (processRequests) {
      this.dataLayer.processRequests();
    }

    return deferred.promise;
  }

  protected getDiscountableSubtotals(): DiscountSubtotals {
    this.hasCasePack = false;
    this.hasFragile = false;

    let discountableSubtotal: Big = new Big(0);
    let subtotal: Big = new Big(0);

    for (const item of this.items) {
      item.calculate();

      if (item.isFragile) {
        this.hasFragile = true;
      }

      let discounted: boolean = item.isDiscountable;

      if (item.total !== null) {
        if (item.onsale && item.onsale.gt(0)) {
          discounted = false; //This is because being on sale prevents discounts
        }

        if (discounted) {
          discountableSubtotal = discountableSubtotal.plus(item.total);
        }
        subtotal = subtotal.plus(item.total);
      } else if (item.itemID && item.quantity && item.cost === null) {
        this.hasCasePack = true;
      }
    }

    return {
      [DiscountTarget['All Products']]: subtotal,
      [DiscountTarget['Discountable Products']]: discountableSubtotal,
    };
  }

  protected async getDiscounts(discountSubtotals: DiscountSubtotals): Promise<void> {
    const allPromises: Promise<unknown>[] = [];

    /*TODO promo if (this.discountCode) {
      allPromises.push(
        this.DiscountsService.getCode(this.discountCode).then((discount: APIDiscount | null) => {
          this.discount = discount;

          if (this.discount && this.discount.target) {
            let appliableSubtotal = discountableSubtotal;
            if (this.discount.target !== DiscountTarget['Discountable Products']) {
              appliableSubtotal = this.getDiscountableSubtotal(this.discount.target);
            }

            if (appliableSubtotal.gte(this.discount.min)) {
              this.discount.active = true;
            }
          }
        })
      );
      this.APIService.processRequests();
    } else {
      this.discount = null;
    }*/

    /*if (this.discount && this.discount.active) { //TODO promo
        let discount: AppliedDiscount = {
          name: 'Promo Discount',
          shortName: 'Promo',
          marker: this.discount.target === DiscountTarget['Discountable Products'] ? '*' : '',

          rate: new Big(this.discount.effectAmount).toFixed(0),
          amount: '',

          message: this.discount.message,
        };
        this.appliedDiscounts.push(discount);
        if (volumeDiscount) {
          volumeDiscount.overridden = discount;
          volumeDiscount.overriddenMessage = 'Volume discount is overriden by using a promo code';
        }
      }*/

    allPromises.push(
      this.dataLayer.discounts
        .getVolumeDiscount(discountSubtotals[DiscountTarget['Discountable Products']])
        .then((volumeDiscount: SQLVolumeDiscount | null) => {
          if (volumeDiscount) {
            this.discounts.push({
              name: 'Volume Discount',
              shortName: 'Volume',
              marker: '*',

              target: DiscountTarget['Discountable Products'],
              effect: DiscountEffect['Subtotal % Reduction'],

              amount: new Big(volumeDiscount.amount).div(100),
              rate: new Big(volumeDiscount.amount).toFixed(0) + '%',
              total: new Big(0),

              message: 'Volume discount only applies to products marked with a *',

              active: true,
            });
          }
        }),
    );

    /*TODO volume discount next allPromises.push(
      this.DiscountsService.getVolumeDiscount(discountableSubtotal, true).then((volumeDiscount) => {
        this.volumeNextDiscount = volumeDiscount;
      })
    );*/

    this.dataLayer.processRequests();

    await Promise.all(allPromises);
  }

  protected calcDiscount(discountSubtotals: DiscountSubtotals): Big {
    let itemDiscount: Big = new Big(0);

    /*if (this.discount && this.discount.active) { //TODO promo
      applyDiscount = true;

      applied = this.discount.applied;

      if (this.discount.effect !== DiscountEffect['Free Freight']) {
        discountEffect = this.discount.effect;
        discountRate = new Big(this.discount.effectAmount);
        if (discountEffect === DiscountEffect['Subtotal % Reduction']) {
          discountRate = discountRate.div(100);
        }
      }
    } else */

    for (const discount of this.discounts) {
      if (discount.effect === DiscountEffect['Subtotal % Reduction'] && discount.target) {
        discount.total = discount.amount.times(discountSubtotals[discount.target]).round(2);
      } else if (discount.targetFunc) {
        discount.targetFunc(discount);
      }

      /* else if (discountEffect === DiscountEffect['Subtotal Flat Reduction']) { //TODO promo
        //TODO check that this is right (flat discount applied after tax)
        itemDiscount = discountRate;
      }*/
      //TODO promo other types of discounts

      if (discount.active && discount.total.gt(0)) {
        itemDiscount = itemDiscount.plus(discount.total);
      }
    }

    return itemDiscount;
  }

  protected suminate(): [Big, Big, Big] {
    let subtotal = new Big(0);
    let totalTax = new Big(0);
    let saleTotal = new Big(0);

    clear(this.taxSums);
    for (const item of this.items) {
      item.calculate();

      let discounted: boolean = item.isDiscountable;

      if (item.total !== null) {
        subtotal = subtotal.plus(item.total);

        if (item.onsale && item.onsale.gt(0)) {
          saleTotal = saleTotal.plus(item.price!.sub(item.onsale).times(item.quantity!));
          discounted = false; //This is because being on sale prevents discounts
        }

        let itemDiscount = new Big(0);
        for (const discount of this.discounts) {
          if (
            discount.effect === DiscountEffect['Subtotal % Reduction'] &&
            (discounted || discount.target === DiscountTarget['All Products'])
          ) {
            itemDiscount = itemDiscount.plus(item.total.times(discount.amount));
          } else if (discount.effectFunc) {
            itemDiscount = itemDiscount.plus(discount.effectFunc(discount, item));
          }
        }

        if (this.locationID && item.taxes && item.taxes[this.locationID]) {
          for (const tax of item.taxes[this.locationID]) {
            if (item.total) {
              tax.value = item.total.minus(itemDiscount).times(tax.taxAmount).div(100);

              if (typeof this.taxSums[tax.taxName] === 'undefined') {
                this.taxSums[tax.taxName] = new Big(0);
              }
              this.taxSums[tax.taxName] = this.taxSums[tax.taxName].plus(tax.value);
            } else {
              delete tax.value;
            }
          }
        }
      }
    }

    for (const taxName in this.taxSums) {
      if (Object.prototype.hasOwnProperty.call(this.taxSums, taxName)) {
        this.taxSums[taxName] = this.taxSums[taxName].round(2);
        totalTax = totalTax.plus(this.taxSums[taxName]);
      }
    }

    return [subtotal, totalTax, saleTotal];
  }

  protected frozen: boolean = false;
  freeze() {
    this.frozen = true;
  }
  unfreeze() {
    this.frozen = false;
  }

  protected calcSubject: Subject<void> = new Subject();
  calculated(): Subject<void> {
    return this.calcSubject;
  }

  protected async preCalc(): Promise<void> {
    return;
  }
  protected async calcFreight(subtotal: Big, itemDiscount: Big, saleTotal: Big): Promise<void> {
    return;
  }
  protected async calcLocation(): Promise<void> {
    const allPromises: Promise<unknown>[] = [];

    let province = this.deliveryInfo.province;
    let country = this.deliveryInfo.country;

    if (this.deliveryInfo.method === DeliveryMethod.Pickup) {
      province = 'BC';
      country = 'Canada';
    }

    if (country && province) {
      allPromises.push(
        this.dataLayer.config.lookupLocation(province, country).then((location: RealLocation | null) => {
          this.locationID = location ? location.locationID : null;
        }),
      ); //TODO handle error i think ?

      this.dataLayer.processRequests();
    }

    await Promise.all(allPromises); //TODO find a way to not block here
  }

  protected async postCalc(): Promise<void> {
    if (this.forceTBD) {
      this.isEstimated = true;

      this.subtotal = null;
      this.totalCost = null;
      delete this.totalDiscount;
      delete this.discountedSubTotal;
      this.totalTax = null;
      this.saleTotal = null;
      clear(this.taxSums);
      clear(this.discounts);
      this.hasCasePack = false;

      delete this.totalFreight;
    }

    return;
  }

  protected calcing: boolean = false;
  async calculate(): Promise<void> {
    if (this.frozen) {
      return;
    }

    if (this.calcing) {
      const deferred = new Deferred<void>();
      const sub = this.calcSubject.subscribe(() => {
        deferred.resolve();
        sub.unsubscribe();
      });
      await deferred.promise;
      return;
    } else {
      this.calcing = true;
    }

    const calcStart = new Date().getTime();

    delete this.subtotal;
    delete this.totalCost;
    delete this.totalDiscount;
    delete this.discountedSubTotal;
    delete this.totalTax;
    delete this.saleTotal;
    delete this.shippingTime;

    delete this.totalFreight;
    delete this.freightTaxRate;
    delete this.freightTaxName;
    clear(this.taxSums);
    clear(this.discounts);

    await this.preCalc();

    await this.calcLocation();

    const discountSubtotals: DiscountSubtotals = this.getDiscountableSubtotals();

    if (this.hasCasePack) {
      const [subtotal] = this.suminate();
      this.subtotal = subtotal.round(2);
    } else {
      await this.getDiscounts(discountSubtotals);

      const [subtotal, itemsTax, saleTotal] = this.suminate();

      const itemDiscount = this.calcDiscount(discountSubtotals);

      await this.calcFreight(subtotal, itemDiscount, saleTotal);

      this.subtotal = subtotal.round(2);

      if (!this.isWholesale) {
        this.saleTotal = saleTotal.round(2);
      }

      this.totalDiscount = itemDiscount.round(2);
      this.discountedSubTotal = subtotal.minus(this.totalDiscount); //Intentionally not rounded

      let otherAmounts = new Big(0);
      if (this.totalFreight) {
        otherAmounts = otherAmounts.plus(this.totalFreight);
      }

      this.totalTax = itemsTax;
      if (this.freightTaxRate && this.totalFreight) {
        const freightTax = (this.totalFreight as Big).times(this.freightTaxRate).round(2);
        this.totalTax = this.totalTax.plus(freightTax);

        if (this.freightTaxName) {
          if (typeof this.taxSums[this.freightTaxName] === 'undefined') {
            this.taxSums[this.freightTaxName] = new Big(0);
          }
          this.taxSums[this.freightTaxName] = this.taxSums[this.freightTaxName].plus(freightTax);
        }
      }

      this.totalTax = this.totalTax.round(2);
      this.totalCost = this.discountedSubTotal.plus(this.totalTax).plus(otherAmounts).round(2);

      if (this.deliveryInfo.method === DeliveryMethod.Ship && this.deliveryInfo.country === 'USA') {
        this.totalTax = null;
        this.totalCost = null;
        this.taxSums = {};
      }
    }

    this.updateEstimation();

    await this.postCalc();

    this.calcing = false;
    this.calcSubject.next();

    window.console && console.debug('Calc Time (' + this.type + '): ' + (new Date().getTime() - calcStart));
  }

  updateEstimation(): void {
    if (
      !this.hasCasePack &&
      (this.deliveryInfo.method === DeliveryMethod.Pickup ||
        (this.deliveryInfo.method === DeliveryMethod.Ship &&
          this.deliveryInfo.country === 'Canada' &&
          this.deliveryInfo.province))
    ) {
      this.isEstimated = false;
    } else {
      this.isEstimated = true;
    }
  }

  async removeItem(item: T, processRequests: ProcessRequests = ProcessRequests.Now): Promise<void> {
    const result = await this.dataLayer.order.removeItem(this, item, processRequests);

    await this.parse(result, 'order.removeItem');

    if (result.outcome === 'Removed' && !result.outofdate) {
      await this._removeItem(item);
      this.updatedSubject.next('order.removeItem'); //TODO makes two calls
    }
  }

  addOrGetItem(item: T): T {
    if (typeof this.itemsByKey[item.key!] === 'undefined') {
      this.itemsByKey[item.key!] = item;
    }
    return this.itemsByKey[item.key!];
  }

  _addItem(item: T, index: number | null = null): Promise<void> {
    this.itemsByKey[item.key!] = item;

    if (index === null) {
      this.items.push(item);
    } else {
      this.items.splice(index, 0, item);
    }

    return this.calculate();
  }

  _removeItem(item: T): Promise<void> {
    const index = this.items.indexOf(item);

    if (index !== -1) {
      this.items.splice(index, 1);
    }
    delete this.itemsByKey[item.key!];

    return this.calculate();
  }

  private savingItemSubject: Subject<void> = new Subject();
  savedItem(): Subject<void> {
    return this.savingItemSubject;
  }

  protected async saveItemPending(): Promise<void> {
    const item = this.pendingSaveItemObjects.shift();
    if (item) {
      return this.saveItem(item, undefined, ProcessRequests.Now);
    }
  }

  protected pendingSaveItemDeferred: { [key: string]: Array<Deferred<void>> } = {};
  protected pendingSaveItemData: { [key: string]: { [key: string]: any } } = {}; //TODO any
  protected pendingSaveItemObjects: Array<T> = [];
  protected savingItem: boolean = false;
  async saveItem(
    item: T,
    fields?: string | Array<string>,
    processRequests: ProcessRequests = ProcessRequests.Now,
  ): Promise<void> {
    const deferred = new Deferred<void>();

    //Causing the order to save any pending in the same request
    this.save(undefined, ProcessRequests.Pool);

    if (item.key === null) {
      throw new Error('Item must have a key to save');
    }

    if (typeof fields !== 'undefined') {
      if (typeof fields === 'string') {
        fields = [fields];
      }

      if (typeof this.pendingSaveItemData[item.key] === 'undefined') {
        this.pendingSaveItemDeferred[item.key] = [deferred];
        this.pendingSaveItemData[item.key] = {};
      } else {
        this.pendingSaveItemDeferred[item.key].push(deferred);
      }

      for (const fieldName of fields) {
        this.pendingSaveItemData[item.key][fieldName] = item[fieldName as keyof BaseOrderItem];
      }
    }

    if (this.savingItem) {
      //TODO look into why this is one at a time
      this.pendingSaveItemObjects.push(item);
    } else {
      this.savingItem = true;

      const saveItemDeferred = this.pendingSaveItemDeferred[item.key];
      delete this.pendingSaveItemDeferred[item.key];

      const saveItemData = this.pendingSaveItemData[item.key];
      delete this.pendingSaveItemData[item.key];

      // TODO this should do something
      // const failure = () => {
      //   this.savingItem = false;
      //   for (const def of saveItemDeferred) {
      //     def.reject('Order failed to save item');
      //   }
      // };

      if (Object.keys(saveItemData).length !== 0) {
        if (item.itemID) {
          saveItemData['itemID'] = item.itemID;
        }

        const result = await this.dataLayer.order.saveItem(this, item.key, saveItemData, processRequests);

        await this.parse(result, 'order.saveItem');

        await this.parseItemResult(result, item);
      }
      this.savingItem = false;
      for (const def of saveItemDeferred) {
        def.resolve();
      }

      this.savingItemSubject.next();

      if (this.pendingSaveItemObjects.length > 0) {
        this.saveItemPending();
      }
    }

    return deferred.promise;
  }

  protected async parseItemResult(result: OrderResponseObject<DatabaseOrder>, item?: T): Promise<void> {
    if (result.item && result.outcome === 'Success' && !result.outofdate) {
      if (typeof item === 'undefined') {
        item = this.newOrderItem();
      }

      if (item.key && item.key !== result.item.key) {
        this.dataLayer.errors.reportError(
          new Error('This code needs review if used'),
          'Not sure it properly cleans up',
          true,
        );
        delete this.itemsByKey[item.key];
      }

      //Use cached item if matched
      if (result.item && typeof this.itemsByKey[result.item.key] !== 'undefined') {
        item = this.itemsByKey[result.item.key];
      }

      item.refreshed(result.item);

      //Add item to cache if missing
      if (item.key && typeof this.itemsByKey[item.key] === 'undefined') {
        this.itemsByKey[item.key] = item as T;
      }

      //Adds item to list if missing
      if (item.key && this.items.indexOf(this.itemsByKey[item.key]) === -1) {
        //TODO this is a bug and should be rewritten so that ghost items don't exist ever
        //On ordersheets its possible to be cached but not 'IN' the items list
        this.items.push(this.itemsByKey[item.key]);
      }

      await this.calculate();
    }
  }

  addItem<K extends DatabaseItem>(item: K, processRequests: ProcessRequests = ProcessRequests.Pool): Promise<T> {
    const deferred = new Deferred<T>();

    const tempItem = this.newOrderItem();
    tempItem.refreshed(item);

    this.saveItem(tempItem, Object.keys(item), processRequests)
      .then(() => {
        deferred.resolve(this.itemsByKey[item.key!]);
      })
      .catch((reason: unknown) => {
        deferred.reject(reason);
      });

    return deferred.promise;
  }

  protected abstract newOrderItem(): T;

  async addFile(uploadID: number, file: OrderFile, fileList: Array<OrderFile>): Promise<void> {
    const result = await this.dataLayer.order.addFile(this, uploadID, file, ProcessRequests.Now);

    await this.parse(result, 'order.addFile');

    if (result.file && result.outcome === 'File Added' && !result.outofdate) {
      Object.assign(file, result.file);
      fileList.push(file);
      this.updatedSubject.next('order.addFile'); //TODO double calls
    }
  }

  async removeFile(file: OrderFile, fileList: Array<OrderFile>): Promise<void> {
    const result = await this.dataLayer.order.removeFile(this, file, ProcessRequests.Now);

    await this.parse(result, 'order.removeFile');

    if (result.outcome === 'File Removed' && !result.outofdate) {
      fileList.splice(fileList.indexOf(file), 1);
      this.updatedSubject.next('order.removeFile'); //TODO double calls
    }
  }

  async advanceStatus(validationMode: string = 'normal'): Promise<string> {
    await this.calculate();

    const data: OrderAdvanceData = {
      subtotal: this.subtotal ? this.subtotal.toFixed(2) : null,
      totalTax: this.totalTax ? this.totalTax.toFixed(2) : null,
      totalCost: this.totalCost ? this.totalCost.toFixed(2) : null,
      totalFreight: this.totalFreight ? this.totalFreight.toFixed(2) : null,
      validationMode: validationMode,
    };

    const result = await this.dataLayer.order.advanceStatus(this, data, true, this.isWholesale, ProcessRequests.Now);

    await this.parse(result, 'order.advanceStatus');

    //TODO change this stuff
    if (result.outcome === 'Finished' && result.ticket) {
      return result.ticket;
    } else if (result.outcome === 'Advanced') {
      return result.outcome;
    } else {
      if (result.outcome === 'Failure verifyValues') {
        throw new TypedError(
          'verifyValues',
          'Quantities / prices updated. Please check your cart before continuing. <br />' +
            'This may happen if you have this cart open in two places at once.',
        );
      } else if (result.outcome === 'Failure valid') {
        throw new TypedError('valid', 'Please confirm that everything is filled in correctly.');
      } else {
        throw new Error(`Problem confirming your order total. If the problem persists please contact us.`);
      }
    }
  }
}
