<!-- TODO title = 'Store locations and hours !-->
<div class="locationsPage pageContent">
  <h1>Locations and Store Hours</h1>

  <div class="burnabyStore">
    <div class="heading2">
      <span class="line1">Burnaby: retail store, factory and offices</span>
      <span class="separator">~</span>
      <span class="line2">location of the famous chocolate river!</span>
    </div>
    <!--TODO click='gaClicked("map", "burnaby")' -->
    <a
      class="mapLink"
      target="_blank"
      href="https://www.google.ca/maps/place/Charlie's+Chocolate+Factory+Ltd/@49.253633,-123.021019,16.35z/data=!4m2!3m1!1s0x548676dbba6831d3:0x9c734eaced2b1c84!6m1!1e1?hl=en"
    >
      <img
        src="/images/static/location-with-map-icon.png"
        alt="map icon"
        width="50"
        height="50"
      />
    </a>

    <div
      class="holidayHours"
      *ngIf="holidayHours"
    >
      <a [routerLink]="['/Holiday-Hours']">
        <img src="/images/static/holiday-hours-black.png" />
        <br />
        Holiday Hours
      </a>
    </div>

    <div class="storeImage">
      <img
        class="burnaby"
        src="/images/static/burnabystoreNew.jpg"
        alt="Burnaby Store"
      />
    </div>

    <p>
      <b
        >3746 Canada Way<br />
        Burnaby</b
      >, B.C. V5G 1G4
    </p>
    <p>
      <!--TODO click='gaClicked("phone", "6044378221")' -->
      Phone: <a href="tel:6044378221">(604) 437-8221</a><br />
    </p>
    <br />
    <strong>Store Hours:</strong>
    <!--<p class='covidMessage'>Due to COVID-19, we are working with limited hours. Please click on "Holiday Hours" for the current schedule.</p>!-->
    <p>
      Tues-Fri: 8:00 a.m. - 4:30 p.m.<br />
      Sat: 9:00 a.m. - 5:00 p.m.
    </p>
    <p>
      <em>Closed Sundays &amp; Mondays</em>.<br />
      <em>Closed all holidays except Good Friday.</em>
    </p>
    <br />
    <strong>Office Hours:</strong>
    <!--<p class='covidMessage'>Due to COVID-19, we are working with limited hours. Please click on "Holiday Hours" for the current schedule.</p>!-->
    <p>Mon-Fri: 8:00 a.m. - 4:30 p.m.</p>
    <p>
      <em>Closed Weekends</em>.<br />
      <em>Closed all holidays except Good Friday.</em>
    </p>
  </div>

  <div class="pocoStore">
    <div>
      <div class="heading2">Port Coquitlam: retail store and warehouse</div>
      <!--TODO click='gaClicked("map", "poco")' -->
      <a
        class="mapLink"
        target="_blank"
        href="https://www.google.ca/maps/place/Charlie's+Chocolate+Factory/@49.2540503,-122.7670132,16.1z/data=!4m5!1m2!2m1!1sCharlie's+Chocolate+Factory+Ltd,+McLean+Avenue,+Port+Coquitlam,+BC!3m1!1s0x0000000000000000:0xcb26513a3f10d5bb?hl=en"
      >
        <img
          src="/images/static/location-with-map-icon.png"
          alt="map icon"
          width="50"
          height="50"
        />
      </a>

      <div
        class="holidayHours"
        *ngIf="holidayHours"
      >
        <a [routerLink]="['/Holiday-Hours']">
          <img src="/images/static/holiday-hours-black.png" />
          <br />
          Holiday Hours
        </a>
      </div>

      <div class="storeImage">
        <img
          class="poco"
          src="/images/static/poco-store.jpg"
          alt="Poco store"
        />
      </div>

      <p>
        <b
          >#2 - 1770 McLean Avenue<br />
          Port Coquitlam</b
        >, B.C. V3C 4K8
      </p>
      <!--TODO click='gaClicked("phone", "6049413811")' -->
      <p>Phone: <a href="tel:6049413811">(604) 941-3811</a></p>
      <p><strong>Hours:</strong></p>
      <!--<p class='covidMessage'>Due to COVID-19, we are working with limited hours. Please click on "Holiday Hours" for the current schedule.</p>!-->
      <p>Mon-Fri: 9:00 a.m. - 5:00 p.m.</p>
      <p>
        <em>
          Closed Saturdays &amp; Sundays.<br />
          Closed all holidays, except Good Friday.<br />
        </em>
      </p>
    </div>
  </div>
</div>
